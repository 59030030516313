import Axios from "../axios";

export const GetCarrierData = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-data?carrier_id=${data.carrier_id}&carrier_version_id=${data.carrier_version_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierVersionListData = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-version-list?carrier_id=${data}`,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierLevelColorList = async (onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/get-carrier-level-color-list`,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const CheckCarrierExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/check-carrier-existence?carrier_name=${data?.carrier_name}&carrier_type_flag=${data?.carrier_type_flag}`,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
};

export const GetCarrierDetailsField = async (onSuccess, onError) => {
    try {
        const response = await Axios.get("/carrier/get-carrier-detail-fields-data",
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const CreateCarrierAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post("/carrier/create-carrier", data,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const UpdateCarrierAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put("/carrier/update-carrier", data,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const CarrierLevelDependencies = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/carrier/level/${data?.levelId}/dependencies?carrier_id=${data.carrier_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                    // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}
import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Divider,
    Grid,
    InputBase,
    Typography,
    Avatar,
    IconButton,
    Popover
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import styled from '@emotion/styled';
import { Columns, Search as SearchIcon } from "react-feather";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { darken } from "polished";
import '../../css/Agency/Agency.css'
import { getAgencyDataList } from '../../../utils/api/AgencyDetailsAPI';
import { flexbox, maxWidth, minWidth, width } from '@mui/system';
import moment from 'moment'
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import BackIcon from "../../../assets/back-icon.png";
import AgencyLogo from './AgencyLogo';
import GlobalDialog from '../../../components/global/GlobalDialog';

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  ,
  .MuiDataGrid-iconSeparator
  {
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;

const Agency = () => {

    const navigate = useNavigate()
    const pathname = window.location.pathname
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const { agencyDetails } = useContext(agencyContext).state
    const {
        setAgencyDetails,
        setFileSelected,
        setAgencyFavIcon,
        setFileUploaded,
        setFavIconFileUploaded
    } = useContext(agencyContext)

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)

    const [agencyRowDetails, setAgencyRowDetails] = useState([])
    const [searchRowDetails, setSearchRowDetails] = useState([])
    const [showLogoImage, setShowLogoImage] = useState(false)
    const [logoInfo, setLogoInfo] = useState({})



    const columns = [
        {
            field: 'agency_id',
            headerName: 'ID',
            width: "40",
            hide: true,
            sortable: false,
        },
        {
            field: 'agency_logo_url',
            headerName: 'Logo',
            minWidth: 60,
            maxWidth: 60,
            headerClassName: "ag-datagrid-logo-header-cell",
            sortable: false,
            description: "Agency Logo",
            renderCell: (params) => {
                // console.log("params->", params);
                let agencyLogo = params.row.agency_logo_url
                const agency_id = params.row.agency_id
                return (
                    <>
                        <Avatar
                            src={agencyLogo ? agencyLogo : ""} sx={{ width: 32, height: 32 }}
                        // Temporary Commented
                        // onClick={(event) => {
                        //     event.stopPropagation()
                        //     console.log("params.row", params.row)
                        //     setShowLogoImage(true)
                        //     setLogoInfo({
                        //         agency: params.row.agency_name,
                        //         image: agencyLogo,
                        //         id: params.row.agency_id,
                        //     })
                        // }}
                        />
                    </>
                )
            },
            cellClassName: (params) => {
                let firstColStyles = params.field
                if (firstColStyles === "agency_logo_url") {
                    return "ag-datagrid-logo-body-cell"
                }

            }
        },
        {
            field: 'agency_name',
            headerName: 'Name',
            minWidth: 160,
            maxWidth: 160,
            description: "Agency Name",
            renderCell: (params) => {
                return params?.row?.agency_name
            }
        },
        {
            field: 'agent_count',
            headerName: '# Agents',
            minWidth: 102,
            maxWidth: 102,
            align: "center",
            headerAlign: "center",
            description: "Agent Count",
            valueGetter: (params) => {
                return params?.row?.agency_info.agent_count
            }
        },
        {
            field: 'admin_count',
            headerName: '# Admin',
            minWidth: 102,
            maxWidth: 102,
            align: "center",
            headerAlign: "center",
            description: "Admin Count",
            valueGetter: (params) => {
                return params?.row?.agency_info.admin_count
            }

        },
        {
            field: 'life_carrier_count',
            headerName: '# Life Carriers',
            minWidth: 136,
            maxWidth: 136,
            align: "center",
            headerAlign: "center",
            description: "Life Carrier Count",
            valueGetter: (params) => {
                return params?.row?.agency_info?.life_carrier_count
            }
        },
        {
            field: 'annuity_carrier_count',
            headerName: '# Annuity Carriers',
            minWidth: 162,
            maxWidth: 162,
            align: "center",
            headerAlign: "center",
            description: "Annuity Carriers Count",
            valueGetter: (params) => {
                return params?.row?.agency_info?.annuity_carrier_count
            }
        },
        {
            field: 'agency_email_address',
            headerName: 'Email',
            minWidth: 290,
            maxWidth: 290,
            description: "Email",
            renderCell: (params) => {
                return params?.row?.agency_email_address
            }
        },
        {
            field: 'agency_mag_url',
            headerName: 'MAG URL',
            minWidth: 300,
            maxWidth: 300,
            description: "MAG URL",
            renderCell: (params) => {
                return params?.row?.agency_mag_url
            }
        },
        {
            field: 'agnecy_status_name',
            headerName: 'Status',
            minWidth: 170,
            maxWidth: 170,
            align: "center",
            headerAlign: "center",
            description: "White Label Status",
            renderCell: (params) => {
                let agentStatus = params.row.agnecy_status_name;
                return (
                    <>
                        <div
                            style={{
                                border: agentStatus == "Active" ? '1px solid #1ACC2C' : agentStatus == "Registered" ? "1px solid #CCB01A" : "000",
                                color: agentStatus == "Active" ? '#1ACC2C' : agentStatus == "Registered" ? "#CCB01A" : "#000",
                                background: agentStatus == "Active" ? '#1ACC2C1A' : agentStatus == "Registered" ? "#CCB01A1A" : "#000",
                                fontSize: "13px",
                                fontWeight: "400",
                                borderRadius: "5px",
                                padding: "3px 10px",
                                textAlign: "center",
                                width: "80px"
                            }}
                        >
                            {agentStatus == "Active" ? "Active" : agentStatus == "Registered" && "Inactive"}
                        </div>

                    </>
                )

            }
        },
        {
            field: 'created_at_timestamp',
            headerName: 'Created Date',
            minWidth: 134,
            maxWidth: 134,
            // sortable: false,
            description: "Created Date",
            renderCell: (params) => {
                return moment(params?.row?.created_at_timestamp).format('DD/MM/YYYY')
            }
        },
        // {
        //     field: 'actions',
        //     type: "actions",
        //     headerName: '',
        //     width: 80,
        //     getActions: (params) => [
        //         <GridActionsCellItem
        //             showInMenu
        //             label="Edit"
        //             onClick={() => { navigate(`/agency/view_agency?agency_id=${params.row.agency_id}`) }}
        //         />,
        //     ]
        // }
    ]

    useEffect(() => {
        FlagApiLoader(true)
        getAgencyDataList({},
            (res) => {
                if (res?.data?.status) {
                    console.log("response of get agency list", res.data.data);
                    setAgencyRowDetails(res?.data?.data)
                    setSearchRowDetails(res?.data?.data)
                    FlagApiLoader(false)
                }
                else {
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res.data.error.message,
                    });
                }
            }, (err) => {
                console.log("response of get agency list", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: err.data.error.message,
                });
                FlagApiLoader(false)
            })
    }, [])

    const handleSearchBar = (query) => {
        const filteredRows = agencyRowDetails.filter((item) =>
            item.agency_name.toLowerCase().includes(query.toLowerCase()) ||
            item.agency_email_address.toLowerCase().includes(query.toLowerCase()) ||
            item.agency_mag_url.toLowerCase().includes(query.toLowerCase())
        )
        setSearchRowDetails(filteredRows)
    }

    return (
        <>
            {/*********SHOW LOGO COMPONENT TEMPORARY COMMENTED *****/}
            {/* {
            showLogoImage &&
            <GlobalDialog
                open={true}
                onClose={() => setShowLogoImage(false)}
                data={<AgencyLogo
                    logoInfo={logoInfo}
                />}
            />
        } */}
            <Grid container>
                <Grid item xs={12} >
                    <Grid container sx={{ gap: "20px " }}>

                        {/* HEADER */}
                        <Grid item xs={12} sx={{ marginTop: "21px" }}>
                            <Grid container sx={{ alignItems: "center", padding: "0px 23px" }}>
                                <Grid item xs={6}>
                                    <Grid container>
                                        {pathname == "/agency" ?
                                            (<>
                                                <Typography variant='span' className='ag-header-main-typo'>
                                                    Agency
                                                </Typography>
                                            </>) : (agencyId || pathname == "agency/add_agency" &&
                                                <>
                                                    <IconButton
                                                        sx={{ padding: "0px 10px 0px 0px" }}
                                                        onClick={() => navigate("/carriers")}
                                                    >
                                                        <img
                                                            src={BackIcon}
                                                            alt="Logo"
                                                        />
                                                    </IconButton>
                                                    <Typography variant='span' className='ag-header-main-typo'>
                                                        Agency
                                                    </Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                {/* HEADER RIGHT CONTENT */}
                                <Grid item xs={6}>
                                    <Grid container sx={{ justifyContent: "end", gap: "15px", alignItems: "center" }}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                                                onClick={() => {
                                                    setAgencyDetails({
                                                        ...agencyDetails,
                                                        agency_name: "",
                                                        agency_email_address: "",
                                                        agency_mag_url: "",
                                                        agency_custom_url: null,
                                                        agency_user_data: [
                                                            {
                                                                index: 0,
                                                                id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                                                user_id: "",
                                                                master_user_id: "",
                                                                username: "",
                                                                email_address: "",
                                                                user_role_flag: true,
                                                            },
                                                        ],
                                                        agency_logo_gcs_id: null,
                                                        agency_favicon_gcs_id: null
                                                    })
                                                    setFileSelected(null)
                                                    setFileUploaded(false)
                                                    setAgencyFavIcon(null)
                                                    setFavIconFileUploaded(false)
                                                    navigate('/agency/add_agency')
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                {/* <Add /> */}
                                                New Agency
                                            </Button>
                                            {/* <Button
                                                variant='contained'
                                                size='large'
                                                fullWidth
                                                className='ag-add-btn'
                                                onClick={() => {
                                                    setAgencyDetails({
                                                        ...agencyDetails,
                                                        agency_name: "",
                                                        agency_email_address: "",
                                                        agency_mag_url: "",
                                                        agency_custom_url: null,
                                                        agency_user_data: [
                                                            {
                                                                index: 0,
                                                                id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                                                user_id: "",
                                                                master_user_id: "",
                                                                username: "",
                                                                email_address: "",
                                                                user_role_flag: true,
                                                            },
                                                        ],
                                                        agency_logo_gcs_id: null,
                                                        agency_favicon_gcs_id: null
                                                    })
                                                    setFileSelected(null)
                                                    setFileUploaded(false)
                                                    setAgencyFavIcon(null)
                                                    setFavIconFileUploaded(false)
                                                    navigate('/agency/add_agency')
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                New Agency
                                            </Button> */}
                                        </Grid>
                                        <Grid item>
                                            <Search>
                                                <SearchIconWrapper>
                                                    <SearchIcon />
                                                </SearchIconWrapper>
                                                <Input
                                                    placeholder={"Search"}
                                                    onChange={(e) => handleSearchBar(e.target.value)}
                                                />
                                            </Search>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* DIVIDER */}
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {/* TABLE */}
                        <Grid item xs={12}>
                            <Grid container className='ag-table-main-container'>
                                <Grid item xs={12} sx={{ height: "calc(76vh - 40px)", overflow: 'auto' }}>
                                    <CustomDataGrid
                                        className='ag-datagrid'
                                        rows={searchRowDetails}
                                        columns={columns}
                                        disableColumnMenu={true}
                                        rowSelection={false}
                                        // disableColumnFilter
                                        disableColumnSelector
                                        disableSelectionOnClick
                                        hideFooterPagination
                                        density="standard"
                                        getRowId={(row) => (row.agency_id)}
                                        onCellClick={(params) => { navigate(`/agency/view_agency?agency_id=${params.row.agency_id}`) }}
                                        experimentalFeatures={{ newEditingApi: true }}
                                        sx={{
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },

                                            '& .MuiDataGrid-footerContainer': {
                                                display: 'none'
                                            }
                                        }}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Agency
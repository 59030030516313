import createDataContext from "./createDataContext";

const AgencyReducer = (state, action) => {
    switch (action.type) {
        case "carrierListData": {
            return { ...state, carrierListData: action.payload }
        }
        case "agencyDetails": {
            return { ...state, agencyDetails: action.payload }
        }

        case "agencyCarrieData": {
            return { ...state, agencyCarrierData: action.payload }
        }
        case "fileSelectedData": {
            return { ...state, fileSelected: action.payload }
        }
        case "flagFileUploaded": {
            return { ...state, fileUploaded: action.payload }
        }
        case "favIconData": {
            return { ...state, agencyFavIcon: action.payload }
        }
        case "flagFavIconFileUploaded": {
            return { ...state, favIconFileUploaded: action.payload }
        }

    }
};

const setCarrierListData = (dispatch) => async (data) => {
    dispatch({
        type: "carrierListData",
        payload: data
    });
}


const setAgencyDetails = (dispatch) => async (data) => {
    dispatch({
        type: "agencyDetails",
        payload: data
    });
}

const setAgencyCarrieData = (dispatch) => async (data) => {
    dispatch({
        type: "agencyCarrieData",
        payload: data
    });
}

const setFileSelected = (dispatch) => async (data) => {
    dispatch({
        type: "fileSelectedData",
        payload: data
    });
}

const setFileUploaded = (dispatch) => async (data) => {
    dispatch({
        type: "flagFileUploaded",
        payload: data
    });
}

const setAgencyFavIcon = (dispatch) => async (data) => {
    dispatch({
        type: "favIconData",
        payload: data
    });
}

const setFavIconFileUploaded = (dispatch) => async (data) => {
    dispatch({
        type: "flagFavIconFileUploaded",
        payload: data
    });
}


export const { Provider, Context, state } = createDataContext(
    AgencyReducer,
    {
        setCarrierListData,
        setAgencyDetails,
        setAgencyCarrieData,
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded
    },
    {
        carrierListData: { life: [], annuity: [] },
        agencyDetails: {
            agency_name: "",
            agency_email_address: "",
            agency_mag_url: "",
            agency_custom_url: null,
            agency_user_data: [
                {
                    index: 0,
                    id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    user_id: "",
                    master_user_id: "",
                    username: "",
                    email_address: "",
                    user_role_flag: true,
                },
            ],
            agency_logo_gcs_id: null,
            agency_favicon_gcs_id: null,
        },
        agencyCarrierData: [], //already checked data,
        fileSelected: null,
        fileUploaded: false,
        agencyFavIcon: null,
        favIconFileUploaded: false,
    }
)
import Axios from "../axios";

export const UserMeApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.get("/auth/user-me");
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const SendOtpApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post(
      "/auth/send-user-authentication-code",
      data
    );
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const LoginApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/auth/login", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const LogOutApi = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/auth/logout", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const SwitchUserRole = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/auth/switch-user-role", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const GrantDelegateAccessForAdmin = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/user/active-delegate-access", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};
export const RevokeDelegateAccessForAdmin = async (onSuccess, onError) => {
  try {
    const response = await Axios.delete("/user/revoke-delegate-access");
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

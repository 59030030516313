import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "../../assets/file-upload-icon.png";
import FileIcon from "../../assets/pdf-icon.png";
import PropTypes from "prop-types";
import "../../pages/css/FileUpload.css";
import { DeleteResources, UploadResources } from "../../utils/api/TemplateApi";
import { Context as authContext } from "../../contexts/reducerContexts/authContext";


var FormData = require("form-data");

const FileUpload = (props) => {
  const [progressBar, setProgressBar] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const { UpdateSnackbarMessage } = useContext(authContext)
  const { setFlagFileUploadPopup } = props

  const removeFile = () => {
    let obj = {
      carrier_id: props?.carrierDetails?.carrier_id,
      carrier_resource_id: resourceData?.carrier_resource_id
    }
    DeleteResources(obj, (res) => {
      console.log("res", res)
      setProgressBar(false)
      if (props.flagCall == null) {
        props.setFlagCall(true)
      } else {
        props.setFlagCall(!props.flagCall)
      }
      setResourceData([])

    }, (err) => {
      console.log("err", err)
      setProgressBar(false)

    })
    // props.saveData();
  };

  const uploadHandler = (e) => {

    const file = e.target.files[0];
    if (!file) return;
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 30) {

      UpdateSnackbarMessage({
        status: "error",
        message: "File size exceeds 30 MB",
      });
      return;
    }
    setProgressBar(true)

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    formData.append("carrier_id", props?.carrierDetails?.carrier_id);

    UploadResources(formData, (res) => {
      console.log("upload file res", res)
      if (res?.data?.status) {
        setResourceData(res.data.data[0])
        setFlagFileUploadPopup(false)
        if (props.flagCall == null) {
          props.setFlagCall(true)
        } else {
          props.setFlagCall(!props.flagCall)
        }
        UpdateSnackbarMessage({
          status: "success",
          message: "Resource uploaded successfully",
        });
      } else {
        setFlagFileUploadPopup(false)
        console.log("response false of UploadResources api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
      setProgressBar(false)
    }, (err) => {
      console.log("upload file err", err)
      setFlagFileUploadPopup(false)
      // setProgressBar(false)
      UpdateSnackbarMessage({
        status: "error",
        message: "Something went wrong!",
      });

    })

  };

  var finalObj = [];
  return (
    <>
      <Grid container>

        {/* {resourceData?.carrier_resource_id ?
          <Grid item className="file-card ">
            <Grid container>
              <Grid item xs={12} textAlign="end" sx={{ padding: "5px 5px 0px 0px" }} >
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => { removeFile(resourceData?.carrier_resource_file_name) }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container flexDirection="column" rowSpacing={3}>
                  <Grid item xs={12} textAlign="center">
                    <Box component="img" src={FileIcon}></Box>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    {resourceData?.carrier_resource_file_name}
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography component="span" color="#7A7A7A">
                      {Math.round((resourceData?.carrier_resource_file_size / 1024 / 1024) * 100) / 100}MB
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          : */}
        <Grid item className="file-card ">
          <Grid container>
            <Grid item className="file-inputs">
              <input
                type="file"
                multiple
                className="file-inputs"
                accept="application/pdf"
                onChange={(e) => { uploadHandler(e) }}
              ></input>

              <button
                className="file-inputs"
                style={{
                  color: "#000000",
                  // display: "flex",
                  // flexDirection: "column",
                  // height: "100% !important",
                }}
              >
                <Grid container flexDirection="column" rowSpacing={3}>
                  <Grid item>
                    <Box component="img" className="FileUploadIcon" src={FileUploadIcon}></Box>
                  </Grid>
                  <Grid item>
                    Drag your files here or{" "}<span style={{ color: "#1565C0" }}> Browse </span>
                  </Grid>
                  <Grid item>
                    <Typography component="span" color="#7A7A7A">
                      Only PDF file supported. Max file size 30MB
                    </Typography>
                  </Grid>
                </Grid>
              </button>
            </Grid>
            <Grid item xs={12}>
              {progressBar && <LinearProgress />}
            </Grid>
          </Grid>
        </Grid>
        {/* } */}
      </Grid>
    </>
  );
};

export default FileUpload;

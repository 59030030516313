import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import { Add, Close } from "@mui/icons-material";
import {
  Divider as MuiDivider,
  Typography,
  Box,
  Grid,
  InputBase,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { DeleteTemplate, GetTemplateDataList } from "../../utils/api/TemplateApi";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import GlobalDialog from "../../components/global/GlobalDialog";
import deleteAlert from '../../assets/Delete-alert.png'
import "../css/template.css"
const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  ,
  .MuiDataGrid-iconSeparator
  {
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;

const CustomChip = styled(Chip)`
  width: 65px;
  height: 27px;
  border-radius: 10px;
  font-weight: 600;
  font-family: 'Nunito';
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'red',
    },
    colorSecondary: {
      backgroundColor: 'brown',
    },
  },
`;

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const CustomFormControl = styled(FormControlLabel)({
  "& .css-1ojsca0-MuiTypography-root": {
    fontFamily: "Nunito",
    fontWeight: "600",
    size: "15px"
  }
})

const Templates = () => {
  const navigate = useNavigate();
  const {
    state,
    UpdateSnackbarMessage,
    FlagApiLoader,
  } = useContext(AuthContext);
  const { setTemplateName, setFlagType } = useContext(globalCarrierContext)
  const { templateName } = useContext(globalCarrierContext).state
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [deleteTemplateData, setDeleteTemplateData] = useState([]);

  const [rows, setRows] = useState([]);
  const [globalRows, setGlobalRows] = useState([]);
  const [searchedString, setSearchedString] = useState("");
  const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);

  const [type, setType] = useState({
    flagLife: true,
    flagAnnuity: true,
  });

  console.log("templateName in list", templateName)
  useEffect(() => {
    FlagApiLoader(true);
    setTemplateName("")
    setFlagType(null)
    GetTemplateDataList(
      {},
      (res) => {
        if (res.data.status) {
          console.log("response true of GetTemplateDataList api", res);
          let data = res.data.data;
          setRows(data);
          setGlobalRows(data);
          FlagApiLoader(false);
        }
        else {
          console.log("response false of GetTemplateDataList api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        FlagApiLoader(false);
        console.log("error of GetTemplateDataList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
      }
    );
  }, []);


  useEffect(() => {
    if (rows?.length) {
      setNoDataMsgFlag(true)
    } else {
      setNoDataMsgFlag(false)
    }
  }, [rows])

  useEffect(() => {
    let temp = [...globalRows];
    temp = temp?.filter((obj) => obj?.template_name?.toLowerCase().indexOf(searchedString.toLowerCase()) > -1)
    if (type.flagLife == true && type.flagAnnuity == false) {
      temp = temp.filter((row) => row.carrier_type_flag == true);
      setRows(temp);
    } else if (type.flagLife == false && type.flagAnnuity == true) {
      temp = temp.filter((row) => row.carrier_type_flag == false);
      setRows(temp);
    } else {
      setRows(temp);
    }
  }, [type]);

  const handleSearch = (searchQuery) => {
    let tempFiltered = [];
    setSearchedString(searchQuery)

    if (!searchQuery) {
      if (type.flagLife == true && type.flagAnnuity == true) {
        setRows(globalRows);
      } else if (type.flagLife == true) {
        globalRows.map((element) => {
          if (element.carrier_type_flag == true) {
            tempFiltered.push(element);
          }
        });
        setRows(tempFiltered);
      } else if (type.flagAnnuity == true) {
        globalRows.map((element) => {
          if (element.carrier_type_flag == false) {
            tempFiltered.push(element);
          }
        });
        setRows(tempFiltered);
      }
    } else {
      if (type.flagLife == true && type.flagAnnuity == true) {
        tempFiltered = [...globalRows];
      } else if (type.flagLife == true) {
        globalRows.map((element) => {
          if (element.carrier_type_flag == true) {
            tempFiltered.push(element);
          }
        });
      } else if (type.flagAnnuity == true) {
        globalRows.map((element) => {
          if (element.carrier_type_flag == false) {
            tempFiltered.push(element);
          }
        });
      }

      tempFiltered = tempFiltered.filter(
        (nn) =>
          nn.template_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
      );
      setRows(tempFiltered);
    }
  };

  function Checkboxes() {
    const handleChange = (event, boxType) => {
      if (boxType == "life") {
        if (event.target.checked == false) {
          if (type.flagAnnuity == true) {
            setType((a) => {
              let tempData = { ...a };
              tempData.flagLife = event.target.checked;
              return tempData;
            });
          }
        } else {
          setType((a) => {
            console.log(a);
            let tempData = { ...a };
            tempData.flagLife = event.target.checked;
            return tempData;
          });
        }
      } else if (boxType == "annuity") {
        if (event.target.checked == false) {
          if (type.flagLife == true) {
            setType((a) => {
              let tempData = { ...a };
              tempData.flagAnnuity = event.target.checked;
              return tempData;
            });
          }
        } else {
          setType((a) => {
            let tempData = { ...a };
            tempData.flagAnnuity = event.target.checked;
            return tempData;
          });
        }
      }
    };

    const { flagLife, flagAnnuity } = type;

    return (
      <Grid container direction="row"
        sx={{
          "& .PrivateSwitchBase-root": {
            padding: " 0 5px 0 0 !important"
          }
        }}
        spacing={2}
      >
        <Grid item>
          <Typography
            variant="outlined"
            sx={{ color: "#7A7A7A", marginRight: "16px" }}
            gutterBottom
            display="inline"
          >
            Type
          </Typography>
        </Grid>

        <Grid item>
          <FormGroup>
            <CustomFormControl
              control={
                <Checkbox
                  checked={flagLife}
                  onChange={(event) => {
                    handleChange(event, "life");
                  }}
                  name="flagLife"
                />
              }
              label="Life"
            />
          </FormGroup>
        </Grid>

        <Grid item>
          <FormGroup sx={{ "& .MuiFormControlLabel-root ": { marginRight: { xs: "0", md: "16px" } } }}>
            <CustomFormControl
              control={
                <Checkbox
                  checked={flagAnnuity}
                  onChange={(event) => {
                    handleChange(event, "annuity");
                  }}
                  name="flagAnnuity"
                />
              }
              label="Annuity"
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      field: "template_name",
      headerName: "Name",
      flex: 2,
      headerClassName: "header_template_name",
      renderCell: (params) => {
        return (
          <Typography sx={{ marginLeft: { xs: "0", sm: "10px" } }} className="template-name" color="#1B6AC5"
            fontFamily="Segoe UI"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px">{params.value}</Typography>
        )
      }
    },
    {
      field: "carrier_type_flag",
      headerName: "Type",
      flex: 2,
      headerClassName: "header_type",
      renderCell: (params) =>
        params.row.carrier_type_flag ? (
          <CustomChip
            label="Life"
            size="small"
            sx={{ color: "#1B2DCF", borderColor: "#1B2DCF", cursor: "pointer" }}
            variant="outlined"
          />
        ) : (
          <CustomChip
            label="Annuity"
            size="small"

            variant="outlined"
            sx={{ color: "#2F970B", borderColor: "#2F970B", cursor: "pointer" }}
          />
        ),
    },
    {
      field: "template_carrier_count",
      flex: 0.5,
      headerName: "# Carriers",
      headerClassName: "header_count",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography marginLeft={{ xs: "0", sm: "20px" }}>{params.value}</Typography>
        )
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      headerClassName: "header_action",
      align: "right",
      width: 20,
      getActions: (params) => [
        // <GridActionsCellItem
        //   // icon={<DeleteIcon />}
        //   label="Delete"
        //   // onClick={}
        // />,
        <GridActionsCellItem
          // icon={<Security />}
          label="Delete"
          onClick={() => {
            console.log("paramRow::", params?.row)
            setDeleteTemplateData(params?.row)
            setFlagDeletePopup(true)
          }}
          showInMenu
        />,
      ],
    },
  ];

  function DataGridDemo() {
    return (
      <Box sx={{ height: { xs: "60vh", sm: "70vh", md: "65vh", lg: "69vh", xl: "82vh" }, width: "100%" }} className="template-table">
        <CustomDataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          getRowId={(row) => row?.template_id}
          experimentalFeatures={{ newEditingApi: true }}
          headerHeight={39}
          className="template_data_grid"
          onRowClick={(params) => {
            console.log("Printing params on row click", params);
            navigate(`view_template?id=${params.row.template_id}`);
          }}
          sx={{
            padding: { xs: "10px", sm: "20px" },
            "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle:first-child": {
              marginLeft: { xs: "0", sm: "10px" }
            },
          }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableSorting
          hideFooterPagination
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {noDataMsgFlag == false && state.apiLoader == false ? "No Data Found!" : ""}
              </Stack>
            ),
          }}
        />
      </Box>
    );
  }

  const deletePopup = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container justifyContent="center" className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={10} className="delete-mesg-item">
              {deleteTemplateData?.template_delete_message}
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagDeletePopup(false)
                        FlagApiLoader(true)
                        DeleteTemplate(deleteTemplateData?.template_id, (res) => {
                          if (res?.data?.status) {
                            console.log("response true of DeleteTemplate api", res);
                            let tempTemplateList = [...rows]
                            let tempGlobalTemplateList = [...globalRows]
                            tempTemplateList = tempTemplateList?.filter((nn) => nn.template_id != deleteTemplateData?.template_id)
                            tempGlobalTemplateList = tempGlobalTemplateList?.filter((nn) => nn.template_id != deleteTemplateData?.template_id)
                            setRows(tempTemplateList)
                            setGlobalRows(tempGlobalTemplateList)
                            UpdateSnackbarMessage({
                              status: "success",
                              message: res?.data?.data?.message
                            });
                            FlagApiLoader(false);
                          }
                          else {
                            console.log("response false of DeleteTemplate api", res);
                            if (res?.data?.error?.message) {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                              });
                            }
                            else {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something Went Wrong!"
                              });
                            }
                            FlagApiLoader(false);
                          }
                        },
                          (err) => {
                            console.log("error of DeleteTemplate api", err);
                            UpdateSnackbarMessage({
                              status: "error",
                              message: "Something went wrong!"
                            });
                            FlagApiLoader(false);
                          })
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagDeletePopup(false); }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTON POSITION CODE, NEW IS ABOVE */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => { setFlagDeletePopup(false); }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setFlagDeletePopup(false)
                          FlagApiLoader(true)
                          DeleteTemplate(deleteTemplateData?.template_id, (res) => {
                            if (res?.data?.status) {
                              let tempTemplateList = [...rows]
                              let tempGlobalTemplateList = [...globalRows]
                              tempTemplateList = tempTemplateList?.filter((nn) => nn.template_id != deleteTemplateData?.template_id)
                              tempGlobalTemplateList = tempGlobalTemplateList?.filter((nn) => nn.template_id != deleteTemplateData?.template_id)
                              setRows(tempTemplateList)
                              setGlobalRows(tempGlobalTemplateList)
                              UpdateSnackbarMessage({
                                status: "success",
                                message: res?.data?.data?.message
                              });
                              FlagApiLoader(false);
                            } else {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                              });
                              FlagApiLoader(false);
                            }
                          }, (err) => {
                            UpdateSnackbarMessage({
                              status: "error",
                              message: "Something went wrong!"
                            });
                            FlagApiLoader(false);
                          })
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {flagDeletePopup && (
        <GlobalDialog
          open={flagDeletePopup}
          data={deletePopup()}
          maxWidth={"sm"}
        />
      )
      }

      <Helmet title="myAdvisorGrids | Templates" />

      {/* Navigation Section */}
      <Grid container justifyContent="center" alignItems="center" className="mainPageStickyHeader">
        <Grid item xs={11.5} sm={11.2} md={11.5} lg={11.6} xl={11.7} my={3}>
          <Grid
            container
            justifyContent="space-between"
            // spacing={2}
            alignItems="center"
          // mb={7}
          >
            {/* Templates */}
            <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
              <Grid container justifyContent={{ xs: "center", sm: "start" }}>
                <Typography variant="h3" display="inline" >
                  Templates
                </Typography>
              </Grid>
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
              <Grid container justifyContent={{ xs: "center", sm: "end" }} marginTop={{ xs: "15px", sm: "none", md: "none", lg: "none", xl: "none" }}>
                <Grid item>
                  <Checkboxes />
                </Grid>
              </Grid>
            </Grid>

            {/* New Template & Search Button  */}
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <Grid container spacing={2} flexDirection={{ xs: "column", sm: "row" }} justifyContent={{ xs: "center", sm: "space-between", md: "end", lg: "end", xl: "end" }} alignItems="center">
                <Grid item width={{ xs: "100%", sm: "initial" }}>
                  {state.userDetail[0]?.user_role_name == "Admin" &&
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("add_template")}

                        sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                      >
                        <Add />
                        New Template
                      </Button>
                    </div>
                  }
                </Grid>

                <Grid item width={{ xs: "100%", sm: "initial", md: "50%", lg: "initial" }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={"Search"}
                      onChange={(e) => {
                        console.log("searchhhh", e);
                        handleSearch(e.target.value);
                      }}
                    />
                  </Search>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}> <Divider mb={4} /></Grid>
      </Grid>



      {/* Template Table Section */}
      <Grid container mt={1} height={"82vh"}>
        <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }}>
          <DataGridDemo />
        </Grid>
      </Grid>
    </>
  );
};

export default Templates;

import React, { useState, useContext, useEffect, } from 'react'
import {
    Grid, Typography, TextField,
    Button, IconButton, Checkbox, Box,
    LinearProgress,
    FormControl,
    Select,
    MenuItem,
    Tooltip,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import styled from '@emotion/styled'

import "../../css/Agency/AgencyDetails.css"

// ASSETS
import greenCheckimg from "../../../assets/greenCheck.png"
import error from "../../../assets/error.png"
import FileUploadIcon from "../../../assets/file-upload-icon.png";
import loadedFile from "../../../assets/loadedfile.png";
import DarkDeleteImg from "../../../assets/Darkdelete.png"
import LightDaleteImg from "../../../assets/lightDlete.png"

//ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

// CONTEXT AND FUNCTIONS
import { Context as authContext } from '../../../contexts/reducerContexts/authContext'
import { DeleteResources, UploadResources } from '../../../utils/api/TemplateApi'
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DeleteFileAgency, UploadFileAgency, checkAgencyExistence, checkAgencyURLExistence, createNewAgency, updateAgency } from '../../../utils/api/AgencyDetailsAPI'
import { AuthContext } from '../../../contexts/JWTContext'

import { useNavigate, useSearchParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';


const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  ,
  .MuiDataGrid-iconSeparator
  {
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;


const AgencyDetails = (props) => {

    const {
        toggleDetails,
        setToggleDetails,
        setCheckLogoFavId,
        checkLogoFavId,
        setCustomUrlChecked,
        customUrlChecked
    } = props

    const pathName = window.location.pathname
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const {
        FlagApiLoader,
        UpdateSnackbarMessage,
    } = useContext(authContext)

    const {
        agencyDetails,
        fileSelected,
        fileUploaded,
        agencyFavIcon,
        favIconFileUploaded
    } = useContext(agencyContext).state

    const {
        setAgencyDetails,
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded
    } = useContext(agencyContext)

    const columns = [
        {
            field: 'firstname',
            headerName: 'Username',
            width: "280",
            headerClassName: "ag-details-datagrid-header-cell",
            cellClassName: "ag-details-datagrid-body-cell",
            editable: true,
            renderCell: (params) => {
                return (
                    <TextField
                        size='small'
                        fullWidth
                        type='text'
                        placeholder='Enter First Name'
                        className='ag-details-datagrid-inp'
                        value={params?.row?.username}
                        onChange={(e) => {
                            console.log('here', e.target.value)
                            let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                            let newAgencyAdminData = prevAgencyAdminData?.map((admin) => {
                                if (admin?.id == params?.row?.id) {
                                    return {
                                        ...admin, username: e.target.value
                                    }
                                }
                                return admin
                            })
                            setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode == 32) {
                                e.stopPropagation();
                            } else {
                                console.log("hdjsbsjd");
                            }
                        }}
                    />
                )
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            width: "350",
            editable: true,
            headerClassName: "ag-details-datagrid-header-cell",
            cellClassName: "ag-details-datagrid-body-cell",
            renderCell: (params) => {
                console.log("params:::", params);
                return (
                    <input
                        size='small'
                        fullWidth
                        type='text'
                        placeholder='Enter Email'
                        className='ag-details-datagrid-inp'
                        value={params?.row?.email_address}
                        onChange={(e) => {
                            // debugger
                            // console.log('here', params?.row?.id)
                            let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                            let newAgencyAdminData = prevAgencyAdminData?.map((admin) => {
                                if (admin?.id == params?.row?.id) {
                                    return {
                                        ...admin, email_address: e.target.value
                                    }
                                }
                                return admin
                            })
                            setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode == 32) {
                                e.stopPropagation();
                            } else {
                                console.log("hdjsbsjd");
                            }
                        }}
                    />
                )
            }
        },
        {
            field: 'role',
            headerName: 'Roles',
            width: "120",
            headerClassName: "ag-details-datagrid-header-cell",
            cellClassName: "ag-details-datagrid-body-cell",
            renderCell: (params) => {
                return (
                    <FormControl fullWidth>
                        <Select
                            className='ag-details-datagrid-role'
                            IconComponent={KeyboardArrowDownIcon}
                            value={params?.row?.user_role_flag}
                            onChange={(e) => {
                                // console.log('here', params?.row?.id)
                                let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                let newAgencyAdminData = prevAgencyAdminData?.map((admin) => {
                                    if (admin?.id == params?.row?.id) {
                                        return {
                                            ...admin, user_role_flag: e.target.value
                                        }
                                    }
                                    return admin
                                })
                                setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                            }}
                        >
                            <MenuItem value={true}>Admin</MenuItem>
                            <MenuItem value={false}>ARM</MenuItem>
                        </Select>
                    </FormControl>
                )
            }
        },
        {
            field: 'delete',
            headerName: '',
            width: "30",
            renderCell: (params) => {
                console.log("params index", params?.row);
                let disable = agencyDetails?.agency_user_data?.length == 1 && params?.row?.index == 0 && params?.row?.username == '' && params?.row?.email_address == '';
                const handleRowDelete = () => {
                    // debugger;
                    if (agencyDetails?.agency_user_data?.length == 1) {
                        let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                        let newAgencyAdminData = prevAgencyAdminData?.map((admin, i) => {
                            if (i == 0) {
                                return {
                                    ...admin, username: "", email_address: ""
                                }
                            }
                            return admin
                        })
                        setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                        console.log("newAgencyAdminData", newAgencyAdminData);
                        return
                    }
                    let findIdxToDelete = { ...agencyDetails }.agency_user_data?.findIndex(agency => agency.id == params?.row?.id);
                    if (findIdxToDelete !== -1) {
                        let prevAgencyAdminData = [...agencyDetails.agency_user_data];
                        prevAgencyAdminData.splice(findIdxToDelete, 1);
                        setAgencyDetails({ ...agencyDetails, agency_user_data: prevAgencyAdminData });
                    }
                }
                return (
                    <IconButton
                        disabled={disable}
                        onClick={handleRowDelete}
                        sx={{ padding: "0px" }}
                    >
                        {disable ? <img src={LightDaleteImg} /> : <img src={DarkDeleteImg} />}
                    </IconButton>)
            },
        }
    ]
    const [showURLTextField, setShowURLTextField] = useState(false)
    const [progressBar, setProgressBar] = useState(false)
    const [flagMatchedAgency, setFlagMatchedAgency] = useState(false)
    const [flagMatchedMag, setFlagMatchedMag] = useState(false)
    const [flagMatchedCustomUrl, setFlagMatchedCustomUrl] = useState(false)
    const [helperTextForAgencyName, setHelperTextForAgencyName] = useState('')
    // const [isDisabled, setIsDisabled] = useState(true);
    //FavIcon States
    const [upLoadingFile, setUpLoadingFile] = useState(false)
    const [maxHeight, setMaxHeight] = useState(0)
    //Pagination State
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3)
    const [userId, setUserID] = useState(null)
    const [copiedMagUrl, setCopiedMagUrl] = useState(false)
    const emailRegex = /^([a-zA-Z0-9\._-]+)@([a-zA-Z)-9])+.([a-zA-Z]+)(.[a-zA-Z]+)?$/
    const customUrlRegex = /^[a-z0-9]+([-.a-z0-9]+)?\.[a-z]{2,}(\.[a-z]{2,})?$/
    // console.log("shubham agencyDetails", agencyDetails)

    // OLD CODE BFR
    // useEffect(() => {
    //     const checkValidation = () => {
    //         const isValid = (
    //             agencyDetails.agency_name &&
    //             agencyDetails.agency_email_address &&
    //             agencyDetails.agency_user_data.every(admin => admin.username && admin.email_address)
    //         );
    //         setIsDisabled(!isValid);
    //     };
    //     checkValidation();
    // }, [agencyDetails]);



    useEffect(() => {
        console.log("agencyDetails.agency_custom_url", agencyDetails.agency_custom_url);
        const alreadyChecked = agencyDetails.agency_custom_url != null
        setCustomUrlChecked(alreadyChecked)
    }, [agencyDetails])

    function convertFileSize(fileSizeInBytes) {
        if (fileSizeInBytes < 1024) {
            return fileSizeInBytes + " bytes";
        } else if (fileSizeInBytes < 1024 * 1024) {
            return (fileSizeInBytes / 1024).toFixed(2) + " KB";
        } else if (fileSizeInBytes < 1024 * 1024 * 1024) {
            return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        } else {
            return (fileSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        }
    }

    //Function To Add Row In DataGrid
    const handleAddRow = () => {
        const lastUserDetails = agencyDetails.agency_user_data[0]
        const { username, email_address, id } = lastUserDetails

        if (username == "" || email_address == "") {
            setUserID(id)
            setTimeout(() => {
                setUserID(null)
            }, 800)
        } else {
            setAgencyDetails({
                ...agencyDetails,
                agency_user_data: [
                    {
                        index: agencyDetails.agency_user_data.length,
                        id: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        username: "",
                        email_address: "",
                        user_role_flag: true,
                    },
                    ...agencyDetails.agency_user_data
                ]
            })
            setUserID(null)
        }
    }

    //OLD CODE
    // const handleAgencyDetails = (e) => {
    //     let fieldsName = e.target.name

    //     if (fieldsName == 'agency_name') {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_name: e.target.value,
    //             agency_mag_url: e.target.value.trim()
    //         })
    //     }
    //     else {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_mag_url: e.target.value.trim()
    //         })
    //     }
    // }

    // OLD CODE WITH VALIDATION 
    // const handleAgencyDetails = (e) => {
    //     const fieldName = e.target.name;
    //     const value = e.target.value;
    //     const regex = /^[a-zA-Z0-9\s]*$/;
    //     let formattedValue = value;
    //     formattedValue = formattedValue.trim();

    //     if (!regex.test(value)) { return; }

    //     if (fieldName === 'agency_name') {
    //         formattedValue = value.replace(/\s/g, '');
    //     }

    //     if (fieldName === 'agency_name') {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_name: value,
    //             agency_mag_url: formattedValue
    //         });
    //     } else {
    //         setAgencyDetails({
    //             ...agencyDetails,
    //             agency_mag_url: formattedValue
    //         });
    //     }
    // };

    const handleAgencyDetails = (e) => {
        const fieldName = e.target.name;
        const value = e?.target?.value;
        let formattedValue = value;
        formattedValue = formattedValue?.trim();

        if (fieldName === 'agency_name') {
            formattedValue = value?.replace(/[^a-z0-9 ]/gi, '')?.replace(/ /gi, '')?.toLowerCase();
        }
        else {
            const regex = /^[a-zA-Z0-9\s]*$/;
            if (!regex.test(value)) {
                return;
            }
            else {
                formattedValue = formattedValue.toLowerCase();
            }
        }

        if (fieldName === 'agency_name') {
            setAgencyDetails({
                ...agencyDetails,
                agency_name: value,
                agency_mag_url: formattedValue.slice(0, 20)
            });
        } else {
            setAgencyDetails({
                ...agencyDetails,
                agency_mag_url: formattedValue
            });
        }
    };

    // PNG FILE UPLOAD. 
    const handlePNGFilesUploading = (e) => {

        const file = e.target.files[0]

        if (!file) return;

        if (file.type !== "image/png") {
            UpdateSnackbarMessage({
                status: "error",
                message: "Only PNG files supported",
            })
            return
        }

        const fileSize = file.size / (1024 * 1024)
        if (fileSize > 2) {
            UpdateSnackbarMessage({
                status: "error",
                message: "File size exceeds 2 MB",
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("AGENCY_LOGO_PATH", true);

        UploadFileAgency(formData, (res) => {
            if (res?.data?.status) {
                setProgressBar(false)
                setFileUploaded(true)
                setFileSelected(file);
                setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: res?.data?.data[0]?.resource_gcs_data_id })
            }
            else {
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
                setProgressBar(false)
                setFileUploaded(false)
            }
        }, (err) => {
            UpdateSnackbarMessage({
                status: "error",
                message: err?.data?.error?.message,
            });
            setProgressBar(false)
            setFileUploaded(false)
        })


        setProgressBar(true)
        setTimeout(() => {
            setProgressBar(false)
            setFileUploaded(true)
        }, 5000)
    }

    // PNG FAVICON UPLOAD. 
    const handleFavIconFileUpLoading = (e) => {
        const file = e.target.files[0]

        if (!file) return;

        if (file.type !== "image/png") {
            UpdateSnackbarMessage({
                status: "error",
                message: " Only PNG files supported",
            })
            return
        }

        const fileSize = file.size / (1024 * 1024)
        if (fileSize > 2) {
            UpdateSnackbarMessage({
                status: "error",
                message: "File size exceeds 2 MB",
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("AGENCY_LOGO_PATH", true);

        setUpLoadingFile(true)
        UploadFileAgency(formData, (res) => {
            if (res?.data?.status) {
                setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: res?.data?.data[0]?.resource_gcs_data_id })
                setAgencyFavIcon(file)
                setUpLoadingFile(false)
                setFavIconFileUploaded(true)
            } else {
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                }
                else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                }
                setUpLoadingFile(false)
                setFavIconFileUploaded(false)
            }
        },
            (err) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
                setUpLoadingFile(false)
                setFavIconFileUploaded(false)
            }
        )

    }

    // DELETE CALL
    const handleFileDelete = (file) => {
        // debugger

        file == "favicon" ? setUpLoadingFile(true) : setProgressBar(true)

        if ((agencyDetails?.agency_logo_gcs_id == checkLogoFavId?.logoId) && file == "logo" && agencyId) {
            setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: null })
            setProgressBar(false)
            setFileUploaded(false)
        }
        else if ((agencyDetails?.agency_favicon_gcs_id == checkLogoFavId?.favIconId) && file == "favicon" && agencyId) {
            setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: null })
            setUpLoadingFile(false)
            setFavIconFileUploaded(false)
        }
        else {
            // alert("here delete API called");

            let payloadForDelete = { resource_gcs_data_id: file == "favicon" ? agencyDetails?.agency_favicon_gcs_id : agencyDetails?.agency_logo_gcs_id }
            DeleteFileAgency(payloadForDelete, (res) => {
                if (res?.data?.status) {
                    if (file == "favicon") {
                        setAgencyDetails({ ...agencyDetails, agency_favicon_gcs_id: null })
                        setUpLoadingFile(false)
                        setFavIconFileUploaded(false)
                    } else {
                        setAgencyDetails({ ...agencyDetails, agency_logo_gcs_id: null })
                        setProgressBar(false)
                        setFileUploaded(false)
                    }
                }
                else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                    setProgressBar(false)
                    setFileUploaded(false)
                    setUpLoadingFile(false)
                }
            }, (err) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
                setProgressBar(false)
                setFileUploaded(false)
                setUpLoadingFile(false)
            })
        }
    }

    return (
        <>
            <Grid container className='ag-details-main-container'  >
                <Grid item xs={12} >
                    <Grid container className='ag-details-container' sx={{ justifyContent: "space-between" }}>
                        <Grid item xs={5.5}>
                            <TextField
                                disabled={agencyId ? true : false}
                                autoComplete='off'
                                fullWidth
                                name={"agency_name"}
                                value={agencyDetails.agency_name}
                                inputProps={{ maxLength: 50 }}
                                onChange={(e) => {
                                    handleAgencyDetails(e)
                                }}
                                onFocus={() => setShowURLTextField(true)}
                                className='ag-details-textfield'
                                variant='standard'
                                label={
                                    <span>Agency Name<span className='ag-details-textfield-asterik'>*</span></span>
                                }
                                InputLabelProps={{
                                    className: "ag-details-textfield-label"
                                }}
                                helperText={flagMatchedAgency ? helperTextForAgencyName : ""}
                                FormHelperTextProps={{ sx: { color: 'red' } }}
                                sx={{
                                    '& .MuiInput-root:after': {
                                        borderBottom: !flagMatchedAgency ? "2px solid #376fd0 !important" : "2px solid red !important"
                                    },
                                    '& .MuiInput-root:before': {
                                        borderBottom: !flagMatchedAgency ? "1px solid rgba(0, 0, 0, 0.42) !important" : "1px solid red !important"
                                    }
                                }}
                                onBlur={() => {
                                    let payloadForAgencyName = {
                                        agency_name: agencyDetails?.agency_name
                                    }
                                    let payloadForAgencyMagUrl = {
                                        agency_url: agencyDetails?.agency_mag_url
                                    }

                                    if (agencyDetails?.agency_name || agencyDetails?.agency_mag_url) {

                                        checkAgencyExistence(payloadForAgencyName, (res) => {
                                            // OLD CODE :
                                            // if (res?.data?.status) {
                                            //     if (res?.data?.error?.code == '') {
                                            //         setHelperTextForAgencyName(res?.data?.error?.message)
                                            //     }
                                            // }
                                            // else {
                                            //     setFlagMatchedAgency(false);
                                            //     setHelperTextForAgencyName('')
                                            // }

                                            if (res?.data?.status) {
                                                console.log("Agency Existance response", res?.data?.data[0])
                                                if (res?.data?.data[0].agency_exist_flag) {
                                                    setFlagMatchedAgency(true)
                                                    setHelperTextForAgencyName('Agency is already exists.')
                                                }
                                                else {
                                                    setFlagMatchedAgency(false);
                                                    setHelperTextForAgencyName('')
                                                }
                                            }
                                        }, (err) => {
                                            setFlagMatchedAgency(false);
                                            setHelperTextForAgencyName(err?.data?.error?.message,)
                                        })

                                        checkAgencyURLExistence(payloadForAgencyMagUrl,
                                            (res) => {
                                                if (res?.data?.status) {
                                                    console.log("Agency URL Existance response", res?.data?.data[0])
                                                    if (res?.data?.data[0].agency_url_exist_flag) {
                                                        setFlagMatchedMag(true)
                                                    }
                                                    else {
                                                        setFlagMatchedMag(false);
                                                    }
                                                }
                                                else {
                                                    setFlagMatchedMag(false);
                                                }
                                            }, (err) => {
                                                setFlagMatchedMag(false);
                                                UpdateSnackbarMessage({
                                                    status: "error",
                                                    message: err?.data?.error?.message,
                                                });
                                            })
                                    }

                                }}
                            />
                        </Grid>
                        <Grid item xs={5.5}>
                            <TextField
                                fullWidth
                                name={"agency_email"}
                                value={agencyDetails.agency_email_address}
                                onChange={(e) => {
                                    setAgencyDetails({
                                        ...agencyDetails,
                                        agency_email_address: e.target.value
                                    })
                                }}
                                variant='standard'
                                className='ag-details-textfield'
                                label={
                                    <span>Agency Email<span className='ag-details-textfield-asterik'>*</span></span>
                                }
                                InputLabelProps={{
                                    className: "ag-details-textfield-label"
                                }}

                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px" }}>
                    {/* THIS CONTAINER CONTAINES BELOW COMENTED CODE */}
                    <Grid container className='ag-details-container'  >
                        <Grid item xs={12}>
                            <Grid container className='ag-details-url-container'>
                                {/* <Grid item xs={12}>
                                    <Typography className='ag-details-typography'> MAG URL  </Typography>
                                </Grid> */}
                                <Grid item sx={{ marginTop: "11px" }}>
                                    <Grid container className={agencyId ? 'ag-edit-details-mag-url-container' : 'ag-details-mag-url-container '}>
                                        <Grid item>
                                            <Typography className='ag-details-typography'> MAG URL :</Typography>
                                        </Grid>
                                        <Grid item sx={{ flex: "1", overflow: "auto" }}>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                {showURLTextField ?
                                                    (
                                                        <>
                                                            <TextField
                                                                variant='standard'
                                                                className='ag-details-url-textfield ag-details-textfield'
                                                                name={"mag_url"}
                                                                value={agencyDetails.agency_mag_url}
                                                                inputProps={{ maxLength: 10 }}
                                                                onChange={(e) => {
                                                                    handleAgencyDetails(e)
                                                                }}
                                                                InputProps={{
                                                                    // readOnly: true,
                                                                    style: {
                                                                        backgroundColor: "white",
                                                                        fontFamily: "Nunito",
                                                                        fontSize: "13px",
                                                                        padding: '3px 10px'

                                                                    },
                                                                    disableUnderline: true
                                                                }}
                                                                sx={{
                                                                    '& .MuiInput-root': {
                                                                        border: flagMatchedMag ? '1px solid red !important' : '1px solid transparent'
                                                                    }
                                                                }}

                                                                onBlur={() => {
                                                                    let payloadForAgencyMagUrl = {
                                                                        agency_url: agencyDetails?.agency_mag_url
                                                                    }

                                                                    // checkAgencyURLExistence(payloadForAgencyMagUrl, (res) => {
                                                                    //     if (res?.data?.status) {
                                                                    //         setFlagMatchedMag(true)
                                                                    //     }
                                                                    //     else {
                                                                    //         setFlagMatchedMag(false);
                                                                    //     }
                                                                    // }, (err) => {
                                                                    //     setFlagMatchedMag(false);
                                                                    //     UpdateSnackbarMessage({
                                                                    //         status: "error",
                                                                    //         message: err?.data?.error?.message,
                                                                    //     });
                                                                    // })

                                                                    checkAgencyURLExistence(payloadForAgencyMagUrl,
                                                                        (res) => {
                                                                            if (res?.data?.status) {
                                                                                console.log("Agency URL Existance response", res?.data?.data[0])
                                                                                if (res?.data?.data[0].agency_url_exist_flag) {
                                                                                    setFlagMatchedMag(true)
                                                                                }
                                                                                else {
                                                                                    setFlagMatchedMag(false);
                                                                                    UpdateSnackbarMessage({
                                                                                        status: "error",
                                                                                        message: res?.data?.error?.message,
                                                                                    });
                                                                                }
                                                                            }
                                                                            else {
                                                                                UpdateSnackbarMessage({
                                                                                    status: "error",
                                                                                    message: res?.data?.error?.message,
                                                                                });
                                                                            }
                                                                        }, (err) => {
                                                                            setFlagMatchedMag(false);
                                                                            UpdateSnackbarMessage({
                                                                                status: "error",
                                                                                message: err?.data?.error?.message,
                                                                            });
                                                                        })
                                                                }}
                                                            />
                                                            <Typography className='ag-details-typography'>-grids.brokersalliance.com </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography className='ag-magurl-typography'>
                                                            {`${agencyDetails?.agency_mag_url == "" ? "agencyname" : agencyDetails?.agency_mag_url}-grids.brokersalliance.com`}
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ marginLeft: "10px" }}>
                                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>

                                                <img src={!flagMatchedMag ? greenCheckimg : error} />

                                                {/* THIS TOOLTIP MOVED OUT OF THIS CONTAINER, REMOVE THIS IF NO CHANGES OCCURS*/}
                                                {/* <Tooltip
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    open={copiedMagUrl}
                                                    placement={"top-start"}
                                                    title="Copied Mag URL"
                                                    PopperProps={{ disablePortal: true }}
                                                >
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`${agencyDetails.agency_mag_url}-grids.brokersalliance.com`)
                                                            setCopiedMagUrl(true)
                                                            setTimeout(() => {
                                                                setCopiedMagUrl(false)
                                                            }, 1000)
                                                        }}
                                                    >
                                                        <ContentCopyOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip> */}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {
                                    pathName != "/agency/add_agency"
                                    &&
                                    <Grid item sx={{ marginTop: "11px" }}>
                                        <Tooltip
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            open={copiedMagUrl}
                                            placement={"top-start"}
                                            title="Copied Mag URL"
                                            PopperProps={{ disablePortal: true }}
                                        >
                                            <IconButton
                                                color='primary'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(`${agencyDetails.agency_mag_url}-grids.brokersalliance.com`)
                                                    setCopiedMagUrl(true)
                                                    setTimeout(() => {
                                                        setCopiedMagUrl(false)
                                                    }, 1000)
                                                }}
                                            >
                                                <ContentCopyOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>

                        {/* ***TEMPORARY COMMENTED TO BE USED THE IN FUTURE, COMMENTED ON 19-02*** */}

                        <Grid item xs={12}>
                            <Grid container className='ag-details-textfield-Checkbox-container'>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'}>
                                        <Typography className='ag-details-typography'>Would you like to incorporate myAdvisorGrids into your website?</Typography>
                                        <Checkbox
                                            size='medium'
                                            // checked={agencyData.custom_url_flage}
                                            name={"custom_url_flage"}
                                            checked={customUrlChecked}
                                            onChange={(e) => {
                                                if (customUrlChecked) {
                                                    setAgencyDetails({
                                                        ...agencyDetails,
                                                        agency_custom_url: null
                                                    })
                                                }
                                                setCustomUrlChecked((prevState) => !prevState)
                                            }} />
                                    </Grid>
                                </Grid>

                                {
                                    customUrlChecked &&
                                    <Grid item xs={12} sx={{ marginBottom: "12px" }}>
                                        <TextField
                                            fullWidth
                                            error={flagMatchedCustomUrl}
                                            className='ag-details-textfield'
                                            name={"custom_url"}
                                            variant='standard'
                                            value={agencyDetails.agency_custom_url}
                                            onChange={(e) => {
                                                // let value = e.target.value.toLowerCase().replace(/[^a-z0-9-.]/gi, '')
                                                // value = value.replace(/^[\-.]+/, '')
                                                const inputValue = e.target.value.toLowerCase().replace(/[^a-z0-9-.]/gi, '')
                                                setAgencyDetails({
                                                    ...agencyDetails,
                                                    agency_custom_url: inputValue
                                                })
                                            }}
                                            label={
                                                <span>Specify the domain/sub-domain for myAdvisorGrids white labeling<span className='ag-details-textfield-asterik'>*</span></span>
                                            }
                                            helperText={
                                                flagMatchedCustomUrl ? agencyDetails.agency_custom_url == "" || agencyDetails.agency_custom_url == null ?
                                                    'Please Fill This Filed' : "Incorrect Custom URL" : ""}
                                            onBlur={() => {
                                                if (customUrlRegex.test(agencyDetails.agency_custom_url)) {
                                                    setFlagMatchedCustomUrl(false)
                                                } else {
                                                    setFlagMatchedCustomUrl(true)
                                                }
                                            }}
                                            onFocus={() => setFlagMatchedCustomUrl(false)}
                                            InputLabelProps={{
                                                className: "ag-details-textfield-label"
                                            }}
                                        />
                                        {/* <input value={agencyDetails.agency_custom_url}
                                            onChange={(e) => {
                                                let value = e.target.value.toLowerCase().replace(/[^a-z0-9-.]/gi, '')
                                                value = value.replace(/^[\-.]+/, '')
                                                setAgencyDetails({
                                                    ...agencyDetails,
                                                    agency_custom_url: value
                                                })
                                            }}
                                        /> */}
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px", maxHeight: "243px" }}>
                    <Grid container className='ag-details-container' sx={{ height: "100%" }} >

                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Typography variant='span' className='ag-details-typography'>Agency Users
                                    <span className='ag-details-textfield-asterik'>*</span>
                                </Typography>

                                {agencyId &&
                                    <Button
                                        startIcon={<AddCircleIcon />}
                                        variant='text'
                                        sx={{
                                            fontSize: "16px",
                                            color: "#1565C0",
                                            padding: "0px",
                                            '&:hover': {
                                                backgroundColor: '#fff',
                                            }
                                        }}
                                        onClick={handleAddRow}
                                    >
                                        Users
                                    </Button>
                                }

                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "11px", height: "calc(100% - 33px)" }}>
                            <TableContainer sx={{ height: '100%' }} >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead className='agency-user-table-head'>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                                className='agency-table-head agnecy-tablefirst-cell'
                                                stickyHeader
                                            >
                                                Username <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className='agency-table-head'
                                                stickyHeader
                                            >
                                                Email <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className='agency-table-head'
                                                stickyHeader
                                                sx={{ minWidth: "80px" }}
                                            >
                                                Roles <span style={{ color: "#FF0000" }}>*</span>
                                            </TableCell>

                                            <TableCell
                                                align="left"
                                                className='agency-table-head agnecy-tablelast-cell'>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='agency-user-table-body'>
                                        {agencyDetails?.agency_user_data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const rowId = row.id ? row.id : ""
                                            return (
                                                <>
                                                    <TableRow className={userId == rowId ? 'agency-table-body-row' : ""} >
                                                        <TableCell align="left" className='agency-table-body-cell'>
                                                            <TextField
                                                                size='large'
                                                                fullWidth
                                                                type='text'
                                                                placeholder='Enter First Name'
                                                                className='ag-details-datagrid-inp'
                                                                autoComplete="off"
                                                                value={row?.username}
                                                                onChange={(e) => {
                                                                    console.log('value of name', e.target.value)
                                                                    let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                    let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                        console.log("adminid", admin);
                                                                        if (admin.index == row?.index) {
                                                                            return {
                                                                                ...admin, username: e.target.value
                                                                            }
                                                                        }
                                                                        return admin
                                                                    })
                                                                    setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode == 32) {
                                                                        e.stopPropagation();
                                                                    } else {
                                                                        console.log("not propogate");
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" className='agency-table-body-cell'>
                                                            <TextField
                                                                size='large'
                                                                fullWidth
                                                                type='text'
                                                                placeholder='Enter Email'
                                                                disabled={row?.user_id ? true : false}
                                                                autoComplete="off"
                                                                className={`ag-details-datagrid-inp ${row?.user_id ? 'agency-email-field-disabled' : ''}`}
                                                                value={row?.email_address}
                                                                onChange={(e) => {
                                                                    // debugger
                                                                    // console.log('here', params?.row?.id)
                                                                    let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                    let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                        if (admin.index == row?.index) {
                                                                            return {
                                                                                ...admin, email_address: e.target.value
                                                                            }
                                                                        }
                                                                        return admin
                                                                    })
                                                                    setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode == 32) {
                                                                        e.stopPropagation();
                                                                    } else {
                                                                        console.log("not propogate");
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" className='agency-table-body-cell'>
                                                            <FormControl fullWidth >
                                                                <Select
                                                                    className='ag-details-datagrid-role'
                                                                    IconComponent={KeyboardArrowDownIcon}
                                                                    value={row?.user_role_flag}
                                                                    onChange={(e) => {
                                                                        // console.log('here', params?.row?.id)
                                                                        let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                        let newAgencyAdminData = prevAgencyAdminData?.map((admin, adminIndex) => {
                                                                            if (admin.index == row?.index) {
                                                                                return {
                                                                                    ...admin, user_role_flag: e.target.value
                                                                                }
                                                                            }
                                                                            return admin
                                                                        })
                                                                        setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                    }}
                                                                >
                                                                    <MenuItem value={true}>Admin</MenuItem>
                                                                    <MenuItem value={false}>ARM</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                sx={{ padding: "0px" }}
                                                                disabled={agencyDetails?.agency_user_data?.length == 1 && row?.index == 0 && row?.username == '' && row?.email_address == ''}
                                                                onClick={(e) => {
                                                                    if (agencyDetails?.agency_user_data?.length == 1) {
                                                                        let prevAgencyAdminData = { ...agencyDetails }.agency_user_data;
                                                                        console.log("prevAgencyAdminData", prevAgencyAdminData);
                                                                        let newAgencyAdminData = prevAgencyAdminData?.map((admin, i) => {
                                                                            console.log("admin", admin);
                                                                            if (i == 0) {
                                                                                return {
                                                                                    ...admin,
                                                                                    username: "",
                                                                                    email_address: "",
                                                                                    user_id: null,
                                                                                    master_user_id: null,
                                                                                }
                                                                            }
                                                                            return admin
                                                                        })
                                                                        setAgencyDetails({ ...agencyDetails, agency_user_data: newAgencyAdminData })
                                                                        console.log("newAgencyAdminData", newAgencyAdminData);
                                                                        return
                                                                    }
                                                                    // debugger
                                                                    let findIdxToDelete = { ...agencyDetails }.agency_user_data?.findIndex(agency => agency.index == row.index);
                                                                    if (findIdxToDelete !== -1) {
                                                                        let prevAgencyAdminData = [...agencyDetails.agency_user_data];
                                                                        prevAgencyAdminData.splice(findIdxToDelete, 1);
                                                                        setAgencyDetails({ ...agencyDetails, agency_user_data: prevAgencyAdminData });
                                                                    }
                                                                }}
                                                            >
                                                                {agencyDetails?.agency_user_data?.length == 1 && row?.index == 0 && row?.username == '' && row?.email_address == '' ? <img src={LightDaleteImg} /> : <img src={DarkDeleteImg} />}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        }
                                        )}
                                    </TableBody>
                                    {agencyDetails?.agency_user_data?.length > 3 &&
                                        <TableFooter className='agency-user-table-footer'>
                                            <TablePagination
                                                className='agency-user-table-footer-pagination'
                                                rowsPerPage={3}
                                                page={page}
                                                colSpan={5}
                                                count={agencyDetails?.agency_user_data.length}
                                                rowsPerPageOptions={[]}
                                                onPageChange={(e, newPage) => setPage(newPage)}
                                            />
                                        </TableFooter>}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: "25px" }}>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                        <Grid item
                            xs={12} sm={12}
                            md={agencyId ? 12 : 5.8}
                            lg={5.8}
                        >
                            <Grid container className='ag-details-container' >

                                <Grid item xs={12} className='ag-details-file-item-typo'>
                                    <Typography component='span' className='ag-details-typography'>Upload Logo</Typography>
                                    <Typography component="span" className='ag-details-typography-grey'> (Only PNG file supported. Max file size 2MB)</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: "11px" }} >
                                    <Grid container>
                                        <Grid item className="ag-details-file-card ">
                                            <Grid container>
                                                <Grid item className="ag-details-file-inputs">
                                                    {!fileUploaded ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                className="ag-details-file-inputs"
                                                                accept="image/png"
                                                                onChange={(e) => handlePNGFilesUploading(e)}
                                                            ></input>

                                                            <button
                                                                className="ag-details-file-inputs ag-input-button"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                <Grid container alignItems={'center'} justifyContent={'center'}>

                                                                    <Grid item sx={{ width: "43px" }}>
                                                                        <Grid container className="ag-details-FileUploadIcon" >
                                                                            <img style={{ width: "30px" }} src={FileUploadIcon} />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Grid container className='ag-details-filetext-container'>
                                                                            Drag your files here or <span style={{ marginLeft: "5px", color: "#1565C0" }}> Browse </span>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </button>
                                                        </>
                                                        :
                                                        <Grid container sx={{ alignItems: "center" }}>

                                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                                    <Grid item>
                                                                        <img style={{ marginLeft: "10px" }} src={loadedFile} />
                                                                    </Grid>

                                                                    <Grid item className='ag-details-uploadedfile-item'>
                                                                        <Typography className='ag-details-noWrap-Typo'>
                                                                            {fileSelected?.name}
                                                                            <Typography sx={{ color: "#676767" }}>{convertFileSize(fileSelected?.size)}</Typography>
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item >
                                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleFileDelete("logo")
                                                                        }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {progressBar && <LinearProgress />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item
                            xs={12} sm={12}
                            md={agencyId ? 12 : 5.8} lg={5.8}
                            marginTop={{ md: agencyId ? "25px" : "0px", lg: "0px" }}
                        >
                            <Grid container className='ag-details-container' >

                                <Grid item xs={12} className='ag-details-file-item-typo'>
                                    <Typography component='span' className='ag-details-typography'>Upload Favicon</Typography>
                                    <Typography component="span" className='ag-details-typography-grey'> (Only PNG file supported. Max file size 2MB)</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: "11px" }}>
                                    <Grid container>
                                        <Grid item className="ag-details-file-card ">
                                            <Grid container>
                                                <Grid item className="ag-details-file-inputs">
                                                    {!favIconFileUploaded ?
                                                        <>
                                                            <input
                                                                type="file"
                                                                multiple
                                                                className="ag-details-file-inputs"
                                                                accept="image/png"
                                                                onChange={(e) => handleFavIconFileUpLoading(e)}
                                                            ></input>

                                                            <button
                                                                className="ag-details-file-inputs ag-input-button"
                                                                style={{
                                                                    color: "#000000",
                                                                }}
                                                            >
                                                                <Grid container alignItems={'center'} justifyContent={'center'}>

                                                                    <Grid item sx={{ width: "43px" }}>
                                                                        <Grid container className="ag-details-FileUploadIcon" >
                                                                            <img style={{ width: "30px" }} src={FileUploadIcon} />
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item>
                                                                        <Grid container className='ag-details-filetext-container'>
                                                                            Drag your files here or{" "}<span style={{ marginLeft: "5px", color: "#1565C0" }}> Browse </span>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </button>
                                                        </>
                                                        :
                                                        <Grid container sx={{ alignItems: "center" }}>
                                                            <Grid item sx={{ flex: 1, overflow: "hidden" }}>
                                                                <Grid container className='ag-details-uploadedfile-conatiner'>

                                                                    <Grid item>
                                                                        <img style={{ marginLeft: "10px" }} src={loadedFile} />
                                                                    </Grid>

                                                                    <Grid item className='ag-details-uploadedfile-item'>
                                                                        <Typography className='ag-details-noWrap-Typo'>
                                                                            {agencyFavIcon.name ? agencyFavIcon.name : ""}
                                                                            <Typography sx={{ color: "#676767" }}>{convertFileSize(agencyFavIcon.size ? agencyFavIcon.size : 0)}</Typography>
                                                                        </Typography>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                            <Grid item >
                                                                <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handleFileDelete("favicon")
                                                                        }}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {upLoadingFile && <LinearProgress />}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}
export default AgencyDetails
import React, { useContext, useState } from "react";
import "../../../css/Agency/AgencyDialogs/AgencyCheckURLPopUp.css"
import {
    Grid, Typography, TextField,
    IconButton, Button, Tooltip, Divider, Accordion, AccordionDetails,
    AccordionSummary, TableContainer, Table, TableRow, TableCell, TableHead, TableBody,
} from "@mui/material"

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

// API
import { verifyAgencyWhiteLabelURL, activateAgencyApi } from "../../../../utils/api/AgencyDetailsAPI";
import { Context as authContext } from '../../../../contexts/reducerContexts/authContext'
import { Context as agencyContext } from "../../../../contexts/reducerContexts/agencyContext";


const AgencyCheckURLPopUp = (props) => {

    const pathName = window.location.pathname
    const cutomMagUrl = pathName !== "/agency/add_agency" ? `${props.magurl}-grids.brokersalliance.com` : props.magurl

    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
    const { setAgencyDetails } = useContext(agencyContext)
    const { agencyDetails } = useContext(agencyContext).state

    const [copiedMagUrl, setCopiedMagUrl] = useState(false)
    const [expanded, setExpanded] = useState(true);

    const handleErrorResponse = (res) => {
        if (res?.data?.error?.message) {
            UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
            FlagApiLoader(false)
        }
        else {
            UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
            FlagApiLoader(false)
        }
    }

    const handleVerifyAndClose = () => {
        FlagApiLoader(true)
        const agencyId = agencyDetails.agency_id || props.agencyId
        verifyAgencyWhiteLabelURL(
            agencyId,
            (res) => {
                if (res?.data?.status) {
                    const domainVerified = res?.data?.data[0].domain_verified
                    if (domainVerified) {
                        activateAgencyApi(
                            agencyId,
                            (res) => {
                                if (res?.data?.status) {
                                    const agencyData = res?.data?.data[0]
                                    setAgencyDetails({ ...agencyData })
                                } else {
                                    FlagApiLoader(false)
                                    handleErrorResponse(res)
                                }
                            },
                            (err) => {
                                console.log("activateAgencyApi err", err)
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: err?.data?.error?.message,
                                });
                                FlagApiLoader(false)
                            }
                        )
                    } else {
                        FlagApiLoader(false)
                        handleErrorResponse(res)
                    }
                    FlagApiLoader(false)
                } else {
                    handleErrorResponse(res)
                }
            },
            (err) => {
                console.log("verifyAgencyWhiteLabelURL err", err)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
                FlagApiLoader(false)
            }
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container className="c-url-main-container">
                <IconButton className="c-url-close-button" onClick={() => props.handleCheckURLPopUp()}>
                    <CloseIcon />
                </IconButton>

                <Grid item xs={12} sx={{ height: "62px" }} >
                    <Grid container className="c-url-heading-container">
                        <Typography sx={{ fontSize: "20px" }}>
                            Check White Label URL
                        </Typography>
                    </Grid>

                </Grid>

                <Grid item xs={12} sx={{ maxHeight: "calc(100vh - 228px)", overflow: "auto" }} >
                    <Grid container className="c-url-content-container">

                        <Grid item xs={12} sx={{ marginTop: "2px", padding: "0px 20px" }}>
                            <Grid container className="c-url-magurl-container">
                                <Grid item>
                                    <Grid container className="c-url-field-label-container" >
                                        <Typography
                                            sx={{ fontSize: "16px" }}
                                        >
                                            MAG URL
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ padding: "4px 0px" }}>
                                    <Divider orientation="vertical" />
                                </Grid>
                                <Grid item sx={{ flex: 1 }}>
                                    <Grid container className="c-url-mag-url-textfield-container">
                                        <Typography sx={{ fontSize: "14px", fontWeight: "500 !important" }}>
                                            {cutomMagUrl}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container className="c-url-copy-btn-container">
                                        <Tooltip
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            open={copiedMagUrl}
                                            arrow
                                            placement={"top-start"}
                                            title="Copied Mag URL"
                                            PopperProps={{ disablePortal: true }}
                                        >
                                            <IconButton
                                                className={copiedMagUrl ? "c-url-copy-button" : ""}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(cutomMagUrl)
                                                    setCopiedMagUrl(true)
                                                    setTimeout(() => {
                                                        setCopiedMagUrl(false)
                                                    }, 1000)
                                                }}
                                            >
                                                <ContentCopyOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: "25px" }}>
                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                Update Your DNS Settings:
                            </Typography>
                            <Typography sx={{ fontSize: "13px", fontWeight: "500 !important", marginTop: "8px" }}>
                                Add the copied MAG URL as a CNAME record in your domain’s DNS settings.
                            </Typography>
                        </Grid>

                        <Grid itme xs={12}>
                            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className="c-url-accordion">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className="c-url-accordion-summary"
                                >
                                    <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#1565C0" }}>
                                        How to configure DNS Records?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ maxHeight: "calc(100vh - 442px)", overflow: "auto" }}>
                                    <Grid container sx={{ padding: "0px 10px" }}>
                                        <Grid item xs={12} >
                                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                                Step 1: Access DNS Management
                                            </Typography>
                                            <ul className="c-url-common-ul">
                                                <li>Go to your domain's DNS records.</li>
                                            </ul>
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                                Step 2: Add Record
                                            </Typography>
                                            <ul className="c-url-common-ul">
                                                <li>Add a record to your DNS setting, selecting <span style={{ fontWeight: "600 !important" }}>CNAME</span> as the record type.</li>
                                            </ul>
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                                Step 3: Configure Your CNAME Record
                                            </Typography>
                                            <ul className="c-url-common-ul">
                                                <li>Place the <span style={{ fontWeight: "600" }}>`{props.customurl}`</span> URL into the <span style={{ fontWeight: "600" }}>'Name'</span> section.</li>
                                                <li>Copy and place the MAG URL into the <span style={{ fontWeight: "600" }}>'Destination/Target'</span> section.</li>
                                            </ul>
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Your record should look similar to the table below,
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                                    <TableContainer className="c-url-table-container">
                                                        <Table>

                                                            <TableHead className="c-url-tabel-head">
                                                                <TableRow>
                                                                    <TableCell className="c-url-tabel-head-cell top-left-border-radius" >
                                                                        Record Type
                                                                    </TableCell>
                                                                    <TableCell className="c-url-tabel-head-cell">
                                                                        Label/Host Field
                                                                    </TableCell>
                                                                    <TableCell className="c-url-tabel-head-cell">
                                                                        Destination/Target Field
                                                                    </TableCell>
                                                                    <TableCell className="c-url-tabel-head-cell">
                                                                        Proxy
                                                                    </TableCell>
                                                                    <TableCell className="c-url-tabel-head-cell top-right-border-radius">
                                                                        Time To Live(TTl)
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className="c-url-table-body-cell"  >
                                                                        CAME
                                                                    </TableCell>
                                                                    <TableCell sx={{ maxWidth: "100px" }} className="c-url-table-body-cell ellipsis-cell">
                                                                        {props.customurl}
                                                                    </TableCell>
                                                                    <TableCell sx={{ maxWidth: "100px" }} className="c-url-table-body-cell ellipsis-cell">
                                                                        {cutomMagUrl}
                                                                    </TableCell>
                                                                    <TableCell className="c-url-table-body-cell">
                                                                        On
                                                                    </TableCell>
                                                                    <TableCell className="c-url-table-body-cell">
                                                                        Auto
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>

                                                        </Table>
                                                    </TableContainer>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '18px' }}>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                                Step 4: Save the Record
                                            </Typography>
                                            <ul className="c-url-common-ul">
                                                <li>Click <span style={{ fontWeight: "600" }}>Add/Save</span> to apply the changes.</li>
                                            </ul>
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "900 !important", color: "#000" }}>
                                                Step 5: Verify the Setup
                                            </Typography>
                                            <ul className="c-url-common-ul">
                                                <li>Once you've finished the above steps, click on the <span style={{ fontWeight: "600" }}>'Verify & Close'</span> button to check if the CNAME record is working.</li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ margin: "30px 0px" }}>
                    <Grid container className="c-url-button-container">
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ width: "130px", fontSize: "13px", fontWeight: "500 !important", padding: "8px" }}
                            onClick={() => props.handleCheckURLPopUp()}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ width: "130px", fontSize: "13px", fontWeight: "500 !important", padding: "8px" }}
                            onClick={() => { handleVerifyAndClose() }}
                        >
                            Verify & Close
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default AgencyCheckURLPopUp;
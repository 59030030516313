import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import { Add, Close, MoreVert, Security } from "@mui/icons-material";
import {
    Divider as MuiDivider,
    Typography,
    Box,
    Grid,
    InputBase,
    TextField,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Fade,
    Chip,
    Stack,
    FormControl,
    Paper,
    RadioGroup,
    Radio,
} from "@mui/material";
import { alpha, spacing } from "@mui/system";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import KebabMenuIcon from "../../assets/kebab-menu-icon.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import GlobalDialog from "../../components/global/GlobalDialog";
import deleteAlert from '../../assets/Delete-alert.png'
import { GetBAMemberList, AddNewBaMemberApi, UpdateBaMemberApi, DeleteBaMemberApi } from "../../utils/api/BATeamApi";
import { UserMeApi } from "../../utils/api/userDetailApi";

const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)`
  border: none;
  border-radius: 10px;
  font-family: Nunito;

  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
    border-radius: "10px 10px 0 0 !important";
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  
  .MuiDataGrid-iconSeparator{
    display: none;
    visibility: none;
  }

  .MuiDataGrid-row {
    background-color: rgba(27, 36, 48, 0.07);
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  },

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
`;

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const BATeam = () => {
    const navigate = useNavigate();
    const {
        FlagApiLoader, UpdateSnackbarMessage, UpdateUserDetail, UpdateDelegateAccessData, UpdateFlagAuthenticated, UpdateDelegateAccessFlag, state
    } = useContext(AuthContext);
    const [flagDeletePopup, setFlagDeletePopup] = useState(false);
    const [deleteTemplateData, setDeleteTemplateData] = useState([]);
    const [flagMemberPopup, setFlagMemberPopup] = useState(false)
    const [popupType, setPopupType] = useState("")
    const [deleteUserId, setDeleteUserId] = useState()
    const [memberDetails, setMemberDetails] = useState({ username: "", email_address: "", user_id: "", user_role_flag: "" })
    const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);

    // const [sortModel, setSortModel] = React.useState([
    //     {
    //       field: 'user_created_timestamp',
    //       sort: 'asc',
    //     },
    //   ]);

    // const [open, setOpen] = React.useState({
    //   status: false,
    //   id: 0,
    // });

    const [userList, setUserList] = useState([]);

    const [globalUserList, setGlobalUserList] = useState([]);

    const CallUserMe = () => {
        UserMeApi({},
            (res) => {
                UpdateFlagAuthenticated(res?.data?.status)
                if (res?.data?.status) {
                    console.log("response true of UserMeApi api", res);
                    UpdateUserDetail(res.data.data)
                    UpdateDelegateAccessFlag(res?.data?.delegate_access_flag)
                    if (res?.data?.delegate_access_flag) {
                        UpdateDelegateAccessData(res?.data?.delegate_user_data)
                    }
                }
                else {
                    console.log("response false of UserMeApi api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    }
                }
            }, (err) => {
                console.log("error response of UserMeApi api", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            })
    }

    useEffect(() => {
        if (userList?.length) {
            setNoDataMsgFlag(true)
        } else {
            setNoDataMsgFlag(false)
        }
    }, [userList])
    useEffect(() => {
        FlagApiLoader(true)
        GetBAMemberList(
            (res) => {
                if (res.data.status) {
                    console.log("response true of GetBAMemberList api", res);
                    // let sortedUserList = res?.data?.data?.sort((a, b) =>
                    //     a.user_created_timestamp < b.user_created_timestamp ? 1 :
                    //         b.user_created_timestamp < a.user_created_timestamp ? -1 : 0
                    // )
                    const userList = res?.data?.data
                    setUserList(userList)
                    setGlobalUserList(userList)
                    FlagApiLoader(false)
                }
                else {
                    console.log("response false of GetBAMemberList api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (err) => {
                FlagApiLoader(false)
                console.log("error of GetBAMemberList api", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!",
                });
            }
        )
    }, [])

    const Validation = (type) => {
        if (type === "Add") {
            if (memberDetails.user_role_flag === "") {
                return { status: false, message: "Please select type" }
            }
            if (memberDetails.username == "" && memberDetails.email_address == "") {
                return { status: false, message: "Please enter username and email address" }
            }
            if (memberDetails.username == "") {
                return { status: false, message: "Please enter username" }
            }
            if (memberDetails.email_address == "") {
                return { status: false, message: "Please enter email address" }
            }
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(memberDetails.email_address))) {
                return { status: false, message: "Please enter valid email address" }
            }
            if (!(/^[a-zA-Z ]+$/.test(memberDetails.username))) {
                return { status: false, message: "Please enter valid username" }
            }
            return { status: true, message: "" }

        } else if (type === "Edit") {
            if (memberDetails.username == "") {
                return { status: false, message: "Please enter username" }
            } else if (!(/^[a-zA-Z ]+$/.test(memberDetails.username))) {
                return { status: false, message: "Please enter valid username" }
            } else {
                return { status: true, message: "" }
            }
        }
    }

    const AddNewMember = () => {
        FlagApiLoader(true)
        let obj = {
            username: memberDetails.username,
            email_address: memberDetails.email_address,
            user_role_flag: memberDetails.user_role_flag
        }
        AddNewBaMemberApi(obj, (res) => {
            if (res?.data?.status) {
                console.log("response true of AddNewBaMemberApi api", res);
                CallUserMe()
                let oldData = [...userList]
                let oldGlobalData = [...globalUserList]
                oldData.unshift(res?.data?.data[0])
                oldGlobalData.unshift(res?.data?.data[0])
                setUserList(oldData)
                setGlobalUserList(oldGlobalData)
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "success",
                    message: "User created successfully."
                });

            }
            else {
                console.log("response false of AddNewBaMemberApi api", res);
                FlagApiLoader(false)
                setFlagMemberPopup(false)
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message
                    });
                }
                else {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    });
                }
            }
        }, (err) => {
            console.log("error of AddNewBaMemberApi api", err);
            FlagApiLoader(false)
            UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!"
            });
        })
    }

    const UpdateMember = () => {
        let obj = {
            username: memberDetails.username,
            user_id: memberDetails.user_id,
            agent_user_data_flag: true
        }
        UpdateBaMemberApi(obj, (res) => {
            if (res?.data?.status) {
                console.log("response true of UpdateBaMemberApi api", res);
                let oldData = [...userList]
                let oldGlobalData = [...globalUserList]
                oldData?.map((row) => {
                    if (memberDetails.user_id == row?.user_id) {
                        row.username = res?.data?.data?.new_username
                    }
                })
                oldGlobalData?.map((row) => {
                    if (memberDetails.user_id == row?.user_id) {
                        row.username = res?.data?.new_username
                    }
                })
                setUserList(oldData)
                setGlobalUserList(oldGlobalData)
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "success",
                    message: res?.data?.data?.message
                });
            }
            else {
                console.log("response false of UpdateBaMemberApi api", res);
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message
                    });
                }
                else {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                    });
                }
            }
        },
            (err) => {
                console.log("error of UpdateBaMemberApi api", err);
                setFlagMemberPopup(false)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something went wrong!"
                });
            })
    }

    const deletePopup = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className="delete-main-container">
                        <Grid item xs={11.5} textAlign={"end"}>
                            <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
                        </Grid>
                        <Grid item xs={12} className="delete-alert-item">
                            <img src={deleteAlert}></img>
                        </Grid>
                        <Grid item xs={12} className="delete-mesg-item">
                            Are you sure you want to delete this user?
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className="delete-btn-container">
                                <Grid container className="popup-delete-button-container" >
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100px" }}
                                        onClick={() => {
                                            FlagApiLoader(true)
                                            DeleteBaMemberApi(deleteUserId,
                                                (res) => {
                                                    if (res?.data?.status) {
                                                        console.log("response true of DeleteBaMemberApi api", res);
                                                        CallUserMe()
                                                        let oldData = [...userList]
                                                        let oldGlobalData = [...globalUserList]
                                                        oldData = oldData?.filter((rr) => rr.user_id != deleteUserId)
                                                        oldGlobalData = oldGlobalData?.filter((rr) => rr.user_id != deleteUserId)
                                                        setUserList(oldData)
                                                        setGlobalUserList(oldGlobalData)
                                                        setFlagDeletePopup(false)
                                                        FlagApiLoader(false)
                                                    } else {
                                                        console.log("response false of DeleteBaMemberApi api", res);
                                                        if (res?.data?.error?.message) {
                                                            UpdateSnackbarMessage({
                                                                status: "error",
                                                                message: res?.data?.error?.message
                                                            });
                                                            setFlagDeletePopup(false)
                                                            FlagApiLoader(false)
                                                        }
                                                        else {
                                                            UpdateSnackbarMessage({
                                                                status: "error",
                                                                message: "Something Went Wrong!"
                                                            });
                                                            setFlagDeletePopup(false)
                                                            FlagApiLoader(false)
                                                        }
                                                    }
                                                },
                                                (err) => {
                                                    console.log("error of DeleteBaMemberApi api", err);
                                                    UpdateSnackbarMessage({
                                                        status: "error",
                                                        message: "Something Went Wrong!"
                                                    });
                                                    setFlagDeletePopup(false)
                                                    FlagApiLoader(false)
                                                })
                                        }}
                                    >
                                        Yes
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: "100px" }}
                                        onClick={() => { setFlagDeletePopup(false); }}
                                    >
                                        No
                                    </Button>
                                </Grid>

                                {/* <Grid item xs={6} >
                                    <Grid container className="delete-no-btn-container">
                                        <Grid item xs={3} className="delete-no-btn-item">
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="error"
                                                onClick={() => { setFlagDeletePopup(false) }}
                                            >No
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={3} className="delete-yes-btn-item">
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    FlagApiLoader(true)
                                                    DeleteBaMemberApi(deleteUserId, (res) => {
                                                        if (res?.data?.status) {
                                                            CallUserMe()
                                                            let oldData = [...userList]
                                                            let oldGlobalData = [...globalUserList]
                                                            oldData = oldData?.filter((rr) => rr.user_id != deleteUserId)
                                                            oldGlobalData = oldGlobalData?.filter((rr) => rr.user_id != deleteUserId)
                                                            setUserList(oldData)
                                                            setGlobalUserList(oldGlobalData)
                                                            setFlagDeletePopup(false)
                                                            FlagApiLoader(false)
                                                        } else {
                                                            setFlagDeletePopup(false)
                                                            FlagApiLoader(false)
                                                        }
                                                    }, (err) => {
                                                        setFlagDeletePopup(false)
                                                        FlagApiLoader(false)
                                                    })
                                                }}
                                            >Yes</Button>
                                        </Grid>
                                    </Grid>
                                </Grid> */}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

    const memberPopup = () => {
        return (
            <Grid item xs={12}>
                <Grid container className="cp-head-container">
                    <Grid item xs={12} className="cp-head-item">
                        <span className="cp-label">{popupType == "Add" ? "Add New User" : "Edit User"}</span>
                    </Grid>

                    <Grid item xs={12} marginTop="15px">
                        <Grid container alignItems="center" justifyContent="center" spacing={6}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} textAlign="center">
                                <FormControl disabled={popupType == "Edit" ? true : false} >
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={memberDetails.user_role_flag.toString()}
                                        onChange={(e) => { setMemberDetails({ ...memberDetails, user_role_flag: e.target.value == "true" ? true : e.target.value == "false" ? false : "" }) }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="Admin" />
                                        <FormControlLabel value="false" control={<Radio />} label="ARM" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6.4} sm={6.4} md={6.5} lg={6.5} xl={6.5}>
                                <TextField
                                    variant="standard"
                                    id="standard-multiline-flexible"
                                    className="req_fields"
                                    label="Name"
                                    onChange={(event) => { setMemberDetails({ ...memberDetails, username: event.target.value }) }}
                                    value={memberDetails.username}
                                    sx={{
                                        // width: { xs: "250px", sm: "320px", md: "320px", lg: "300px", xl: "300px" },
                                        "& .MuiInputBase-root": {
                                            height: "40px"
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6.4} sm={6.4} md={6.5} lg={6.5} xl={6.5}>
                                <TextField
                                    variant="standard"
                                    id="standard-multiline-flexible"
                                    className="req_fields"
                                    label="Email"
                                    onChange={(event) => { setMemberDetails({ ...memberDetails, email_address: event.target.value }) }}
                                    value={memberDetails.email_address}
                                    disabled={popupType == "Edit" ? true : false}
                                    sx={{
                                        // width: { xs: "250px", sm: "320px", md: "320px", lg: "300px", xl: "300px" },
                                        "& .MuiInputBase-root": {
                                            height: "40px"
                                        }
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ margin: "50px auto 40px auto" }}>
                        <Grid container justifyContent={"center"} spacing={6}>
                            <Grid item xl={3.2} lg={3.2} md={3.5} sm={3.3} xs={3.3}>
                                {/* <Grid container className="cp-checkbox-cancel-container">
                                    <Grid item xl={3} lg={4} md={5} sm={5} xs={9}> */}
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    sx={{ padding: "10px 0" }}
                                    onClick={() => {
                                        setFlagMemberPopup(false);
                                    }}
                                >
                                    Close
                                </Button>
                                {/* </Grid>
                                </Grid> */}
                            </Grid>

                            <Grid item xl={3.2} lg={3.2} md={3.5} sm={3.3} xs={3.3}>
                                {/* <Grid container>
                                    <Grid item xl={3} lg={4} md={5} sm={5} xs={9}> */}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {
                                        if (popupType == "Add") {
                                            let validateMessage = Validation("Add")

                                            if (validateMessage?.status) {
                                                AddNewMember()
                                            } else {
                                                UpdateSnackbarMessage({
                                                    status: "error",
                                                    message: validateMessage.message
                                                });
                                            }
                                        } else if (popupType == "Edit") {
                                            let validateMessage = Validation("Edit")

                                            if (validateMessage?.status) {
                                                UpdateMember()
                                            } else {
                                                UpdateSnackbarMessage({
                                                    status: "error",
                                                    message: validateMessage.message
                                                });
                                            }
                                        }
                                    }}
                                    sx={{ padding: "10px 0" }}
                                >
                                    {popupType === "Add" ? "Create" : popupType === "Edit" ? "Save" : ""}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const columns = [
        {
            field: "username",
            headerName: "Name",
            // sortable: false,
            // width: 350,
            flex: 0.9,
            headerClassName: "username_header",
            renderCell: (params) => {
                return (
                    <Typography
                        className="template-name"
                        color="#1B6AC5"
                        fontFamily="Segoe UI"
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="20px"
                        marginLeft={{ xs: "0", sm: "10px" }}
                    >{params.value}</Typography>
                )
            }
        },
        {
            field: "user_email_address",
            headerName: "Email",
            headerClassName: "email_header",
            // sortable: false,
            // width: 500,
            flex: 1.4,
            renderCell: (params) => {
                return (
                    <Typography marginLeft={{ xs: "0", sm: "10px" }}>{params.value}</Typography>
                )
            }
        },
        {
            field: "user_created_timestamp",
            headerName: "Created at",
            headerClassName: "created_at_header",
            // sortable: false,
            // width: 480,
            flex: 0.9,
            renderCell: (params) => {
                const currentVersionList = new Date(params.value);
                const day = currentVersionList.getUTCDate();
                const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                const year = currentVersionList.getUTCFullYear();
                const hours = currentVersionList.getUTCHours();
                const minutes = currentVersionList.getUTCMinutes();
                const seconds = currentVersionList.getUTCSeconds();
                let formattedDate = "";
                formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

                return (
                    <Typography >{formattedDate}</Typography>
                )
            }
        },
        {
            field: "user_role_name",
            headerName: "Role",
            headerClassName: "role_header",
            // sortable: false,
            // width: 220,
            flex: 0.8,
            renderCell: (params) => {
                return (
                    <Typography >{params.value == "Member" ? "ARM" : params.value == "Admin" ? "Admin" : ""}</Typography>
                )
            }
        },
        {
            field: "actions",
            type: "actions",
            headerName: "",
            sortable: false,
            align: "center",
            sortable: false,
            headerClassName: "action_header",
            flex: 0.2,
            flex: 0.2,
            getActions: (params) => {
                console.log("Actions params:", params); // Logging the params object
                const actions = [
                    <GridActionsCellItem
                        label="Edit"
                        onClick={() => {
                            setPopupType("Edit");
                            setMemberDetails({
                                username: params.row.username,
                                email_address: params.row.user_email_address,
                                user_id: params.row.user_id,
                                user_role_flag: params.row.user_role_name == "Admin" ? "true" : "false"
                            });
                            setFlagMemberPopup(true);
                        }}
                        showInMenu
                    />,
                ];

                if (params.row.user_role_name !== "Admin") {
                    actions.push(
                        <GridActionsCellItem
                            label="Delete"
                            onClick={() => {
                                setDeleteUserId(params.row.user_id);
                                setFlagDeletePopup(true);
                            }}
                            showInMenu
                        />
                    );
                }

                return actions;
            },
        },
    ];

    const getCellClassName = (params) => {
        if (params.field == "username") {
            return "username_cell_class"
        }
        if (params.field == "user_email_address") {
            return "email_cell_class"
        }
        if (params.field == "user_created_timestamp") {
            return "created_cell_class"
        }
        if (params.field == "user_role_name") {
            return "role_cell_class"
        }
        if (params.field == "actions") {
            return "action_cell_class"
        }
    }

    const handleSearch = (name) => {
        let tempGlobalData = [...globalUserList]
        if (!name) {
            setUserList(tempGlobalData)
        } else {
            tempGlobalData = tempGlobalData?.filter((obj) => obj.username.toLowerCase().indexOf(name.toLowerCase()) > -1)
            setUserList(tempGlobalData)
        }
    }

    function DataGridDemo() {
        return (
            <Box sx={{ height: { xs: "60vh", sm: "70vh", md: "65vh", lg: "69vh", xl: "82vh" }, width: "100%" }} className="template-table">
                <CustomDataGrid
                    rows={userList?.length && userList}
                    columns={columns}
                    // pageSize={userList.length}
                    getRowId={(row) => row?.user_id}
                    experimentalFeatures={{ newEditingApi: true }}
                    headerHeight={39}
                    pageSize={15}
                    rowsPerPageOptions={[10]}
                    className="user_datagrid_class"
                    sx={{
                        padding: { xs: "10px", sm: "20px" },
                        // "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle:first-child": {
                        //     marginLeft: { xs: "0", sm: "10px" }
                        // },
                    }}
                    disableSelectionOnClick
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    disableSorting
                    getCellClassName={getCellClassName}

                    // hideFooterPagination
                    // hideFooter
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                {noDataMsgFlag == false && state.apiLoader == false ? "No Data Found!" : ""}
                            </Stack>
                        ),
                    }}
                />
            </Box>
        );
    }

    return (
        <>
            {flagDeletePopup && (
                <GlobalDialog
                    open={flagDeletePopup}
                    data={deletePopup()}
                    maxWidth={"sm"}
                />
            )}

            {flagMemberPopup && (
                <GlobalDialog
                    open={flagMemberPopup}
                    data={memberPopup()}
                    maxWidth={"sm"}
                />
            )}


            <Helmet title="myAdvisorGrids | Users" />

            {/* Navigation Section */}
            <Grid container justifyContent="center" alignItems="center" className="mainPageStickyHeader">
                <Grid item xs={11.5} sm={11.2} md={11.5} lg={11.6} xl={11.7} my={3}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={2}
                        alignItems="center"
                        mb={1}
                    >
                        {/* BA Team */}
                        <Grid item xs={12} sm={3} md={3} lg={2} xl={3}>
                            <Grid container justifyContent={{ xs: "center", sm: "start" }}>
                                <Typography variant="h3" gutterBottom display="inline">
                                    Users
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* New Member & Search Button  */}
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Grid container spacing={2} flexDirection={{ xs: "column", sm: "row" }} justifyContent={{ xs: "center", sm: "space-between", md: "end", lg: "end", xl: "end" }} alignItems="center">
                                <Grid item width={{ xs: "100%", sm: "initial" }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => { setPopupType("Add"); setMemberDetails({ username: "", email_address: "", user_id: "", user_role_flag: "" }); setFlagMemberPopup(true) }}
                                            sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                                        >
                                            <Add />
                                            New User
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item width={{ xs: "100%", sm: "initial", md: "50%", lg: "initial" }}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <Input
                                            placeholder={"Search"}
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                        />
                                    </Search>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}><Divider mb={4} /></Grid>
            </Grid>


            {/* Users Table Section */}
            <Grid container mt={1}>
                <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }}>
                    <DataGridDemo />
                </Grid>
            </Grid>
        </>
    );
};

export default BATeam;

import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Add, Close } from "@mui/icons-material";
import {
  Divider as MuiDivider, Typography, Box, Grid, InputBase, Button, TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { Stack, spacing } from "@mui/system";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

// Image Import
import PrimaryLogo from "../../assets/primary-logo.png";
import SecondaryLogo from "../../assets/secondary-logo.png";
import LifeInsuranceChip from "../../assets/life-insurance-chip.png";
import AnnuityInsuranceChip from "../../assets/annuity-insurance-chip.png";
import KebabMenuIcon from "../../assets/kebab-menu-icon.png";
import { DeleteAgentDataApi, GetAgentDataList } from "../../utils/api/AgentApi";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import GlobalDialog from "../../components/global/GlobalDialog";
import deleteAlert from '../../assets/Delete-alert.png'
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { Context as agentContext } from "../../contexts/reducerContexts/agentContext";
import "../css/Agent.css"
import { UserMeApi } from "../../utils/api/userDetailApi";
const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)`
  border: none;
  padding: 20px;
  .MuiDataGrid-columnHeaders {
    background-color: #233044;
    color: white;
  }

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-cell {
    border-bottom:1px solid white;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row:nth-child(odd) {
    background-color: #efefef;
    margin: 1px 0;
  }

  .MuiDataGrid-row:nth-child(even) {
    background-color: rgba(230 230 230);
    margin: 1px 0;
  }
  .customRowSigned{
    border-left: 5px solid #3fa976;
  }
  .customRowUnsigned{
    border-left: 5px solid #d65656;
  }

  ${'' /* .MuiDataGrid-sortIcon {
    opacity: 1;
    color: white;
  }

  .MuiDataGrid-menuIconButton {
    opacity: 1;
    color: white;
  } */}

`;

const CustomTextField = styled(TextField)({
  backgroundColor: "#EFEFF0",
  width: "230px",
  "& input": {
    padding: "9px 16px",
  },
  "& fieldset": {
    border: "none",
  },
});

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

function CheckboxesGroup(props) {
  const { ndaStatus, handleChangeNda } = props
  const { signed, not_signed } = ndaStatus;

  return (
    <Grid container justifyContent={{ xs: "center", sm: "center", md: "end", lg: "center" }} direction="row"
      sx={{
        "& .PrivateSwitchBase-root": {
          padding: " 0 5px 0 0 !important"
        }
      }}
    // spacing={1}
    >
      <Grid item xs={3} sm={4} md={4} lg={4} xl={4}>
        <Typography
          variant="outlined"
          sx={{ color: "#7A7A7A", marginRight: "16px" }}
          gutterBottom
          display="inline"
        >
          NDA status
        </Typography>
      </Grid>
      <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={signed}
                onChange={handleChangeNda}
                name="signed"
              />
            }
            label="Signed"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={3.5} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={not_signed}
                onChange={handleChangeNda}
                name="not_signed"
              />
            }
            label="Not Signed"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

function Checkboxes(props) {
  const { typeState, handleChangeType } = props
  const { life, annuity } = typeState;

  return (
    <Grid container justifyContent="center" direction="row"
      sx={{
        "& .PrivateSwitchBase-root": {
          padding: " 0 5px 0 0 !important"
        }
      }}
    // spacing={2}
    >
      <Grid item xs={3} sm={3.5} md={2.3} lg={2.3} xl={2.3}>
        <Typography
          variant="outlined"
          sx={{ color: "#7A7A7A", marginRight: "16px" }}
          gutterBottom
          display="inline"
        >
          Type
        </Typography>
      </Grid>

      <Grid item xs={3.5} sm={3.8} md={2.8} lg={2.8} xl={2.8}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={life}
                onChange={handleChangeType}
                name="life"
              />
            }
            label="Life"
          />
        </FormGroup>
      </Grid>

      <Grid item xs={3.5} sm={3.5} md={3} lg={3} xl={3}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={annuity}
                onChange={handleChangeType}
                name="annuity"
              />
            }
            label="Annuity"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  return `${year}/${month < 10 ? "0" : ""}${month}/${day < 10 ? "0" : ""}${day}`;
}

const Agents = () => {
  const { FlagApiLoader, UpdateSnackbarMessage, UpdateUserDetail, UpdateFlagAuthenticated, state, UpdateDelegateAccessFlag, UpdateDelegateAccessData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [ndaStatus, setNdaStatus] = React.useState({
    signed: true,
    not_signed: true,
  });
  const [typeState, setTypeState] = React.useState({
    life: true,
    annuity: true,
  });
  const [agentDataList, setAgentDataList] = useState([])
  const [agentGloablDataList, setAgentGlobalDataList] = useState([])
  const { setGlobalLifeCarrierData, setGlobalAnnuityCarrierData, setCarrierLevelData, setCarrierAnnuityLevelData, setCarrierLifeLevelData } = useContext(globalCarrierContext);
  const { setAgentUserData } = useContext(agentContext);
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [deleteAgentData, setDeleteAgentData] = useState({});
  const [changeAgentStatusAndType, setChangeAgentStatusAndType] = useState(false);
  const [noDataMsgFlag, setNoDataMsgFlag] = useState(true);

  useEffect(() => {
    var arr = []
    var singed = ndaStatus.signed;
    var NotSinged = ndaStatus.not_signed;
    var life = typeState.life;
    var annuity = typeState.annuity;
    let tt = [...agentGloablDataList]
    if (singed && NotSinged && life && annuity) {
      setAgentDataList(tt)
    } else {
      for (var i in tt) {
        var insertFlag = false;
        for (var j in tt[i]?.agent_user_data) {
          if (tt[i]?.agent_user_data[j]) {
            if (singed) {
              var findSignedcheck = tt[i]?.agent_user_data[j]['user_nda_log'].filter(rr => rr.nda_activity_message == "Signed")
              if (findSignedcheck.length) {
                insertFlag = true
              }

            } else if (NotSinged) {
              var findSignedcheck = tt[i]?.agent_user_data[j]['user_nda_log'].filter(rr => (rr.nda_activity_message.indexOf("Sent") > -1 || rr.nda_activity_message.indexOf("Remind") > -1))
              if (findSignedcheck.length) {
                insertFlag = true
              }
            }
            if (!insertFlag) {
              continue
            } else {
              if (life && annuity) {
                insertFlag = true
              } else if (life) {
                if (tt[i]?.agent_commission_data.length) {
                  var findCommissionData = tt[i]?.agent_commission_data.filter(rr => rr.carrier_type_flag == true)
                  if (findCommissionData.length) {
                    insertFlag = true
                  } else {
                    insertFlag = false
                  }
                }
              } else if (annuity) {
                var findCommissionData = tt[i]?.agent_commission_data.filter(rr => rr.carrier_type_flag == false)
                if (findCommissionData.length) {
                  insertFlag = true
                } else {
                  insertFlag = false
                }
              }

            }

          }
        }
        if (insertFlag) {
          arr.push(tt[i])
        }

      }
      setAgentDataList(arr)

    }

  }, [changeAgentStatusAndType])

  const columns = [
    {
      field: "logo",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return (
          <Grid
            container
            alignItems={params?.row?.agent_user_data?.length === 2 ? "end" : "center"}
            marginTop={params?.row?.agent_user_data?.length === 1 ? "5px" : "0"}
            justifyContent="center"
            height="100%"
          >
            {params?.row?.agent_user_data?.map((element) => {
              return (
                <Grid item>
                  {element?.primary_data_flag == true
                    ?
                    <img src={PrimaryLogo} />
                    :
                    <img src={SecondaryLogo} />
                  }
                </Grid>
              )
            })}
          </Grid>
        );
      },
      sortable: false,
    },
    {
      field: "name", headerName: "Name", width: 200,
      headerClassName: "type_header_class",
      renderCell: (params) => {
        return (<Grid container flexDirection="column" rowGap={4} key={params} mt="1px">
          {params?.row?.agent_user_data?.map((element) => {
            return (
              <Grid item>
                <Typography
                  color="#1B6AC5"
                  fontFamily="Segoe UI"
                  fontWeight="400"
                  fontSize="15px"
                  lineHeight="20px"
                >
                  {element?.username}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_user_data?.map(v => v.username).join(" "),
      sortable: true,
    },
    {
      field: "email", headerName: "Email", width: 300,
      renderCell: (params) => {
        return (<Grid container flexDirection="column" rowGap={4} marginTop="1px">
          {params?.row?.agent_user_data?.map((element) => {
            return (
              <Grid item>
                <Typography
                  fontFamily="Segoe UI"
                  fontWeight="400"
                  fontSize="15px"
                  lineHeight="20px">

                  {element.email_address}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_user_data?.map(v => v.email_address).join(" "),
      sortable: true,
      headerClassName: "type_header_class",
    },
    {
      field: "type",
      headerName: "Type",
      width: 190,
      renderCell: (params) => {
        return (
          <Grid container columnGap={2} marginTop="6px">
            {params?.row?.agent_commission_data?.map((element) => {
              return (
                element.carrier_type_flag == true
                  ?
                  <Grid item >
                    <img src={LifeInsuranceChip} />
                  </Grid>
                  :
                  <Grid item >
                    <img src={AnnuityInsuranceChip} />
                  </Grid>
              )
            })}
          </Grid>
        );
      },
      headerClassName: "type_header_class",
      sortable: false
    },
    {
      field: "lifeTemplate",
      headerName: "Life Template",
      width: 180,
      headerClassName: "type_header_class",
      renderCell: (params) => {
        return (
          <Grid container columnGap={2} marginTop="1px">
            {params?.row?.agent_commission_data?.map((element) => {
              return (
                element.template_assign_flag == true && element.carrier_type_flag == true
                &&
                <Grid item>
                  <Typography
                    fontFamily="Segoe UI"
                    fontWeight="400"
                    fontSize="15px"
                    lineHeight="20px">

                    {element?.template_name}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>

        )
      },
      sortable: true,
      valueGetter: (params) => params?.row?.agent_commission_data[0].template_name,
    },
    {
      field: "annuityTemplate",
      headerName: "Annuity Template",
      headerClassName: "type_header_class",
      width: 180,
      renderCell: (params) => {
        return (
          <Grid container columnGap={2} marginTop="1px">
            {params?.row?.agent_commission_data?.map((element) => {
              return (
                element.template_assign_flag == true && element.carrier_type_flag == false
                &&
                <Grid item>
                  <Typography
                    fontFamily="Segoe UI"
                    fontWeight="400"
                    fontSize="15px"
                    lineHeight="20px">

                    {element?.template_name}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>

        )
      },
      sortable: true,
      valueGetter: (params) => params?.row?.agent_commission_data?.map(v => v?.template_name),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      headerClassName: "type_header_class",
      width: 170,
      renderCell: (params) => {
        return (
          <Grid container columnGap={2} marginTop="1px">
            <Grid item>
              <Typography
                fontFamily="Segoe UI"
                fontWeight="400"
                fontSize="15px"
                lineHeight="20px">

                {formatDate(params?.row?.agent_created_at_timestamp)}
              </Typography>
            </Grid>
          </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_created_at_timestamp,
      sortable: true,
    },
    {
      field: "ndaStatus", headerName: "NDA Status", width: 250,
      headerClassName: "type_header_class",
      renderCell: (params) => {
        return (<Grid container flexDirection="column" rowGap={4} marginTop="1px">
          {params?.row?.agent_user_data?.map((element) => {
            return (
              element?.user_nda_log?.map((ndaRow) => {
                return (
                  <Grid item>
                    <Typography
                      fontFamily="Segoe UI"
                      fontWeight="400"
                      fontSize="15px"
                      lineHeight="20px">
                      {ndaRow?.nda_activity_message}
                    </Typography>
                  </Grid>
                )
              })
            )
          })}
        </Grid>
        );
      },
      valueGetter: (params) => params?.row?.agent_user_data?.map(v => v?.user_nda_log?.map(ele => ele.nda_activity_message)).join(" "),
      sortable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      align: "right",
      width: 100,
      headerClassName: "type_header_class",
      sortable: false,
      getActions: (params) => [
        // <GridActionsCellItem
        //   // icon={<DeleteIcon />}
        //   label="Delete"
        //   // onClick={}
        // />,
        <GridActionsCellItem
          // icon={<Security />}
          label="Delete"
          onClick={() => {
            console.log("paramRow::", params?.row)
            setDeleteAgentData(params?.row)
            setFlagDeletePopup(true)
          }}
          showInMenu
        />,
      ],
    },
  ];

  const deletePopup = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to delete {deleteAgentData?.agent_user_data[0]?.username}?
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagDeletePopup(false)
                        FlagApiLoader(true)
                        DeleteAgentDataApi(deleteAgentData?.agent_id, (res) => {
                          console.log("resss", res)
                          if (res?.data?.status) {
                            console.log("response status true of DeleteAgentDataApi", res);
                            let tempAgentGlobalList = [...agentGloablDataList];
                            let tempAgentList = [...agentDataList];
                            tempAgentGlobalList = tempAgentGlobalList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                            tempAgentList = tempAgentList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                            setAgentGlobalDataList(tempAgentGlobalList)
                            setAgentDataList(tempAgentList)
                            UpdateSnackbarMessage({
                              status: "success",
                              message: res?.data?.data?.message
                            });
                            CallUserMe()
                            FlagApiLoader(false)
                          }
                          else {
                            console.log("response status false of DeleteAgentDataApi", res);
                            if (res?.data?.error?.message) {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                              });
                              FlagApiLoader(false)
                            }
                            else {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            }
                          }
                        },
                          (err) => {
                            console.log("error of this DeleteAgentDataApi api", err)
                            UpdateSnackbarMessage({
                              status: "error",
                              message: "Something went wrong!"
                            });
                            FlagApiLoader(false)
                          })
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagDeletePopup(false) }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTON CODE IF ERROR OCCURES ABOVE REFER THIS*/}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => { setFlagDeletePopup(false) }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setFlagDeletePopup(false)
                          FlagApiLoader(true)
                          DeleteAgentDataApi(deleteAgentData?.agent_id, (res) => {
                            console.log("resss", res)
                            if (res?.data?.status) {
                              let tempAgentGlobalList = [...agentGloablDataList];
                              let tempAgentList = [...agentDataList];
                              tempAgentGlobalList = tempAgentGlobalList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                              tempAgentList = tempAgentList?.filter((element) => element?.agent_id != deleteAgentData?.agent_id)
                              setAgentGlobalDataList(tempAgentGlobalList)
                              setAgentDataList(tempAgentList)
                              UpdateSnackbarMessage({
                                status: "success",
                                message: res?.data?.data?.message
                              });
                              CallUserMe()
                              FlagApiLoader(false)

                            } else {
                              UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.error?.message
                              });
                              FlagApiLoader(false)
                            }
                          }, (err) => {
                            UpdateSnackbarMessage({
                              status: "error",
                              message: "Something went wrong!"
                            });
                            console.log("errrr", err)
                            FlagApiLoader(false)
                          })
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  function DataGridDemo(props) {
    const { agentDataList } = props
    const navigate = useNavigate();
    return (
      <Box sx={{ height: "81vh", width: "100%" }}>
        <CustomDataGrid
          rows={agentDataList?.length && agentDataList}
          getRowId={(row) => row?.agent_id}
          onCellClick={(param) => {
            if (param?.field != "actions") {
              navigate(`/view_agent?id=${param?.row?.agent_id}`)
            }
          }}
          className="agent_list_data_grid"
          sx={{ padding: { xs: "10px 10px 0 10px", sm: "20px 20px 0 20px" }, }}
          columns={columns}
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: 'name', sort: 'desc' }],
          //   },
          // }}
          pageSize={20}
          // rowCount={agentDataList?.length}
          // rowCount={(agentDataList?.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          rowsPerPageOptions={[8]}
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          density="comfortable"
          headerHeight={30}
          column
          // count={agentDataList?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {noDataMsgFlag == false && state.apiLoader == false ? "No Data Found!" : ""}
              </Stack>
            ),
          }}
          getRowClassName={(params) => {
            let ndaMessageArray = []
            params?.row?.agent_user_data?.map((user) => {
              user?.user_nda_log?.map((ndaRow) => {
                ndaMessageArray.push(ndaRow?.nda_activity_message)
              })
            })
            if (ndaMessageArray?.indexOf("Signed") > -1) {
              return "customRowSigned"
            } else {
              return "customRowUnsigned"
            }
          }}
        />
      </Box>
    );
  }

  const handleChangeNda = (event) => {
    setChangeAgentStatusAndType(!changeAgentStatusAndType)
    if (event.target.checked == false) {
      if (event.target.name == "signed") {
        if (ndaStatus?.not_signed == true) {
          setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
        }
      } else {
        if (ndaStatus?.signed == true) {
          setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
        }
      }
    } else {
      setNdaStatus({ ...ndaStatus, [event.target.name]: event.target.checked });
    }
  };

  const handleChangeType = (event) => {
    setChangeAgentStatusAndType(!changeAgentStatusAndType)
    if (event.target.checked == false) {
      if (event.target.name == "life") {
        if (typeState?.annuity == true) {
          setTypeState({ ...typeState, [event.target.name]: event.target.checked });
        }
      } else {
        if (typeState?.life == true) {
          setTypeState({ ...typeState, [event.target.name]: event.target.checked });
        }
      }
    } else {
      setTypeState({ ...typeState, [event.target.name]: event.target.checked });
    }
  };

  // console.log("agentGloablDataList", agentGloablDataList)

  const SearchAgent = (name) => {
    let tempAgentData = [...agentGloablDataList]
    let filteredAgents;
    if (!name) {
      filteredAgents = tempAgentData;
    } else {
      filteredAgents = tempAgentData?.filter((rr) => {
        var matchFound = []
        rr?.agent_user_data?.map((nn) => {
          // if (nn.primary_data_flag) {
          if (nn.username.toLowerCase().indexOf(name.toLowerCase()) > -1 || nn.email_address.toLowerCase().indexOf(name.toLowerCase()) > -1) {
            matchFound.push(true)
          } else {
            matchFound.push(false)
          }
          // }
        })
        if (matchFound.indexOf(true) > -1) {
          return true
        } else {
          return false
        }
      })
    }
    setAgentDataList(filteredAgents)
  }

  const ClearAgentDataContext = () => {
    setAgentUserData({
      primary: {
        "primary_data_flag": true,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      secondary: {
        "primary_data_flag": false,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      carrier_type_life: false,
      carrier_type_annuity: false
    })
  }

  const CallUserMe = () => {
    UserMeApi({},
      (res) => {
        UpdateFlagAuthenticated(res?.data?.status)
        if (res?.data?.status) {
          console.log("response true of UserMeApi api", res);
          UpdateUserDetail(res.data.data)
          UpdateDelegateAccessFlag(res?.data?.delegate_access_flag)
          if (res?.data?.delegate_access_flag) {
            UpdateDelegateAccessData(res?.data?.delegate_user_data)
          }
        }
        else {
          console.log("response false of UserMeApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      }, (err) => {
        console.log("error of UserMeApi api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!"
        });
      })
  }

  useEffect(() => {
    if (agentDataList?.length) {
      setNoDataMsgFlag(true)
    } else {
      setNoDataMsgFlag(false)
    }
  }, [agentDataList])

  useEffect(() => {
    FlagApiLoader(true)
    CallUserMe()
    ClearAgentDataContext()

    setGlobalLifeCarrierData([]);
    setGlobalAnnuityCarrierData([])

    // OLD CODE 
    // setCarrierLevelData([])

    // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
    setCarrierAnnuityLevelData([])
    setCarrierLifeLevelData([])
    // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **


    GetAgentDataList((res) => {
      if (res?.data?.status) {
        console.log("response true of GetAgentDataList api", res);
        let sortedAgentList = res?.data?.data?.sort((a, b) =>
          a.agent_created_at_timestamp < b.agent_created_at_timestamp ? 1 :
            a.agent_created_at_timestamp > b.agent_created_at_timestamp ? -1 : 0
        )
        setAgentDataList(sortedAgentList)
        setAgentGlobalDataList(sortedAgentList)
        FlagApiLoader(false)
      }
      else {
        console.log("response false of GetAgentDataList api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
        FlagApiLoader(false)
      }
    }, (err) => {
      console.log("error response false of GetAgentDataList api", err);
      FlagApiLoader(false)
      UpdateSnackbarMessage({
        status: "error",
        message: "Something went wrong!"
      });
    })
  }, [])

  // useEffect(() => {
  //   let tempAgentList = [...agentGloablDataList]
  //   if (ndaStatus.signed == true && ndaStatus.not_signed == false) {
  //     if (typeState.life == true && typeState.annuity == false) {
  //       tempAgentList = tempAgentList?.filter((obj)=>{
  //         obj?.
  //       })
  //     }else if (typeState.life == false && typeState.annuity == true) {

  //     }else if (typeState.life == true && typeState.annuity == true) {

  //     }
  //   }else if (ndaStatus.signed == false && ndaStatus.not_signed == true) {

  //     if (typeState.life == true && typeState.annuity == false) {

  //     }else if (typeState.life == false && typeState.annuity == true) {

  //     }else if (typeState.life == true && typeState.annuity == true) {

  //     }

  //   }
  //   if (ndaStatus.signed == true && ndaStatus.not_signed == true) {

  //     if (typeState.life == true && typeState.annuity == false) {

  //     }else if (typeState.life == false && typeState.annuity == true) {

  //     }else if (typeState.life == true && typeState.annuity == true) {

  //     }

  //   }

  // }, [ndaStatus, typeState])

  return (
    <>
      {flagDeletePopup && (
        <GlobalDialog
          open={flagDeletePopup}
          data={deletePopup()}
          maxWidth={"sm"}
        />
      )
      }
      <Helmet title="myAdvisorGrids | Agents" />

      {/* Navigation Section */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="agentmainPageStickyHeader"
      // sx={{ marginBottom: "15px" }}
      // spacing={3}
      >
        <Grid item xs={11.5} sm={11.2} md={11.5} lg={11.6} xl={11.6} my={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={2} md={2} lg={1.5} xl={2.5}>
              <Grid container justifyContent={{ xs: "center", sm: "start" }}>
                <Typography variant="h3" gutterBottom display="inline">
                  Agents
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={9.8} lg={6.5} xl={6} direction={"row"}>
              <Grid
                container
                sx={{ justifyContent: { sm: "end", md: "end", } }}
                paddingTop="8px"
              // spacing={3.5}
              >
                <Grid item xs={12} sm={6.5} md={6} lg={6} xl={5.5}>
                  <CheckboxesGroup ndaStatus={ndaStatus} handleChangeNda={handleChangeNda} />
                </Grid>
                <Grid item xs={12} sm={4.5} md={5} lg={6} xl={5.5} marginTop={{ xs: "10px", sm: "0px" }}>
                  <Checkboxes typeState={typeState} handleChangeType={handleChangeType} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4} xl={3.5}>
              <Grid
                container
                // spacing={2}
                // marginTop={{ xs: "5px", sm: "0px", md: "0px", lg: "0px", xl: "0px" }}
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-between", lg: "end", xl: "end" }}
                alignItems="center">
                <Grid
                  item
                  marginTop={{ xs: "10px", sm: "0px" }}
                  // xs={12}
                  xl={4.5}
                  lg={4.5}
                  md={8}
                  sm={7}
                  sx={{ marginRight: "8px", textAlign: { md: "start", lg: "center" } }}
                // width={{ xs: "100%", sm: "initial" }}
                >
                  <Button
                    // mr={2}
                    variant="contained"
                    color="primary"
                    // fullWidth
                    sx={{ padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 16px", lg: "6px 16px" } }}
                    onClick={() => navigate("add_agent")}

                  >
                    <Add />
                    New Agent
                  </Button>
                </Grid>
                <Grid
                  item
                  // xs={12}
                  xl={6.3}
                  lg={6.3}
                  md={3}
                  sm={4}
                  marginTop={{ xs: "10px", sm: "0px" }}
                // width={{ xs: "100%", sm: "initial", md: "30%", lg: "initial" }}
                >
                  <Search >
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={"Search"}
                      onChange={(e) => { SearchAgent(e.target.value) }}
                    />
                  </Search>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider mb={4} />
        </Grid>
      </Grid>


      {/* Agent Data Table Section */}
      <Grid container mt={1}>
        <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }}>
          <DataGridDemo agentDataList={agentDataList} />
        </Grid>
      </Grid>
    </>
  );
};

export default Agents;

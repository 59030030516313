import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Calculate, CheckBox, Close, ExpandMore, Flag } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import EditIcon from '@mui/icons-material/Edit';
import LifeAnnuityButtons from "./ViewCarrierComponents/LifeAnnuityBttons";
import dot from '../../../src/assets/Dot.png'
import Popover from '@mui/material/Popover';
import { Search as SearchIcon } from "react-feather";
import DeleteCarrierLevelPopUp from "./ViewCarrierDialogs/DeleteCarrierLevelPopup";
import {
  Link,
  Autocomplete,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  capitalize,
  InputLabel,
  InputBase,
  NativeSelect,
  ListItemIcon,
  useMediaQuery,
  Dialog,
  Checkbox,
  Slide,
  Backdrop,
  Tooltip,
  tooltipClasses,
  Collapse
} from "@mui/material";
import { flexbox, spacing, style, textAlign, width } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import BackIcon from "../../assets/back-icon.png";
import PdfIcon from "../../assets/pdf-icon.png";
import DeleteIcon from "../../assets/delete-icon.png";
import { CreateCarrierAPI, GetCarrierData, GetCarrierDetailsField, GetCarrierLevelColorList, GetCarrierVersionListData, UpdateCarrierAPI } from "../../utils/api/CarriersApi";
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import FileUpload from "../../components/global/FileUpload";
import { DeleteResources, GetCarrierDataList } from "../../utils/api/TemplateApi";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import GlobalDialog from "../../components/global/GlobalDialog";
import '../css/ViewCarriers.css'
import deleteAlert from '../../assets/Delete-alert.png'
import LifeInsuranceChip from "../../assets/life-insurance-chip.png";
import AnnuityInsuranceChip from "../../assets/annuity-insurance-chip.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import moment from "moment";
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";
import DeleteCarriarSectionConfirmationDialog from "./ViewCarrierDialogs/DeleteCarriarSectionConfirmationDialog";
import LevelCustomizationDialog from "./ViewCarrierDialogs/LevelCustomizationDialog";
import UnsavedChangesDialog from "./ViewCarrierDialogs/UnsavedChangesDialog";
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteRowPopup from "./ViewCarrierDialogs/DeleteRowPopup";
import DeleteCarrierSectionDialog from "./ViewCarrierDialogs/DeleteCarrierSectionDialog";
import { Context as CarrierContext } from "../../contexts/reducerContexts/globalCarrierContext"
import { darken } from "polished";
import Loader from "../Loader/loader";

const Search = styled.div`
  border-radius: 0px;
  background-color: #fff;
  display: none;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 6px #000;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const BackIconComponent = () => (
  <img
    src={BackIcon}
    alt="Logo"
    width="28px"
    height="28px"
    text-align="center"
    justify-content="center"
  />
);

const CustomLabelTypography = styled(Typography)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding-top:4.5px
`;

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1565c0",
    color: '#ffffff',
    fontWeight: 400,
    fontSize: "13px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#1565c0", // Apply the arrow styles here
  },
}));

const CustomAccordion = styled(Accordion)({
  marginBottom: "20px !important",
});

const CustomTextFied = styled(TextField)({
  // padding: '5px 0px',
})

const ViewCarrier = () => {
  const { carrierName, } = useContext(CarrierContext).state

  // const { setCarrierDetailsDuplicate } = useContext(CarrierContext)
  // const { carrierName, carrierDetailsDuplicate } = useContext(CarrierContext).state


  const location = useLocation();
  const PathName = window.location.pathname

  const minScreenWidth = useMediaQuery('(max-width:1280px)')
  const navigate = useNavigate();
  const [editDetails, setEditDetails] = useState(window.location.pathname == "/carriers/add_carrier" ? true : false)
  const [carrierVersionList, setCarrierVersionList] = useState()
  const [carrierDetails, setCarrierDetails] = useState({
    carrier_details: {
      carrier_data_column_left: [],
      carrier_data_column_right: []
    }
  })
  const [carrierDetailsDuplicate, setCarrierDetailsDuplicate] = useState([])

  const [currentVersion, setCurrentVersion] = useState("")
  const [type, setType] = useState("all")
  const { FlagApiLoader, UpdateSnackbarMessage, } = useContext(authContext)
  const { userDetail, apiLoader } = useContext(authContext).state

  const { setCarrierData } = useContext(templateContext)
  const { carrierData, } = useContext(templateContext).state

  // console.log("userDetail", userDetail);


  const [currentCarrierName, setCurrentCarrierName] = useState("")
  const [flagCall, setFlagCall] = useState("default")
  const [flagLevelTablePopup, setFlagLevelTablePopup] = useState(false)
  const [flagPdfPopup, setFlagPdfPopup] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  let [searchParams, setSearchParams] = useSearchParams();
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [flagResourceExpand, setFlagResourceExpand] = useState(false);
  const [flagCarrierDetailsExpand, setFlagCarrierDetailsExpand] = useState(true);
  const [flagLevelAccordionExpanded, setFlagLevelAccordionExpanded] = useState(true)
  const [flagFileUploadPopup, setFlagFileUploadPopup] = useState(false);
  const [deleteResourceData, setDeleteresourceData] = useState({
    carrier_detail: {},
    file_detail: {}
  })
  // const [addLevels, setAddLevels] = useState()
  const [newLevel, setNewLevel] = useState({
    carrier_level_id: null,
    carrier_level_name: "L4",
    carrier_ba_level_flag: true,
    carrier_level_sequence: 1,
    carrier_level_color_id: 3,
    carrier_level_color_hex_code: "#ffffff",
    carrier_level_color_name: "White"
  })

  const [toggleButton, setToggleButton] = useState()
  //_______________this state is to Selecte Color For Column_________________//
  // const [selectedColor, setSelectedColor] = useState('#000000')
  const [AddColumn, setAddColumn] = useState(null)
  const [addRow, setAddRow] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addLevels, setAddLevels] = useState(null)
  const [anchorElForRow, setAnchorElForRow] = React.useState(null);
  const [anchorElForLevelsColumn, setAnchorElForLevelsColumn] = React.useState({ type: null, columnId: 0 });
  const [selectedRowGroupId, setSelectedRowGroup] = useState({ selectId: null, selectedId: null })
  const [isSelectedRow, setSelectedRow] = useState(null)
  const [isRowGroup, SelectedRow] = useState({ rowGroup: true, Id: null })

  const [flagDialogGloballyRender, setFlagDialogGloballyRender] = useState(false)
  const [flagAfterLevelPopoverRender, setFlagAfterLevelPopoverRender] = useState(false)
  const [flagPageRender, setFlagPageRender] = useState(null)

  const [flagCarrierCustomizationGlobalDialog, setFlagCarrierCustomizationGlobalDialog] = useState(false)
  const [flagCarrierCustomizationDeleteDialog, setFlagCarrierCustomizationDeleteDialog] = useState(false)
  const [flagCarrierCustomizationGlobalDeleteDialog, setFlagCarrierCustomizationGlobalDeleteDialog] = useState(false)
  const [flagCarrierSelection, setFlagCarrierSelection] = useState(false)


  // LEVEL FLOW
  const [levelSelectedData, setLevelSelectedData] = useState(null)
  const [colorList, setColorList] = useState([])
  const [selectedColor, setSelectedColor] = useState({ color: null, hexCode: null })
  const [sectionOrRow, setSectionOrRow] = useState('')
  const [emptyLevelName, setEmptyLevelName] = useState(false)

  // DIALOG
  const [flagDeleteCarriarSectionDialog, setFlagDeleteCarriarSectionDialog] = useState(false)
  const [flagCustomizationCarriarSectionDialog, setFlagCustomizationCarriarSectionDialog] = useState(false)
  const [flagUnsavedChangesDialog, setFlagUnsavedChangesDialog] = useState(false)
  const [selectedLine, setSelectedLine] = useState(null)
  const [selectedLinePopover, setSelectedLinePopover] = useState({
    groups: null,
    index: null
  })

  const [selectedRowIndex, setSelectedRowIndex] = useState({
    groupData: null,
    levelIdx: null,
    groupIdx: null
  })
  const [flagShowCloseLevelSection, setFlagShowCloseLevelSection] = useState(true)
  const [flagAddRow, setFlagAddRow] = useState(false)
  const [flagShowAddIcons, setFlagShowAddIcons] = useState(false)
  const [deleteRowPopupFlag, setDeleteRowPopupFlag] = useState(false)
  const [newLevelData, setNewLevelData] = useState({
    carrier_level_after: null,
    carrier_level_id: null,
    carrier_level_name: "",
    carrier_ba_level_flag: false,
    // carrier_level_sequence: 1,
    carrier_level_color_id: 1,
    carrier_level_color_hex_code: "#1b2430",
    carrier_level_color_name: "Default"
  })
  const [hideTransparentCell, setHideTransperentCell] = useState(false)
  const [deleteRow, setDeleteRow] = useState(null)
  const [carrierLevelLength, setCarrierLevelLength] = useState(false)
  const [slectedAddIcon, setSelectedAddIcon] = useState(null)
  const [isColHover, setColHover] = useState(false)

  // AUTOM COMPLETE STATE MAY BE use in Future
  // const [autoCompleteCarrierList, setAutoCompleteCarrierList] = useState([])
  // const [autoCompleteValue, setAutoCompleteValue] = useState({ carrier_name: "", carrier_id: "" })
  const [navSearchBar, setNavSearchBar] = useState(false)

  const [currentlyAddedCols, setCurrentlyAddedCols] = useState([])

  let leftSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == true)
  let rightSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == false)
  let Levels
  if (leftSideHeaders && rightSideHeaders) {
    Levels = [...leftSideHeaders, ...rightSideHeaders]
  }
  const rowPopOveropen = Boolean(anchorElForRow);
  const rowId = rowPopOveropen ? 'simple-popover1' : undefined;

  const [sliderCoordinates, setSliderCoordinates] = useState({
    x: 0,
    y: 0
  })

  // console.log("sliderCoordinates", sliderCoordinates);

  const [lastCellExtraWidth, setLastCellExtraWidth] = useState(0);

  const [flagAddLevelEnable, setFlagAddLevelEnable] = useState(false)


  const tableContainerRef = useRef(null)

  const numsOfCarrierLevelCols = carrierDetails?.carrier_level?.length

  /* useEffect(() => {
    const vcLevelTable = document.getElementById('vc-level-table');
    const customTable = document.getElementById('custom-table');

    if (vcLevelTable && customTable) {
      if (vcLevelTable.clientWidth < customTable.clientWidth) {
        const extraWidth = Math.abs(vcLevelTable.clientWidth - customTable.clientWidth);
        setLastCellExtraWidth(extraWidth)
      }
    }
  }, []); */


  const handleCarrierSaveUpdate = () => {

    if (window.location.pathname == "/carriers/add_carrier") {
      let payload = { ...carrierDetails, carrier_details: [...carrierDetails?.carrier_details?.carrier_data_column_left, ...carrierDetails?.carrier_details?.carrier_data_column_right] }
      FlagApiLoader(true)
      CreateCarrierAPI(payload, (res) => {
        if (res?.data?.status) {
          navigate("/carriers")
          UpdateSnackbarMessage({
            status: "success",
            message: "New Carrier Created Successfully"
          })
          FlagApiLoader(false)
        }
        else {
          console.log("response false of CreateCarrierAPI api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
            FlagApiLoader(false)
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
            FlagApiLoader(false)
          }
        }
      }, (err) => {
        console.log("error of CreateCarrierAPI api", err);
        FlagApiLoader(false);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
      })
    }
    else {
      const filteredCarrierLevelData = carrierDetails?.carrier_level_data?.filter(group => group?.carrier_group_data?.length > 0);
      let payload = {
        ...carrierDetails,
        carrier_level_data: filteredCarrierLevelData,
        carrier_details: [
          ...carrierDetails?.carrier_details?.carrier_data_column_left,
          ...carrierDetails?.carrier_details?.carrier_data_column_right
        ]
      };
      // Old Code
      // let payload = { ...carrierDetails, 
      // carrier_details: [...carrierDetails?.carrier_details?.carrier_data_column_left, ...carrierDetails?.carrier_details?.carrier_data_column_right] }

      FlagApiLoader(true)

      UpdateCarrierAPI(payload, (res) => {
        if (res?.data?.status) {
          setFlagCall(new Date().getTime())
          setEditDetails(false)
          setHideTransperentCell(false)
          UpdateSnackbarMessage({
            status: "success",
            message: "Carrier Updated Successfully"
          })
          FlagApiLoader(false)
          setFlagShowAddIcons(false)
        }
        else {
          console.log("response false of UpdateCarrierAPI api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
            FlagApiLoader(false)
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
            FlagApiLoader(false)
          }

        }
      }, (err) => {
        console.log("error of UpdateCarrierAPI api", err);
        if (err?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: err?.data?.error?.message
          });
          FlagApiLoader(false)
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
          FlagApiLoader(false)
        }
      })
    }
  }

  // const handleValidation = () => {
  //   if (carrierDetails.carrier_name == "") {
  //     UpdateSnackbarMessage({
  //       status: "error",
  //       message: "Please add carrier name"
  //     })
  //     return
  //   }
  //   let stopFunction = false;
  //   if (carrierDetails?.carrier_level.length == 0) {
  //     stopFunction = true
  //     UpdateSnackbarMessage({
  //       status: "error",
  //       message: "Please add atleast one level"
  //     })
  //     return
  //   }
  //   carrierDetails?.carrier_level?.forEach((level) => {
  //     let levelId = level?.carrier_level_id || level?.carrier_level_id_custom;
  //     if (stopFunction) return;
  //     const dynamicColsRes = [];
  //     const staticColsRes = [{
  //       carrier_types: 0,
  //       carrier_state: 0,
  //       carrier_product_name: 0,
  //       carrier_sub_name: 0,
  //       carrier_ba_total_comp: 0
  //     }]
  //     for (const levelData of carrierDetails?.carrier_level_data) {
  //       if (stopFunction) return;

  //       for (const groupData of levelData?.carrier_group_data) {
  //         if (stopFunction) return;

  //         /* rows check */
  //         const rowDynamicColsEmpty = groupData?.carrier_commission_data?.filter(commission => commission?.commission_value == "");
  //         if (groupData?.carrier_types == "" && groupData?.carrier_state == "" && groupData?.carrier_product_name == "" && groupData?.carrier_sub_name == "" && groupData?.carrier_ba_total_comp == "" && rowDynamicColsEmpty?.length == carrierDetails?.carrier_level?.length) {
  //           // console.log("rows found empty");
  //           UpdateSnackbarMessage({
  //             status: "error",
  //             message: "Please fill all data"
  //           })
  //           stopFunction = true;
  //         }

  //         /* cols check */
  //         staticColsRes[0].carrier_types += groupData?.carrier_types != "" ? 1 : 0;
  //         staticColsRes[0].carrier_state += groupData?.carrier_state != "" ? 1 : 0;
  //         staticColsRes[0].carrier_product_name += groupData?.carrier_product_name != "" ? 1 : 0;
  //         staticColsRes[0].carrier_sub_name += groupData?.carrier_sub_name != "" ? 1 : 0;
  //         staticColsRes[0].carrier_ba_total_comp += groupData?.carrier_ba_total_comp != "" ? 1 : 0;

  //         const commissionData = groupData?.carrier_commission_data?.filter((commission) => {
  //           let commissionId = commission?.carrier_level_id || commission?.carrier_level_id_custom;
  //           return levelId == commissionId
  //         });
  //         if (commissionData?.length > 0) {
  //           dynamicColsRes.push(commissionData[0]);
  //         }
  //       }
  //     }
  //     const isEmptyDynamicCols = dynamicColsRes.filter(res => res?.commission_value == "");
  //     const isEmptyStaticCols = staticColsRes[0].carrier_types == 0 || staticColsRes[0].carrier_state == 0 || staticColsRes[0].carrier_product_name == 0 || staticColsRes[0].carrier_sub_name == 0 || staticColsRes[0].carrier_ba_total_comp == 0;

  //     if (isEmptyDynamicCols?.length == dynamicColsRes?.length || isEmptyStaticCols) {
  //       // console.log("cols found empty");
  //       UpdateSnackbarMessage({
  //         status: "error",
  //         message: "Please fill all data",
  //       })
  //       stopFunction = true;
  //     }
  //   });

  //   if (!stopFunction) {
  //     handleCarrierSaveUpdate()
  //   }
  // }

  const handleValidation = () => {
    let outerArr = []
    let errorFound = false;
    carrierDetails?.carrier_level_data?.map((level) => {
      level?.carrier_group_data?.map((group) => {
        let innerArr = [];
        group.carrier_types == "" ? innerArr.push(0) : innerArr.push(1);
        group.carrier_state == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_product_name == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_sub_name == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_ba_total_comp == "" ? innerArr.push(0) : innerArr.push(1)

        group?.carrier_commission_data?.map((commission) => {

          commission?.commission_value == "" ? innerArr.push(0) : innerArr.push(1)
        })
        outerArr.push(innerArr)
      })

    })

    for (let i = 0; i < outerArr.length; i++) {
      for (let j = 0; j < outerArr[i].length; j++) {
        if (outerArr[i][j] == 0) {
          let rowEmptyFound = outerArr[i].every((val) => val == 0);
          let colEmptyFound = outerArr.every((row) => row[j] === 0);
          if (rowEmptyFound && colEmptyFound) {
            errorFound = true
            break;
          }
        }
      }
    }

    if (errorFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please fill all data"
      })
      return
    }
    else {
      handleCarrierSaveUpdate()
    }

  }

  {/*Below section of code used to manipulate add row popover -->  Start*/ }
  const handleAddRowPopOverClick = (event, groupData, levelIdx, groupIdx) => {
    setAnchorElForRow(event.currentTarget);
    setSelectedRowIndex({
      groupData: groupData,
      levelIdx: levelIdx,
      groupIdx: groupIdx
    })
  };


  const handleAddRowPopOverClose = () => {
    setAnchorElForRow(null);
  };
  {/*Above section of code used to manipulate add row popover -->  End*/ }

  //  Level Popover
  const columnCellPopoverOpen = Boolean(anchorElForLevelsColumn.type);
  const columnId = columnCellPopoverOpen ? 'simple-popover1' : undefined;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const handleClick = (event, id) => {
    setSelectedRowGroup({
      selectId: id,
      selectedId: null
    })
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedLine(null)
  };

  {/*___this varible is to extract element to open popover on click of level button in add carrier page____ */ }
  /* const elementToOpenPopOver = document.getElementById('blank-tablecell') */

  const handleOpenColumnPopover = (event, i, ele) => {
    if (ele) {
      setAnchorElForLevelsColumn({ type: ele, columnId: 7 });
      // // console.log("anchorElForLevelsColumn::::::", ele)
    } else {
      // console.log("event", event.currentTarget)
      setAnchorElForLevelsColumn({ type: event.currentTarget, columnId: i });
      // // console.log("anchorElForLevelsColumn::::::", event.currentTarget)
    }

  };

  const handleCloseColumnPopover = () => {
    setAnchorElForLevelsColumn({ type: null, columnId: 0 });
    /* setSelectedAddIcon(null) */

  };

  const colorOptions = [
    { label: 'Downline Level', value: '#000000' },
    { label: 'Direct Carrier Level', value: '#CC4125' },
    { label: 'BA Level', value: '#F1C232' },
  ];


  const DeleteResouceFile = () => {
    setFlagDeletePopup(false)
    FlagApiLoader(true)
    let obj = {
      carrier_id: deleteResourceData?.carrier_detail?.carrier_id,
      carrier_resource_id: deleteResourceData?.file_detail.carrier_resource_id
    }
    DeleteResources(obj,
      (res) => {
        if (res?.data?.status) {
          setFlagCall(new Date().getTime())
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });
        } else {
          console.log("response false of DeleteResources api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
            FlagApiLoader(false)
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
            FlagApiLoader(false)
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateVersionList api", err);
        FlagApiLoader(false);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
      })
  }

  // handlers
  const handleCarrierLevelData = (e, carrier_group_id, carrier_group_data_id_custom, name) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_level_data: carrierDetails?.carrier_level_data?.map((group) => {
        if (group?.carrier_group_id == carrier_group_id || group?.carrier_group_id_custom == carrier_group_id) {
          return {
            ...group,
            carrier_group_data: group?.carrier_group_data?.map((groupData) => {
              if (groupData.carrier_group_data_id_custom == carrier_group_data_id_custom || groupData?.carrier_group_data_id == carrier_group_data_id_custom) {
                return { ...groupData, [name]: e.target.value }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }

    setCarrierDetails(newCarrierDetails)

    // // console.log("newCarrierDetails", newCarrierDetails)
  }

  const handleCarrierLevelCommissionData = (e, carrier_group_id, carrier_group_data_id_custom, carrier_commission_value_id) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_level_data: carrierDetails?.carrier_level_data?.map((group) => {
        if (group?.carrier_group_id == carrier_group_id || group?.carrier_group_id_custom == carrier_group_id) {
          return {
            ...group,
            carrier_group_data: group?.carrier_group_data?.map((groupData) => {
              if (groupData.carrier_group_data_id == carrier_group_data_id_custom || groupData.carrier_group_data_id_custom == carrier_group_data_id_custom) {
                return {
                  ...groupData,
                  carrier_commission_data: groupData?.carrier_commission_data?.map((commission) => {
                    if (commission?.carrier_commission_value_id == carrier_commission_value_id || commission?.carrier_commission_value_id_custom == carrier_commission_value_id) {
                      return { ...commission, commission_value: e.target.value }
                    }
                    return commission
                  })
                }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }
    // // console.log("newCarrierDetails", newCarrierDetails)
    setCarrierDetails(newCarrierDetails)

  }

  const handleAddNewLevel = () => {
    // let idToFind = newLevelData?.carrier_level_after == null ? carrierDetails?.carrier_level.length > 0 ? carrierDetails?.carrier_level[0].carrier_level_id : null : newLevelData?.carrier_level_after;

    if (newLevelData?.carrier_level_name == "") {
      setEmptyLevelName(true)
      UpdateSnackbarMessage({
        status: "error",
        message: "Please add level name"
      })
      return;
    }

    let duplicateFound = carrierDetails?.carrier_level?.find(level => level?.carrier_level_id != newLevelData?.carrier_level_id && level?.carrier_level_name == newLevelData?.carrier_level_name)
    if (duplicateFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please select unique level name"
      })
      setEmptyLevelName(true)
      return
    }
    let prevCarrierLevel = [...carrierDetails.carrier_level];
    let findCurrLevelIdx = prevCarrierLevel?.findIndex(level =>
      (level?.carrier_level_id != null && level?.carrier_level_id == newLevelData?.carrier_level_after) ||
      (level?.carrier_level_id_custom != null && level?.carrier_level_id_custom == newLevelData?.carrier_level_after)
    );

    let new_custom_id = new Date().getTime() + parseInt(Math.random() * 1000000000000000);

    let newCarrierLevelData = carrierDetails?.carrier_level_data?.map((section) => {
      return {
        ...section, carrier_group_data: section?.carrier_group_data?.map((group) => {
          let newCommission = [...group.carrier_commission_data]
          newCommission.splice(findCurrLevelIdx + 1, 0, {
            carrier_level_id_custom: new_custom_id,
            carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            carrier_level_name: newLevelData?.carrier_level_name,
            carrier_ba_level_flag: newLevelData?.carrier_ba_level_flag,
            // carrier_level_sequence: 1,
            commission_value: ""
          })
          // // console.log("newCommision", newCommission)
          return { ...group, carrier_commission_data: newCommission }
        })
      }
    });


    // let newCarrierLevelData = carrierDetails?.carrier_level_data?.map((section) => {
    //     // console.log("newCommisionnnnnn", section)
    //     return section;

    // });



    if (findCurrLevelIdx != -1) {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": newLevelData?.carrier_ba_level_flag,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: newLevelData?.carrier_level_color_id,
        "carrier_level_color_name": newLevelData?.carrier_level_color_name
      })
    }
    else {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": newLevelData?.carrier_ba_level_flag,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: newLevelData?.carrier_level_color_id,
        "carrier_level_color_name": newLevelData.carrier_level_color_name
      })
    }

    setCarrierDetails({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_level_data: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_level_data: newCarrierLevelData })
    setNewLevelData({
      carrier_level_after: null,
      "carrier_level_id": null,
      "carrier_level_name": "",
      "carrier_ba_level_flag": false,
      // "carrier_level_sequence": 1,
      "carrier_level_color_id": 1,
      "carrier_level_color_hex_code": "#1b2430",
      "carrier_level_color_name": "Default"
    })
    // handleCloseColumnPopover()
    document.getElementById("addLevelPopup").style.display = "none"
    setAnchorElForLevelsColumn({ type: null, columnId: 0 })
    setFlagAfterLevelPopoverRender(false)
    document.getElementById('addLevelPopup').style.display = "none"
    currentlyAddedCols.push(new_custom_id)
    setSelectedAddIcon(null)

  }

  // ADD NEW ROW
  const addNewRowForSection = (currGroup, levelIdx, groupIdx) => {
    let newCarrierLevelData = carrierDetails?.carrier_level_data?.map((level, i) => {
      if (i == levelIdx) {
        let newGroupData = { ...level }.carrier_group_data;
        let newObj = {
          ...currGroup, carrier_group_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000), carrier_types: "", carrier_state: "", carrier_product_name: "", carrier_sub_name: "", carrier_ba_total_comp: "",
          carrier_commission_data: currGroup?.carrier_commission_data?.map((commission) => {
            let carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
            return {
              carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000), //Nikunj Rathod
              [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
              carrier_level_name: commission?.carrier_level_name,
              carrier_ba_level_flag: commission?.carrier_ba_level_flag,
              commission_value: ""
            }
          })
        }

        delete newObj?.carrier_group_data_id;
        newGroupData.splice(groupIdx, 0, newObj)


        // let groupDataId = data-groupDataId || data-customeGroupDataId
        // if (carrier_commission_value_id == groupDataId || carrier_commission_value_id_custom == groupDataId){
        // }
        // {
        //     carrier_group_data_id_custom: 1,
        //     carrier_types: "",
        //     carrier_state: "",
        //     carrier_product_name: "",
        //     carrier_sub_name: "",
        //     carrier_ba_total_comp: "",
        //     carrier_commission_data: [
        //         {
        //             "carrier_commission_value_id_custom": 13639472,
        //             "carrier_level_id": 1,
        //             "carrier_level_name": "L3",
        //             "carrier_ba_level_flag": true,
        //             "carrier_level_sequence": 1,
        //             "commission_value": "120"
        //         },
        //     ]

        // }

        return { ...level, carrier_group_data: newGroupData }
      }
      return level
    });

    setCarrierDetails({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    handleAddRowPopOverClose()

  }

  // ADD NEW SECTION
  const addNewSection = (currGroup, levelIdx) => {
    const newCarrierLevelData = [...carrierDetails?.carrier_level_data];
    const currGroupData = { ...currGroup?.carrier_group_data[0] };

    // Create a deep copy of currGroupData
    const newObj = JSON.parse(JSON.stringify(currGroupData));

    newObj.carrier_group_data_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
    newObj.carrier_types = "";
    newObj.carrier_state = "";
    newObj.carrier_product_name = "";
    newObj.carrier_sub_name = "";
    newObj.carrier_ba_total_comp = "";

    delete newObj?.carrier_group_data_id;

    newObj.carrier_commission_data = newObj.carrier_commission_data.map((commission) => {
      const carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
      return {
        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
        [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
        carrier_level_name: commission?.carrier_level_name,
        carrier_ba_level_flag: commission?.carrier_ba_level_flag,
        commission_value: ""
      };
    });

    newCarrierLevelData.splice(levelIdx, 0, {
      carrier_group_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
      carrier_group_data: [newObj]
    });

    setCarrierDetails({ ...carrierDetails, carrier_level_data: newCarrierLevelData });
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData });
    handleClose();
  };

  const deleteRowForSection = () => {
    let newCarrierLevelData = carrierDetails?.carrier_level_data?.map((level, i) => {
      if (i == selectedRowIndex?.levelIdx) {
        let newGroupData = { ...level }.carrier_group_data;
        newGroupData.splice(selectedRowIndex?.groupIdx, 1)
        return { ...level, carrier_group_data: newGroupData }
      }
      return level
    });

    setCarrierDetails({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    setDeleteRowPopupFlag(false)
    handleClose()

  }

  // DELETE SECTION
  const deleteSection = () => {
    let newCarrierLevelData = [...carrierDetails?.carrier_level_data];
    newCarrierLevelData?.splice(selectedLinePopover?.index, 1)

    setCarrierDetails({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData })
    // handleClose()
    setDeleteRowPopupFlag(false)
  }

  // console.log("carrierDetails-----", carrierDetails);

  // HANDLE CARRIER DETAILS CHANGE
  const handleCarrierDetailsChangeLeft = (e, carrier_detail_field_id) => {
    setCarrierDetails({
      ...carrierDetails,
      carrier_details: {
        ...carrierDetails?.carrier_details,
        carrier_data_column_left: carrierDetails?.carrier_details?.carrier_data_column_left?.map((det) => {
          if (det.carrier_detail_field_id === carrier_detail_field_id) {
            return {
              ...det,
              carrier_detail_field_value: e.target.value
            };
          }
          return det;
        })
      }
    });
  };

  const handleCarrierDetailsChangeRight = (e, carrier_detail_field_id) => {
    setCarrierDetails({
      ...carrierDetails,
      carrier_details: {
        ...carrierDetails?.carrier_details,
        carrier_data_column_right: carrierDetails?.carrier_details?.carrier_data_column_right?.map((det) => {
          if (det.carrier_detail_field_id === carrier_detail_field_id) {
            return {
              ...det,
              carrier_detail_field_value: e.target.value
            };
          }
          return det;
        })
      }
    });
  };

  const handleRevertColumnAddition = () => {
    let newCarrierLevel = { ...carrierDetails }.carrier_level?.filter(level => !currentlyAddedCols.includes(level?.carrier_level_id_custom));

    let newCarrierLevelData = carrierDetails?.carrier_level_data?.map((section) => {
      return {
        ...section, carrier_group_data: section?.carrier_group_data?.map((group) => {
          let newCommissionData = [...group.carrier_commission_data].filter(commission => !currentlyAddedCols.includes(commission?.carrier_level_id_custom))
          // // console.log("newCommision", newCommission)
          return { ...group, carrier_commission_data: newCommissionData }
        })
      }
    });

    setCurrentlyAddedCols([])
    setCarrierDetails({ ...carrierDetails, carrier_level: newCarrierLevel, carrier_level_data: newCarrierLevelData })
  }

  const levelTablePopup = () => {
    let leftSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == true)
    let rightSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == false)
    return (
      <Grid item xs={12} textAlign="center">
        <Grid container className="" sx={{ justifyContent: "center", padding: { xs: "10px", sm: "20px" } }}>
          <Grid item xs={12} textAlign={"end"} marginBottom={{ xs: "5px", sm: "10px" }}>
            <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagLevelTablePopup(false) }} width="100%" />
          </Grid>
          <TableContainer
            className=""
            // sx={{ minHeight: "50vh" }}
            sx={{ height: "80vh" }}
            component={Paper}
          >
            <Table
              className="templateCarrierTable"
              aria-label="a dense table"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell className="header-color sticky-table-head sticky-carrier-table-head-1" sx={{ padding: "6px 8px" }} >
                    Types
                  </TableCell>

                  <TableCell className="header-color sticky-table-head sticky-carrier-table-head-2">
                    State
                  </TableCell>

                  <TableCell
                    className="header-color sticky-table-head sticky-carrier-table-head-3"
                    stickyHeader

                  >
                    Product
                  </TableCell>

                  <TableCell
                    className="header-color sticky-table-head sticky-carrier-table-head-4"
                    stickyHeader
                    sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
                  >
                    Sub Name
                  </TableCell>

                  {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
                    ?
                    <TableCell
                      className="header-color sticky-table-head sticky-carrier-table-head-5"
                      stickyHeader
                      sx={{
                        borderRight: "2px solid white",
                        // HERE UPDATE BG COLOR FOR THIS COLUMN 
                        backgroundColor: "#F1C232 !important",
                      }}
                    >
                      BA Total Comp
                    </TableCell>
                    :
                    <>
                      {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                        <>
                          {leftSideHeaders?.map((leftCell, leftIndex) => {
                            return (
                              <TableCell
                                className="header-color sticky-table-head sticky-carrier-table-head-5"
                                stickyHeader
                                sx={{ minWidth: '60px', borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "", background: `${leftCell?.carrier_level_color_hex_code} !important` }}
                              >
                                {leftCell?.carrier_level_name}
                              </TableCell>
                            )
                          })}
                        </>
                      }
                    </>
                  }

                  {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" ?
                    carrierDetails?.carrier_level?.map((levelRow, i) => {
                      return (
                        <TableCell
                          className="header-color"
                          align="left"
                          stickyHeader
                          sx={{ minWidth: '60px', background: `${levelRow?.carrier_level_color_hex_code} !important` }}
                        >
                          {levelRow?.carrier_level_name}
                        </TableCell>
                      );
                    })
                    :
                    <>
                      {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                        <>
                          {rightSideHeaders?.map((rightCell, i) => {
                            return (
                              <TableCell
                                className="header-color"
                                align="left"
                                stickyHeader
                                sx={{ minWidth: '60px', }}
                              >
                                {rightCell?.carrier_level_name}
                              </TableCell>
                            );
                          })}
                        </>
                      }
                    </>
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                {carrierDetails?.carrier_level_data?.map((groups) => {
                  return (
                    groups?.carrier_group_data?.map((groupData, i) => {
                      let flagSaprator = groups?.carrier_group_data.length == i + 1 ? true : false;
                      let leftSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == true)
                      let rightSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == false)
                      return (
                        <TableRow>
                          <TableCell
                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                            className="sticky-table-body sticky-table-color sticky-carrier-table-body-1"
                          >{groupData?.carrier_types}</TableCell>
                          <TableCell
                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                            className={`sticky-table-body sticky-table-color sticky-carrier-table-body-2
                            `}
                          // ${editDetails ? "sticky-carrier-table-body-2" : "cell-with-left-prop"}
                          >{groupData?.carrier_state}</TableCell>
                          <TableCell
                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                            className="sticky-table-body sticky-table-color sticky-carrier-table-body-3"

                          >{groupData?.carrier_product_name}</TableCell>
                          <TableCell
                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", borderRight: leftSideData?.length == 0 ? "2px solid #EFEFF0" : "" }}
                            className="sticky-table-body sticky-table-color sticky-carrier-table-body-4"

                          >{groupData?.carrier_sub_name}</TableCell>
                          {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
                            ?
                            <TableCell
                              sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", borderRight: "2px solid #EFEFF0" }}
                              className="sticky-table-body sticky-table-color sticky-carrier-table-body-5"
                            >{groupData?.carrier_ba_total_comp}</TableCell>
                            :
                            <>
                              {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                                <>
                                  {leftSideData?.map((leftDataCell) => {
                                    return (
                                      <TableCell
                                        sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "", padding: "0px 15px !important" }}
                                        className="sticky-table-body sticky-table-color sticky-carrier-table-body-5"
                                      >
                                        {leftDataCell?.commission_value}
                                      </TableCell>
                                    )
                                  })}
                                </>
                              }
                            </>
                          }

                          {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
                            groupData?.carrier_commission_data?.map((commissionData) => {
                              return (
                                <TableCell sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", padding: "0px 15px !important" }}>{commissionData?.commission_value}</TableCell>
                              )
                            })
                            :
                            rightSideData?.map((rightDataCell, i) => {
                              return (
                                <TableCell sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", padding: "0px 15px !important" }}>{rightDataCell?.commission_value}</TableCell>
                              )
                            })
                          }
                        </TableRow>
                      )
                    }))
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }

  const ResourceSection = (props) => {
    return (
      <Grid container height="200px" sx={{ overflowY: props?.carrierDetails?.carrier_resources?.length ? "auto" : "auto", overflowX: "auto" }}>
        <Grid item
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            flexDirection="row"

          // rowGap={2}
          // sx={{ overflowY: props?.carrierDetails?.carrier_resources?.length ? "scroll" : "auto", overflowX: "auto" }}
          // alignItems="start"
          >
            {props?.carrierDetails?.carrier_resources?.length ?
              props?.carrierDetails?.carrier_resources?.map((file) => {
                return (
                  <Grid item xs={12} md={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: { xs: "5px", md: "10px" },
                      borderRadius: "7px",
                      marginRight: "15px",
                      marginBottom: "10px"
                    }}
                  >
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <>
                        <Grid item xs={11}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImageUrl(file?.carrier_resource_file_url)
                            setFlagPdfPopup(true)
                          }}
                        >
                          <Grid
                            container
                            alignItems="center"
                          // sx={{ columnGap: { xs: 2, sm: 5 } }}
                          >
                            <Grid item xs={2} sm={1} md={1.5} lg={1.2} xl={1}>
                              <img src={PdfIcon} />
                            </Grid>

                            <Grid item xs={10} sm={4} md={4} lg={3.5} xl={2.5}>
                              <Grid container alignItems="center" justifyContent="center" marginLeft={{ xs: "10px", sm: "0" }} >
                                <Grid item xs={12} sx={{ wordWrap: "break-word", fontWeight: "600" }}>
                                  {file?.carrier_resource_file_name}
                                </Grid>

                                <Grid item xs={12} width="100%">
                                  <Grid container display={{ xs: "flex", sm: "none" }}>
                                    <Grid item xs={6}>
                                      {moment(file?.carrier_resource_created_at_timestamp).format("YYYY/MM/DD HH:mm:ss")}
                                    </Grid>
                                    <Grid item xs={4} >{Math.round((file?.carrier_resource_file_size / 1024 / 1024) * 100) / 100}MB</Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item sm={2.5} md={3} lg={3} xl={2} display={{ xs: "none", sm: "inline" }}>{moment(file?.carrier_resource_created_at_timestamp).format("YYYY-MM-DD HH:mm:ss")}</Grid>
                            <Grid item sm={2} md={3} lg={3} xl={2} display={{ xs: "none", sm: "inline" }}>{Math.round((file?.carrier_resource_file_size / 1024 / 1024) * 100) / 100}MB</Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={0.9}>
                          <Grid container justifyContent="end">
                            <Grid item>
                              {
                                props?.userRole == "Admin" &&
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={DeleteIcon}
                                  onClick={() => {
                                    setDeleteresourceData({
                                      carrier_detail: props.carrierDetails,
                                      file_detail: file
                                    })
                                    setFlagDeletePopup(true)
                                    // DeleteResouceFile(props.carrierDetails, props?.setFlagCall, props?.flagCall, file) 
                                  }} />
                              }
                            </Grid>
                          </Grid>
                        </Grid>

                      </>
                    </Grid>
                  </Grid>
                );
              })
              :
              <>
                <Grid item xs={12} md={12} textAlign="center" marginTop={"10px"} >No Files Found</Grid>
              </>
            }
          </Grid>
        </Grid>

        {/* {props?.userRole == "Admin" &&
          <>
            <Grid item md={0.5}></Grid>
            <Grid item xs={12} md={4} lg={4} xl={3.3}>
              <Grid container sx={{
                backgroundColor: "#FFFFFF",
                width: { xs: "calc(100% - 10px) !important", md: "calc(100% - 20px) !important" },
                marginTop: { xs: "15px", md: "0" },
                // height: { xs: "200px", md: "inherit" },
                padding: "10px",
                borderRadius: "5px !important"
              }}>
                <FileUpload
                  type="file"
                  carrierDetails={props.carrierDetails}
                  setFlagCall={props.setFlagCall}
                  flagCall={props.flagCall}
                  saveData={(e) => {
                    // onChangeSave("widget_logo_id", e);
                  }}
                />
              </Grid>
            </Grid>
          </>
        } */}
      </Grid>
    );
  };

  useEffect(() => {
    const carrierLevelLen = carrierDetails?.carrier_level_data?.length
    if (carrierLevelLen >= 2) {
      setCarrierLevelLength(false)
    } else {
      setCarrierLevelLength(true)
    }
  }, [carrierDetails])

  useEffect(() => {
    // console.log("selectedLine", selectedLine)
  }, [selectedLine])

  const CarrierDetailsAccordian = (props) => {

    return (
      <CustomAccordion
        // defaultExpanded
        disableGutters
        className="carrier_dertails_accordian"
        sx={{
          backgroundColor: editDetails ? "#fff" : "#EFEFF0",
        }}
        expanded={flagCarrierDetailsExpand}
      >
        {!editDetails && <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: "#1565C0", fontWeight: "600", fontSize: "16px", paddingLeft: "25px" }}
          onClick={() => {
            setFlagCarrierDetailsExpand(!flagCarrierDetailsExpand)
            setEditDetails(false)
          }}
        >
          Carrier Details
        </AccordionSummary>}

        <AccordionDetails >
          <Grid container rowGap={4} padding={editDetails ? "0px" : "0px 25px"} >
            <Grid item xs={12} sm={12} md={12} lg={8.7} xl={editDetails ? 7.5 : 9}>
              <Grid container spacing={5} justifyContent={"space-between"} sx={{ height: "100%" }}>

                <Grid item xs={12}>
                  <Grid container
                    sx={{
                      display: "grid",
                      gridTemplateRows: "repeat(6, auto)",
                      gridAutoFlow: "column",
                      gridTemplateColumns: `repeat(${Math.ceil(props?.carrierDetailsD?.carrier_details?.carrier_data_column_left?.length / 6)}, 1fr)`,
                      gap: "8px",
                      columnGap: '20px',
                      '@media (max-width: 840px)': {
                        gridTemplateRows: `repeat(${props?.carrierDetails?.carrier_details?.carrier_data_column_left?.length}, auto)`,
                        gridTemplateColumns: "repeat(1, 1fr)"
                      }
                    }}
                  >
                    {
                      props?.carrierDetails?.carrier_details?.carrier_data_column_left.map((data, ind) => (

                        (data?.carrier_detail_field_name !== "" && data !== undefined) &&
                        <Grid item key={ind} sx={{ height: 'fit-content' }}>
                          <Grid container alignItems={"start"} sx={{}} >

                            <Grid item className="carrierVersion_DetailsLable">
                              <CustomLabelTypography sx={{ color: "#7A7A7A" }}>
                                {data?.carrier_detail_field_name}
                              </CustomLabelTypography>
                            </Grid>

                            <Grid item className="carrierVersion_DetailsContent">
                              <CustomTextFied
                                key={ind}
                                id="standard-read-only-input"
                                multiline
                                value={data?.carrier_detail_field_value}
                                variant="standard"
                                fullWidth
                                InputProps={{
                                  readOnly: !editDetails,
                                  style: { fontSize: "15px" },
                                  disableUnderline: !editDetails,
                                }}
                                onChange={(e) => {
                                  handleCarrierDetailsChangeLeft(e, data?.carrier_detail_field_id)
                                  // e.target.focus()
                                }}
                              />
                            </Grid>

                          </Grid>
                        </Grid>
                      ))
                    }
                  </Grid >
                </Grid>

                {
                  !editDetails && <Grid item xs={12} display={"flex"} alignItems={'center'} justifyContent={'center'} >
                    {/* {props?.carrierDetails?.carrier_details?.carrier_data_column_left?.map((row, i) => { */}
                    {/* return ( */}
                    {/* row?.carrier_detail_flag_show_field == true && */}
                    <Typography
                      className="carrier-details-footer-Note"
                    >
                      img elements must have an alt prop, either with meaningful text, or an empty string for decorative images
                      {/* {row?.carrier_detail_field_name} */}
                    </Typography>
                    {/* )
                    })} */}
                  </Grid>
                }

              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={0.3} xl={0.3} container direction={"row"} justifyContent="center">
              <Divider orientation={minScreenWidth ? 'horizontal' : 'vertical'} sx={minScreenWidth ? { display: 'flex', width: "100%" } : {}} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={3} xl={editDetails ? 4.2 : 2.7}>
              <Grid
                container
                flexDirection="column"
                rowGap={3}
              >
                <Grid item xs={12} textAlign={"center"}>
                  <CustomLabelTypography sx={{
                    textTransform: "none",
                    color: "#00345E"
                  }}>
                    {props?.carrierDetails?.carrier_details?.carrier_data_column_right[0]?.column_value}
                  </CustomLabelTypography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container rowGap={editDetails ? 3 : 0}>
                    {
                      props?.carrierDetails?.carrier_details?.carrier_data_column_right?.map((row, index) =>

                      // row.column_sequence !== 1 && row.column_sequence !== 12 && index % 2 !== 0 &&
                      // ?
                      (
                        <Grid key={index} item xs={12}>
                          <Grid container className="viewCarrier_Internalinfo_Container" alignItems={'start'}>
                            {/* xs={12} sm={4} md={3} lg={12} xl={editDetails ? 6 : 12} */}
                            <Grid item className="viewCarrier_Internalinfo_itemLable" >
                              <CustomLabelTypography sx={{ color: "#7A7A7A", marginRight: "10px" }}>
                                {row?.carrier_detail_field_name}
                              </CustomLabelTypography>
                            </Grid>
                            {/* xs={12} sm={8} md={9} lg={12} xl={editDetails ? 6 : 12} */}
                            <Grid item className="viewCarrier_Internalinfo_itemContent" sx={{ wordBreak: "break-all" }}>
                              {
                                row?.carrier_detail_field_name == 'Drive Folder' ?
                                  (
                                    editDetails ?
                                      <CustomTextFied
                                        key={index}
                                        id="standard-read-only-input"
                                        // defaultValue={props?.carrierDetails?.carrier_details?.carrier_data_column_right[index + 1]?.column_value}
                                        defaultValue={row?.carrier_detail_field_value}
                                        variant="standard"
                                        fullWidth
                                        multiline
                                        InputProps={{
                                          readOnly: !editDetails,
                                          style: { fontSize: "15px" },
                                          disableUnderline: !editDetails,
                                        }}
                                        onChange={(e) => {
                                          handleCarrierDetailsChangeRight(e, row?.carrier_detail_field_id)
                                        }}
                                      />

                                      :
                                      <Link href={row?.carrier_detail_field_value} >
                                        {row?.carrier_detail_field_value}
                                      </Link>

                                  )
                                  :
                                  (
                                    <CustomTextFied
                                      key={index}
                                      id="standard-read-only-input"
                                      defaultValue={row?.carrier_detail_field_value}
                                      variant="standard"
                                      fullWidth
                                      multiline
                                      InputProps={{
                                        readOnly: !editDetails,
                                        style: { fontSize: "15px" },
                                        disableUnderline: !editDetails,
                                      }}
                                      onChange={(e) => {
                                        handleCarrierDetailsChangeRight(e, row?.carrier_detail_field_id)
                                      }}
                                    />
                                  )
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                        // :
                        // null
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* New GRID END */}
        </AccordionDetails>
      </CustomAccordion >
    );
    // }
  };

  const ResourcesAccordian = (props) => {
    const { carrierDetails, setFlagCall, flagCall, userRole } = props
    return (
      <CustomAccordion
        disableGutters
        sx={{
          backgroundColor: "#EFEFF0",
          borderRadius: "7px",
          paddingLeft: "10px"
        }}
        expanded={flagResourceExpand}
      // onClick={() => { setFlagResourceExpand(!flagResourceExpand) }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore onClick={() => {
            if (userDetail[0]?.user_role_name == "Admin") {
              setFlagResourceExpand(!flagResourceExpand)
            }
          }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ color: "#1565C0", fontSize: "16px", fontWeight: "600" }}
          onClick={() => {
            if (userDetail[0]?.user_role_name == "Member" || userDetail[0]?.user_role_name == "Agent") {
              setFlagResourceExpand(!flagResourceExpand)
            }
          }
          }
        >
          <Grid container justifyContent="start">
            <Grid item xl={0.8} onClick={() => {
              if (userDetail[0]?.user_role_name == "Admin") {
                setFlagResourceExpand(!flagResourceExpand)
              }
            }}>
              Resources
            </Grid>
            <Grid item xl={0.3} textAlign="left">
              {(flagResourceExpand && userDetail[0]?.user_role_name == "Admin") && <AddBoxOutlinedIcon sx={{ marginLeft: "10px", marginTop: "3px" }} onClick={() => { setFlagFileUploadPopup(true) }} />}
            </Grid>
            <Grid item xl={10.9} onClick={() => {
              if (userDetail[0]?.user_role_name == "Admin") {
                setFlagResourceExpand(!flagResourceExpand)
              }
            }}>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className="ResourcesAccordianDetails" >
          <Grid spacing={5} container>

            <Grid item xs={8} sm={8} md={8} lg={8} xl={userDetail[0]?.user_role_name == "Admin" && (userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") ? 8 : 12}>
              <ResourceSection carrierDetails={carrierDetails} setFlagCall={setFlagCall} flagCall={flagCall} userRole={userRole} />
            </Grid>
            {(userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") &&
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <FileUpload
                  type="file"
                  carrierDetails={carrierDetails}
                  setFlagCall={setFlagCall}
                  flagCall={flagCall}
                  setFlagFileUploadPopup={setFlagFileUploadPopup}
                  saveData={(e) => {
                    // onChangeSave("widget_logo_id", e);
                  }}
                />
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    );
  };

  const AddColumnCard = ({ Levels, LevelId, Position }) => {
    const colorOptions = [
      { label: 'Downline Level', value: '#000000' },
      { label: 'Direct Carrier Level', value: '#CC4125' },
      { label: 'BA Level', value: '#F1C232' },
    ];
    const handleColorChange = (event) => {
      setSelectedColor(event.target.value);
    }
    return (
      <Box className="AddColumn_Popper" sx={{ right: Position ? "49px" : "" }}>
        <Grid container rowGap={4}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                After Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={LevelId}
                label="After Level"
                onChange={(e) => {
                  // // console.log("etar", e)
                  // setLevelSelectedData()
                }}
              // onChange={handleChange}
              >
                {Levels.map((level) => (
                  <MenuItem value={level.carrier_level_id}>{level.carrier_level_name}</MenuItem>
                ))}
              </Select>

            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-helperText"
              label="Lavel Name"
              defaultValue="L2"
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel className="AddIconPopUp_MenuItem" variant="standard" id="color-picker-label">Select Color</InputLabel>
              <Select labelId="color-picker-label" value={selectedColor} onChange={handleColorChange}>
                {colorOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <ListItemIcon sx={{ minWidth: '28px' }}>
                      <Box className="showColor_inDropDown"
                        style={{
                          backgroundColor: option.value,
                        }}
                      />
                    </ListItemIcon>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} padding={'15px 15px 8px 15px'}>
            <Grid container spacing={3}>
              <Grid item xs={6} className="AddIconPopUp_Buttons" >
                <Button
                  onClick={() => {
                    setAddColumn(null)
                    setFlagShowAddIcons(false)
                  }}
                  variant="outlined"
                  sx={{ padding: "5px 30px" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} className="AddIconPopUp_Buttons">
                <Button variant="contained" sx={{ padding: "5px 36px" }} >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  // OLD CODE ACCORDION 
  // const LevelsAccordian = (props) => {
  //   const { carrierDetails } = props;

  //   let leftSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == true)
  //   let rightSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj.carrier_ba_level_flag == false)
  //   let Levels
  //   if (leftSideHeaders && rightSideHeaders) {
  //     Levels = [...leftSideHeaders, ...rightSideHeaders]
  //   }

  //   return (
  //     <CustomAccordion
  //       disableGutters
  //       expanded={editDetails ? true : flagLevelAccordionExpanded}
  //       onChange={editDetails ? undefined : () => { setFlagLevelAccordionExpanded(!flagLevelAccordionExpanded) }}
  //       defaultExpanded
  //       sx={{
  //         backgroundColor: "#EFEFF0",
  //         borderRadius: "7px",
  //         paddingLeft: "10px"
  //       }}
  //     >
  //       <AccordionSummary
  //         expandIcon={!editDetails && <ExpandMore />}
  //         aria-controls="panel1a-content"
  //         id="panel1a-header"
  //         className="levels_accordian"
  //       >
  //         <Grid container justifyContent="space-between">
  //           <Grid item>
  //             <Typography className="accordionSummury_Label" >
  //               Levels
  //             </Typography>
  //           </Grid>
  //           <Grid item paddingRight="12px" display={editDetails ? "inline" : flagLevelAccordionExpanded ? "inline" : "none"}>
  //             {editDetails ?
  //               (
  //                 <>
  //                   <Grid container>
  //                     {flagShowAddIcons ?
  //                       (
  //                         <Grid item>
  //                           <Button
  //                             variant="outlined"
  //                             size="medium"
  //                             onClick={() => {
  //                               setFlagShowAddIcons(!flagShowAddIcons)
  //                             }}
  //                           >
  //                             Cancel
  //                           </Button>
  //                         </Grid>
  //                       ) : (
  //                         <Grid item>
  //                           <Button
  //                             variant="contained"
  //                             size="medium"
  //                             startIcon={<AddCircleIcon />}
  //                             onClick={() => {
  //                               setFlagShowAddIcons(!flagShowAddIcons)
  //                             }}
  //                           >
  //                             Levels
  //                           </Button>
  //                         </Grid>
  //                       )
  //                     }
  //                   </Grid>
  //                 </>
  //               ) : (<ZoomOutMapIcon onClick={() => setFlagLevelTablePopup(true)} />)
  //             }
  //           </Grid>
  //         </Grid>
  //       </AccordionSummary>
  //       <AccordionDetails>
  //         <Grid item xs={12} textAlign="center">
  //           <Grid container className="" sx={{ justifyContent: "center", position: "relative" }}>
  //             {/* <Box className="backdropBox"></Box> */}
  //             <TableContainer
  //               className="vc-main-table-contanier"
  //               // sx={{ minHeight: "50vh" }}
  //               sx={{ height: "55vh" }}
  //               component={Paper}

  //             >
  //               <Table
  //                 className="templateCarrierTable"
  //                 aria-label="a dense table"
  //                 size="small"
  //                 stickyHeader
  //               >
  //                 <TableHead>
  //                   {
  //                     editDetails &&
  //                     <TableRow className="leveltable_IconRow">
  //                       <TableCell className="leveltable_IconCell sticky-table-head sticky-carrier-table-head-1" sx={{ border: "none" }} >
  //                       </TableCell>

  //                       <TableCell className="leveltable_IconCell sticky-table-head sticky-carrier-table-head-2" >
  //                       </TableCell>

  //                       <TableCell
  //                         className="leveltable_IconCell sticky-table-head sticky-carrier-table-head-3"
  //                         stickyHeader
  //                       >
  //                       </TableCell>

  //                       <TableCell
  //                         className="leveltable_IconCell sticky-table-head sticky-carrier-table-head-4"
  //                         stickyHeader
  //                         sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
  //                       >
  //                       </TableCell >
  //                       {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member")
  //                         ?
  //                         <TableCell
  //                           className="leveltable_IconCell sticky-table-head sticky-carrier-table-head-5 "
  //                           stickyHeader
  //                           sx={{ borderRight: "2px solid white", display: 'block', border: "none" }}
  //                         >
  //                         </TableCell>
  //                         :
  //                         <>

  //                           {leftSideHeaders?.map((leftCell, leftIndex) => {
  //                             return (
  //                               <>
  //                                 {flagShowAddIcons ?
  //                                   (
  //                                     <TableCell
  //                                       className="leveltable_IconCell addLeft_Border sticky-table-head sticky-carrier-table-head-5"
  //                                       // className={`leveltable_IconCell addLeft_Border sticky-table-head sticky-carrier-table-head-5 ${flagShowAddIcons ? `addLeft_Border` : ""}`}
  //                                       stickyHeader
  //                                       onClick={() => {
  //                                         setLevelSelectedData(leftCell)
  //                                         setFlagCarrierCustomizationGlobalDialog(true)
  //                                         setFlagCustomizationCarriarSectionDialog(true)
  //                                         setFlagDialogGloballyRender(true)
  //                                         setHideTransperentCell(false)
  //                                       }}
  //                                       sx={{
  //                                         borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
  //                                         background: `${leftCell?.carrier_level_color_hex_code} !important`,
  //                                         cursor: editDetails ? "pointer" : "auto"
  //                                       }}                                      >

  //                                       <IconButton className="leveltable_IconButton">
  //                                         <AddCircleIcon />
  //                                       </IconButton>

  //                                     </TableCell>
  //                                   ) : (<></>)
  //                                 }
  //                               </>
  //                             )
  //                           })}
  //                         </>
  //                       }

  //                       {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?

  //                         carrierDetails?.carrier_level?.map((levelRow, i) => {
  //                           const TotalLength = carrierDetails?.carrier_level.length
  //                           const NumOfCellToOpenLeft = 5
  //                           return (
  //                             <>
  //                               {flagShowAddIcons ?
  //                                 (<TableCell
  //                                   align="left"
  //                                   className="leveltable_IconCell addLeft_Border"
  //                                   // className={`leveltable_IconCell ${flagShowAddIcons ? `addLeft_Border` : ""}`}
  //                                   stickyHeader
  //                                 >
  //                                   <Box>
  //                                     {/***********************
  //                                       {
  //                                       AddColumn === levelRow.carrier_level_id &&
  //                                       <AddColumnCard
  //                                         Levels={Levels}
  //                                         LevelId={levelRow.carrier_level_id}
  //                                         Position={i >= TotalLength - NumOfCellToOpenLeft}
  //                                       />
  //                                     } 
  //                                     ************************/}
  //                                     <IconButton
  //                                       onClick={() => {
  //                                         setAddColumn(levelRow.carrier_level_id)
  //                                       }}
  //                                       className="leveltable_IconButton"
  //                                     >
  //                                       <AddCircleIcon fontSize="10px" />
  //                                     </IconButton>


  //                                   </Box>
  //                                 </TableCell>) : (<></>)
  //                               }
  //                             </>
  //                           );
  //                         })
  //                         :
  //                         rightSideHeaders?.map((rightCell, i) => {
  //                           return (
  //                             <>
  //                               {flagShowAddIcons ?
  //                                 <TableCell
  //                                   align="left"
  //                                   stickyHeader
  //                                   className="leveltable_IconCell addLeft_Border"
  //                                 >
  //                                   <IconButton className="leveltable_IconButton">
  //                                     <AddCircleIcon />
  //                                   </IconButton>
  //                                 </TableCell>
  //                                 : <></>
  //                               }
  //                             </>
  //                           );
  //                         })
  //                       }
  //                     </TableRow>
  //                   }
  //                   <TableRow>
  //                     <TableCell
  //                       sx={{
  //                         backgroundColor: "transparent !important",
  //                         border: "none",
  //                         borderBottom: "none"
  //                       }}
  //                       // className="table-right-table-cells"
  //                       className={
  //                         `table-right-table-cells 
  //                          sticky-table-head 
  //                          sticky-carrier-table-head-1 
  //                       ${editDetails ? "addLeft_Border" : ""}`
  //                       }
  //                     >
  //                     </TableCell>
  //                     <TableCell
  //                       className={`header-color 
  //                       sticky-table-head 
  //                       sticky-carrier-table-head-2 
  //                       ${editDetails ? "addLeft_Border" : ""}`
  //                       }
  //                       stickyHeader
  //                     >
  //                       Types
  //                     </TableCell>

  //                     <TableCell
  //                       className={`header-color 
  //                     sticky-table-head 
  //                     sticky-carrier-table-head-3 
  //                     ${editDetails ? "addLeft_Border" : ""}`
  //                       }
  //                       stickyHeader
  //                     >
  //                       State
  //                     </TableCell>

  //                     <TableCell
  //                       className={`header-color 
  //                       sticky-table-head 
  //                       sticky-carrier-table-head-4 
  //                       ${editDetails ? "addLeft_Border" : ""}`
  //                       }
  //                       stickyHeader

  //                     >
  //                       Product
  //                     </TableCell>

  //                     <TableCell
  //                       className={`header-color 
  //                       sticky-table-head 
  //                       sticky-carrier-table-head-5
  //                       ${editDetails ? "addLeft_Border" : ""}`
  //                       }
  //                       stickyHeader
  //                       sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
  //                     >
  //                       Sub Name
  //                     </TableCell>

  //                     {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
  //                       (
  //                         <TableCell
  //                           className={`header-color 
  //                         sticky-table-head 
  //                         sticky-carrier-table-head-6 
  //                         ${editDetails ? "addLeft_Border" : ""}`
  //                           }
  //                           stickyHeader
  //                           sx={{ borderRight: "2px solid white" }}
  //                         >
  //                           BA
  //                         </TableCell>
  //                       ) : (
  //                         <>
  //                           {leftSideHeaders?.map((leftCell, leftIndex) => {
  //                             return (
  //                               <TableCell

  //                                 className={`header-color sticky-table-head sticky-carrier-table-head-5 ${editDetails ? "addLeft_Border" : ""}`}
  //                                 stickyHeader
  //                                 onClick={() => {
  //                                   setLevelSelectedData(leftCell)
  //                                   setFlagCustomizationCarriarSectionDialog(true)
  //                                   setFlagDialogGloballyRender(true)
  //                                 }}
  //                                 sx={{
  //                                   borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
  //                                   background: `${leftCell?.carrier_level_color_hex_code} !important`,
  //                                   cursor: editDetails ? "pointer" : "auto"
  //                                 }}
  //                               >
  //                                 {leftCell?.carrier_level_name}
  //                               </TableCell>
  //                             )
  //                           })}
  //                         </>
  //                       )
  //                     }

  //                     {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
  //                       carrierDetails?.carrier_level?.map((levelRow, i) => {
  //                         return (
  //                           <TableCell
  //                             className={`header-color ${editDetails ? "addLeft_Border" : ""}`}
  //                             align="left"
  //                             stickyHeader
  //                             sx={{
  //                               background: `${levelRow?.carrier_level_color_hex_code} !important`,
  //                               cursor: editDetails ? "pointer" : "auto"
  //                             }}
  //                             onClick={() => {
  //                               setLevelSelectedData(levelRow)
  //                               setFlagCustomizationCarriarSectionDialog(true)
  //                               setFlagDialogGloballyRender(true)
  //                             }}
  //                           >
  //                             {levelRow?.carrier_level_name}
  //                           </TableCell>
  //                         );
  //                       })
  //                       :
  //                       rightSideHeaders?.map((rightCell, i) => {
  //                         return (
  //                           <TableCell
  //                             className={`header-color ${editDetails ? "addLeft_Border" : ""}`}
  //                             align="left"
  //                             stickyHeader
  //                             onClick={() => {
  //                               setLevelSelectedData(rightCell)
  //                               setFlagCustomizationCarriarSectionDialog(true)
  //                               setFlagDialogGloballyRender(true)
  //                             }}
  //                             sx={{
  //                               background: `${rightCell?.carrier_level_color_hex_code} !important`,
  //                               cursor: editDetails ? "pointer" : "auto"
  //                             }}
  //                           >
  //                             {rightCell?.carrier_level_name}
  //                           </TableCell>
  //                         );
  //                       })
  //                     }
  //                   </TableRow>
  //                 </TableHead>

  //                 <TableBody>
  //                   {carrierDetails?.carrier_level_data?.map((groups) => {
  //                     return (
  //                       groups?.carrier_group_data?.map((groupData, i) => {
  //                         let flagSaprator = groups?.carrier_group_data.length == i + 1 ? true : false;
  //                         let leftSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == true)
  //                         let rightSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == false)
  //                         return (
  //                           <>
  //                             <TableRow>
  //                               <TableCell
  //                                 // className="vc-table-transparent-cell" 
  //                                 className={`vc-table-transparent-cell sticky-table-body sticky-carrier-table-body-1 add_padding `}
  //                                 sx={{ border: "none", borderBottom: "none" }}
  //                               >

  //                               </TableCell>

  //                               <TableCell
  //                                 contentEditable={editDetails ? true : false}
  //                                 suppressContentEditableWarning
  //                                 sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
  //                                 className={`sticky-table-body sticky-table-color ${editDetails ? "sticky-carrier-table-body-2 addLeft_Border" : "cell-with-left-prop"} add_padding `}
  //                                 onKeyUp={(e) => {
  //                                   handleCarrierLevelData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, 'carrier_types')
  //                                 }}
  //                               >
  //                                 {groupData?.carrier_types}
  //                               </TableCell>

  //                               <TableCell
  //                                 contentEditable={editDetails ? true : false}
  //                                 suppressContentEditableWarning
  //                                 sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
  //                                 className={`sticky-table-body sticky-table-color sticky-carrier-table-body-3  ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                 onKeyUp={(e) => {
  //                                   handleCarrierLevelData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, 'carrier_state')
  //                                 }}
  //                               >
  //                                 {groupData?.carrier_state}
  //                               </TableCell>

  //                               <TableCell
  //                                 contentEditable={editDetails ? true : false}
  //                                 suppressContentEditableWarning
  //                                 sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
  //                                 className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4  ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                 onKeyUp={(e) => {
  //                                   handleCarrierLevelData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, 'carrier_product_name')
  //                                 }}
  //                               >
  //                                 {groupData?.carrier_product_name}
  //                               </TableCell>

  //                               <TableCell
  //                                 contentEditable={editDetails ? true : false}
  //                                 suppressContentEditableWarning
  //                                 sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", borderRight: leftSideData?.length == 0 ? "2px solid #EFEFF0" : "" }}
  //                                 className={`sticky-table-body sticky-table-color sticky-carrier-table-body-5`}
  //                                 onKeyUp={(e) => {
  //                                   handleCarrierLevelData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, 'carrier_sub_name')
  //                                 }}
  //                               >
  //                                 {groupData?.carrier_sub_name}
  //                               </TableCell>

  //                               {
  //                                 userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member"
  //                                   ?
  //                                   <TableCell
  //                                     contentEditable={editDetails ? true : false}
  //                                     suppressContentEditableWarning
  //                                     sx={{
  //                                       borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
  //                                       borderRight: "2px solid #EFEFF0",
  //                                     }}
  //                                     className={`sticky-table-body sticky-table-color sticky-carrier-table-body-6  ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                   >
  //                                     {groupData?.carrier_ba_total_comp}
  //                                   </TableCell>
  //                                   :
  //                                   leftSideData?.map((leftDataCell) => {
  //                                     return (
  //                                       <TableCell
  //                                         contentEditable={editDetails ? true : false}
  //                                         suppressContentEditableWarning
  //                                         sx={{
  //                                           borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
  //                                           borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
  //                                         }}
  //                                         className={`sticky-table-body sticky-table-color sticky-carrier-table-body-6  ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                         onChange={(e) => {
  //                                           handleCarrierLevelCommissionData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, leftDataCell?.carrier_commission_value_id)
  //                                         }}
  //                                       >
  //                                         {leftDataCell?.commission_value}
  //                                       </TableCell>
  //                                     )
  //                                   })
  //                               }

  //                               {
  //                                 (userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member")
  //                                   ?
  //                                   groupData?.carrier_commission_data?.map((commissionData) => {
  //                                     return (
  //                                       <TableCell
  //                                         contentEditable={editDetails ? true : false}
  //                                         suppressContentEditableWarning
  //                                         className={`vc-table-right-table-cells ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                         sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
  //                                         onChange={(e) => {
  //                                           handleCarrierLevelCommissionData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, commissionData?.carrier_commission_value_id)
  //                                         }}
  //                                       >
  //                                         {commissionData?.commission_value}
  //                                       </TableCell>
  //                                     )
  //                                   })
  //                                   :
  //                                   rightSideData?.map((rightDataCell, i) => {
  //                                     return (
  //                                       <TableCell
  //                                         contentEditable={editDetails ? true : false}
  //                                         suppressContentEditableWarning
  //                                         className={`vc-table-right-table-cells ${editDetails ? "addLeft_Border add_padding" : ""}`}
  //                                         sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
  //                                         onChange={(e) => {
  //                                           handleCarrierLevelCommissionData(e, groups?.carrier_group_id, groupData?.carrier_group_data_id, rightDataCell?.carrier_commission_value_id)
  //                                         }}
  //                                       >
  //                                         {rightDataCell?.commission_value}
  //                                       </TableCell>
  //                                     )
  //                                   })
  //                               }
  //                             </TableRow>

  //                             {i == groups?.carrier_group_data.length - 1 && (
  //                               <TableRow >
  //                                 <TableCell
  //                                   // className=""
  //                                   className={`vc-table-transparent-cell sticky-table-body sticky-carrier-table-body-1 sticky-carrier-table-head-1 highlight-add-section-cell`}
  //                                   sx={{
  //                                     padding: '0px',
  //                                     borderBottom: "none",
  //                                     display: "flex",
  //                                     alignItems: "center",
  //                                     justifyContent: "center",
  //                                     height: "3px",
  //                                     // background: selectedLine == groups?.carrier_group_id ? "#1565C0" : ""
  //                                   }}
  //                                 >
  //                                   <img
  //                                     src={dot}
  //                                     style={{ cursor: "pointer", zIndex: 100, }}
  //                                     alt=""
  //                                     onMouseEnter={() => {
  //                                       setSelectedLine(groups?.carrier_group_id || groups?.carrier_group_id_custom)
  //                                     }}
  //                                     onMouseLeave={() => {
  //                                       setSelectedLine(null)
  //                                     }}
  //                                   ></img>
  //                                 </TableCell>

  //                                 <TableCell
  //                                   className={`vc-table-transparent-cell sticky-table-body ${editDetails ? "sticky-carrier-table-body-2" : "cell-with-left-prop"} sticky-carrier-table-head-2 }`}
  //                                   colSpan={leftSideData.length + rightSideData.length}
  //                                   sx={{
  //                                     padding: '0px',
  //                                     height: "1px",
  //                                     background: (selectedLine == groups?.carrier_group_id || selectedLine == groups?.carrier_group_id_custom) ? "#1565C0" : ""

  //                                   }}
  //                                 >
  //                                 </TableCell>
  //                               </TableRow>
  //                             )}
  //                           </>
  //                         )
  //                       }))
  //                   })
  //                   }
  //                 </TableBody>
  //               </Table>
  //             </TableContainer>
  //           </Grid>
  //         </Grid>
  //       </AccordionDetails>
  //     </CustomAccordion>
  //   );
  // };

  const RevokeFunction = () => {
    FlagApiLoader(true)
    RevokeDelegateAccessForAdmin((res) => {
      if (res?.data?.status) {
        window.location.href = "/"
      } else {
        console.log("response false of RevokeDelegateAccessForAdmin api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
      FlagApiLoader(false)
    }, (err) => {
      console.log("error of RevokeDelegateAccessForAdmin api", err);
      UpdateSnackbarMessage({
        status: "error",
        message: "Something Went Wrong!"
      });
      FlagApiLoader(false)
    })
  }


  useEffect(() => {
    if (window.location.pathname == "/carriers/add_carrier") {
      FlagApiLoader(true)
      GetCarrierDetailsField((res) => {
        if (res?.data?.status) {
          let carrier_details = {
            carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
            carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
          }
          setCarrierDetails({
            carrier_name: carrierName,
            carrier_type_flag: location?.state?.carrier_type_flag, carrier_details: carrier_details,
            carrier_level: [

            ],
            carrier_level_data: [
              {
                carrier_group_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_group_data: [
                  {
                    carrier_group_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_types: "",
                    carrier_state: "",
                    carrier_product_name: "",
                    carrier_sub_name: "",
                    carrier_ba_total_comp: "",
                    carrier_commission_data: [
                    ]
                  }
                ]
              },
            ],
          })
          setCarrierDetailsDuplicate({
            carrier_name: carrierName,
            carrier_type_flag: location?.state?.carrier_type_flag, carrier_details: carrier_details,
            carrier_level: [

            ],
            carrier_level_data: [
              {
                carrier_group_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_group_data: [
                  {
                    carrier_group_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_types: "",
                    carrier_state: "",
                    carrier_product_name: "",
                    carrier_sub_name: "",
                    carrier_ba_total_comp: "",
                    carrier_commission_data: [
                    ]
                  }
                ]
              },
            ],
          })
          FlagApiLoader(false)
        }
        else {
          console.log("response false of GetCarrierDetailsField api", res);
          FlagApiLoader(false)
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
        (err) => {
          console.log("error of GetCarrierDetailsField api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!",
          });
          FlagApiLoader(false)
        })

      if (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") {
        FlagApiLoader(true)
        GetCarrierLevelColorList((res) => {
          if (res?.data?.status) {
            setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
            FlagApiLoader(false)
          } else {
            console.log("response false of GetCarrierLevelColorList api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
              FlagApiLoader(false)
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            }
          }
        }, (err) => {
          console.log("error of GetCarrierLevelColorList api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!",
          });
          FlagApiLoader(false)
        })
      }

    } else {
      // debugger

      FlagApiLoader(true)
      let carrierIdExists = searchParams.has("id");
      let versionIdExists = searchParams.has("version_id");

      if (carrierIdExists) {
        let searchParamsCarrierId = searchParams.get("id");
        let searchParamsVersionId = searchParams.get("version_id");

        GetCarrierDataList(null,
          (res) => {
            if (res.data.status) {
              setCarrierData(res.data.data)
              // FlagApiLoader(false)
            }
            else {
              // FlagApiLoader(false)
              console.log("response false of GGetCarrierDataList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
                FlagApiLoader(false)
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
                FlagApiLoader(false)
              }
            }
          },
          (err) => {
            console.log("error of GetCarrierDataList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            // FlagApiLoader(false)
          }
        )

        // 1st call
        if (userDetail[0]?.user_role_name == "Admin") {
          // FlagApiLoader(true)
          GetCarrierVersionListData(searchParamsCarrierId,
            (res) => {
              if (res.data.status) {
                if (searchParamsVersionId) {
                  setCurrentVersion(Number(searchParamsVersionId))
                  // FlagApiLoader(false)
                }
                else {
                  res?.data?.data[0]?.carrier_version_data?.map((row) => {
                    if (row.carrier_version_latest) {
                      setCurrentVersion(row?.carrier_version_id)
                      setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                    }
                  })
                  // FlagApiLoader(false)
                }
                setCarrierVersionList(res.data.data[0].carrier_version_data)
                // FlagApiLoader(false)
              }
              else {
                console.log("response false of GetCarrierVersionListData api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                  FlagApiLoader(false)
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                  FlagApiLoader(false)
                }
                navigate("/carriers")
              }
            },
            (err) => {
              console.log("error of GetCarrierVersionListData api", err);
              FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              navigate("/carriers")
            }
          )
        }

        // 2nd Call
        if (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name.trim().toLowerCase() === "brokers alliance") {
          // FlagApiLoader(true)
          GetCarrierLevelColorList((res) => {
            if (res?.data?.status) {
              setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
              // FlagApiLoader(false)
            } else {
              console.log("response false of GetCarrierLevelColorList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
                FlagApiLoader(false)
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
                FlagApiLoader(false)
              }
            }
          }, (err) => {
            console.log("error of GetCarrierLevelColorList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            // FlagApiLoader(false)
          })
        }

        let obj = {
          carrier_id: searchParamsCarrierId,
          carrier_version_id: searchParamsVersionId,
        }
        // 3rd call
        setTimeout(() => {
          GetCarrierData(obj,
            (res) => {
              FlagApiLoader(false)
              if (res.data.status) {
                // alert('1')
                // console.log("data res::", res)
                const updatedCarrierDetails = {
                  ...res?.data?.data[0],
                  carrier_details: {
                    carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                    carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                  }
                };

                setCarrierDetails(updatedCarrierDetails)
                // let newUpdatedCarrierDetails = { ...updatedCarrierDetails }
                setCarrierDetailsDuplicate(JSON.parse(JSON.stringify(updatedCarrierDetails)))
                const carrierTypeFlage = res.data.data[0].carrier_type_flag
                setToggleButton(carrierTypeFlage ? "life" : "annuity")
              }
              else {
                console.log("response false of GetCarrierData api", res);
                if (res?.data?.error?.code === 9087) {
                  RevokeFunction()
                } else {
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                  }
                  else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                  }
                  navigate("/carriers")
                }
              }
            }, (err) => {
              console.log("error of GetCarrierData api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              FlagApiLoader(false)
              navigate("/carriers")
            })
        }, 300);
      }
    }
  }, [])

  useEffect(() => {
    setType("all")
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }
    if (flagCall != "default") {
      FlagApiLoader(true)
      let carrierIdExists = searchParams.has("id");
      let versionIdExists = searchParams.has("version_id");
      let latestVersionId = ""

      if (carrierIdExists) {
        let searchParamsCarrierId = searchParams.get("id");
        let searchParamsVersionId = searchParams.get("version_id");

        GetCarrierDataList(null,
          (res) => {
            if (res.data.status) {
              setCarrierData(res.data.data)
              // FlagApiLoader(false)
            }
            else {
              // FlagApiLoader(false)
              console.log("response false of GetCarrierDataList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                FlagApiLoader(false)
              }
              else {
                UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                FlagApiLoader(false)
              }

            }
          },
          (err) => {
            console.log("response error of GetCarrierDataList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            // FlagApiLoader(false)
          }
        )

        if (userDetail[0]?.user_role_name == "Admin") {
          GetCarrierVersionListData(searchParamsCarrierId,
            (res) => {
              if (res.data.status) {
                if (searchParamsVersionId) {
                  // prev
                  // setCurrentVersion(Number(searchParamsVersionId))
                  // new
                  res?.data?.data[0]?.carrier_version_data?.map((row) => {
                    if (row.carrier_version_latest) {
                      setCurrentVersion(row?.carrier_version_id)
                      setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                      latestVersionId = row?.carrier_version_id;
                      mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                    }
                  })
                }
                else {
                  res?.data?.data[0]?.carrier_version_data?.map((row) => {
                    if (row.carrier_version_latest) {
                      setCurrentVersion(row?.carrier_version_id)
                      setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                      latestVersionId = row?.carrier_version_id;
                      mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                    }
                  })
                }
                setCarrierVersionList(res.data.data[0].carrier_version_data)
              } else {
                console.log("response false of GetCarrierVersionListData api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                  FlagApiLoader(false)
                }
                else {
                  UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                  FlagApiLoader(false)
                }
                navigate("/carriers")
                FlagApiLoader(false)
              }
            },
            (err) => {
              console.log("error of GetTemplateVersionList api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              FlagApiLoader(false)
              navigate("/carriers")
            }
          )
        }

        if (!searchParamsVersionId && userDetail[0]?.user_role_name == "Admin") {
          return;
        }

        let obj = {
          carrier_version_id: searchParamsVersionId,
          carrier_id: searchParamsCarrierId,
        }
        if (userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "agent" || userDetail[0]?.user_role_name?.trim()?.toLowerCase() == "member") {
          GetCarrierData(obj,
            (res) => {
              if (res.data.status) {
                // alert('2')
                // console.log("data res:: of GetCarrierData", res)
                const updatedCarrierDetails = {
                  ...res?.data?.data[0],
                  carrier_details: {
                    carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                    carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                  }
                };
                setCarrierDetails(updatedCarrierDetails)
                // console.log(updatedCarrierDetails, "updatedCarrierDetails++")
                setCarrierDetailsDuplicate(updatedCarrierDetails)
                const carrierTypeFlage = res.data.data[0].carrier_type_flag
                setToggleButton(carrierTypeFlage ? "life" : "annuity")
                FlagApiLoader(false)
              }
              else {
                if (res?.data?.error?.code === 9087) {
                  RevokeFunction()
                }
                else {
                  console.log("response false of GetCarrierData api", res);
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    FlagApiLoader(false)
                  }
                  else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    FlagApiLoader(false)
                  }
                  navigate("/carriers")
                  FlagApiLoader(false)
                }

              }
            }, (err) => {
              FlagApiLoader(false)
              console.log("response error of GetCarrierData api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              navigate("/carriers")
            })
        }
      }
      setFlagResourceExpand(false);
    }
  }, [flagCall])

  const mainDataCall = (searchParamsCarrierId, latestVersionId) => {
    let obj = {
      carrier_id: searchParamsCarrierId,
      carrier_version_id: latestVersionId
    }
    FlagApiLoader(true)
    GetCarrierData(obj,
      (res) => {
        if (res.data.status) {
          // alert('3')
          const updatedCarrierDetails = {
            ...res?.data?.data[0],
            carrier_details: {
              carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
              carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
            }
          };
          setCarrierDetails(updatedCarrierDetails)
          setCarrierDetailsDuplicate(updatedCarrierDetails)
          const carrierTypeFlage = res.data.data[0].carrier_type_flag
          setToggleButton(carrierTypeFlage ? "life" : "annuity")
          FlagApiLoader(false)
        } else {
          console.log("response false of GetCarrierData api", res);
          if (res?.data?.error?.code === 9087) {
            RevokeFunction()
          }
          else {
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
              FlagApiLoader(false)
            }
            else {
              UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
              FlagApiLoader(false)
            }
            FlagApiLoader(false)
            navigate("/carriers")
          }
        }

      }, (err) => {
        FlagApiLoader(false)
        console.log("response error of GetCarrierData api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
        navigate("/carriers")
      })
  }
  useEffect(() => {
    // debugger?
    if (type != "all") {
      // FlagApiLoader(true)
      let carrierId = carrierDetails?.carrier_types_list?.length ? carrierDetails?.carrier_types_list[0]?.carrier_id : ""

      let navigateURL = userDetail[0]?.user_role_name == "Agent" ? `/carriers/view_carrier?id=${carrierId}` : `/carriers/view_carrier?id=${carrierId}&version_id=`
      navigate(navigateURL)
      const flag = new Date().getTime()
      setFlagCall(flag)
    }
  }, [type])

  useEffect(() => {
    if (PathName === "/carriers/add_carrier") {
      setHideTransperentCell(true)
    } else {
      setHideTransperentCell(false)
    }
  }, [])

  const handleChangeVersion = (e) => {
    FlagApiLoader(true)
    let carrierId = searchParams.get("id");
    let obj = {
      carrier_id: carrierId,
      carrier_version_id: e.target.value
    }
    GetCarrierData(obj,
      (res) => {
        // console.log("handleChangeVersion res::", res)
        if (res?.data?.status) {
          // alert('4')
          const updatedCarrierDetails = {
            ...res?.data?.data[0],
            carrier_details: {
              carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
              carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
            }
          };
          setCarrierDetails(updatedCarrierDetails)
          setCarrierDetailsDuplicate(updatedCarrierDetails)
          setCurrentVersion(obj.carrier_version_id)
          setSearchParams({ id: carrierId, version_id: obj.carrier_version_id })
          FlagApiLoader(false)
        }
        else {
          console.log("response false of GetCarrierData api", res);
          if (res?.data?.error?.code === 9087) {
            RevokeFunction()
          } else {
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
              FlagApiLoader(false)
            }
            else {
              UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
              FlagApiLoader(false)
            }
          }
        }
      }, (err) => {
        console.log("error of GetCarrierData api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
        FlagApiLoader(false)
      }
    )
  }

  const PdfView = () => {
    return (
      <>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} textAlign={"end"} sx={{ margin: "10px 10px 0px 0px" }}>
            <Close sx={{ cursor: "pointer", }} onClick={() => { setFlagPdfPopup(false); }} width="100%" />
          </Grid>
          <Grid item xs={10}>
            <iframe style={{ height: "100vh", width: "100%", }} src={imageUrl} title="Doc"></iframe>
          </Grid>
        </Grid>
      </>
    )
  }

  const DeletePopup = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={12} sx={{ paddingRight: "15px" }} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to delete resource {deleteResourceData?.file_detail?.carrier_resource_file_name}?
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        DeleteResouceFile()
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagDeletePopup(false); }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const FileUploadPopup = () => {
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="center" className="cp-head-container">
          <Grid item xs={11} textAlign="center" className="cp-head-item">
            <span className="cp-label" style={{ marginLeft: "20px" }} >Upload Document</span>
          </Grid>
          <Grid item xs={1} className="cp-head-item">
            <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagFileUploadPopup(false) }} />
          </Grid>

          <Grid item xs={10} marginTop="20px">
            <Grid container sx={{
              backgroundColor: "#efeff0",
              // width: { xs: "calc(100% - 10px) !important", md: "calc(100% - 20px) !important" },
              marginTop: { xs: "15px", md: "0" },
              marginBottom: "20px",
              // height: { xs: "200px", md: "inherit" },
              padding: "10px",
              borderRadius: "5px !important"
            }}>
              <FileUpload
                type="file"
                carrierDetails={carrierDetails}
                setFlagCall={setFlagCall}
                flagCall={flagCall}
                setFlagFileUploadPopup={setFlagFileUploadPopup}
                saveData={(e) => {
                  // onChangeSave("widget_logo_id", e);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const deleteCarriarSectionDialog = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className="delete-main-container">
              <Grid item xs={12} sx={{ paddingRight: "15px" }} textAlign={"end"}>
                <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
              </Grid>
              <Grid item xs={12} className="delete-alert-item">
                <img src={deleteAlert}></img>
              </Grid>
              <Grid item xs={12} className="delete-mesg-item">
                Are you sure you want to delete this section?
              </Grid>
              <Grid item xs={12}>
                <Grid container sx={{ justifyContent: "center", gap: "10px" }} >

                  <Grid item xs={2.5}>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => { setFlagDeleteCarriarSectionDialog(false) }}
                        >No
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={2.5}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {

                          }}
                        >Yes</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const handleDeletePopOverExtraFuncs = () => {
    setHideTransperentCell(true)
    setSelectedRowGroup({
      selectId: null,
      selectedId: null
    })
    setSelectedRow(null)
  }

  const removeUnsavedChangesHandler = () => {
    setSelectedAddIcon(null)
    setFlagShowAddIcons(false)
    setEditDetails(false)
    document.getElementById('addLevelPopup').style.display = "none"
  }

  // // console.log("component rerendered")


  return (
    <div class="temp">
      {apiLoader == true && <Loader />}
      {flagLevelTablePopup && (
        <GlobalDialog
          open={flagLevelTablePopup}
          data={levelTablePopup()}
          maxWidth={"false"}
        />
      )}

      {flagPdfPopup && (
        <GlobalDialog
          open={flagPdfPopup}
          data={PdfView()}
          maxWidth={"false"}
          className="pdf-dialog-carrier"

        />
      )}

      {flagFileUploadPopup && (
        <GlobalDialog
          open={flagFileUploadPopup}
          data={FileUploadPopup()}
          maxWidth={"sm"}
        />
      )}

      {flagDeletePopup && (
        <GlobalDialog
          open={flagDeletePopup}
          data={DeletePopup()}
          maxWidth={"sm"}
        />
      )}

      {flagDeleteCarriarSectionDialog && (
        <GlobalDialog
          onClose={() => {
            setFlagDialogGloballyRender(false)
          }}
          open={flagDeleteCarriarSectionDialog}
          data={<DeleteCarriarSectionConfirmationDialog />}
          maxWidth={"xs"}
        />
      )}

      {/* {flagCustomizationCarriarSectionDialog && (
        <GlobalDialog
          open={flagCustomizationCarriarSectionDialog}
          data={<LevelCustomizationDialog carrierDetails={carrierDetails} setCarrierDetails={setCarrierDetails} carrierDetailsDuplicate={carrierDetailsDuplicate} setCarrierDetailsDuplicate={setCarrierDetailsDuplicate} levelSelectedData={levelSelectedData} colorList={colorList} setFlagCustomizationCarriarSectionDialog={setFlagCustomizationCarriarSectionDialog} />}
          maxWidth={"md"}
        // levelSelectedData={levelSelectedData}
        // maxWidth={"sm"}
        />
      )} */}

      {true && (
        <GlobalDialog
          open={flagUnsavedChangesDialog}
          data={
            <UnsavedChangesDialog
              setFlagUnsavedChangesDialog={setFlagUnsavedChangesDialog}
              setEditDetails={setEditDetails}
              setHideTransperentCell={setHideTransperentCell}
              setCarrierDetails={setCarrierDetails}
              carrierDetailsDuplicate={carrierDetailsDuplicate}
              removeUnsavedChangesHandler={removeUnsavedChangesHandler}
            />}
          className={"unsaved-changes-backdrop"}
          maxWidth={"sm"}
        />
      )}

      {flagDialogGloballyRender && (

        //<div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '108' }}></div>
        // <Backdrop
        //   sx={{ color: '#fff', zIndex: 108 }}
        //   open={flagDialogGloballyRender}
        // // onClick={handleClose}
        // >
        // </Backdrop>

        // <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '108' }}></div>
        <Backdrop
          sx={{ color: '#fff', zIndex: 108 }}
          open={flagDialogGloballyRender}
        // onClick={handleClose}
        >
        </Backdrop>

      )}

      {flagAfterLevelPopoverRender &&
        <Backdrop
          sx={{ color: '#fff', zIndex: 1101 }}
          open={flagAfterLevelPopoverRender}
        // onClick={handleClose}
        >
        </Backdrop>
      }

      {flagCarrierCustomizationGlobalDialog && (
        <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
      )}

      {flagCarrierCustomizationGlobalDeleteDialog &&
        <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
      }

      <div id="deleteHighlighter" style={{ position: "fixed", top: "0", left: "0", border: "1px solid #1565c0", width: "0px", height: "40px", zIndex: "108" }}>

      </div>

      <Helmet title="myAdvisorGrids | Carrier View" />


      {/* Navigation Section */}
      <Grid container justifyContent="center" className="mainPageStickyHeader">
        <Grid item xs={12} sm={12} md={12} lg={11.6} xl={12}>
          <Grid container rowGap={2} alignItems="center" width="100%" marginTop={"7px"}>
            <Grid item xs={editDetails ? 12 : 11} sm={editDetails ? 8 : 8.5} md={editDetails ? 8 : 8.5} lg={editDetails ? 6 : 5} xl={editDetails ? 6 : 5} >
              <Grid container alignItems={"center"}>

                <Grid item xs={12}>
                  <Box display={"flex"} width={'100%'} alignItems={"center"}>
                    {
                      !editDetails &&
                      <IconButton
                        sx={{ padding: "0px 10px 0px 0px" }}
                        onClick={() => navigate("/carriers")}
                      >
                        <BackIconComponent />
                      </IconButton>
                    }
                    {/* BELOW HEADER NAME IS NOW USED IN BELOW AutoComplete */}
                    {/* {
                      !editDetails &&
                      <Typography display="inline" sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "100%"
                      }} fontSize={{ xs: "18px", md: "20px" }}>
                        {carrierDetails.carrier_name}
                      </Typography>
                    } */}

                    {
                      !editDetails &&
                      // <Autocomplete
                      //   disableClearable
                      //   sx={{ minWidth: 300 }}
                      //   value={autoCompleteValue}
                      //   className="shubh-100"
                      //   slotProps={{
                      //     popper: { className: 'vc-nav-carrier-dropdown-backdrop' }
                      //   }}
                      //   id="combo-box-demo"
                      //   options={autoCompleteCarrierList}
                      //   getOptionLabel={(option) => option.carrier_name}
                      //   onChange={(e, newVal) => {
                      //     // console.log("newVal", newVal)
                      //     navigate(`/carriers/view_carrier?id=${newVal.carrier_id}&version_id=`)
                      //     setFlagCall(newVal.carrier_id)
                      //     setAutoCompleteValue({
                      //       carrier_name: newVal.carrier_name,
                      //       carrier_id: newVal.carrier_id
                      //     })
                      //   }}
                      //   renderInput={(params) =>
                      //     <TextField
                      //       className="vc-nav-automcomplete-textfield"
                      //       variant="standard"
                      //       {...params}
                      //       placeholder="Carriers"
                      //       InputProps={{
                      //         ...params.InputProps,
                      //         disableUnderline: true,
                      //         style: { fontSize: 20 }
                      //       }}
                      //     />}
                      // />

                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          className="vc-nav-carrier-select"
                          disableUnderline
                          value={searchParams.get("id")}
                          MenuProps={{
                            className: 'vc-nav-carrier-dropdown-backdrop',
                          }}
                          PaperProps={
                            { className: 'vc-nav-carrier-dropdown-paper' }
                          }
                          onOpen={() => setNavSearchBar(true)}
                          // on={() => setNavSearchBar(false)}
                          onChange={(e) => {
                            let navigateURL = userDetail[0]?.user_role_name == "Agent" ? `/carriers/view_carrier?id=${e.target.value}` : `/carriers/view_carrier?id=${e.target.value}&version_id=`
                            navigate(navigateURL)
                            let value = e.target.value
                            setFlagCall(e.target.value)
                          }}
                        >

                          {
                            carrierData.map((option) => {
                              return (
                                <MenuItem
                                  key={option?.carrier_name}
                                  value={
                                    option?.carrier_types?.length > 0
                                    && (
                                      toggleButton === "life" ? option.carrier_types[0]?.carrier_id || option?.carrier_types[1]?.carrier_id
                                        : option?.carrier_types[1]?.carrier_id || option.carrier_types[0]?.carrier_id
                                    )
                                  }
                                >
                                  {option.carrier_name}
                                </MenuItem>
                              )
                            })
                          }

                        </Select>
                      </FormControl>
                    }
                    {
                      // Textfield to edit Version Name 
                      editDetails &&
                      <Grid item>
                        <TextField
                          className={flagCarrierSelection ? "vc-carrier-name-focus-textfield" : "vc-carrier-name-textfield"}
                          value={carrierDetails.carrier_name}
                          variant="standard"
                          onFocus={() => {
                            setFlagCarrierSelection(true)
                          }}
                          onBlur={() => {
                            setFlagCarrierSelection(false)
                          }}
                          onChange={(e) => {
                            setCarrierDetails({
                              ...carrierDetails,
                              carrier_name: e.target.value
                            })
                          }}
                          InputProps={{
                            readOnly: false,
                            style: { fontSize: "24px", fontFamily: "Nunito", textOverflow: "ellipsis" },
                            disableUnderline: true
                          }}

                        />
                      </Grid>
                    }
                    {/* OLD DROP DOWN CODE WHICH IS REPLACED ABOVE USING FORM CONTROL SELECT */}
                    {/* {
                      !editDetails &&
                      <TextField
                        sx={{
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        id="outlined-select-currency"
                        select
                        SelectProps={{
                          MenuProps: {
                            className: 'vc-nav-carrier-dropdown-backdrop',
                          }
                        }
                        }
                        onChange={(e) => {
                          navigate(`/carriers/view_carrier?id=${e.target.value}&version_id=`)
                          setFlagCall(e.target.value)
                        }}
                        IconComponent={KeyboardArrowDownIcon}
                        value=""
                      >
                        {carrierData.map((option) => (
                          <MenuItem key={option?.carrier_name} value={option?.carrier_types?.length && option.carrier_types[0]?.carrier_id}>
                            {option.carrier_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    } */}
                  </Box>
                </Grid>
                {
                  editDetails &&
                  <Grid item xs={12} className="cg-crs-text-item">
                    <Typography component={'span'} sx={{ color: "#777777", fontSize: "15px", fontWeight: "500" }}>
                      Changes such as Create, Edit and Delete will only take effect when the
                      <Typography component={'span'} sx={{ color: "#000", fontSize: "15px", fontWeight: "500" }}> Save </Typography>button is pressed.
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
            {!editDetails &&
              <Grid item xs={1} sm={3.5} md={3.5} lg={2} xl={2} justifyContent={"end"}>
                <div className="displaySliderButtonAbove">
                  <LifeAnnuityButtons
                    editDetails={editDetails}
                    toggleButton={toggleButton}
                    setType={setType}
                    setToggleButton={setToggleButton}
                    carrierDetails={carrierDetails}
                  />
                </div>

                {
                  carrierDetails.carrier_version_latest && userDetail[0]?.user_role_name == "Admin" &&
                  <div className="displayEditButtonAbove">
                    <Button
                      className="viewCarrier_EditIconBtn"
                      variant="contained"
                      onClick={() => setEditDetails(true)}
                      size="small"
                    >
                      <EditIcon />
                    </Button>
                  </div>
                }

                {
                  (carrierDetails.carrier_version_latest && userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance") &&
                  <div className="displayEditCarrierButtonAbove">
                    <Button
                      className="viewCarrier_EditBtn"
                      // value={carrierDetails?.carrier_type_flag} display={'flex'} direction={'row'} justifyContent={"space-between"}
                      startIcon={<EditIcon />}
                      sx={{
                        size: "small",
                        borderRadius: "4px",
                        backgroundColor: "#1565C0",
                        marginRight: "20px",
                        padding: "8px 20px",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#1565C0"
                        }
                      }}
                      onClick={() => {
                        setEditDetails(true)
                        setHideTransperentCell(true)
                        setFlagShowAddIcons(false)
                      }}
                    >
                      Edit Carrier
                    </Button>
                  </div>
                }

              </Grid>
            }
            <Grid item xs={12} sm={editDetails ? 4 : 12} md={editDetails ? 4 : 12} lg={editDetails ? 6 : 5} xl={editDetails ? 6 : 5} display={'flex'} justifyContent={'end'}>

              {editDetails ?
                <Box className="viewCarrier_SaveCancel_Box" >
                  {/* sx={{ width: { xs: "100%", sm: "100%", md: "100%" }, display: "flex", justifyContent: "space-between" }} */}
                  <Button
                    disabled={flagShowAddIcons}
                    variant="outlined"
                    onClick={() => {

                      // setCarrierDetails(carrierDetailsDuplicate)
                      // setEditDetails(false)
                      if (tableContainerRef.current) {
                        tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                      }
                      setFlagUnsavedChangesDialog(true)
                    }}
                    sx={{ padding: "7px 40px", marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={flagShowAddIcons}
                    onClick={(e) => {
                      if (currentlyAddedCols && currentlyAddedCols?.length > 0 && flagShowAddIcons) {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: "Please first finish level addition process"
                        })
                        return;
                      }
                      // handleCarrierSaveUpdate()
                      handleValidation()
                    }}
                    sx={{
                      padding: "7px 40px",
                      borderRadius: "4px",
                      marginLeft: "10px",
                      backgroundColor: "#1565C0",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1565C0"
                      }
                    }}>
                    {PathName == "/carriers/add_carrier" ? "Create" : "Save"}
                  </Button>
                </Box>
                :
                <Box className="viewCarrier_EditBtnVersionList_Container"  >
                  {(carrierDetails.carrier_version_latest && userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance") ?
                    (
                      <div className="displayEditButtonBelow">
                        <Button
                          className="viewCarrier_EditBtn"
                          // value={carrierDetails?.carrier_type_flag} display={'flex'} direction={'row'} justifyContent={"space-between"}
                          startIcon={<EditIcon />}
                          sx={{
                            size: "small",
                            borderRadius: "4px",
                            backgroundColor: "#1565C0",
                            marginRight: "20px",
                            padding: "8px 20px",
                            color: "white",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#1565C0"
                            }
                          }}
                          onClick={() => {
                            setEditDetails(true)
                            setHideTransperentCell(true)
                            setFlagShowAddIcons(false)
                          }}>
                          Edit Carrier
                        </Button>
                      </div>
                    ) : ("")
                  }
                  <div className="displaySliderButtonBelow">
                    <LifeAnnuityButtons
                      editDetails={editDetails}
                      toggleButton={toggleButton}
                      setType={setType}
                      setToggleButton={setToggleButton}
                      carrierDetails={carrierDetails}
                    />
                  </div>
                  {userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance" &&
                    <FormControl className="viewCarrier_VersionList_Dropdown">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{
                          backgroundColor: "#EFEFF0",
                          "& .MuiSelect-select": {
                            padding: "10px 14px",
                          },
                          "& fieldset": {
                            border: "none"
                          }
                        }}
                        value={currentVersion}
                        onChange={(e) => {
                          // alert('he')
                          handleChangeVersion(e)
                        }}
                      >
                        {carrierVersionList?.map((element) => {
                          const currentVersionList = new Date(element?.carrier_version_timestamp);
                          const day = currentVersionList.getUTCDate();
                          const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                          const year = currentVersionList.getUTCFullYear();
                          const hours = currentVersionList.getUTCHours();
                          const minutes = currentVersionList.getUTCMinutes();
                          const seconds = currentVersionList.getUTCSeconds();
                          let formattedDate = "";
                          formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${element.carrier_version_latest ? '(Current version)' : ''}`.trim()

                          return (
                            <MenuItem value={element?.carrier_version_id}>{formattedDate}</MenuItem>
                          )
                        })
                        }
                      </Select>
                    </FormControl>
                  }
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider my={2} mb={4} />
        </Grid>
      </Grid>


      {/* Carrier View Section */}
      < Grid container spacing={2} mt={1} sx={{ maxWidth: { xs: "100vw", md: "87vw" }, margin: "auto" }}>
        <Grid item xs={12} id={"carrier_view_container"} className="main_box_shadow cg-view-carrier-inner-scrollable-wrapper" sx={{ backgroundColor: "#FFFFFF", padding: "15px !important", }}>
          <Grid container sx={{ maxHeight: "100%", }}>

            {/* Carrier Details Section */}
            <Grid item xs={12}>
              <CustomAccordion
                // defaultExpanded
                disableGutters
                className="carrier_dertails_accordian"
                sx={{
                  backgroundColor: editDetails ? "#fff" : "#EFEFF0",
                }}
                expanded={flagCarrierDetailsExpand}
              >
                {!editDetails && <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#1565C0", fontWeight: "600", fontSize: "16px", paddingLeft: "25px" }}
                  onClick={() => {
                    setFlagCarrierDetailsExpand(!flagCarrierDetailsExpand)
                    setEditDetails(false)
                  }}
                >
                  Carrier Details
                </AccordionSummary>}

                <AccordionDetails >
                  <Grid container rowGap={4} padding={editDetails ? "0px" : "0px 25px"} >
                    <Grid item xs={12} sm={12} md={12} lg={8.7} xl={editDetails ? 7.5 : userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim().toLowerCase() == "brokers alliance" ? 9 : 12}>
                      <Grid container spacing={5} justifyContent={"space-between"} sx={{ height: "100%" }}>

                        <Grid item xs={12}>
                          <Grid container
                            sx={{
                              display: "grid",
                              gridTemplateRows: "repeat(6, auto)",
                              gridAutoFlow: "column",
                              gridTemplateColumns: `repeat(${Math.ceil(carrierDetails?.carrier_details?.carrier_data_column_left?.length / 6)}, 1fr)`,
                              gap: "8px",
                              columnGap: '20px',
                              '@media (max-width: 840px)': {
                                gridTemplateRows: `repeat(${carrierDetails?.carrier_details?.carrier_data_column_left?.length}, auto)`,
                                gridTemplateColumns: "repeat(1, 1fr)"
                              }
                            }}
                          >
                            {
                              carrierDetails?.carrier_details?.carrier_data_column_left?.map((data, ind) => (

                                (data?.carrier_detail_field_name !== "" && data !== undefined) &&
                                <Grid item key={ind} sx={{ height: 'fit-content' }}>
                                  <Grid container alignItems={"start"} sx={{}} >

                                    <Grid item className="carrierVersion_DetailsLable">
                                      <CustomLabelTypography sx={{ color: "#7A7A7A" }}>
                                        {data?.carrier_detail_field_name}
                                      </CustomLabelTypography>
                                    </Grid>

                                    <Grid item className="carrierVersion_DetailsContent">
                                      <CustomTextFied
                                        key={ind}
                                        id="standard-read-only-input"
                                        multiline
                                        value={data?.carrier_detail_field_value}
                                        variant="standard"
                                        fullWidth
                                        InputProps={{
                                          readOnly: !editDetails,
                                          style: { fontSize: "15px" },
                                          disableUnderline: !editDetails,
                                        }}
                                        onChange={(e) => {
                                          handleCarrierDetailsChangeLeft(e, data?.carrier_detail_field_id)
                                          // e.target.focus()
                                        }}
                                      />
                                    </Grid>

                                  </Grid>
                                </Grid>
                              ))
                            }
                          </Grid >
                        </Grid>
                        {
                          carrierDetails?.carrier_data_column_left?.filter(dt => dt?.carrier_detail_field_id == 15)[0].carrier_detail_flag_show_field == true && (
                            <>

                              {
                                !editDetails && <Grid item xs={12} display={"flex"} alignItems={'center'} justifyContent={'center'} >
                                  {/* {props?.carrierDetails?.carrier_details?.carrier_data_column_left?.map((row, i) => { */}
                                  {/* return ( */}
                                  {/* row?.carrier_detail_flag_show_field == true && */}
                                  <Typography
                                    className="carrier-details-footer-Note"
                                  >
                                    All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                                    {/* {row?.carrier_detail_field_name} */}
                                  </Typography>
                                  {/* )
                      })} */}
                                </Grid>
                              }
                            </>
                          )
                        }
                        {editDetails ? (<></>) : (
                          <>
                            <Grid item xs={12} sx={{ textAlign: "center", marginBottom: '10px' }}>
                              <Typography variant="span" sx={{ color: "#1565C0", fontSize: "13px", fontWeight: "500" }}>
                                All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                              </Typography>
                            </Grid>
                          </>
                        )
                        }
                      </Grid>
                    </Grid>

                    {userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" ? (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={0.3} xl={0.3} container direction={"row"} justifyContent="center">
                          <Divider orientation={minScreenWidth ? 'horizontal' : 'vertical'} sx={minScreenWidth ? { display: 'flex', width: "100%" } : {}} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={editDetails ? 4.2 : 2.7}>
                          <Grid
                            container
                            flexDirection="column"
                            rowGap={3}
                          >

                            <Grid item xs={12} textAlign={"center"}>
                              <Typography variant="span"
                                sx={{
                                  textTransform: "none",
                                  color: "#00345E",
                                  fontSize: "15px",
                                  fontWeight: "600"
                                }}>
                                Internal Info Only
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container rowGap={editDetails ? 3 : 0}>
                                {
                                  carrierDetails.carrier_details?.carrier_data_column_right?.map((row, index) =>
                                  // row.column_sequence !== 1 && row.column_sequence !== 12 && index % 2 !== 0 &&
                                  // ?
                                  (
                                    <Grid key={index} item xs={12}>
                                      <Grid container className="viewCarrier_Internalinfo_Container" alignItems={'start'}>
                                        {/* xs={12} sm={4} md={3} lg={12} xl={editDetails ? 6 : 12} */}
                                        <Grid item className="viewCarrier_Internalinfo_itemLable" >
                                          <CustomLabelTypography sx={{ color: "#7A7A7A", marginRight: "10px" }}>
                                            {row?.carrier_detail_field_name}
                                          </CustomLabelTypography>
                                        </Grid>
                                        {/* xs={12} sm={8} md={9} lg={12} xl={editDetails ? 6 : 12} */}
                                        <Grid item className="viewCarrier_Internalinfo_itemContent" sx={{ wordBreak: "break-all" }}>
                                          {
                                            row?.carrier_detail_field_name == 'Drive Folder' ?
                                              (
                                                editDetails ?
                                                  <CustomTextFied
                                                    key={index}
                                                    id="standard-read-only-input"
                                                    // defaultValue={props?.carrierDetails?.carrier_details?.carrier_data_column_right[index + 1]?.column_value}
                                                    defaultValue={row?.carrier_detail_field_value}
                                                    variant="standard"
                                                    fullWidth
                                                    multiline
                                                    InputProps={{
                                                      readOnly: !editDetails,
                                                      style: { fontSize: "15px" },
                                                      disableUnderline: !editDetails,
                                                    }}
                                                    onChange={(e) => {
                                                      handleCarrierDetailsChangeRight(e, row?.carrier_detail_field_id)
                                                    }}
                                                  />

                                                  :
                                                  <Link href={row?.carrier_detail_field_value} target="_blank" >
                                                    {row?.carrier_detail_field_value}
                                                  </Link>

                                              )
                                              :
                                              (
                                                <CustomTextFied
                                                  key={index}
                                                  id="standard-read-only-input"
                                                  value={row?.carrier_detail_field_value}
                                                  variant="standard"
                                                  fullWidth
                                                  multiline
                                                  InputProps={{
                                                    readOnly: !editDetails,
                                                    style: { fontSize: "15px" },
                                                    disableUnderline: !editDetails,
                                                  }}
                                                  onChange={(e) => {
                                                    handleCarrierDetailsChangeRight(e, row?.carrier_detail_field_id)
                                                  }}
                                                />
                                              )
                                          }
                                        </Grid>

                                      </Grid>
                                    </Grid>
                                  )
                                    // :
                                    // null
                                  )
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (<></>)}

                  </Grid>
                  {/* New GRID END */}
                </AccordionDetails>
              </CustomAccordion >



              {/* <CarrierDetailsAccordian carrierDetails={carrierDetails}  userRole={userDetail[0]?.user_role_name} /> */}
            </Grid>

            {/* Resources Section */}
            {
              !editDetails &&
              <Grid item xs={12}>
                <ResourcesAccordian carrierDetails={carrierDetails} setFlagCall={setFlagCall} userRole={userDetail[0]?.user_role_name} />
              </Grid>

            }

            {/* Levels Section */}
            <Grid item xs={12} className={"vw-levels-table-main-item"}>
              <Grid container>
                {/* Temp commented due to issue  */}
                {/* <LevelsAccordian carrierDetails={carrierDetails} /> */}

                {/* INSERT COLUMN POPOVER*/}
                <div>
                  <Popover
                    id={columnId}
                    open={columnCellPopoverOpen}
                    anchorEl={anchorElForLevelsColumn.type}
                    onClose={handleCloseColumnPopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId) <= 6 ? 'left' : "right"
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: PathName === "/carriers/view_carrier" ? (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId <= 6 ? 'right' : "left") : "left"
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '300px',
                        borderRadius: '10px',
                      }
                    }}
                    className="vw-action-popover"
                    transitionDuration={500}
                  >
                    <Grid container>
                      <Grid item xs={12} sx={{ padding: "10px" }}>
                        <Grid container>
                          {
                            // PathName === "/carriers/view_carrier" &&
                            carrierDetails?.carrier_level?.length != 0 &&

                            <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select
                                    fullWidth
                                    variant="standard"
                                    size="medium"
                                    value={newLevelData?.carrier_level_after}
                                    onChange={(e) => {
                                      // console.log("nilesh", e.target)
                                      setLevelSelectedData({
                                        name: e.target.value,
                                        value: e.target.value
                                      })
                                      // const element = document.querySelector(`[data-id="${e.target.value}"]`);
                                      // // // console.log("rect", element.getBoundingClientRect())
                                      // setSliderCoordinates({
                                      //   x: element.getBoundingClientRect().y,
                                      //   y: element?.getBoundingClientRect().x
                                      // })
                                      // // console.log("element", element)
                                      // let eleIdx = carrierDetails?.carrier_level?.findIndex(level => level?.carrier_level_id == e.target.value)


                                      // if (element) {
                                      //   element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                      //   setAnchorElForLevelsColumn({ type: null, columnId: 0 });
                                      //   setTimeout(function () {

                                      //     setAnchorElForLevelsColumn({ type: element, columnId: eleIdx });

                                      //   }, 900)
                                      // } else {
                                      //   // console.log('Element not found');
                                      // }

                                    }}
                                  >
                                    {carrierDetails?.carrier_level?.map((level) => (
                                      <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          {/* <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Enter Level</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField fullWidth id="standard-basic" variant="standard" />
                                <Select
                                  fullWidth
                                  variant="standard"
                                  size="medium"
                                  onChange={(e) => {
                                    setLevelSelectedData({
                                      name: e.target.value.carrier_level_id,
                                      value: e.target.value
                                    })
                                    // // console.log("etar", e)
                                    // setLevelSelectedData()
                                  }}
                                >
                                  {carrierDetails?.carrier_level?.map((level) => (
                                    <MenuItem value={level.carrier_level_id} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                <Typography variant="span" sx={{ color: "red", fontSize: "12px", fontWeight: "500" }}>*</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="standard"
                                  fullWidth
                                  error={emptyLevelName}
                                  value={newLevelData.carrier_level_name}
                                  onChange={(e) => {
                                    setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                  }}
                                ></TextField>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button fullWidth textAlign="left" varient="text">
                                  Insert Before
                                </Button>
                              </Grid>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button fullWidth textAlign="left" varient="text">
                                  Insert After
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid> */}

                          {/* //Harsh's commented code */}
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  fullWidth
                                  variant="standard"
                                  size="medium"
                                  value={{
                                    carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                    carrier_level_color_id: newLevelData?.carrier_level_color_id,
                                    carrier_level_color_name: newLevelData?.carrier_level_color_name,
                                  }}
                                  renderValue={() =>
                                    <div>
                                      <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </span>
                                      <span>{newLevelData.carrier_level_color_name}</span>
                                    </div>}
                                  onChange={(e) => {
                                    // console.log("etar", e.target)
                                    setNewLevelData({ ...newLevelData, carrier_level_color_id: e.target.value?.carrier_level_color_id, carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name })
                                  }}
                                >
                                  {
                                    colorList?.map((color) => {
                                      return (
                                        <MenuItem value={{
                                          carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                          carrier_level_color_id: color.carrier_level_color_id,
                                          carrier_level_color_name: color.carrier_level_color_name,


                                        }}>
                                          <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}>

                                          </span>
                                          <span value={color}>{color?.carrier_level_color_name}</span>
                                        </MenuItem>
                                      )
                                    })

                                  }
                                </Select>


                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sx={{ margin: "15px 0px", border: '1px solid rgba(138, 138, 138, 0.30)', padding: "10px" }}>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>BA Level</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
                                <Checkbox checked={newLevelData?.carrier_ba_level_flag} onChange={(e) => {
                                  setNewLevelData({
                                    ...newLevelData, carrier_ba_level_flag: e.target.checked
                                  })
                                }} />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                            <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                              <Grid item xs={5}>
                                <Button
                                  variant="outlined"
                                  size="medium"
                                  fullWidth
                                  // onClick={handleCloseColumnPopover}
                                  onClick={() => {
                                    setFlagAfterLevelPopoverRender(false)
                                    handleCloseColumnPopover();
                                    setNewLevelData({
                                      carrier_level_after: null,
                                      carrier_level_id: null,
                                      carrier_level_name: "",
                                      carrier_ba_level_flag: false,
                                      // carrier_level_sequence: 1,
                                      carrier_level_color_id: 1,
                                      carrier_level_color_hex_code: "#1b2430",
                                      carrier_level_color_name: "Default"
                                    })
                                    setEmptyLevelName(false)
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                              <Grid item xs={5}>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  fullWidth
                                  onClick={() => {
                                    // console.log("i am OnClick")
                                    handleAddNewLevel()
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>


                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>

                {/* INSERT ROW POPOVER*/}
                <div>
                  <Popover
                    id={rowId}
                    open={rowPopOveropen}
                    anchorEl={anchorElForRow}
                    onClose={handleAddRowPopOverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '150px',
                        borderRadius: '10px'
                      }
                    }}
                    className="vw-action-popover"
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11}>
                                <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Add New Row</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <IconButton sx={{ padding: "2px", }} onClick={() => handleAddRowPopOverClose()}>
                                  <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Typography
                                  variant="span"
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    setAnchorElForRow(null)
                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx)
                                  }}
                                >
                                  Insert Above
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Typography
                                  variant="span"
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    setAnchorElForRow(null)
                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx + 1)
                                  }}
                                >
                                  Insert Below
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>

                {/* ADD NEW SECTION POPOVER */}
                <div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '180px',
                        borderRadius: '10px'
                      }
                    }}
                    className="vw-action-popover"
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11}>
                                <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Action Name</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <IconButton sx={{ padding: "2px", }}>
                                  <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} onClick={() => handleClose()} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button
                                  variant="text"
                                  fullWidth
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    addNewSection(selectedLinePopover.groups, selectedLinePopover.index)
                                  }}
                                >
                                  Add New Section
                                </Button>
                              </Grid>

                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button
                                  variant="text"
                                  // disabled = {}
                                  fullWidth
                                  className={
                                    PathName === "/carriers/add_carrier" ?
                                      (
                                        carrierLevelLength ? "vw-vw-popover-btn-disable"
                                          : "vw-popover-action-text"
                                      )
                                      : "vw-popover-action-text"
                                  }
                                  disabled={(PathName === "/carriers/add_carrier" && carrierDetails?.carrier_level_data?.length == 1) ? true : false}
                                  onClick={() => {
                                    setDeleteRowPopupFlag(true)
                                    setSelectedRowGroup({
                                      selectId: null,
                                      selectedId: selectedRowGroupId.selectId
                                    })
                                    // setHideTransperentCell(false)
                                    handleClose()
                                    handleClose()

                                    handleClose()
                                    setTimeout(() => {
                                      let rowToHighLight = document.querySelector(`[data-section-id="${selectedRowGroupId.selectId}"]`);
                                      let rect = rowToHighLight.getBoundingClientRect();
                                      let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                      let tableContainer = document.getElementById('custom-table').getBoundingClientRect();

                                      let sectionToHighLight = carrierDetails?.carrier_level_data?.filter(group => group?.carrier_group_id == selectedRowGroupId.selectId || group?.carrier_group_id_custom == selectedRowGroupId.selectId)[0]
                                      let top = rect.y;
                                      let left = tableContainer.left + 50;
                                      let width = tableContainer.width - 50;
                                      let height = 40 * sectionToHighLight?.carrier_group_data?.length;
                                      hiddenHighLighter.style.top = `${top}px`;
                                      hiddenHighLighter.style.width = `${width}px`;
                                      hiddenHighLighter.style.left = `${left}px`;
                                      hiddenHighLighter.style.height = `${height}px`;
                                      hiddenHighLighter.style.border = `1px solid #376fd0`;

                                      document.body.style.overflow = 'hidden';
                                      document.getElementById("carrier_view_container").style.overflow = "hidden"
                                    }, 700)

                                    document.body.style.overflow = 'hidden';
                                    document.getElementById("carrier_view_container").style.overflow = "hidden"

                                    // deleteSection(selectedLinePopover?.groups, selectedLinePopover?.index)
                                  }}>
                                  Delete Section
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>

                <Grid item xs={12} className="vw-levels-heading-main-item">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography className="accordionSummury_Label" >
                        Levels
                      </Typography>
                    </Grid>
                    <Grid item display={editDetails ? "inline" : flagLevelAccordionExpanded ? "inline" : "none"}>
                      {editDetails ?
                        (
                          <>
                            <Grid container>
                              {flagShowAddIcons ?
                                (
                                  <Grid container gap={"0.5rem"}>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        size="medium"
                                        disabled={currentlyAddedCols && currentlyAddedCols?.length == 0}
                                        onClick={() => {
                                          setFlagShowAddIcons(!flagShowAddIcons)
                                          setCurrentlyAddedCols([])
                                        }}

                                      /* onClick={() => {
                                        setFlagShowAddIcons(!flagShowAddIcons)
                                      }} */
                                      >
                                        Continue
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="outlined"
                                        size="medium"
                                        onClick={() => {
                                          if (tableContainerRef.current) {
                                            tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                                          }
                                          handleRevertColumnAddition();
                                          setFlagShowAddIcons(!flagShowAddIcons)
                                          setCurrentlyAddedCols([])
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>

                                ) : (
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      startIcon={<AddCircleIcon />}
                                      onClick={() => {
                                        carrierDetails?.carrier_level?.length > 0 ?
                                          setFlagShowAddIcons(!flagShowAddIcons) :
                                          carrierDetails?.carrier_level?.length === 0 ?
                                            handleOpenColumnPopover(undefined, 1, document.getElementById('blank-tablecell'))
                                            :
                                            setAddLevels(false)
                                        setFlagShowAddIcons(true)

                                      }}
                                    >
                                      Levels
                                    </Button>
                                  </Grid>
                                )
                              }
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container sx={{ gap: "10px" }}>
                              <Grid item>
                                <ZoomOutMapIcon sx={{ cursor: 'pointer' }} onClick={() => setFlagLevelTablePopup(true)} />
                              </Grid>
                              <Grid item>
                                {flagShowCloseLevelSection ?
                                  <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} /> :
                                  <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} />
                                }
                              </Grid>
                            </Grid>
                          </>
                        )

                      }
                    </Grid>
                  </Grid>
                </Grid>

                {flagShowCloseLevelSection ? (
                  // NILESH
                  <>
                    <Grid item className="custom_grid" id="custom-grid" xs={12} textAlign="center" sx={{ position: "relative" }}>
                      {deleteRowPopupFlag &&
                        <DeleteCarrierSectionDialog
                          sectionOrRow={sectionOrRow}
                          deleteRowPopupFlag={deleteRowPopupFlag}
                          setDeleteRowPopupFlag={setDeleteRowPopupFlag}
                          deleteSection={deleteSection}
                          deleteRowForSection={deleteRowForSection}
                          extraFunction={handleDeletePopOverExtraFuncs}
                          flagDialogGloballyRender={flagDialogGloballyRender}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                          setFlagAfterLevelPopoverRender={setFlagAfterLevelPopoverRender}
                        />
                      }

                      {flagCarrierCustomizationDeleteDialog && (
                        <DeleteCarrierLevelPopUp
                          flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                          setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}

                          flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                          setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                          carrierDetails={carrierDetails}
                          setCarrierDetails={setCarrierDetails}
                          levelSelectedData={levelSelectedData}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                          setHideTransperentCell={setHideTransperentCell}
                        />
                      )}

                      {flagCustomizationCarriarSectionDialog && (
                        <LevelCustomizationDialog
                          carrierDetails={carrierDetails}
                          setCarrierDetails={setCarrierDetails}
                          levelSelectedData={levelSelectedData}
                          colorList={colorList}
                          setFlagCustomizationCarriarSectionDialog={setFlagCustomizationCarriarSectionDialog}
                          setFlagCarrierCustomizationGlobalDialog={setFlagCarrierCustomizationGlobalDialog}

                          flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                          setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}

                          flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                          setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                          carrierId={searchParams.get("id")}
                          setSelectedAddIcon={setSelectedAddIcon}
                          setHideTransperentCell={setHideTransperentCell}
                          setCurrentlyAddedCols={setCurrentlyAddedCols}

                        // setFlagDialogGloballyRender = {setFlagDialogGloballyRender}
                        />
                      )}
                      <Grid container className="" sx={{ justifyContent: "center", position: "relative" }}>
                        <TableContainer
                          // id="vc-level-table"
                          className="vc-main-table-contanier "
                          // sx={{ minHeight: "50vh" }}
                          sx={{ height: "55vh", position: "relative" }}
                          component={Paper}
                          id="custom-table"
                          ref={tableContainerRef}
                        >

                          <div style={{ position: "absolute", top: "40px", left: sliderCoordinates?.x, zIndex: "2002", transition: "all 1s", display: "none", textAlign: "left", width: "325px" }} id="addLevelPopup">
                            {/* slider */}
                            <Box className={`AddColumn_Popper ${(slectedAddIcon > carrierDetails?.carrier_level?.length - 7 && carrierDetails?.carrier_level?.length - 7 > 0) ? "position-right" : "position-left"} `}
                            // sx={{ right: Position ? "49px" : "" }}
                            >
                              {/* <Grid container rowGap={4}>
                                <Grid item xs={12}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                      After Level
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      // value={LevelId}
                                      label="After Level"
                                      onChange={(e) => {
                                        // // console.log("etar", e)
                                        // setLevelSelectedData()
                                        const element = document.querySelector(`[data-id="${e.target.value}"]`);

                                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });

                                        // setTimeout(() => {
                                        setSliderCoordinates({
                                          x: element.offsetLeft + element.offsetWidth,
                                          y: element.x
                                        })
                                        // }, 800)

                                      }}
                                    // onChange={handleChange}
                                    >
                                      {carrierDetails?.carrier_level?.map((level) => (
                                        <MenuItem value={level.carrier_level_id || level?.carrier_level_id_custom}>{level.carrier_level_name}</MenuItem>
                                      ))}
                                    </Select>

                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="outlined-helperText"
                                    label="Lavel Name"
                                    defaultValue="L2"
                                    fullWidth
                                    variant="standard"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl variant="standard" fullWidth>
                                    <InputLabel className="AddIconPopUp_MenuItem" variant="standard" id="color-picker-label">Select Color</InputLabel>
                                    <Select labelId="color-picker-label" value={selectedColor}
                                    // onChange={handleColorChange}
                                    >
                                      {colorOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          <ListItemIcon sx={{ minWidth: '28px' }}>
                                            <Box className="showColor_inDropDown"
                                              style={{
                                                backgroundColor: option.value,
                                              }}
                                            />
                                          </ListItemIcon>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} padding={'15px 15px 8px 15px'}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6} className="AddIconPopUp_Buttons" >
                                      <Button
                                        onClick={() => {
                                          setAddColumn(null)
                                          setFlagShowAddIcons(false)
                                        }}
                                        variant="outlined"
                                        sx={{ padding: "5px 30px" }}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6} className="AddIconPopUp_Buttons">
                                      <Button variant="contained" sx={{ padding: "5px 36px" }} >
                                        Add
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid> */}
                              <Grid item xs={12} sx={{ padding: "10px" }}>
                                <Grid container>
                                  {
                                    carrierDetails?.carrier_level?.length != 0 &&

                                    <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Select
                                            fullWidth
                                            variant="standard"
                                            size="medium"
                                            MenuProps={{
                                              className: 'vc-addLevel-dropdown-backdrop',
                                            }}
                                            value={newLevelData?.carrier_level_after == "None" ? "None" : newLevelData?.carrier_level_after}
                                            onChange={(e) => {
                                              setSelectedAddIcon(null)
                                              setNewLevelData({ ...newLevelData, carrier_level_after: e.target.value })
                                              // document.getElementById("custom-table").addEventListener("scrollend", () => {
                                              if (e.target.value === "None") {
                                                setSelectedAddIcon("nullPlus")
                                                setLevelSelectedData({
                                                  name: e.target.name,
                                                  value: e.target.value
                                                })
                                              } else {
                                                const levelIndex = carrierDetails?.carrier_level.find((level) => level.carrier_level_id == e.target.value || level?.carrier_level_id_custom == e.target.value)
                                                setSelectedAddIcon(levelIndex.carrier_level_id || levelIndex.carrier_level_id_custom)
                                                setLevelSelectedData({
                                                  name: e.target.name,
                                                  value: e.target.value
                                                })
                                              }
                                              // })

                                              // console.log(e, "e tar")
                                              let dataIdAttr = e.target.value == "None" ? (carrierDetails?.carrier_level[0].carrier_level_id || carrierDetails?.carrier_level[0].carrier_level_id_custom) : e.target.value
                                              const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                              const popup = document.getElementById('addLevelPopup')
                                              element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });

                                              // setTimeout(() => {
                                              setSliderCoordinates({
                                                // x: e.target.value == "None" ? element.offsetLeft - 8 : element.offsetLeft + element.offsetWidth - 8,
                                                x: e.target.value == "None" ? element.offsetLeft - 8 : (element.offsetLeft + popup.clientWidth) > document.getElementById("vc-level-table").clientWidth ? (element.offsetLeft + element.offsetWidth - popup.clientWidth - 8) : element.offsetLeft + element.offsetWidth - 8,
                                                y: element.x
                                              })

                                              document.getElementById('addLevelPopup').style.position = "none"
                                              // }, 800)

                                            }}
                                          >
                                            <MenuItem value={"None"} label={"None"}>None</MenuItem>
                                            {carrierDetails?.carrier_level?.map((level) => (
                                              <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                            ))}
                                          </Select>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  }
                                  {/* <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Enter Level</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField fullWidth id="standard-basic" variant="standard" />
                                <Select
                                  fullWidthaddLevelPopup
                                  variant="standard"
                                  size="medium"
                                  onChange={(e) => {
                                    setLevelSelectedData({
                                      name: e.target.value.carrier_level_id,
                                      value: e.target.value
                                    })
                                    // // console.log("etar", e)
                                    // setLevelSelectedData()
                                  }}
                                >
                                  {carrierDetails?.carrier_level?.map((level) => (
                                    <MenuItem value={level.carrier_level_id} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid> */}
                                  <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container sx={{ alignItems: "center" }}>
                                          <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                          <Typography variant="span" sx={{ color: "red", fontSize: "14px", fontWeight: "500" }}>*</Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          size="small"
                                          variant="standard"
                                          error={emptyLevelName}
                                          fullWidth
                                          value={newLevelData.carrier_level_name}
                                          onChange={(e) => {
                                            setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  {/* <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button fullWidth textAlign="left" varient="text">
                                  Insert Before
                                </Button>
                              </Grid>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button fullWidth textAlign="left" varient="text">
                                  Insert After
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid> */}

                                  {/* //Harsh's commented code */}
                                  <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Select
                                          fullWidth
                                          variant="standard"
                                          size="medium"
                                          value={{
                                            carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                            carrier_level_color_id: newLevelData?.carrier_level_color_id,
                                            carrier_level_color_name: newLevelData?.carrier_level_color_name,

                                          }}
                                          MenuProps={{
                                            className: 'vc-addLevel-dropdown-backdrop',
                                          }}
                                          onChange={(e) => {

                                            // console.log("etar", e.target)
                                            setNewLevelData({ ...newLevelData, carrier_level_color_id: e.target.value?.carrier_level_color_id, carrier_level_color_hex_code: e.target.value, carrier_level_color_name: e.target.name })

                                            setSelectedColor({
                                              color: e.target.value.carrier_level_color_name,
                                              hexCode: e.target.value.carrier_level_color_hex_code
                                            })
                                            setNewLevelData({ ...newLevelData, carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name, carrier_level_color_id: e.target.value.carrier_level_color_id })

                                          }}
                                          renderValue={() =>
                                            <div>
                                              <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </span>
                                              <span>{newLevelData.carrier_level_color_name}</span>
                                            </div>}
                                        >

                                          {
                                            colorList?.map((color) => {
                                              return (
                                                <MenuItem value={{
                                                  carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                                  carrier_level_color_id: color.carrier_level_color_id,
                                                  carrier_level_color_name: color.carrier_level_color_name,

                                                }}>
                                                  <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}>

                                                  </span>
                                                  <span value={color}>{color?.carrier_level_color_name}</span>
                                                </MenuItem>
                                              )
                                            })

                                          }
                                        </Select>


                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} sx={{ margin: "15px 0px", border: '1px solid rgba(138, 138, 138, 0.30)', padding: "10px" }}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                      <Grid item xs={11}>
                                        <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>BA Level</Typography>
                                      </Grid>
                                      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Checkbox checked={newLevelData?.carrier_ba_level_flag} onChange={(e) => {
                                          setNewLevelData({
                                            ...newLevelData, carrier_ba_level_flag: e.target.checked
                                          })
                                        }} />
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} sx={{ margin: "10px 0px 10px" }}>
                                    <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                      <Grid item xs={5}>
                                        <Button
                                          variant="outlined"
                                          size="medium"
                                          fullWidth
                                          // onClick={handleCloseColumnPopover}
                                          onClick={() => {
                                            setSelectedAddIcon(null)
                                            document.getElementById('addLevelPopup').style.display = "none"
                                            setHideTransperentCell(true)
                                            // setFlagDialogGloballyRender(false)
                                            // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                            setFlagAfterLevelPopoverRender(false)
                                            setNewLevelData({
                                              carrier_level_after: null,
                                              carrier_level_id: null,
                                              carrier_level_name: "",
                                              carrier_ba_level_flag: false,
                                              // carrier_level_sequence: 1,
                                              carrier_level_color_id: 1,
                                              carrier_level_color_hex_code: "#1b2430",
                                              carrier_level_color_name: "Default"
                                            })
                                            setFlagAfterLevelPopoverRender(false)
                                            setEmptyLevelName(false)
                                            tableContainerRef.current.style.overflow = 'auto'
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          fullWidth
                                          onClick={() => {
                                            // setSelectedAddIcon(null)
                                            handleAddNewLevel()
                                            setHideTransperentCell(true)
                                            setFlagDialogGloballyRender(false)
                                            // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                            tableContainerRef.current.style.overflow = 'auto'

                                          }}
                                        >
                                          Add
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>


                                </Grid>
                              </Grid>

                            </Box>
                          </div>

                          <Table
                            id="vc-level-table"
                            sx={{ position: "relative", width: "auto !important", minWidth: "100%" }}
                            className="templateCarrierTable"
                            aria-label="a dense table"
                            size="small"
                            stickyHeader

                          >
                            <TableHead>
                              {
                                (flagShowAddIcons && !deleteRowPopupFlag) &&
                                <TableRow className="leveltable_IconRow vc_icons_row ">
                                  <TableCell className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-1" sx={{ border: "none" }} data-type="header-plus-static-cells">
                                    {/* blank */}
                                  </TableCell>
                                  <TableCell className="leveltable_IconCell-staticsticky-table-head sticky-carrier-table-head-2" data-type="header-plus-static-cells">
                                    {/* types */}
                                  </TableCell>

                                  <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-3"
                                    stickyHeader
                                  >
                                    {/* state */}
                                  </TableCell>

                                  <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-4"
                                    stickyHeader
                                    sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
                                  >
                                    {/* Product */}
                                  </TableCell >
                                  <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-5"
                                    stickyHeader
                                    sx={{ borderRight: "2px solid white", display: 'block', border: "none" }}
                                  >
                                    {/* sub name 5 */}
                                  </TableCell>
                                  {userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member"
                                    ?
                                    <TableCell
                                      className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-6 add-level-plus-icon-cell"
                                      stickyHeader
                                      sx={{ borderRight: "2px solid white", border: "none" }}
                                      data-type="header-plus-static-cells"
                                    >
                                      {/* ba total cc */}
                                    </TableCell>
                                    :
                                    <>

                                      {leftSideHeaders?.map((leftCell, leftIndex) => {
                                        // console.log("leftCell", leftCell)
                                        return (
                                          <>
                                            {flagShowAddIcons && slectedAddIcon === leftIndex ?
                                              (<TableCell
                                                className="leveltable_IconCell addLeft_Border sticky-table-head sticky-carrier-table-head-5"
                                                stickyHeader
                                                onClick={(e) => {
                                                  setFlagAddLevelEnable(true)
                                                  // const elementTemp = e.currentTarget;
                                                  // const offsetLeft = elementTemp.offsetLeft;
                                                  // // setLevelSelectedData(leftCell)
                                                  // const elemente = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                  // // console.log("element", elemente)
                                                  // const element = elemente.getBoundingClientRect()
                                                  // // console.log("element", element)
                                                  // setSliderCoordinates({
                                                  //   x: offsetLeft,
                                                  //   y: element.x
                                                  // })
                                                  document.getElementById('addLevelPopup').style.display = "block";
                                                  const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                  element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                  setSliderCoordinates({
                                                    x: element.offsetLeft + element.offsetWidth - 8,
                                                    y: element.x
                                                  })
                                                  setLevelSelectedData(leftCell)
                                                  setFlagCustomizationCarriarSectionDialog(true)
                                                  // setFlagDialogGloballyRender(true)
                                                  setFlagAfterLevelPopoverRender(true)
                                                  setHideTransperentCell(false)
                                                  setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })

                                                }}
                                                // data-id={leftCell?.carrier_level_id || leftCell?.carrier_level_id_custom}
                                                sx={{
                                                  borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                  background: `${leftCell?.carrier_level_color_hex_code} !important`,
                                                  cursor: editDetails ? "pointer" : "auto"
                                                }}
                                              >
                                                <IconButton className={(editDetails && slectedAddIcon == leftIndex) ? "buleIcon" : "leveltable_IconButton"}  >
                                                  <AddCircleIcon />
                                                </IconButton>
                                              </TableCell >)
                                              : (<></>)
                                            }
                                          </>
                                        )
                                      })}
                                    </>
                                  }

                                  {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
                                    carrierDetails?.carrier_level?.map((levelRow, i) => {
                                      let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                      const firstCondition = slectedAddIcon == carrierId;
                                      const secondCondition = isColHover == carrierId
                                      const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                      return (
                                        <>
                                          {/* ___________addcolumn Icon Buttons ____________*/}
                                          {/* {// console.log(isColHover === 'nullPlus' && i === 0, "isColHover", "i")} */}

                                          {flagShowAddIcons ?
                                            (<TableCell
                                              sx={{
                                                borderLeft:
                                                  (i == 0 && slectedAddIcon == null && (isColHover == null || isColHover !== "nullPlus")) ? "1.5px solid #c0c0c0"
                                                    : (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0"
                                                      : "none",
                                                textAlign: slectedAddIcon === 'nullPlus' ? "left !important" : "right !important"
                                              }}
                                              className={
                                                isBlueLeftBorder ? "blue-left-border zero-Padding "
                                                  : (slectedAddIcon === null) ? "addLeft_Border pointer-event leveltable_IconCell"
                                                    : (slectedAddIcon === 'nullPlus') ? "leveltable_IconCell" : "leveltable_IconCell"
                                              }
                                              stickyHeader
                                              data-parent-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                              onClick={(e) => {
                                              }}
                                            >
                                              <Grid container>
                                                <Grid item xs={6}>
                                                  {i == 0 && (
                                                    <Grid container position={"relative"}>
                                                      <IconButton
                                                        data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                        aria-describedby={columnId}
                                                        sx={{
                                                          display: slectedAddIcon != null && slectedAddIcon != "nullPlus" ? "none" : "",
                                                        }}
                                                        className={(editDetails && slectedAddIcon === "nullPlus") || (isColHover == "nullPlus") ? "first-blue-Addicon " : "first-leveltable_IconButton"}
                                                        onClick={(e) => {
                                                          if (tableContainerRef.current) {
                                                            tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                            tableContainerRef.current.style.overflow = 'hidden'
                                                          }
                                                          setSelectedAddIcon('nullPlus')
                                                          setAddColumn(levelRow.carrier_level_id)
                                                          setFlagAddLevelEnable(true)
                                                          setFlagAfterLevelPopoverRender(true)
                                                          document.getElementById('addLevelPopup').style.display = "block";
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                          element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft - 8,
                                                            y: element.x
                                                          })
                                                          setNewLevelData({ ...newLevelData, carrier_level_after: null })
                                                          // handleOpenColumnPopover(e, i)
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setColHover("nullPlus")
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);

                                                          if (element) {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft - 8,
                                                              y: element.x
                                                            })
                                                            let showPoover = document.getElementById('addLevelPopup');
                                                            showPoover.classList.add('addLevelPopup-popover-animation')
                                                          }
                                                        }}
                                                        onMouseLeave={() => setColHover(null)}
                                                      >
                                                        <AddCircleIcon
                                                          fontSize="10px"
                                                        />
                                                      </IconButton>
                                                    </Grid>
                                                  )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <Grid container display={"flex"} justifyContent={"end"} position={"relative"}>
                                                    <IconButton
                                                      disableRipple
                                                      aria-describedby={columnId}
                                                      data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                      onClick={(e) => {
                                                        if (tableContainerRef.current) {
                                                          tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                          tableContainerRef.current.style.overflow = 'hidden'
                                                        }
                                                        setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                        setAddColumn(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                        setFlagAddLevelEnable(true)
                                                        setFlagAfterLevelPopoverRender(true)
                                                        let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                        const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                        document.getElementById('addLevelPopup').style.display = "block";
                                                        const butnRect = element.getBoundingClientRect()
                                                        const totalWidth = butnRect.left + 325 - 50
                                                        const gridItem = document.getElementById("custom-grid")
                                                        const gridItemWidth = gridItem.clientWidth + 50
                                                        if (totalWidth >= gridItemWidth) {
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                            y: element.x
                                                          })
                                                        } else {
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft + element.offsetWidth - 8,
                                                            y: element.x
                                                          })
                                                        }
                                                        setNewLevelData({ ...newLevelData, carrier_level_after: dataIdAttr })
                                                      }}

                                                      onMouseEnter={(e) => {
                                                        setColHover(levelRow.carrier_level_id || levelRow?.carrier_level_id_custom);
                                                        let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                        const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                        const butnRect = element.getBoundingClientRect()
                                                        const totalWidth = butnRect.left + 325 - 50
                                                        const gridItemWidth = document.getElementById("custom-grid").clientWidth + 50
                                                        if (element) {
                                                          if (totalWidth >= gridItemWidth) {
                                                            // console.log("greter width in mouse enter")
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                              y: element.x
                                                            })
                                                          } else {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8,
                                                              y: element.x
                                                            })
                                                          }
                                                          let showPoover = document.getElementById('addLevelPopup');
                                                          showPoover.classList.add('addLevelPopup-popover-animation')
                                                        }
                                                      }}
                                                      onMouseLeave={() => setColHover(null)}
                                                      sx={{ display: slectedAddIcon !== null && slectedAddIcon !== carrierId ? "none" : "" }}
                                                      className={isBlueLeftBorder ? "blue-Addicon" : "leveltable_IconButton"}
                                                      disableElevation={true}
                                                    >
                                                      <AddCircleIcon
                                                        fontSize="10px"
                                                      />
                                                    </IconButton>

                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </TableCell >) : (<></>)
                                          }

                                        </>
                                      );

                                    })
                                    :
                                    rightSideHeaders?.map((rightCell, i) => {
                                      return (
                                        <>
                                          {flagShowAddIcons ?
                                            <TableCell
                                              align="left"
                                              stickyHeader
                                              className="leveltable_IconCell addLeft_Border"
                                              data-id={rightCell?.carrier_level_id || rightCell?.carrier_level_id_custom}
                                              onClick={(e) => {
                                                setFlagAddLevelEnable(true)
                                                // setFlagDialogGloballyRender(true)
                                                setHideTransperentCell(false)
                                                setFlagAfterLevelPopoverRender(true)
                                                // const elementTemp = e.currentTarget;
                                                // const offsetLeft = elementTemp.offsetLeft;
                                                // // setLevelSelectedData(leftCell)
                                                // const elemente = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                // // console.log("element", elemente)
                                                // const element = elemente.getBoundingClientRect()
                                                // // console.log("element", element)
                                                // setSliderCoordinates({
                                                //   x: offsetLeft,
                                                //   y: element.x
                                                // })
                                                document.getElementById('addLevelPopup').style.display = "block";
                                                const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                setSliderCoordinates({
                                                  x: element.offsetLeft + element.offsetWidth - 8,
                                                  y: element.x
                                                })
                                                setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })
                                              }}
                                            >
                                              <IconButton className="leveltable_IconButton">
                                                <AddCircleIcon />
                                              </IconButton>
                                              {/* {levelSelectedData?.value == rightCell?.carrier_level_id && (
                                                <Slide in={levelSelectedData?.value == rightCell?.carrier_level_id}>
                                                  here
                                                </Slide>
                                              )} */}
                                            </TableCell> : <></>
                                          }
                                        </>
                                      );
                                    })
                                  }
                                  {700 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                    <TableCell
                                      id="blank-tablecell"
                                      data-type="header-plus-static-cells"
                                      className={`sticky-table-head new-carrier-blank-row ${editDetails ? "addLeft_Border" : ""}`}
                                      sx={{
                                        width: `${700 - carrierDetails?.carrier_level?.length * 60}px`,
                                        backgroundColor: "transparent",
                                        color: "#ffffff"
                                      }}
                                      colSpan={5}
                                    >
                                    </TableCell>
                                  }
                                </TableRow>
                              }
                              <TableRow className={flagShowAddIcons && !deleteRowPopupFlag ? "vc_header_row-top" : "vc_header_row"}>
                                {
                                  (hideTransparentCell && !flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                  <TableCell
                                    sx={{
                                      // backgroundColor: "transparent !important",
                                      border: "none",
                                      borderBottom: "none",
                                    }}
                                    // className="table-right-table-cells"
                                    className={`sticky-table-head  table-right-table-cells hide-vc-tbl-cell-by-bg sticky-carrier-table-head-1 `
                                    }
                                  >
                                    {/* hide cell header */}
                                  </TableCell>
                                }
                                < TableCell
                                  className={`sticky-table-head ${hideTransparentCell ? "sticky-carrier-table-head-2" : "cell-with-left-prop"} ${editDetails ? "addLeft_Border" : ""}`
                                  }
                                  stickyHeader
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}
                                >
                                  Types
                                </TableCell>

                                <TableCell
                                  className={`sticky-carrier-table-head-3 ${editDetails ? "addLeft_Border" : ""} sticky-table-head`
                                  }
                                  stickyHeader
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}
                                >
                                  State
                                </TableCell>

                                <TableCell
                                  className={`sticky-table-head sticky-carrier-table-head-4 ${editDetails ? "addLeft_Border" : ""}`
                                  }
                                  stickyHeader
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}

                                >
                                  Product
                                </TableCell>

                                <TableCell
                                  className={`sticky-table-head sticky-carrier-table-head-5 ${editDetails ? "addLeft_Border" : ""}`
                                  }
                                  stickyHeader
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || userDetail[0]?.user_role_name == "Agent") ? "2px solid #EFEFF0" : "",
                                    color: "#ffffff"
                                  }}
                                >
                                  Sub Name
                                </TableCell>

                                {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
                                  ?
                                  <TableCell
                                    className={`sticky-table-head sticky-carrier-table-head-6 ${editDetails ? "addLeft_Border" : ""}`}
                                    stickyHeader
                                    sx={{
                                      borderRight: carrierDetails?.carrier_level?.length == 0 ? "2px solid white" : (!editDetails ? "3px solid #EFEFF0" : editDetails ? '1px solid #000000' : ''),
                                      color: "#ffffff",
                                      // HERE UPDATE BG COLOR FOR THIS COLUMN 
                                      // backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099" : "#1b2430",
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#F1C232" : deleteRowPopupFlag ? "#F1C232" : "#F1C232",
                                    }}
                                  >
                                    BA Total Comp
                                  </TableCell>
                                  :
                                  <>
                                    {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                                      <>
                                        {leftSideHeaders?.map((leftCell, leftIndex) => {
                                          return (
                                            <TableCell
                                              className={`header-color sticky-table-head sticky-carrier-table-head-5 ${editDetails ? "addLeft_Border" : ""}`}
                                              stickyHeader
                                              onDoubleClick={() => {
                                                if (editDetails) {
                                                  setLevelSelectedData(leftCell)
                                                  setFlagCustomizationCarriarSectionDialog(true)
                                                  // setFlagDialogGloballyRender(true)
                                                  setSelectedAddIcon(leftCell.carrier_level_id || leftCell.carrier_level_id_custom)
                                                  setFlagShowAddIcons(false)
                                                  setHideTransperentCell(false)
                                                }
                                              }}
                                              // data-id={leftCell?.carrier_level_id}
                                              sx={{
                                                borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : leftIndex === 0 ? "2px solid white" : "none",
                                                background: `${(slectedAddIcon === leftCell.carrier_level_id || slectedAddIcon === leftCell.carrier_level_id_custom) ? "#1b2430" : leftCell?.carrier_level_color_hex_code} !important`,
                                                cursor: editDetails ? "pointer" : "auto"
                                              }}
                                            >
                                              {/* this tooltip we use later */}
                                              {/* <div className="vc-corner-tringle-container">
                                            <CustomTooltip title="The extra props for the slot components. You can override the existing props or add new ones.This prop is an alias for the componentsProps prop, which will be deprecated in the future." placement="top" arrow>
                                              <div className="right-angle-triangle" ></div>
                                            </CustomTooltip>
                                          </div> */}
                                              <div> {leftCell?.carrier_level_name} </div>
                                            </TableCell>
                                          )
                                        })}
                                      </>
                                    }

                                  </>
                                }

                                {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
                                  carrierDetails?.carrier_level?.map((levelRow, i) => {

                                    let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                    const firstCondition = slectedAddIcon == carrierId;
                                    const secondCondition = isColHover == carrierId
                                    const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                    return (
                                      <TableCell
                                        className={`header-color cell-width  ${isBlueLeftBorder ? "blue-left-border" : editDetails ? "addLeft_Border add_padding" : ""} `}
                                        align="left"
                                        stickyHeader
                                        sx={{
                                          padding: "0px",
                                          zIndex: slectedAddIcon == null ? "2" :
                                            ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && !flagCustomizationCarriarSectionDialog) ? "1300" :
                                              ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && flagCustomizationCarriarSectionDialog) ? "2000" : "2",
                                          cursor: editDetails ? "pointer" : "auto",
                                          // borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") && !flagCustomizationCarriarSectionDialog ? "1px solid #1565c0" : "",
                                          background: `${levelRow?.carrier_level_color_hex_code} !important`,
                                          // border: (slectedAddIcon === levelRow.carrier_level_id || slectedAddIcon === levelRow?.carrier_level_id_custom)
                                          //   ? `1px solid ${levelRow?.carrier_level_color_hex_code} !important`
                                          //   : "", // border-left=(i === 0 && !editDetails) ? "3px solid white" :
                                          borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : (i === 0 && editDetails) && "1.5px solid #EFEFF0",
                                        }}
                                        // data-id={levelRow?.carrier_level_id}
                                        onDoubleClick={() => {
                                          if (editDetails) {
                                            setLevelSelectedData(levelRow)
                                            setFlagCustomizationCarriarSectionDialog(true)
                                            // setFlagDialogGloballyRender(true)
                                            setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                            setFlagShowAddIcons(false)
                                            setHideTransperentCell(false)
                                          }
                                        }}
                                      >
                                        {/* this tooltip we use later */}
                                        {/* <div className="vc-corner-tringle-container">
                                          <CustomTooltip title="The extra props for the slot components. You can override the existing props or add new ones.This prop is an alias for the componentsProps prop, which will be deprecated in the future." placement="top" arrow>
                                            <div className="right-angle-triangle" ></div>
                                          </CustomTooltip>
                                        </div> */}
                                        <div className="vc-tablecell-header-div">{levelRow?.carrier_level_name}</div>
                                      </TableCell>
                                    );
                                  })
                                  :
                                  rightSideHeaders?.map((rightCell, i) => {
                                    return (
                                      <TableCell
                                        className={`header-color ${editDetails ? "addLeft_Border" : ""}`}
                                        align="left"
                                        stickyHeader
                                        onDoubleClick={() => {
                                          if (editDetails) {
                                            setLevelSelectedData(rightCell)
                                            setFlagCustomizationCarriarSectionDialog(true)
                                            // setFlagDialogGloballyRender(true)
                                            setSelectedAddIcon(rightCell.carrier_level_id || rightCell.carrier_level_id_custom)
                                            setFlagShowAddIcons(false)
                                            setHideTransperentCell(false)
                                          }
                                        }}
                                        // data-id={rightCell?.carrier_level_id}
                                        sx={{
                                          background: `${(slectedAddIcon === rightCell.carrier_level_id || slectedAddIcon === rightCell.carrier_level_id_custom) ? "#1b2430" : rightCell?.carrier_level_color_hex_code} !important`,
                                          cursor: editDetails ? "pointer" : "auto",
                                          borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1px solid #1565c0" : ""
                                        }}
                                      >
                                        {/* this tooltip we use later */}
                                        {/* <div className="vc-corner-tringle-container">
                                          <CustomTooltip title="The extra props for the slot components. You can override the existing props or add new ones.This prop is an alias for the componentsProps prop, which will be deprecated in the future." placement="top" arrow>
                                            <div className="right-angle-triangle" ></div>
                                          </CustomTooltip>
                                        </div> */}
                                        <div style={{ width: "100%" }}>{rightCell?.carrier_level_name}</div>
                                      </TableCell>
                                    );
                                  })
                                }


                                {700 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                  <TableCell
                                    id="blank-tablecell"
                                    className={`sticky-table-head ${editDetails ? "addLeft_Border" : ""}`}
                                    sx={{
                                      width: `${700 - carrierDetails?.carrier_level?.length * 60}px`,
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                      color: "#ffffff"
                                    }}
                                    colSpan={5}
                                  >
                                  </TableCell>
                                }
                              </TableRow>
                            </TableHead>

                            <TableBody >
                              {
                                (hideTransparentCell && !deleteRowPopupFlag && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                <TableRow>
                                  <>
                                    <TableCell
                                      className={`vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                      sx={{
                                        position: "sticky",
                                        zIndex: "110 !important",
                                        left: "0",
                                        background: flagCarrierCustomizationDeleteDialog || flagCustomizationCarriarSectionDialog || deleteRowPopupFlag ? 'transparent' : '#efeff0',
                                      }} >
                                      <Grid container >
                                        <Grid item xs={7}>
                                          <Grid container justifyContent={'end'} >
                                            <div
                                              className={`${(selectedLine == 0) ? "selected-dot-icon" : "default-dot-icon"}`}
                                              onClick={(e) => {
                                                setSelectedRowGroup({
                                                  selectId: carrierDetails?.carrier_level_data[0]?.carrier_group_id || carrierDetails?.carrier_level_data[0]?.carrier_group_id_custom,
                                                  selectedId: null
                                                })
                                                setSelectedLinePopover({
                                                  groups: carrierDetails?.carrier_level_data[0],
                                                  index: 0
                                                })
                                                setSelectedLine(0)
                                                setAnchorEl(e.currentTarget)
                                                // // console.log(groups, "groups")
                                              }}
                                              onMouseEnter={() => {
                                                setSelectedLine(0)
                                              }}
                                              // onMouseLeave={() => {
                                              //   setSelectedLine(null)
                                              // }}

                                              onMouseLeave={() => {
                                                if (anchorEl == null) {
                                                  setSelectedLine(null)
                                                }
                                                else {
                                                  // console.log("Not null value");
                                                }
                                              }}
                                            >
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={5} margin={'auto'}>
                                          {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                        </Grid>
                                      </Grid>
                                    </TableCell>

                                    <TableCell
                                      colSpan={numsOfCarrierLevelCols + 6}
                                      className={`shubh-2 vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                    >
                                      {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                    </TableCell>
                                  </>
                                </TableRow>
                              }

                              {carrierDetails?.carrier_level_data?.map((groups, j) => {
                                return (
                                  groups?.carrier_group_data?.map((groupData, i) => {
                                    let flagSaprator = groups?.carrier_group_data.length == i + 1 ? true : false;
                                    let leftSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == true)
                                    let rightSideData = groupData?.carrier_commission_data?.filter((obj) => obj.carrier_ba_level_flag == false)

                                    const selectedGroupId = groups?.carrier_group_id || groups.carrier_group_id_custom
                                    const sectedRowId = groups?.carrier_group_data[i].carrier_group_data_id || groups?.carrier_group_data[i].carrier_group_data_id_custom
                                    const carrierGroupDataLen = groups?.carrier_group_data?.length
                                    let present = groups?.carrier_group_id || groups?.carrier_group_id_custom
                                    return (
                                      <>
                                        <TableRow
                                          className={editDetails ? 'vc-editable-tabel-row' : "vc-table-row"}
                                          data-row-id={groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom}
                                          data-section-id={present}
                                          key={groups?.carrier_group_id || groups?.carrier_group_id_custom}
                                          // sx={{ '&::after': { width: `${document?.getElementById('custom-table')?.clientWidth}px` } }}
                                          // className={
                                          //   selectedGroupId === selectedRowGroupId.selectedId ?
                                          //     carrierGroupDataLen <= 1 ? "border-all border-container"
                                          //       : `${i == 0 ? "border-top"
                                          //         : flagSaprator ? "border-bottom"
                                          //           : "bordered-left-right"} border-container`
                                          //     : sectedRowId === isSelectedRow ? "border-all border-container" : ""
                                          // }
                                          onMouseEnter={() => {
                                            if (editDetails) {
                                              if (groupData.carrier_group_data_id) {
                                                setAddRow(groupData.carrier_group_data_id)
                                              }
                                              else if (groupData?.carrier_group_data_id_custom) {
                                                setAddRow(groupData.carrier_group_data_id_custom)
                                              }
                                              else {
                                                setAddRow(null)
                                              }
                                            }
                                          }}
                                          onMouseLeave={editDetails ? () => {
                                            anchorElForRow == null ? setAddRow(null) : console.log("..")
                                          } : () => { }}>
                                          {
                                            (hideTransparentCell && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                            <TableCell
                                              // className="vc-table-transparent-cell"
                                              className={`vc-table-transparent-cell 
                                                sticky-table-body 
                                                sticky-carrier-table-body-1 
                                                add_padding hide-vc-tbl-cell-by-bg `}
                                              sx={{
                                                border: "none",
                                                borderBottom: "none"
                                              }}
                                            >


                                              {
                                                (addRow === groupData.carrier_group_data_id || addRow === groupData.carrier_group_data_id_custom) &&
                                                < Box className="vc-addremove-btn-container" aria-describedby={selectedGroupId} >
                                                  <IconButton
                                                    className="vc-addremove-buttons"
                                                    onClick={(e) => {
                                                      handleAddRowPopOverClick(e, groupData, j, i)
                                                    }}
                                                  >
                                                    <AddCircleOutlineIcon className="vc-addremove-buttons" />
                                                  </IconButton>

                                                  <IconButton
                                                    className="vc-addremove-buttons"
                                                    disabled={PathName === "/carriers/add_carrier" ? (carrierGroupDataLen <= 1 || carrierLevelLength >= 2) : false}
                                                    onClick={() => {
                                                      // deleteRowForSection(groupData, j, i)
                                                      setSelectedRowIndex({
                                                        groupData: groupData,
                                                        levelIdx: j,
                                                        groupIdx: i
                                                      })
                                                      setSectionOrRow("row")
                                                      setSelectedRow(sectedRowId)
                                                      /* setHideTransperentCell(false) */
                                                      setFlagDialogGloballyRender(true)
                                                      setDeleteRowPopupFlag(true)
                                                      setDeleteRow(groupData.carrier_group_data_id_custom)
                                                      setTimeout(() => {
                                                        let rowToHighLight = document.querySelector(`[data-row-id="${groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom}"]`);
                                                        let rect = rowToHighLight.getBoundingClientRect();
                                                        let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                        let tableContainer = document.getElementById('custom-table').getBoundingClientRect();
                                                        let top = rect.y;
                                                        let left = tableContainer.left + 50;
                                                        let width = tableContainer.width - 50;
                                                        let height = 40;
                                                        hiddenHighLighter.style.top = `${top}px`;
                                                        hiddenHighLighter.style.width = `${width}px`;
                                                        hiddenHighLighter.style.left = `${left}px`;
                                                        hiddenHighLighter.style.height = `${height}px`;
                                                        hiddenHighLighter.style.border = `1px solid #376fd0`;
                                                      }, 700)
                                                      document.body.style.overflow = 'hidden';
                                                      document.getElementById("carrier_view_container").style.overflow = "hidden"

                                                    }}>
                                                    <RemoveCircleOutlineIcon className="vc-addremove-buttons" />
                                                  </IconButton>
                                                </Box>
                                              }
                                            </TableCell>
                                          }

                                          <TableCell
                                            key={'carrier_types-' + (groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom)}
                                            /* contentEditable={editDetails ? true : false} */
                                            /* suppressContentEditableWarning */
                                            sx={{
                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                            }}
                                            className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${hideTransparentCell ? "sticky-carrier-table-body-2" : "cell-with-left-prop"} ${editDetails && "addLeft_Border"} add_padding `}
                                            onKeyUp={(e) => {
                                              handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_types')
                                            }}
                                          >
                                            {/* {groupData?.carrier_types}
                                             */}
                                            <Tooltip
                                              arrow
                                              title={groupData?.carrier_types}
                                              disableHoverListener={editDetails || groupData?.carrier_types?.length < 16}
                                              disableFocusListener={editDetails}
                                              PopperProps={{ className: 'vc-tabel-tooltip', }}
                                              placement="bottom"
                                            >
                                              <input
                                                className="vc-table-right-table-cells-input"
                                                disabled={!editDetails}
                                                onChange={(e) => {
                                                  handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_types')
                                                }} value={groupData?.carrier_types} />
                                            </Tooltip>
                                          </TableCell>

                                          <TableCell
                                            key={'carrier_state-' + (groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom)}
                                            // contentEditable={editDetails ? true : false}
                                            /* suppressContentEditableWarning */
                                            sx={{
                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                            }}
                                            className={`sticky-table-body sticky-table-color sticky-carrier-table-body-3 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                          // onKeyUp={(e) => {
                                          //   handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_state')
                                          // }}
                                          >
                                            <Tooltip
                                              arrow
                                              title={groupData?.carrier_state}
                                              disableHoverListener={editDetails || groupData?.carrier_state?.length < 20}
                                              disableFocusListener={editDetails}
                                              PopperProps={{ className: 'vc-tabel-tooltip', }}
                                              placement="bottom"
                                            >
                                              <input
                                                className="vc-table-right-table-cells-input"
                                                disabled={!editDetails}
                                                onChange={(e) => {
                                                  handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_state')
                                                }} value={groupData?.carrier_state} />
                                            </Tooltip>
                                          </TableCell>

                                          <TableCell
                                            key={'carrier_product_name-' + (groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom)}

                                            // contentEditable={editDetails ? true : false}
                                            /* suppressContentEditableWarning */
                                            sx={{
                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                            }}
                                            className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                          // onKeyUp={(e) => {
                                          //   handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_product_name')
                                          // }}
                                          >
                                            <Tooltip
                                              arrow
                                              title={groupData?.carrier_product_name}
                                              disableHoverListener={editDetails || groupData?.carrier_product_name?.length < 20}
                                              disableFocusListener={editDetails}
                                              PopperProps={{ className: 'vc-tabel-tooltip', }}
                                              placement="bottom"
                                            >
                                              <input
                                                className="vc-table-right-table-cells-input vc-table-content-input-wrapper"
                                                disabled={!editDetails}
                                                onChange={(e) => {
                                                  handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_product_name')
                                                }}
                                                value={groupData?.carrier_product_name}
                                              />
                                            </Tooltip>
                                          </TableCell>

                                          <TableCell

                                            key={'carrier_sub_name-' + (groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom)}
                                            // contentEditable={editDetails ? true : false}
                                            /* suppressContentEditableWarning */
                                            sx={{
                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                              borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || userDetail[0]?.user_role_name == "Agent") ? "2px solid #EFEFF0" : ""
                                              // borderRight: leftSideData?.length === 0 ? "2px solid #EFEFF0" : "",
                                            }}
                                            className={`sticky-table-body sticky-table-color sticky-carrier-table-body-5 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                          // onKeyUp={(e) => {
                                          //   handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_sub_name')
                                          // }}

                                          >
                                            <Tooltip
                                              arrow
                                              title={groupData?.carrier_sub_name}
                                              disableHoverListener={editDetails || groupData?.carrier_sub_name?.length < 16}
                                              disableFocusListener={editDetails}
                                              PopperProps={{ className: 'vc-tabel-tooltip', }}
                                              placement="bottom"
                                            >
                                              <input
                                                className="vc-table-right-table-cells-input"
                                                disabled={!editDetails}
                                                onChange={(e) => {
                                                  handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_sub_name')
                                                }} value={groupData?.carrier_sub_name}
                                              />
                                            </Tooltip>
                                          </TableCell>

                                          {
                                            (userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
                                              ?
                                              <TableCell
                                                /* contentEditable={editDetails ? true : false}
                                                suppressContentEditableWarning */
                                                sx={{
                                                  borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                  borderRight: (!editDetails ? "2px solid #EFEFF0" : "1px solid #000000")
                                                }}
                                                className={`sticky-table-body sticky-table-color sticky-carrier-table-body-6 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                              >

                                                <input
                                                  className="vc-table-right-table-cells-input"
                                                  disabled={!editDetails}
                                                  onChange={(e) => {
                                                    handleCarrierLevelData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, 'carrier_ba_total_comp')
                                                  }} value={groupData?.carrier_ba_total_comp} />
                                              </TableCell>
                                              :
                                              <>
                                                {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                                                  <>
                                                    {
                                                      leftSideData?.map((leftDataCell, i) => {

                                                        return (
                                                          <TableCell
                                                            /* contentEditable={editDetails ? true : false}
                                                            suppressContentEditableWarning */
                                                            sx={{
                                                              borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                              borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                              borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : i === 0 ? "1.5px solid #EFEFF0" : "none"
                                                            }}
                                                            className={`sticky-table-body sticky-table-color  table-left-padding-tableCell  ${((editDetails && slectedAddIcon == i) || isColHover === i) ? "blue-left-border" : editDetails ? " addLeft_Border add_padding" : ""}`}
                                                          // onKeyUp={(e) => {
                                                          //   handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, leftDataCell?.carrier_commission_value_id || leftDataCell?.carrier_commission_value_id_custom)
                                                          // }}

                                                          >
                                                            {/* {leftDataCell?.commission_value} */}
                                                            <input
                                                              className="vc-table-right-table-cells-input"
                                                              disabled={!editDetails}
                                                              onChange={(e) => {
                                                                handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, leftDataCell?.carrier_commission_value_id || leftDataCell?.carrier_commission_value_id_custom)
                                                              }} value={leftDataCell?.commission_value} />
                                                          </TableCell>
                                                        )
                                                      })
                                                    }
                                                  </>
                                                }
                                              </>
                                          }

                                          {
                                            (userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member")
                                              ?
                                              groupData?.carrier_commission_data?.map((commissionData, ind) => {
                                                let carrierId = commissionData.carrier_level_id || commissionData.carrier_level_id_custom
                                                const firstCondition = slectedAddIcon == carrierId;
                                                const secondCondition = isColHover == carrierId

                                                const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog
                                                const isAddLeftBorder = editDetails;

                                                // // console.log("print", isBlueLeftBorder, isAddLeftBorder)

                                                return (
                                                  <TableCell
                                                    suppressContentEditableWarning
                                                    className={`vc-table-right-table-cells table-left-padding-tableCell ${isBlueLeftBorder ? "blue-left-border" : isAddLeftBorder ? "addLeft_Border add_padding" : ""
                                                      }`}
                                                    // className={`vc-table-right-table-cells table-left-padding-tableCell 
                                                    // ${((editDetails && (slectedAddIcon == commissionData.carrier_level_id || slectedAddIcon == commissionData.carrier_level_id_custom))
                                                    //     || (isColHover == commissionData.carrier_level_id || isColHover == commissionData.carrier_level_id_custom))
                                                    //     && !flagCustomizationCarriarSectionDialog ? "blue-left-border" : editDetails && flagCustomizationCarriarSectionDialog ? "addLeft_Border add_padding" : ""}`}
                                                    sx={{
                                                      borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                      zIndex: slectedAddIcon == null ? "2" : (slectedAddIcon == commissionData.carrier_level_id || slectedAddIcon == commissionData?.carrier_level_id_custom) ? "2000" : "2",
                                                      borderLeft: (ind === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                    }}
                                                  // onKeyUp={(e) => {
                                                  //   handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, commissionData?.carrier_commission_value_id || commissionData?.carrier_commission_value_id_custom)
                                                  // }}
                                                  >
                                                    {/* {commissionData?.commission_value} */}

                                                    <input className="vc-table-right-table-cells-input" disabled={!editDetails} onChange={(e) => {
                                                      handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, commissionData?.carrier_commission_value_id || commissionData?.carrier_commission_value_id_custom)
                                                    }} value={commissionData?.commission_value} />
                                                  </TableCell>
                                                )
                                              })
                                              :
                                              rightSideData?.map((rightDataCell, i) => {
                                                let carrierId = rightDataCell.carrier_level_id || rightDataCell.carrier_level_id_custom
                                                const firstCondition = slectedAddIcon == carrierId;
                                                const secondCondition = isColHover == carrierId
                                                const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog
                                                return (
                                                  <TableCell
                                                    /* contentEditable={editDetails ? true : false}
                                                    suppressContentEditableWarning */
                                                    className={`vc-table-right-table-cells table-left-padding-tableCell ${(editDetails && slectedAddIcon == null) ? "addLeft_Border add_padding"
                                                      : isBlueLeftBorder ? "blue-left-border" : ""}`}
                                                    sx={{
                                                      borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                      borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : i === 0 ? "1.5px solid #EFEFF0" : "none"
                                                    }}
                                                  // onKeyUp={(e) => {
                                                  //   handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, rightDataCell?.carrier_commission_value_id || rightDataCell?.carrier_commission_value_id_custom)
                                                  // }}
                                                  >
                                                    {/* {rightDataCell?.commission_value} */}
                                                    <input
                                                      className="vc-table-right-table-cells-input"
                                                      disabled={!editDetails}
                                                      onChange={(e) => {
                                                        handleCarrierLevelCommissionData(e, groups?.carrier_group_id || groups?.carrier_group_id_custom, groupData?.carrier_group_data_id || groupData?.carrier_group_data_id_custom, rightDataCell?.carrier_commission_value_id || rightDataCell?.carrier_commission_value_id_custom)
                                                      }} value={rightDataCell?.commission_value} />
                                                  </TableCell>
                                                )
                                              })
                                          }


                                          {
                                            // HERE ADD CONDITION FOR ADDING AND DELETING TABLE CELLS SO PUT THE OR CONDITION 
                                            ((carrierDetails?.carrier_level?.length > 0 || carrierDetails?.carrier_level?.length == 0) && (700 - carrierDetails?.carrier_level?.length * 60 >= 60)) &&
                                            (<TableCell
                                              className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}

                                              /* contentEditable={editDetails ? true : false}
                                              suppressContentEditableWarning */
                                              colSpan={5}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                width: `${700 - carrierDetails?.carrier_level?.length * 60}px `
                                              }}
                                            >
                                            </TableCell>)
                                          }

                                        </TableRow >
                                        {hideTransparentCell &&
                                          <>
                                            {i == groups?.carrier_group_data.length - 1 && (
                                              <TableRow>
                                                <TableCell
                                                  // className=""
                                                  className={`vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                  sx={{
                                                    padding: '0px',
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "5px",
                                                    background: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog || deleteRowPopupFlag ? "transparent" : "#efeff0",
                                                    position: "sticky",
                                                    zIndex: "110 !important",
                                                    left: "0"
                                                    // background: selectedLine == groups?.carrier_group_id ? "#1565C0" : ""
                                                  }}

                                                >
                                                  {
                                                    (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                                    <div className={"vw-img-div vw-img-click-div"}
                                                      onClick={(e) => {
                                                        if (carrierDetails?.carrier_level_data?.length == 1) {
                                                          setSelectedRowGroup({
                                                            selectId: carrierDetails?.carrier_level_data[0].carrier_group_id || carrierDetails?.carrier_level_data[0].carrier_group_id_custom,
                                                            selectedId: null
                                                          })
                                                          setSelectedLinePopover({
                                                            groups: groups,
                                                            index: j + 1
                                                          })
                                                          setAnchorEl(e.currentTarget)
                                                        }
                                                        else {
                                                          let idTempArr = j == carrierDetails?.carrier_level_data?.length - 1 ? carrierDetails?.carrier_level_data[j] : carrierDetails?.carrier_level_data[j + 1];
                                                          handleClick(e, idTempArr.carrier_group_id || idTempArr.carrier_group_id_custom)
                                                          setSelectedLinePopover({
                                                            groups: groups,
                                                            index: j + 1
                                                          })
                                                        }
                                                        // setSelectedRowGroup({
                                                        //   selectId: groups.carrier_group_id,
                                                        //   selectedId: null
                                                        // })
                                                        // setSelectedLine(groups?.carrier_group_id)
                                                        setSelectedLine(groups?.carrier_group_id || groups.carrier_group_id_custom)
                                                        setFlagAddRow(true)

                                                        setSectionOrRow("section")


                                                      }}
                                                    //HERE COMMENTED CODE DUE TO FLUCTUATIONS OF DIV 
                                                    // aria-describedby={id}
                                                    // onMouseEnter={() => {
                                                    //   setSelectedLine(groups?.carrier_group_id || groups.carrier_group_id_custom)
                                                    // }}
                                                    // onMouseLeave={() => {
                                                    //   if (anchorEl == null) {
                                                    //     setSelectedLine(null)
                                                    //   }
                                                    //   else {
                                                    //     // console.log("Not null value");
                                                    //   }
                                                    // }}
                                                    >
                                                      <div
                                                        style={{ background: (selectedLine !== null && (selectedLine === groups?.carrier_group_id || selectedLine === groups?.carrier_group_id_custom)) ? "#1565C0" : "#1B243080" }}
                                                        className={`${(selectedLine == groups?.carrier_group_id || selectedLine == groups?.carrier_group_id_custom) ? "selected-dot-icon" : "default-dot-icon"}`}
                                                        onClick={() => {
                                                          let idTempArr = j == carrierDetails?.carrier_level_data?.length - 1 ? carrierDetails?.carrier_level_data[j - 2] : carrierDetails?.carrier_level_data[j + 1];
                                                          // setSelectedRowGroup({
                                                          //   selectId: groups.carrier_group_id || groups.carrier_group_id_custom,
                                                          //   selectedId: null
                                                          // })
                                                          setSelectedRowGroup({
                                                            selectId: idTempArr?.carrier_group_id || idTempArr?.carrier_group_id_custom,
                                                            selectedId: null
                                                          })
                                                          // setSelectedLine(groups?.carrier_group_id)
                                                          setSelectedLine(groups?.carrier_group_id || groups.carrier_group_id_custom)
                                                          setFlagAddRow(true)
                                                          setSelectedLinePopover({
                                                            groups: groups,
                                                            index: j + 1
                                                          })
                                                          setSectionOrRow("section")
                                                        }}
                                                        onMouseEnter={() => {
                                                          setSelectedLine(groups?.carrier_group_id || groups.carrier_group_id_custom)
                                                        }}
                                                        onMouseLeave={() => {
                                                          if (anchorEl == null) {
                                                            setSelectedLine(null)
                                                          }
                                                          else {
                                                            // console.log("Not null value");
                                                          }
                                                        }}
                                                      >
                                                        {/* Dot Icon using css */}
                                                      </div>
                                                    </div>
                                                  }
                                                  {

                                                    (selectedLine == present) &&
                                                    <Divider sx={{ width: "42%", background: (selectedLine !== null && (selectedLine === groups?.carrier_group_id || selectedLine === groups?.carrier_group_id_custom)) ? "#1565C0" : "transparent" }} className="vc-rowgroup-divider" />
                                                  }


                                                </TableCell>

                                                <TableCell
                                                  className={`shubh-1 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                  colSpan={leftSideData.length + rightSideData.length}
                                                  sx={{
                                                    padding: '0px',
                                                    height: "1px",
                                                  }}
                                                >
                                                  {(
                                                    selectedLine == present) &&
                                                    <Divider
                                                      fullWidth
                                                      className="vc-rowgroup-divider"
                                                      sx={{
                                                        background: (selectedLine !== null && (selectedLine === groups?.carrier_group_id || selectedLine === groups?.carrier_group_id_custom)) ? "#1565C0" : "transparent"
                                                      }}
                                                    />
                                                  }
                                                </TableCell>
                                                {PathName == '/carriers/view_carrier' ? (
                                                  <>
                                                    <TableCell
                                                      className={`shubh-2 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}

                                                      colSpan={leftSideData.length + rightSideData.length < 10 ? 10 : leftSideData.length + rightSideData.length}

                                                      sx={{
                                                        padding: '0px',
                                                        height: "1px",
                                                        // background: (selectedLine == groups?.carrier_group_id) ? "#1565C0" : ""
                                                      }}
                                                    >
                                                      {(
                                                        selectedLine == present) &&
                                                        <Divider
                                                          fullWidth
                                                          className="vc-rowgroup-divider"
                                                          sx={{
                                                            background: (selectedLine !== null && (selectedLine === groups?.carrier_group_id || selectedLine === groups?.carrier_group_id_custom)) ? "#1565C0" : "transparent"
                                                          }}
                                                        />
                                                      }
                                                    </TableCell>
                                                  </>
                                                ) : (
                                                  <>
                                                    <TableCell
                                                      className={`shubh-3 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                      colSpan={20}
                                                      sx={{
                                                        padding: '0px',
                                                        height: "1px",
                                                        // background: (selectedLine == groups?.carrier_group_id) ? "#1565C0" : ""
                                                      }}
                                                    >
                                                      {(
                                                        selectedLine == present) &&
                                                        <Divider
                                                          fullWidth
                                                          className="vc-rowgroup-divider"
                                                          sx={{
                                                            background: (selectedLine !== null && (selectedLine === groups?.carrier_group_id || selectedLine === groups?.carrier_group_id_custom)) ? "#1565C0" : "transparent"
                                                          }}
                                                        />
                                                      }
                                                    </TableCell>
                                                  </>
                                                )
                                                }
                                              </TableRow>
                                            )}
                                          </>
                                        }

                                      </>
                                    )
                                  }))
                              })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </>
                ) : (<></>)}

              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div >
  );
};

export default ViewCarrier;

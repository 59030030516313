import { Dialog, Grid, Button, Typography } from "@mui/material"
import deleteAlert from "../../../assets/Delete-alert.png"
import "../../css/ViewCarriarStyles/DeletePopupRow.css"

export default function DeleteRowPopup(props) {
    // console.log("i am working from DeleteRowPopup")
    return (

        <Grid container className="DRD-Dialog-Container" >
            <Grid item xs={12} className="DRD-Dialog-Container-item-1">
                <img src={deleteAlert} alt="del img" className="DRD-img" />
            </Grid>
            <Grid item xs={12}>
                <Typography className="DRD-Typography">
                    Are you sure you want to delete this {props.sectionOrRow}?
                </Typography>
            </Grid>
            <Grid item xs={12} className="DRD-Dialog-Container-item-3">
                <Grid container className="DRD-Dialog-Container-item-3-container" >
                    <Button
                        variant="outlined"
                        className="DRD-buttons"
                        onClick={() => {
                            props?.sectionOrRow == "row" ? props?.deleteRowForSection() : props?.deleteSection()
                        }}
                    >
                        Yes
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        className="DRD-buttons"
                        onClick={() => {
                            props?.setDeleteRowPopupFlag(false)
                            props?.extraFunction()
                        }}
                    >
                        No
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    )
}
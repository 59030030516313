import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Grid, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import BaLogo from "../../assets/balogo.png";
import { AddAgencyLogo } from "../../utils/api/AgencyDetailsAPI";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 380px
  };
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
    width: 500px
  };
`;

const BigAvatar = styled(Avatar)`
  width: 252px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;
const LogoComponent = () => (
  <img
    src={BaLogo}
    alt="Logo"
    width="216px"
    height="92px"
    text-align="center"
    justify-content="center"
  />
);

function SignIn() {
  const { AgencyLogoAPI } = useContext(AuthContext);
  const { agencyLogo } = useContext(AuthContext).state


  console.log("agencyLogo", agencyLogo);
  // useEffect(() => {
  //   AgencyLogoAPI()
  // }, [])

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper className="signup-card">
        <Helmet title="myAdvisorGrids | Sign In" />
        <Grid container sx={{ width: "100%" }} id="grid_container_auth_sign" justifyContent="center">
          <Grid item width={{ xs: "300px", sm: "350px" }}>
            {agencyLogo?.agency_name?.trim().toLowerCase() === "brokers alliance" ? <BigAvatar alt="" src={BaLogo} /> : agencyLogo?.agency_logo_url !== null ? <BigAvatar alt="Logo" src={agencyLogo?.agency_logo_url} /> : <></>}

            {/* <Typography component="h1" variant="h4" align="center" gutterBottom>
              Welcome to myAdvisorGrids
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Sign in to your account to continue
            </Typography> */}
            <Grid container justifyContent="center">
              <Grid item justifyContent="center" sx={{ width: "22vw" }} xs={11.2}>
                <SignInComponent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;

import { Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Protection from "../../assets/protection.png"
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { UserMeApi } from '../../utils/api/userDetailApi';
import { Context as authContext } from '../../contexts/reducerContexts/authContext';


const NdaAlert = () => {
    const { UpdateSnackbarMessage } = useContext(authContext)
    const [errorMessages, setErrorMessages] = useState([])
    const { state, FlagApiLoader, UpdateUserDetail, UpdateFlagAuthenticated, UpdateDelegateAccessFlag, UpdateDelegateAccessData } = useContext(AuthContext);
    const { userDetail } = { ...state }
    useEffect(() => {
        let temp = userDetail[0]?.error_message;
        let TempMessages = temp?.split(". ")
        setErrorMessages(TempMessages)
        FlagApiLoader(false)
    }, [])

    return (
        <Grid container justifyContent="center">
            <Grid item xs={8.5} sx={{ borderRadius: "5px", backgroundColor: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px" }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container justifyContent="start">
                            <Grid item xs={4} sx={{ backgroundColor: "#233044", padding: "15px", borderRadius: "5px 0px 0px 0px" }}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} sx={{ marginTop: "100px", marginBottom: "20px" }}>
                        <Grid container justifyContent="center" rowGap={"30px"}>
                            <Grid item xs={12} textAlign="center">
                                <img src={Protection} />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "center", color: "#233044", fontSize: "24px", fontWeight: 700 }}>
                                Portal access restricted
                            </Grid>

                            {errorMessages?.map((message, i) => {
                                return (
                                    <Grid item xs={12} sx={{ textAlign: "center", fontSize: "17px", fontWeight: 600 }}>
                                        {message}
                                    </Grid>
                                )
                            })}

                            {/* <Grid item xs={12} sx={{ textAlign: "center", fontSize: "17px", fontWeight: 600 }}>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={9} sx={{ marginTop: "10px", marginBottom: "80px", textAlign: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 16px", lg: "6px 16px" } }}
                            onClick={() => {
                                UserMeApi({}, (res) => {
                                    UpdateFlagAuthenticated(res?.data?.status)
                                    if (res?.data?.status) {
                                        UpdateUserDetail(res.data.data)
                                        UpdateDelegateAccessFlag(res?.data?.delegate_access_flag)
                                        if (res?.data?.delegate_access_flag) {
                                            UpdateDelegateAccessData(res?.data?.delegate_user_data)

                                        }
                                    } else {
                                        console.log("response false of UserMeApi api", res);
                                        if (res?.data?.error?.message) {
                                            UpdateSnackbarMessage({
                                                status: "error",
                                                message: res?.data?.error?.message
                                            });
                                        }
                                        else {
                                            UpdateSnackbarMessage({
                                                status: "error",
                                                message: "Something Went Wrong!"
                                            });
                                        }
                                    }
                                }, (err) => {
                                    console.log("error of UserMeApi api", err);
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: "Something Went Wrong!"
                                    });
                                })
                            }}
                        >
                            Refresh
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="end">
                            <Grid item xs={4} sx={{ backgroundColor: "#233044", padding: "15px", borderRadius: "0px 0px 5px 0px" }}></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NdaAlert;

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import { useContext, useEffect, useState } from "react";
import "../../pages/css/CarrierTable.css";
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Select,
} from "@mui/material";
import deleteAlert from '../../assets/Delete-alert.png'
import styled from "@emotion/styled";
import GlobalDialog from "../../components/global/GlobalDialog";
import CloseIcon from '@mui/icons-material/Close';
import { GetCarrierLevelList, GetTemplateData, GetTemplateVersionList } from "../../utils/api/TemplateApi";
import { Navigate, useSearchParams, useNavigate, Link } from "react-router-dom";
import { Add, MoreVert } from "@mui/icons-material";
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CarriersPopup from "./CarriersPopup";
import { GlobalCarrierTableRow } from "../globalCarrierTableRow/GlobalCarrierTableRow";

const TableHeadCell = styled(TableCell)({
    //   "& .sticky-table": {
    //      position: "fixed",
    //   },
});

const GlobalCarrierTable = (props) => {

    const { setDeletedCarrierId, deletedCarrierId, assignmentId, setAssignmentId, flagAddPage, editFlag, pageName, selectedTab, changeTemplateCall, tempVersionCurrentId, setTemplateVersionList, setTempVersionCurrentId } = props;
    const [deleteCarrierData, setDeleteCarrierData] = useState({});
    const { apiLoader, flagCarrierList } = useContext(authContext).state;
    const [flagDeletePopup, setFlagDeletePopup] = useState(false);
    const [DLLength, setDLLength] = useState(0);
    const [flagOpenCarrierPopup, setFlagOpenCarrierPopup] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();
    const { FlagApiLoader, setFlagCarrierList, UpdateSnackbarMessage } = useContext(authContext);
    const { setFlagType, setCarrierLevelData, setCarrierAnnuityLevelData, setTemplateName, setTemplateId, setGlobalLifeCarrierData, setGlobalAnnuityCarrierData, setCarrierLifeLevelData } = useContext(globalCarrierContext);
    const { carrierLevelData, flagType, templateName, templateId, globalLifeCarrierData, globalAnnuityCarrierData, carrierAnnuityLevelData, carrierLifeLevelData } = useContext(globalCarrierContext).state;
    const navigate = useNavigate();

    useEffect(() => {

        let tempCarrierData = selectedTab == "life" ? [...globalLifeCarrierData] : selectedTab == "annuity" ? [...globalAnnuityCarrierData] : []
        let maximumDlLength = 0

        for (var i in tempCarrierData) {
            if (maximumDlLength < tempCarrierData[i]['carrier_level_data']?.length) {
                maximumDlLength = tempCarrierData[i]['carrier_level_data']?.length
            }
        }
        if (maximumDlLength == 0 || maximumDlLength < 5) {
            setDLLength(10);
        } else {
            setDLLength(maximumDlLength);
        }

        if ((carrierAnnuityLevelData?.length == 0 && selectedTab == "annuity") || (carrierLifeLevelData?.length == 0 && selectedTab == "life")) {
            setFlagCarrierList(false)
        }
        // var maxLength = 0
        // for (var i in carrierLevelData) {
        //     if (maxLength < carrierLevelData[i]['carrier_level_data'].length) {
        //         maxLength = carrierLevelData[i]['carrier_level_data'].length
        //     }
        // }

    }, [carrierLifeLevelData, carrierAnnuityLevelData, globalLifeCarrierData, globalAnnuityCarrierData, selectedTab]);

    useEffect(() => {
        // alert('called1')
        // setGlobalCarrierData([])
        if (pageName == "Template") {
            setGlobalLifeCarrierData([])
            setGlobalAnnuityCarrierData([])
            let Flag = false;
            let searchParamsTemplateId = null;
            let templateIdExists = searchParams.has("id");
            if (templateIdExists === true) {
                searchParamsTemplateId = searchParams.get("id");
                if (searchParamsTemplateId !== null) {
                    Flag = true
                }
            }

            if (Flag) {
                FlagApiLoader(true);
                GetTemplateData(
                    {
                        template_id: searchParamsTemplateId,
                        template_version_id: tempVersionCurrentId
                    },
                    (res) => {
                        if (res.data.status === true) {
                            setFlagType(res?.data?.data[0]?.carrier_type_flag)
                            setTemplateName(res?.data?.data[0]?.template_name)
                            setTemplateId(res?.data?.data[0]?.template_id)
                            // setDLLength(res?.data?.data[0]?.max_dl_length)
                            let tempInnerArray = []
                            res?.data?.data[0]?.template_data?.map((row) => {
                                setAssignmentId([...assignmentId, row?.template_carrier_assignment_id])
                                let obj = {}
                                obj.carrier_name = row?.carrier_name
                                obj.carrier_id = row?.carrier_id
                                obj.edit_flag = false
                                obj.template_carrier_assignment_id = row?.template_carrier_assignment_id
                                obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: res?.data?.data[0]?.carrier_type_flag }]
                                obj.baLevel = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                                obj.directCarrier = row?.carrier_level_data.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                                obj.carrier_level_data = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                                tempInnerArray.push(obj)
                            })
                            if (res?.data?.data[0]?.carrier_type_flag) {
                                setGlobalLifeCarrierData(tempInnerArray)
                            } else {
                                setGlobalAnnuityCarrierData(tempInnerArray)
                            }
                            // setGlobalCarrierData(tempInnerArray);
                            let carrierIds = ''
                            res?.data?.data[0]?.template_data?.map((ele) => {
                                carrierIds += `,${ele?.carrier_id}`
                            })
                            carrierIds = carrierIds.slice(1)
                            GetCarrierLevelList(carrierIds,
                                (res) => {
                                    if (res.data.status) {
                                        setFlagCarrierList(true);
                                        // setCarrierLevelData(res.data.data)

                                        // ** UPDATED CODE : HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                                        setCarrierLifeLevelData(res.data.data)
                                        setCarrierAnnuityLevelData(res.data.data)
                                        // ** UPDATED CODE : HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE  **
                                    }
                                    FlagApiLoader(false);
                                },
                                (err) => {
                                    FlagApiLoader(false);
                                    console.log(err);
                                })


                        }
                        else {
                            FlagApiLoader(false);
                            console.log("response false of GetTemplateData api", res);
                            if (res?.data?.error?.message) {
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: res?.data?.error?.message
                                });
                            }
                            else {
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: "Something Went Wrong!"
                                });
                            }
                        }
                    },
                    (err) => {
                        console.log("response error of GetTemplateData api", err);
                        FlagApiLoader(false);
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something went wrong, Please try again later!",
                        });
                    }
                );
            } else {
                FlagApiLoader(false);
            }
        }
    }, [tempVersionCurrentId]);

    useEffect(() => {
        // alert('called2')
        if (pageName == "Template") {
            if (changeTemplateCall !== "default") {
                setGlobalLifeCarrierData([])
                setGlobalAnnuityCarrierData([])
                let Flag = false;
                let searchParamsTemplateId = null;
                let templateIdExists = searchParams.has("id");
                if (templateIdExists === true) {
                    searchParamsTemplateId = searchParams.get("id");
                    if (searchParamsTemplateId !== null) {
                        Flag = true
                    }
                }
                GetTemplateVersionList(
                    searchParamsTemplateId,
                    (res) => {
                        if (res?.data?.status) {
                            setTemplateVersionList(res.data.data)
                            const CurrentTemplateVersion = res.data.data.find(obj => obj.template_version_latest === true)
                            setTempVersionCurrentId(CurrentTemplateVersion.template_version_id)
                            navigate(`/templates/view_template?id=${searchParamsTemplateId}&version_id=${CurrentTemplateVersion.template_version_id}`)
                        }
                        else {
                            console.log("response false of GetTemplateVersionList api", res);
                            if (res?.data?.error?.message) {
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: res?.data?.error?.message
                                });
                            }
                            else {
                                UpdateSnackbarMessage({
                                    status: "error",
                                    message: "Something Went Wrong!"
                                });
                            }
                        }
                    },
                    (err) => {
                        console.log("response error of GetTemplateVersionList api", err);
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    })
                if (Flag) {
                    FlagApiLoader(true);
                    GetTemplateData(
                        {
                            template_id: searchParamsTemplateId,
                            template_version_id: null
                        },
                        (res) => {
                            if (res.data.status === true) {
                                setFlagType(res?.data?.data[0]?.carrier_type_flag)
                                setTemplateName(res?.data?.data[0]?.template_name)
                                setTemplateId(res?.data?.data[0]?.template_id)
                                // setDLLength(res?.data?.data[0]?.max_dl_length)
                                let tempInnerArray = []
                                res?.data?.data[0]?.template_data?.map((row) => {
                                    setAssignmentId([...assignmentId, row?.template_carrier_assignment_id])
                                    let obj = {}
                                    obj.carrier_name = row?.carrier_name
                                    obj.carrier_id = row?.carrier_id
                                    obj.edit_flag = false
                                    obj.template_carrier_assignment_id = row?.template_carrier_assignment_id
                                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: res?.data?.data[0]?.carrier_type_flag }]
                                    obj.baLevel = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                                    obj.directCarrier = row?.carrier_level_data.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                                    obj.carrier_level_data = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                                    tempInnerArray.push(obj)
                                })
                                if (res?.data?.data[0]?.carrier_type_flag) {
                                    setGlobalLifeCarrierData(tempInnerArray)
                                } else {
                                    setGlobalAnnuityCarrierData(tempInnerArray)
                                }
                                // setGlobalCarrierData(tempInnerArray);

                                let carrierIds = ''
                                res?.data?.data[0]?.template_data?.map((ele) => {
                                    carrierIds += `,${ele?.carrier_id}`
                                })
                                carrierIds = carrierIds.slice(1)
                                GetCarrierLevelList(carrierIds,
                                    (res) => {
                                        if (res.data.status) {
                                            setFlagCarrierList(true);
                                            // setCarrierLevelData(res.data.data)

                                            // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                                            setCarrierLifeLevelData(res.data.data)
                                            setCarrierAnnuityLevelData(res.data.data)
                                            // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                                        }
                                        else {
                                            console.log("response false of UserMeApi api", res);
                                            if (res?.data?.error?.message) {
                                                UpdateSnackbarMessage({
                                                    status: "error",
                                                    message: res?.data?.error?.message
                                                });
                                            }
                                            else {
                                                UpdateSnackbarMessage({
                                                    status: "error",
                                                    message: "Something Went Wrong!"
                                                });
                                            }
                                            FlagApiLoader(false);
                                        }
                                    },
                                    (err) => {
                                        console.log("response error of GetTemplateData api", err);
                                        FlagApiLoader(false);
                                        UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Something Went Wrong!"
                                        });
                                    })


                            } else {
                                FlagApiLoader(false);
                                console.log("response false of GetTemplateData api", res);
                                if (res?.data?.error?.message) {
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: res?.data?.error?.message
                                    });
                                }
                                else {
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: "Something Went Wrong!"
                                    });
                                }
                            }
                        },
                        (err) => {
                            console.log("response error of GetTemplateData api", err);
                            FlagApiLoader(false);
                            UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong, Please try again later!",
                            });
                        }
                    );
                } else {
                    FlagApiLoader(false);
                }
            }
        }
    }, [changeTemplateCall])


    useEffect(() => {
        // OLD CODE
        // if (carrierLevelData?.length) {
        //     if (DLLength <= 5) {
        //         let templateIdExists = searchParams.has("id");
        //         if (templateIdExists === true) {
        //             FlagApiLoader(true);
        //         }
        //     } else {
        //         FlagApiLoader(false);
        //     }
        // }

        // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
        if ((carrierAnnuityLevelData?.length == 0 && selectedTab == "annuity") || (carrierLifeLevelData?.length == 0 && selectedTab == "life")) {
            if (DLLength <= 5) {
                let templateIdExists = searchParams.has("id");
                if (templateIdExists === true) {
                    FlagApiLoader(true);
                }
            } else {
                FlagApiLoader(false);
            }
        }
    }, [DLLength]);

    const DeleteCarrier = () => {
        if (pageName == "Agent") {
            if (selectedTab == "life") {
                var tempGlobalCarrierData = [...globalLifeCarrierData]
            } else if (selectedTab == "annuity") {
                var tempGlobalCarrierData = [...globalAnnuityCarrierData]
            }
            tempGlobalCarrierData = tempGlobalCarrierData?.filter((row) => {
                return row.carrier_types[0]?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id
            })
            if (deleteCarrierData?.agent_commission_carrier_assignment_id) {
                if (deletedCarrierId?.indexOf(deleteCarrierData?.agent_commission_carrier_assignment_id) == -1) {
                    setDeletedCarrierId([...deletedCarrierId, deleteCarrierData?.agent_commission_carrier_assignment_id])
                }
            }
            if (selectedTab == "life") {
                setGlobalLifeCarrierData(tempGlobalCarrierData)
            } else {
                setGlobalAnnuityCarrierData(tempGlobalCarrierData)
            }
        } else {

            if (flagType) {
                var tempGlobalCarrierData = [...globalLifeCarrierData]
            } else {
                var tempGlobalCarrierData = [...globalAnnuityCarrierData]
            }
            if (flagAddPage) {
                tempGlobalCarrierData = tempGlobalCarrierData?.filter((row) => {
                    return row.carrier_types[0]?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id
                })
            } else {
                tempGlobalCarrierData = tempGlobalCarrierData?.filter((row) => {
                    return row?.carrier_types[0]?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id
                })
                if (deleteCarrierData?.template_carrier_assignment_id) {
                    if (deletedCarrierId?.indexOf(deleteCarrierData?.template_carrier_assignment_id) == -1) {
                        setDeletedCarrierId([...deletedCarrierId, deleteCarrierData?.template_carrier_assignment_id])
                    }
                }
            }
            if (flagType) {
                setGlobalLifeCarrierData(tempGlobalCarrierData)
            } else {
                setGlobalAnnuityCarrierData(tempGlobalCarrierData)
            }
            setFlagDeletePopup(false)

            // OLD CODE :
            // let tempLevel = carrierLevelData?.filter((ele) => ele?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id)
            // setCarrierLevelData(tempLevel);

            // UPDATED CODE : 
            if (selectedTab == 'life') {
                let tempLevel = carrierLifeLevelData?.filter((ele) => ele?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id)
                setCarrierLifeLevelData(tempLevel);
            }
            else {
                let tempLevel = carrierAnnuityLevelData?.filter((ele) => ele?.carrier_id != deleteCarrierData?.carrier_types[0]?.carrier_id)
                setCarrierAnnuityLevelData(tempLevel);
            }
        }
        setFlagDeletePopup(false)
    }

    const deletePopup = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Grid container className="delete-main-container">
                        <Grid item xs={11.5} textAlign={"end"}>
                            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
                        </Grid>
                        <Grid item xs={12} className="delete-alert-item">
                            <img src={deleteAlert}></img>
                        </Grid>
                        <Grid item xs={12} className="delete-mesg-item">
                            Are you sure you want to delete {deleteCarrierData?.carrier_name}?
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container className="delete-btn-container">
                                <Grid item xs={12}>
                                    <Grid container className="popup-delete-button-container" >
                                        <Button
                                            variant="contained"
                                            sx={{ width: "100px" }}
                                            onClick={() => {
                                                DeleteCarrier()
                                                handleClose()
                                            }}
                                        >
                                            Yes
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="error"
                                            sx={{ width: "100px" }}
                                            onClick={() => { setFlagDeletePopup(false) }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={6} >
                                    <Grid container className="delete-no-btn-container">
                                        <Grid item xs={3} className="delete-no-btn-item">
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="error"
                                                onClick={() => { setFlagDeletePopup(false) }}
                                            >No
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={3} className="delete-yes-btn-item">
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    DeleteCarrier()
                                                    handleClose()
                                                }}
                                            >Yes</Button>
                                        </Grid>
                                    </Grid>
                                </Grid> */}

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </>
        )
    }

    const handleOpenpopup = () => {
        setFlagOpenCarrierPopup(true);
    };
    const handleClosePopup = () => {
        setFlagOpenCarrierPopup(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setDeleteCarrierData(row);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const EnableEdit = (row) => {

        let tempCarrierData =
            selectedTab == "life" ? [...globalLifeCarrierData] : [...globalAnnuityCarrierData]
        tempCarrierData?.map((obj) => {
            if (obj?.carrier_types[0]?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                obj.edit_flag = true
            } else {
                obj.edit_flag = false
            }
        })
        if (selectedTab == "life") {
            setGlobalLifeCarrierData(tempCarrierData)
        } else {
            setGlobalAnnuityCarrierData(tempCarrierData)
        }
    }

    const DisableEdit = (row) => {
        let tempCarrierData =
            selectedTab == "life" ? [...globalLifeCarrierData] : [...globalAnnuityCarrierData]
        tempCarrierData?.map((obj) => {
            if (obj?.carrier_types[0]?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                obj.edit_flag = false
            }
        })
        if (selectedTab == "life") {
            setGlobalLifeCarrierData(tempCarrierData)
        } else {
            setGlobalAnnuityCarrierData(tempCarrierData)
        }
    }

    const ChangeBaLevel = (e, row) => {

        let tempCarrierData =
            selectedTab == "life" ? [...globalLifeCarrierData] : selectedTab == "annuity" ? [...globalAnnuityCarrierData] : []
        tempCarrierData.map((ff) => {
            if (ff.carrier_types[0]["carrier_id"] == row.carrier_types[0].carrier_id) {
                ff['baLevel'] = e.target.value
            }
            return ff;
        });
        if (selectedTab == "life") {
            setGlobalLifeCarrierData(tempCarrierData)
        } else if (selectedTab == "annuity") {
            setGlobalAnnuityCarrierData(tempCarrierData)
        }
    }

    const ChangeDirectLevel = (e, row) => {
        let tempCarrierData = selectedTab == "life" ? [...globalLifeCarrierData] : selectedTab == "annuity" ? [...globalAnnuityCarrierData] : []
        // OLD CODE :
        // var filterdata = carrierLevelData?.filter((rr) => rr.carrier_id == row.carrier_types[0].carrier_id)[0]["carrier_direct_level_data"].filter((rr) =>
        //     rr.carrier_level_sequence > e.target.value.carrier_level_sequence
        // );

        // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
        if (selectedTab == "life") {
            var filterdata = carrierLifeLevelData?.filter((rr) => rr.carrier_id == row.carrier_types[0].carrier_id)[0]["carrier_direct_level_data"].filter((rr) =>
                rr.carrier_level_sequence > e.target.value.carrier_level_sequence
            );
        }
        else if (selectedTab == "annuity") {
            var filterdata = carrierAnnuityLevelData?.filter((rr) => rr.carrier_id == row.carrier_types[0].carrier_id)[0]["carrier_direct_level_data"].filter((rr) =>
                rr.carrier_level_sequence > e.target.value.carrier_level_sequence
            );
        } else {
            console.log("Else Triggered");
        }
        // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **

        tempCarrierData.map((ff) => {
            if (ff.carrier_types[0]["carrier_id"] == row.carrier_types[0].carrier_id) {
                ff['directCarrier'] = e.target.value
                ff['carrier_level_data'] = filterdata
            }
            return ff;
        });

        if (selectedTab == "life") {
            setGlobalLifeCarrierData(tempCarrierData)
        } else if (selectedTab == "annuity") {
            setGlobalAnnuityCarrierData(tempCarrierData)
        }
        setTimeout(() => {
            FlagApiLoader(false);
        }, 1100);
    }

    const ChangeDlSection = (e, row, j) => {
        let tempCarrierData =
            selectedTab == "life" ? [...globalLifeCarrierData] : selectedTab == "annuity" ? [...globalAnnuityCarrierData] : []
        if (e.target.value == "") {
            tempCarrierData.map((ff) => {
                if (ff.carrier_types[0]["carrier_id"] == row.carrier_types[0].carrier_id) {
                    ff['carrier_level_data'].splice(j, 1)
                }
                return ff;
            });
        } else {
            tempCarrierData.map((ff) => {
                if (ff.carrier_types[0]["carrier_id"] == row.carrier_types[0].carrier_id) {
                    ff['carrier_level_data'][j] = e.target.value
                }
                return ff;
            });
        }
        if (selectedTab == "life") {
            setGlobalLifeCarrierData(tempCarrierData)
        } else if (selectedTab == "annuity") {
            setGlobalAnnuityCarrierData(tempCarrierData)
        }
    }

    return (
        <>
            {flagOpenCarrierPopup && (
                <GlobalDialog
                    open={true}
                    data={
                        <CarriersPopup
                            handleClosePopup={handleClosePopup}
                            handleOpenpopup={handleOpenpopup}

                            // AFTER LIFE ANNUITY DROPDOWN ISSUE 
                            selectedTab={selectedTab}
                        />
                    }
                    maxWidth={"lg"}
                />
            )}
            {flagDeletePopup && (
                <GlobalDialog
                    open={flagDeletePopup}
                    data={deletePopup()}
                    maxWidth={"sm"}
                    sx={{ borderRadius: "20px" }}
                />
            )}
            {flagAddPage ?
                (((pageName == "Template" && (flagType == null || templateName == "")) || (pageName == "Agent" && (flagType == null)))
                    ?
                    null
                    : (flagType != null && flagCarrierList && ((selectedTab == "life" && globalLifeCarrierData.length) || (selectedTab == "annuity" && globalAnnuityCarrierData.length)))
                        ?
                        <>
                            {/* Add New Template  */}
                            <Grid
                                container
                                justifyContent={"center"}
                                sx={{ maxWidth: "83vw" }}
                            >
                                <Grid item xs={12} textAlign="center" sx={{ padding: "15px 5px 2px 15px" }}>
                                    <Grid container className="" sx={{ justifyContent: "center" }}>
                                        <TableContainer
                                            className=""
                                            sx={{ minHeight: "54vh", maxHeight: "57vh" }}
                                            component={Paper}
                                        >
                                            <Table
                                                stickyHeader
                                                className="templateCarrierTable"
                                                aria-label="a dense table"
                                                size="small"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {/* Serial Number */}
                                                        <TableHeadCell
                                                            className="header-color sticky-table-head sticky-table-head-1"
                                                        >
                                                            #
                                                        </TableHeadCell>

                                                        {/* Carrier */}
                                                        <TableHeadCell
                                                            className="header-color sticky-table-head sticky-table-head-2"
                                                            align="left"
                                                            nowrap
                                                        >
                                                            Carrier
                                                        </TableHeadCell>

                                                        {/* BA Level */}
                                                        <TableHeadCell
                                                            className="header-color sticky-table-head sticky-table-head-3"
                                                            align="center"
                                                            nowrap
                                                        >
                                                            BA Level
                                                        </TableHeadCell>

                                                        {/* Direct Carrier */}
                                                        <TableHeadCell
                                                            className="header-color sticky-table-head sticky-table-head-4"
                                                            align="center"
                                                            sx={{ borderRight: "2px solid white" }}
                                                            nowrap
                                                        >
                                                            Direct Carrier
                                                        </TableHeadCell>

                                                        {/* DL Selection */}
                                                        {Array.from({ length: DLLength }).map((rr, i) => {
                                                            return (
                                                                <TableHeadCell
                                                                    key={i}
                                                                    className="font-color header-color"
                                                                    align="center"
                                                                >
                                                                    DL
                                                                </TableHeadCell>
                                                            );
                                                        })}

                                                        {/* Menu Item */}
                                                        <TableHeadCell
                                                            className="header-color  sticky-table-head sticky-table-head-5"
                                                            align="left"
                                                            nowrap
                                                        ></TableHeadCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        (selectedTab == "life" ? globalLifeCarrierData : selectedTab == "annuity" ? globalAnnuityCarrierData : []).map((row, i) =>
                                                        (
                                                            <GlobalCarrierTableRow
                                                                key={i}
                                                                row={row}
                                                                i={i}
                                                                ChangeBaLevel={ChangeBaLevel}
                                                                ChangeDirectLevel={ChangeDirectLevel}
                                                                DLLength={DLLength}
                                                                DisableEdit={DisableEdit}
                                                                ChangeDlSection={ChangeDlSection}
                                                                anchorEl={anchorEl}
                                                                handleClick={handleClick}
                                                                handleClose={handleClose}
                                                                setFlagDeletePopup={setFlagDeletePopup}
                                                                open={open}
                                                                EnableEdit={EnableEdit}
                                                                selectedTab={selectedTab}
                                                            />
                                                        )
                                                        )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        className="buttonaf"
                                        variant="text"
                                        sx={{ fontSize: "14px", fontWeight: "500", padding: "0px 8px 2px 8px" }}
                                        // disabled={(!flagAddPage && editFlag == false) || templateName == "" ? true : false}
                                        onClick={() => {
                                            handleOpenpopup();
                                        }}
                                    >
                                        <Add /> Add New Carrier
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                        : ((flagType != null) &&
                            <>
                                {/* Add New Template  */}
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        sx={{
                                            backgroundColor: "white",
                                            height: "50vh",
                                            overflow: "auto",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            {apiLoader == false &&
                                                <Button
                                                    className="buttonaf"
                                                    variant="contained"
                                                    sx={{ fontSize: "14px", fontWeight: "500" }}
                                                    onClick={() => {
                                                        handleOpenpopup();
                                                    }}
                                                >
                                                    <Add /> New Carrier
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )
                )
                :
                ((selectedTab == "life" && globalLifeCarrierData.length) || (selectedTab == "annuity" && globalAnnuityCarrierData.length)) ?
                    (editFlag == false ?
                        <Grid
                            container
                            justifyContent={"center"}
                            sx={{ maxWidth: "100%" }}
                        >
                            {/* Only view Template  */}
                            <Grid item xs={12} textAlign="center" sx={{ padding: "15px 15px 5px 15px" }}>
                                <Grid container className="" sx={{ justifyContent: "center" }}>
                                    <TableContainer
                                        className=""
                                        sx={{ minHeight: "54vh", maxHeight: "57vh" }}
                                        component={Paper}
                                    >
                                        <Table
                                            stickyHeader
                                            className="templateCarrierTable"
                                            aria-label="a dense table"
                                            size="small"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-1"
                                                    >
                                                        #
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-2"
                                                        align="left"
                                                        nowrap
                                                    >
                                                        Carrier
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-3"
                                                        align="center"
                                                        nowrap
                                                    >
                                                        BA Level
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-4"
                                                        align="center"
                                                        nowrap
                                                        sx={{ borderRight: "2px solid white" }}
                                                    >
                                                        Direct Carrier
                                                    </TableHeadCell>

                                                    {Array.from({ length: DLLength }).map((rr, i) => {
                                                        return (
                                                            <TableHeadCell
                                                                key={i}
                                                                className="font-color header-color"
                                                                align="center"
                                                            >
                                                                DL
                                                            </TableHeadCell>
                                                        );
                                                    })}

                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {(selectedTab == "life" ? globalLifeCarrierData : selectedTab == "annuity" ? globalAnnuityCarrierData : [])?.map((row, i) =>
                                                (<TableRow
                                                    // sx={{ width: '50%' }}
                                                    key={i}
                                                    className="carrier_table_row"
                                                >
                                                    <TableCell
                                                        className="sticky-table-body rowHeight sticky-table-body-1"
                                                        stickyHeader
                                                    >
                                                        {i + 1}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        className="sticky-table-body rowHeight sticky-table-body-2 clickable_item"
                                                        stickyHeader
                                                    // onClick={() => {
                                                    //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
                                                    // }}
                                                    >
                                                        <Link
                                                            style={{ textDecoration: "none" }}
                                                            className="clickable_item"
                                                            target="_blank"
                                                            to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`}
                                                        >

                                                            {row.carrier_name}
                                                        </Link>
                                                        {/* {row.carrier_name} */}
                                                    </TableCell>

                                                    {/* BA Level */}
                                                    <TableCell
                                                        align="center"
                                                        className="sticky-table-body rowHeight sticky-table-body-3"
                                                        stickyHeader
                                                    >
                                                        {row?.baLevel?.carrier_level_name}
                                                    </TableCell>

                                                    {/* Direct Carrier */}
                                                    <TableCell
                                                        align="center"
                                                        className="sticky-table-body rowHeight sticky-table-body-4"
                                                        sx={{
                                                            borderRight: "2px solid white",
                                                        }}
                                                    >
                                                        {row?.directCarrier?.carrier_level_name}
                                                    </TableCell>

                                                    {/* DL Starts */}
                                                    {Array.from({ length: DLLength }).map((mm, j) => {
                                                        return (
                                                            <>
                                                                <TableCell
                                                                    key={j}
                                                                    align="center"
                                                                    className="rowHeight"
                                                                >
                                                                    {row?.carrier_level_data?.length ? row?.carrier_level_data[j]?.carrier_level_name : ""}
                                                                </TableCell>
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>)
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid
                            container
                            justifyContent={"center"}
                            sx={{ maxWidth: "100%" }}
                        >
                            {/* Edit Template  */}

                            <Grid item xs={12} textAlign="center" sx={{ padding: "15px 5px 2px 15px" }}>
                                <Grid container className="" sx={{ justifyContent: "center" }}>
                                    <TableContainer
                                        className=""
                                        sx={{ minHeight: "54vh", maxHeight: "57vh" }}
                                        component={Paper}
                                    >
                                        <Table
                                            stickyHeader
                                            className="templateCarrierTable"
                                            aria-label="a dense table"
                                            size="small"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-1"
                                                    >
                                                        #
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-2"
                                                        align="left"
                                                        nowrap
                                                    >
                                                        Carrier
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-3"
                                                        align="center"
                                                        nowrap
                                                    >
                                                        BA Level
                                                    </TableHeadCell>

                                                    <TableHeadCell
                                                        className="header-color sticky-table-head sticky-table-head-4"
                                                        align="center"
                                                        sx={{ borderRight: "2px solid white" }}
                                                        nowrap
                                                    >
                                                        Direct Carrier
                                                    </TableHeadCell>

                                                    {Array.from({ length: DLLength }).map((rr, i) => {
                                                        return (
                                                            <TableHeadCell
                                                                key={i}
                                                                className="font-color header-color"
                                                                align="center"
                                                            >
                                                                DL
                                                            </TableHeadCell>
                                                        );
                                                    })}

                                                    {/* Menu Item */}
                                                    <TableHeadCell
                                                        className="header-color  sticky-table-head sticky-table-head-5"
                                                        align="left"
                                                        nowrap
                                                    ></TableHeadCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {(selectedTab == "life" ? globalLifeCarrierData : selectedTab == "annuity" ? globalAnnuityCarrierData : [])?.map((row, i) =>
                                                (
                                                    <GlobalCarrierTableRow
                                                        row={row}
                                                        i={i}
                                                        ChangeBaLevel={ChangeBaLevel}
                                                        ChangeDirectLevel={ChangeDirectLevel}
                                                        DLLength={DLLength}
                                                        DisableEdit={DisableEdit}
                                                        ChangeDlSection={ChangeDlSection}
                                                        anchorEl={anchorEl}
                                                        handleClick={handleClick}
                                                        handleClose={handleClose}
                                                        setFlagDeletePopup={setFlagDeletePopup}
                                                        open={open}
                                                        EnableEdit={EnableEdit}
                                                        selectedTab={selectedTab}
                                                    />
                                                )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    className="buttonaf"
                                    variant="text"
                                    sx={{ fontSize: "14px", fontWeight: "500", padding: "0px 8px 2px 8px" }}
                                    disabled={(!flagAddPage && editFlag == false) ? true : false}
                                    onClick={() => {
                                        handleOpenpopup();
                                    }}
                                >
                                    <Add /> Add New Carrier
                                </Button>
                            </Grid>
                        </Grid>
                    ) :
                    (
                        <>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    sx={{
                                        backgroundColor: "white",
                                        height: "50vh",
                                        overflow: "auto",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Grid item xs={12}>
                                        {apiLoader == false &&
                                            <Button
                                                className="buttonaf"
                                                variant="contained"
                                                sx={{ fontSize: "14px", fontWeight: "500" }}
                                                onClick={() => {
                                                    handleOpenpopup();
                                                }}
                                            >
                                                <Add /> New Carrier
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
            }

        </>
    );
};

export default GlobalCarrierTable;

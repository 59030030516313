import React from 'react'
import { Grid, Button, Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AgencyLogo = (props) => {
    const navigate = useNavigate()
    const { logoInfo } = props
    return (
        <Card sx={{ maxWidth: "100%" }}>
            <CardActionArea onClick={() => navigate(`/agency/view_agency?agency_id=${logoInfo.id}`)}>
                <CardMedia
                    component="img"
                    height="auto"
                    image={logoInfo.image}
                    alt="Logo"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {logoInfo.agency}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>

        // TEMPORARY COMENTED
        // <Grid conatiner sx={{ height: "100%" }}>
        //     <Grid item xs={12}>
        //         <Grid container >
        //             <img
        //                 src={logoInfo.image}
        //                 alt='image'
        //                 style={{ height: "auto", width: '100%' }} />
        //         </Grid>
        //     </Grid>
        //     <Grid item xs={12} >
        //         <Button
        //             sx={{ backgroundColor: "white" }}
        //             fullWidth
        //         >
        //             {logoInfo.agency}
        //         </Button>
        //     </Grid>
        // </Grid>

    )
}
export default AgencyLogo;
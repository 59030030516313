import Axios from "../axios"
export const getAgencyData = async (agency_id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/get-agency-data?agency_id=${agency_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const getAgencyDataList = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/get-agency-data-list`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const checkAgencyExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/check-agency-existence?agency_name=${data.agency_name}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}


export const checkAgencyURLExistence = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/check-agency-url-existence?agency_url=${data.agency_url}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const UploadFileAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`/storage/file`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const DeleteFileAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete(`/storage/file?resource_gcs_data_id=${data?.resource_gcs_data_id}`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const createNewAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/create-agency`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const updateAgency = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put(`agency/update-agency-data`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const addUpdateAgencyCarrier = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/manage-agency-carrier`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const AddAgencyLogo = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/global/get-company-logo`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const verifyAgencyWhiteLabelURL = async (agency_id, onSuccess, onError) => {
    try {
        const response = await Axios.get(`/agency/dns-lookup?agency_id=${agency_id}`,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}

export const activateAgencyApi = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post(`agency/activate-agency`, data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err);
    }
}
import { LoginApi, SendOtpApi, UserMeApi } from "../../utils/api/userDetailApi";
import CreateDataContext from "./createDataContext";

const AgentReducer = (state, action) => {
  switch (action.type) {
    case "agentUserData": {
      return {
        ...state,
        agentUserData: action.payload,
      };
    }
    case "lifeTemplateList": {
      return {
        ...state,
        lifeTemplateList: action.payload,
      };
    }
    case "annuityTemplateList": {
      return {
        ...state,
        annuityTemplateList: action.payload,
      };
    }
    case "flagAgent": {
      return {
        ...state,
        flagAgent: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

const setAgentUserData = (dispatch) => async (data) => {
  dispatch({
    type: "agentUserData",
    payload: data,
  });
};
const setFlagAgent = (dispatch) => async (data) => {
  dispatch({
    type: "flagAgent",
    payload: data,
  });
};

const setLifeTemplateList = (dispatch) => async (data) => {
  dispatch({
    type: "lifeTemplateList",
    payload: data,
  });
};

const setAnnuityTemplateList = (dispatch) => async (data) => {
  dispatch({
    type: "annuityTemplateList",
    payload: data,
  });
};

export const { Provider, Context, state } = CreateDataContext(
  AgentReducer,
  {
    setAgentUserData,
    setFlagAgent,
    setLifeTemplateList,
    setAnnuityTemplateList,
  },
  {
    flagAgent: null,
    carrierLevelData: [],
    lifeTemplateList: [{}],
    annuityTemplateList: [{}],
    agentUserData: {
      primary: {
        "primary_data_flag": true,
        "username": "",
        "user_id": "",
        "email_address": "",
        "address": "",
        "state": "",
        "country": "",
        "zip_code": "",
        "sso_email": "",
        "nda_status": []
      },
      secondary: {
        "primary_data_flag": false,
        "user_id": "",
        "email_address": "",
        "address": "",
        "state": "",
        "country": "",
        "zip_code": "",
        "sso_email": "",
        "nda_status": []
      },
      carrier_type_life: false,
      carrier_type_annuity: false
    },
    agent_commission_data: [
      {
        carrier_type_flag: true
      }
    ],
  }
);

import React, { useContext, useState } from 'react';
import '../../css/ViewCarriarStyles/AddNewCarrierDialog.css'
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { CheckCarrierExistence } from '../../../utils/api/CarriersApi';
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { useNavigate } from 'react-router-dom';
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"

const AddNewCarrierDialog = (props) => {
    const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext);
    const { setCarrierName } = useContext(CarrierContext);

    const navigate = useNavigate()
    const [flagCarrierExistanceCheck, setFlagCarrierExistanceCheck] = useState(false)

    const [newCarrierData, setNewCarrierData] = useState({
        carrier_name: "",
        carrier_type_flag: ""
    })

    const handleCarrierAdd = () => {

        let params = {
            carrier_name: newCarrierData.carrier_name.trim(),
            carrier_type_flag: newCarrierData.carrier_type_flag
        }

        if (params && params?.carrier_name != "", params?.carrier_type_flag != "") {
            CheckCarrierExistence(newCarrierData, (res) => {
                // console.log("res", res)
                let carrierExistanceResponse = res?.data?.data[0]
                if (res?.data?.status == true && res?.data?.data?.code == 9095) {
                    UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.data?.message
                    })
                }
                else {
                    if (carrierExistanceResponse.carrier_exist_flag == true) {
                        setFlagCarrierExistanceCheck(true)
                    }
                    else {
                        setCarrierName(newCarrierData?.carrier_name)
                        navigate("/carriers/add_carrier", { state: newCarrierData })
                    }
                }
            })
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container flexDirection={"column"} justifyContent={'center'}>

                    {/* header */}
                    <Grid item xs={12} className='anc_header'>
                        <Typography>Add Carrier</Typography>
                    </Grid>

                    {/* content */}
                    <Grid item xs={12}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            {/* name and type */}
                            <Grid item xs={7} className='anc_nametype'>
                                <Grid container alignItems={'flexStart'}>
                                    {/* name */}
                                    <Grid item xs={12} className='anc_textfield'>
                                        <TextField
                                            value={newCarrierData?.name}
                                            placeholder='Carrier Name'
                                            fullWidth
                                            variant="standard"
                                            helperText={flagCarrierExistanceCheck ? "This carrier already exists!" : null}
                                            FormHelperTextProps={{ sx: { color: 'red' } }}
                                            onFocus={() => { setFlagCarrierExistanceCheck(false) }}
                                            onChange={(event) => {
                                                setNewCarrierData({
                                                    ...newCarrierData,
                                                    carrier_name: event.target.value
                                                })
                                            }}></TextField>
                                    </Grid>
                                    {/* type */}
                                    <Grid item xs={12} className='anc_type_radiobtns'>
                                        <div className='anc_type_radiobtns_header'>
                                            <Typography>Type</Typography>
                                            <sup style={{ color: "red" }} className='anc_required_symbol'>*</sup>
                                        </div>
                                        <FormControl>
                                            <RadioGroup
                                                onChange={(event) => {
                                                    setNewCarrierData({
                                                        ...newCarrierData,
                                                        carrier_type_flag: event.target.value
                                                    })
                                                }}
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue=""
                                                name="radio-buttons-group"
                                            >
                                                <FormControlLabel value={true} control={<Radio />} label="Life" />
                                                <FormControlLabel value={false} control={<Radio />} label="Annuity" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* buttons */}
                            <Grid item className='anc_btns' xs={7}>
                                <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                    <Grid item xs={5}>
                                        <Button
                                            fullWidth
                                            className="anc_btns_close"
                                            variant='outlined'
                                            onClick={() => props.setFlagAddNewCarrierDialog(false)} >
                                            Close
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Button
                                            fullWidth
                                            className={
                                                (newCarrierData?.carrier_name == "" || newCarrierData?.carrier_type_flag == "") ?
                                                    "anc_btns_save_disabled"
                                                    : "anc_btns_save"
                                            }
                                            variant='contained'
                                            onClick={() => {
                                                handleCarrierAdd()
                                            }}>
                                            Save
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddNewCarrierDialog
import React, { Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Verification from "./pages/auth/Verification";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components

// Form components

// Icon components

// Page components
import Templates from "./pages/pages/Templates";
import Pricing from "./pages/pages/Pricing";
import Carriers from "./pages/pages/Carriers";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";

// Table components

// Documentation

// Landing

// Protected routes
import Agents from "./pages/pages/Agents";
import { Context as AuthContext } from "./contexts/reducerContexts/authContext";
import { UserMeApi } from "./utils/api/userDetailApi";
import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "./pages/Loader/loader";
import AddNewTemplate from "./pages/pages/AddNewTemplate";
import AddNewAgents from "./pages/pages/AddNewAgent";
// import { AuthContext } from "./contexts/JWTContext";
import { Provider as TemplateProvider } from "./contexts/reducerContexts/templateContext";
import { Provider as CarrierTableProvider } from "./contexts/reducerContexts/globalCarrierContext";
import ViewCarrier from "./pages/pages/ViewCarrier";
import BATeam from "./pages/pages/BATeam";
import NdaAlert from "./pages/auth/NdaAlert";
import NdaError from "./layouts/NdaError";
import AddCarrier from "./pages/pages/AddCarrier";
import Agency from "./pages/pages/Agency/Agency";
import AgencyDetails from "./pages/pages/Agency/AgencyDetails";
import { ImportExport } from "@mui/icons-material";
import AgencyCarrierDetails from "./pages/pages/Agency/AgencyCarrierDetails";
import ApprovalWaiting from "./pages/pages/Agency/ApprovalWaiting";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components

// Icon components

// Table components

// Chart components

// Maps components
// Provider component
const GlobalRoutes = () => {

  const { state, UserMeContextApi, UpdateSnackbarMessage, FlagApiLoader, AgencyLogoAPI } = useContext(AuthContext);

  const { flagAuthenticated, snackbarMessage, apiLoader, initialLoader, agencyLogo } = {
    ...state,
  };

  const [routes, setRoutes] = useState([])

  useEffect(() => {
    if (state?.userDetail?.length) {

      // if (state.userDetail[0]?.user_agency_data?.status_name == "Registered" || agencyLogo?.agency_status_name == "Registered") {
      //   setRoutes([
      //     {
      //       path: "/",
      //       element: <ApprovalWaiting />,
      //       children: [
      //         {
      //           path: "*",
      //           element: <ApprovalWaiting />
      //         }
      //       ]
      //     }
      //   ])
      // }
      // else {
      if (state?.userDetail[0]?.user_role_name == "Admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance") {
        setRoutes([
          {
            path: "templates",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Templates />,
              },
              {
                path: "add_template",
                element: <AddNewTemplate />,
              },
              {
                path: "view_template",
                element: <AddNewTemplate />,
              }
            ],
          },
          {
            path: "carriers",
            element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
            children: [
              {
                path: "",
                element: <Carriers />,
              },
              {
                path: "view_carrier",
                element: <ViewCarrier />,
              },
              {
                path: "add_carrier",
                element: <ViewCarrier />,
              }
            ],
          },
          // {
          //   path: "agency",
          //   element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
          //   children: [
          //     {
          //       path: "",
          //       element: <Agency />,
          //     },
          //     {
          //       path: "add_agency",
          //       element: <AgencyCarrierDetails />,
          //     },
          //     {
          //       path: "view_agency",
          //       element: <AgencyCarrierDetails />,
          //     },
          //     // {
          //     //   path: "approval_waiting",
          //     //   element: <ApprovalWaiting />,
          //     // }
          //   ],
          // },
          {
            path: "/",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Agents />,
              },
              {
                path: "add_agent",
                element: <AddNewAgents />,
              },
              {
                path: "view_agent",
                element: <AddNewAgents />,
              },
              {
                path: "users",
                element: <BATeam />,
              },
            ],
          },
          {
            path: "invoices",
            element: <DashboardLayout />,
            children: [
              {
                path: "",
                element: <InvoiceList />,
              },
              {
                path: "detail",
                element: <InvoiceDetails />,
              },
            ],
          },
          {
            path: "auth",
            element: <Navigate to="/" replace={true} />,
            children: [
              {
                path: "sign",
                element: <Navigate to="/" replace={true} />,
              },
              {
                path: "404",
                element: <Page404 />,
              },
              {
                path: "500",
                element: <Page500 />,
              },
            ],
          },
          {
            path: "nda_alert",
            element: <NdaError />,
            children: [
              {
                path: "",
                element: <NdaAlert />,
              },
            ],
          },
          {
            path: "*",
            element: <AuthLayout />,
            children: [
              {
                path: "*",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
        ])
      }
      else if (state.userDetail[0]?.user_role_name == "Admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() !== "brokers alliance") {
        setRoutes([
          {
            path: "templates",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Templates />,
              },
              {
                path: "add_template",
                element: <AddNewTemplate />,
              },
              {
                path: "view_template",
                element: <AddNewTemplate />,
              }
            ],
          },
          {
            path: "carriers",
            element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
            children: [
              {
                path: "",
                element: <Carriers />,
              },
              {
                path: "view_carrier",
                element: <ViewCarrier />,
              },
              {
                path: "add_carrier",
                element: <ViewCarrier />,
              }
            ],
          },
          {
            path: "/",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Agents />,
              },
              {
                path: "add_agent",
                element: <AddNewAgents />,
              },
              {
                path: "view_agent",
                element: <AddNewAgents />,
              },
              {
                path: "users",
                element: <BATeam />,
              },
            ],
          },
          // {
          //   path: "agency",
          //   element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
          //   children: [
          //     {
          //       path: "",
          //       element: <Navigate to="/" />,
          //     },
          //     {
          //       path: "view_agency",
          //       element: <AgencyCarrierDetails />,
          //     },
          //   ],
          // },
          {
            path: "invoices",
            element: <DashboardLayout />,
            children: [
              {
                path: "",
                element: <InvoiceList />,
              },
              {
                path: "detail",
                element: <InvoiceDetails />,
              },
            ],
          },
          {
            path: "auth",
            element: <Navigate to="/" replace={true} />,
            children: [
              {
                path: "sign",
                element: <Navigate to="/" replace={true} />,
              },
              {
                path: "404",
                element: <Page404 />,
              },
              {
                path: "500",
                element: <Page500 />,
              },
            ],
          },
          {
            path: "nda_alert",
            element: <NdaError />,
            children: [
              {
                path: "",
                element: <NdaAlert />,
              },
            ],
          },
          {
            path: "*",
            element: <AuthLayout />,
            children: [
              {
                path: "*",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
        ])
      }
      else if (state.userDetail[0]?.user_role_name == "Member") {
        setRoutes([
          {
            path: "templates",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Templates />,
              },
              {
                path: "add_template",
                element: <AddNewTemplate />,
              },
              {
                path: "view_template",
                element: <AddNewTemplate />,
              }
            ],
          },
          {
            path: "carriers",
            element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
            children: [
              {
                path: "",
                element: <Carriers />,
              },
              {
                path: "view_carrier",
                element: <ViewCarrier />,
              },
            ],
          },
          {
            path: "/",
            element: (
              <TemplateProvider>
                <CarrierTableProvider>
                  <DashboardLayout />
                </CarrierTableProvider>
              </TemplateProvider>
            ),
            children: [
              {
                path: "",
                element: <Agents />,
              },
              {
                path: "add_agent",
                element: <AddNewAgents />,
              },
              {
                path: "view_agent",
                element: <AddNewAgents />,
              },
            ],
          },
          {
            path: "auth",
            element: <Navigate to="/" replace={true} />,
            children: [
              {
                path: "sign",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
          {
            path: "*",
            element: <AuthLayout />,
            children: [
              {
                path: "*",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
        ])
      }
      else if (state.userDetail[0]?.user_role_name == "Agent" && state.userDetail[0]?.user_status_name == "Registered") {
        setRoutes([
          {
            path: "*",
            element: <NdaError />,
            children: [
              {
                path: "*",
                element: <NdaAlert />,
              },
            ],
          },
        ]);
      }
      else {
        setRoutes([
          {
            path: "/",
            element: <TemplateProvider><DashboardLayout /></TemplateProvider>,
            children: [
              {
                path: "",
                element: <Carriers />,
              },
              {
                path: "view",
                children: [
                  {
                    path: ":viewId",
                    element: <ViewCarrier />,
                  },
                ],
              },
            ],
          },
          {
            path: "carriers",
            element: <TemplateProvider><CarrierTableProvider><DashboardLayout /></CarrierTableProvider></TemplateProvider>,
            children: [
              {
                path: "",
                element: <Carriers />,
              },
              {
                path: "view_carrier",
                element: <ViewCarrier />,
              }
            ],
          },
          {
            path: "auth",
            element: <Navigate to="/" replace={true} />,
            children: [
              {
                path: "sign",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
          {
            path: "*",
            element: <AuthLayout />,
            children: [
              {
                path: "*",
                element: <Navigate to="/" replace={true} />,
              },
            ],
          },
        ]);
      }
      // }
    }
  }, [state.userDetail])


  const authenticatedRoutesElement = useRoutes(routes);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    if (agencyLogo?.agency_favicon_url == null && agencyLogo?.agency_name?.trim()?.toLowerCase() == "brokers alliance") {
      link.href = "https://ba.myadvisorgrids.com/masterGridFav.png"
    }
    else if (agencyLogo && agencyLogo?.agency_favicon_url !== null) {
      link.href = agencyLogo?.agency_favicon_url;
    }
    else {
      link.href = "https://ba.myadvisorgrids.com/masterGridFav.png";
    }
  }, [agencyLogo])


  useEffect(() => {
    (async () => {
      await AgencyLogoAPI();
      if (flagAuthenticated == null || flagAuthenticated == false) {
        await UserMeContextApi();
      }
    })();
  }, []);

  useEffect(() => {
    if (snackbarMessage?.status !== false) {
      setTimeout(function () {
        UpdateSnackbarMessage({ status: false, message: "" });
      }, 6000)
    }
  }, [snackbarMessage])

  const handleClose = () => {
    UpdateSnackbarMessage({ status: false, message: "" });
    return;
  };

  return (
    <>
      {apiLoader == true && <Loader />}
      {initialLoader == true ? (
        <>
          <Box
            display="flex"
            justifyContent="center"
            textAlign="center"
            my={6}
            mt="45vh"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Fragment>
          {snackbarMessage.status && (
            <Snackbar
              open={true}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
              onClose={handleClose}
              sx={{ zIndex: 2003 }}
            >
              <Alert
                onClose={handleClose}
                severity={snackbarMessage.status}
                sx={{ width: "100%", height: "100%" }}
              >
                {snackbarMessage.message}
              </Alert>
            </Snackbar>
          )}

          {
            flagAuthenticated == true ?
              (
                authenticatedRoutesElement
              )
              :
              (
                <Fragment>
                  <Routes>
                    <Route
                      path="/auth/sign"
                      element={
                        <AuthLayout>
                          <SignIn />
                        </AuthLayout>
                      }
                    />
                    <Route path="*" element={
                      <Navigate to="/auth/sign" />
                      // state?.userDetail?.length && state.userDetail[0]?.user_agency_data?.status_name == "Registered"
                      //   ?
                      //   <Navigate to="/" />
                      //   :
                      //   <Navigate to="/auth/sign" />
                    } />
                  </Routes>
                </Fragment>
              )
          }
        </Fragment>
      )}
    </>
  );
};

export default GlobalRoutes;

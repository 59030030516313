import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Add, Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs as MuiBreadcrumbs, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider as MuiDivider, FormControlLabel, FormGroup, IconButton, Paper, Tab, Tabs, TextField, Typography, Select, MenuItem, FormControl, InputLabel, InputAdornment, Tooltip, Table, TableRow, TableHead, TableBody,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Link, Navigate, NavLink, useNavigate, useSearchParams } from "react-router-dom";

// Import image 
import PrimaryLogo from "../../assets/primary-logo.png";
import SecondaryLogo2 from "../../assets/secondary2-logo.png";
import Agent from "../../assets/agent1.png";
import Mail from "../../assets/mail.png";
import LifeInsuranceChip from "../../assets/life-insurance-chip.png";
import AnnuityInsuranceChip from "../../assets/annuity-insurance-chip.png";
import BackIcon from "../../assets/back-icon.png";
import CheckedIcon from "../../assets/checked.png";
import UnCheckedIcon from "../../assets/Uncheck.png";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RestorePageRoundedIcon from '@mui/icons-material/RestorePageRounded';
import deleteAlert from '../../assets/Delete-alert.png'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import GlobalDialog from "../../components/global/GlobalDialog";

import "../css/Agent.css";

// Context Import
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as agentContext } from "../../contexts/reducerContexts/agentContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";


// API Import 
import { GetCarrierDataList, GetCarrierLevelList, GetTemplateData, GetTemplateDataList } from "../../utils/api/TemplateApi";
import CarriersPopup from "../../components/global/CarriersPopup";
import GlobalCarrierTable from "../../components/global/GlobalCarrierTable";
import { AddSecondaryAgentDataApi, AssignTemplateToAgentApi, CreateNewAgentApi, DetachTemplateToAgentApi, GetAgentData, ResendExpiredNdaAgentApi, ResendNdaAgentApi, SendNewNdaAgentApi, UpdateAgentDataApi, UpdateUserDataApi } from "../../utils/api/AgentApi";
import { GrantDelegateAccessForAdmin } from "../../utils/api/userDetailApi";

const Divider = styled(MuiDivider)(spacing);

const AddNewAgents = () => {
  //Auth context
  const { FlagApiLoader, setFlagCarrierList, UpdateSnackbarMessage } = useContext(authContext);
  const { userDetail, delegateAccessFlag } = useContext(authContext).state

  //Agent Context
  const { setFlagAgent, setAgentUserData, setAnnuityTemplateList, setLifeTemplateList } = useContext(agentContext);
  const { flagAgent, agentUserData, lifeTemplateList, annuityTemplateList } = useContext(agentContext).state;

  const { setFlagType, setGlobalCarrierData, setGlobalLifeCarrierData, setGlobalAnnuityCarrierData, setCarrierLevelData, setCarrierAnnuityLevelData, setTemplateName, setTemplateId, setCarrierLifeLevelData } = useContext(globalCarrierContext);
  const { carrierLevelData, flagType, templateName, templateId, globalCarrierData, globalLifeCarrierData, globalAnnuityCarrierData, carrierAnnuityLevelData } = useContext(globalCarrierContext).state;

  // State variable
  const [flagShowBottomButtons, setFlagShowBottomButtons] = useState(false);
  const [editFlag, setEditFlag] = useState(false)
  const [selectedLifeTemplate, setSelectedLifeTemplate] = useState(null);
  const [selectedAnnuityTemplate, setSelectedAnnuityTemplate] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");
  const [flagAddPage, setFlagAddPage] = useState(true);
  const [flagNewAgentPage, setFlagNewAgentPage] = useState(false);
  const [deletedCarrierId, setDeletedCarrierId] = useState([])
  const [flagAccordianExpanded, setFlagAccordianExpanded] = useState(false);
  const [flagResendNdaPopup, setFlagResendNdaPopup] = useState({
    flag: false,
    type: "",
    expiredFlag: false
  });
  const [flagNdaInfoPopup, setFlagNdaInfoPopup] = useState(false);
  const [flagEditCarrierPopup, setFlagEditCarrierPopup] = useState(false);
  const [editClearFlag, setEditClearFlag] = useState("");
  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [clearButtonVisible, setClearEditButtonVisible] = useState(false);
  const [agentId, setAgentId] = useState(null);

  const [primaryUsername, setPrimaryUsername] = useState("");
  const [flagSecondaryDetailsAlert, setFlagSecondaryDetailsAlert] = useState(false);

  const [ndaStatus, setNdaStatus] = useState("");
  const [agentFieldOldValue, setAgentFieldOldValue] = useState("");
  const [flagShowNdaSecondary, setFlagShowNdaSecondary] = useState(false);
  const [flagShowRemindIcon, setFlagShowRemindIcon] = useState({
    primary: false,
    secondary: false
  });
  const [flagShowExpiredIcon, setFlagShowExpiredIcon] = useState({
    primary: false,
    secondary: false
  });
  const [openChangeTemplatePopup, setOpenChangeTemplatePopup] = useState({
    flag: false,
    type: "",
    newValue: {}
  });
  const [flagEmailHelperText, setFlagEmailHelperText] = useState({
    primary: false,
    secondary: false
  });
  const [customNdaStatus, setCustomNdaStatus] = useState({
    type: "",
    message: ""
  });

  const [flagTextFieldFocus, setFlagTextFieldFocus] = useState({
    primary: {
      username: false,
      email_address: false,
      address: false,
      state: false,
      country: false,
      zip_code: false,
      sso_email: false
    },
    secondary: {
      username: false,
      email_address: false,
      address: false,
      state: false,
      country: false,
      zip_code: false,
      sso_email: false
    }
  })

  const [oldUserDetails, setOldUserDetails] = useState({
    primary: {
      username: "",
      address: "",
      state: "",
      country: "",
      zip_code: "",
      sso_email: ""
    },
    secondary: {
      username: "",
      address: "",
      state: "",
      country: "",
      zip_code: "",
      sso_email: ""
    }
  })

  const [flagDisableUserDetails, setFlagDisableUserDetails] = useState({
    primary: true,
    secondary: true
  })

  // const [tempPreviousUserValue, setTempPreviousUserValue] = useState()

  // navigation
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  // console.log("+++selectedLifeTemplate", selectedLifeTemplate)
  // console.log("====globalLifeCarrierData", globalLifeCarrierData)
  // console.log("====globalAnnuityCarrierData", globalAnnuityCarrierData)
  // console.log("?????lifeTemplateList", lifeTemplateList)
  // console.log("?????selectedLifeTemplate", selectedLifeTemplate)
  // console.log("============agentUserData", agentUserData)
  // console.log("============oldUserDetails", oldUserDetails)

  // UseEffect for get life and Annuity Template details
  useEffect(() => {
    (async () => {
      FlagApiLoader(true)
      let templateIdExists = searchParams.has("id");
      await GetLifeAndAnnuityTemplate()
      if (templateIdExists === true) {
        let searchParamsAgentId = searchParams.get("id");
        if (searchParamsAgentId !== null) {
          setAgentId(searchParamsAgentId)
          setFlagAddPage(false);
          setFlagNewAgentPage(false);
          setFlagAccordianExpanded(false);
          GetAgentData(searchParamsAgentId, (res) => {
            if (res?.data?.status) {
              console.log("response true of GetAgentData api", res);
              let tempAgentData = { ...agentUserData }
              let tempChangedDetails = { ...oldUserDetails }
              let tempFlagRemind = { ...flagShowRemindIcon }
              let tempFlagExpired = { ...flagShowExpiredIcon }
              let flagPrimaryRemind;
              let flagSecondaryRemind;
              let flagPrimaryExpired = false;
              let flagSecondaryExpired = false;
              res?.data?.data[0].agent_user_data?.map((row) => {
                if (row.primary_data_flag) {
                  tempAgentData['primary']['primary_data_flag'] = true
                  tempAgentData['primary']['user_id'] = row?.user_id
                  tempAgentData['primary']['username'] = row?.username
                  tempAgentData['primary']['email_address'] = row?.email_address
                  tempAgentData['primary']['address'] = row?.address
                  tempAgentData['primary']['state'] = row?.state
                  tempAgentData['primary']['country'] = row?.country
                  tempAgentData['primary']['zip_code'] = row?.zip_code
                  tempAgentData['primary']['sso_email'] = row?.sso_email
                  tempAgentData['primary']['nda_status'] = row?.user_nda_log
                  tempAgentData['primary']['created_by'] = row?.created_by_user_email_address

                  tempChangedDetails['primary']['username'] = row?.username
                  tempChangedDetails['primary']['address'] = row?.address
                  tempChangedDetails['primary']['state'] = row?.state
                  tempChangedDetails['primary']['country'] = row?.country
                  tempChangedDetails['primary']['zip_code'] = row?.zip_code
                  tempChangedDetails['primary']['sso_email'] = row?.sso_email
                  setPrimaryUsername(row?.username)

                  flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagPrimaryExpired = row?.nda_expire_flag == true ? true : false

                }
                else {
                  if (row?.username) {
                    setFlagShowNdaSecondary(true)
                  } else {
                    setFlagShowNdaSecondary(false)
                  }
                  tempAgentData['secondary']['primary_data_flag'] = false
                  tempAgentData['secondary']['user_id'] = row?.user_id
                  tempAgentData['secondary']['username'] = row?.username
                  tempAgentData['secondary']['email_address'] = row?.email_address
                  tempAgentData['secondary']['address'] = row?.address
                  tempAgentData['secondary']['state'] = row?.state
                  tempAgentData['secondary']['country'] = row?.country
                  tempAgentData['secondary']['zip_code'] = row?.zip_code
                  tempAgentData['secondary']['sso_email'] = row?.sso_email
                  tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                  tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address

                  tempChangedDetails['secondary']['username'] = row?.username
                  tempChangedDetails['secondary']['address'] = row?.address
                  tempChangedDetails['secondary']['state'] = row?.state
                  tempChangedDetails['secondary']['country'] = row?.country
                  tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                  tempChangedDetails['secondary']['sso_email'] = row?.sso_email

                  flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                  flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
                }
              })

              tempFlagRemind.primary = flagPrimaryRemind
              tempFlagRemind.secondary = flagSecondaryRemind
              tempFlagExpired.primary = flagPrimaryExpired
              tempFlagExpired.secondary = flagSecondaryExpired
              setFlagShowRemindIcon(tempFlagRemind)
              setFlagShowExpiredIcon(tempFlagExpired)

              let carrierIds = ''
              res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
                if (commissionRow?.carrier_type_flag == true) {
                  tempAgentData.carrier_type_life = true
                  if (commissionRow?.template_assign_flag == true) {
                    setSelectedLifeTemplate({
                      carrier_type_flag: true,
                      template_id: commissionRow?.template_id,
                      template_name: commissionRow?.template_name
                    })
                  }
                  else {
                    setSelectedLifeTemplate(null)
                  }
                }
                else if (commissionRow?.carrier_type_flag == false) {
                  tempAgentData.carrier_type_annuity = true
                  if (commissionRow?.template_assign_flag == true) {
                    setSelectedAnnuityTemplate({
                      carrier_type_flag: false,
                      template_id: commissionRow?.template_id,
                      template_name: commissionRow?.template_name
                    })
                  }
                  else {
                    setSelectedAnnuityTemplate(null)
                  }
                }

                let tempInnerArray = []
                if (commissionRow?.template_assign_flag == true) {
                  commissionRow?.template_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]

                    row?.carrier_level_data?.map((element) => {
                      element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                      element.carrier_level_sequence = element?.template_level_sequence
                    })
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }
                else {
                  commissionRow?.agent_carrier_assignment_data?.map((row) => {
                    let obj = {}
                    obj.carrier_name = row?.carrier_name
                    obj.carrier_id = row?.carrier_id
                    obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                    obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                    obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                    obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                    obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                    tempInnerArray.push(obj)
                    carrierIds += `,${row?.carrier_id}`
                  })
                }

                if (commissionRow?.carrier_type_flag == true) {
                  setGlobalLifeCarrierData(tempInnerArray)
                } else {
                  setGlobalAnnuityCarrierData(tempInnerArray)
                }

              })
              carrierIds = carrierIds.slice(1)
              GetCarrierLevelList(carrierIds,
                (res) => {
                  if (res.data.status) {
                    console.log("response true of GetCarrierLevelList api", res);
                    // OLD CODE ********
                    // setCarrierLevelData(res.data.data)
                    // OLD CODE ********

                    // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                    setCarrierLifeLevelData(res.data.data)
                    setCarrierAnnuityLevelData(res.data.data)
                    // if (selectedTab == 'life') {
                    //   setCarrierLevelData(res.data.data)
                    // }
                    // else if (selectedTab == 'annuity') {
                    //   setCarrierAnnuityLevelData(res.data.data)
                    // }
                    // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                  }
                  else {
                    console.log("response false of GetCarrierLevelList api", res);
                    if (res?.data?.error?.message) {
                      UpdateSnackbarMessage({
                        status: "error",
                        message: res?.data?.error?.message
                      });
                      FlagApiLoader(false)
                    }
                    else {
                      UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                      });
                      FlagApiLoader(false)
                    }
                  }
                },
                (err) => {
                  console.log("error of GetCarrierLevelList api", err);
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!",
                  });
                  FlagApiLoader(false)
                })

              setAgentUserData(tempAgentData);
              setOldUserDetails(tempChangedDetails);
              FlagApiLoader(false)
            }
            else {
              console.log("response false of GetAgentData api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
              FlagApiLoader(false)
            }
          },
            (err) => {
              console.log("error of GetAgentData api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            })
        }
      }
      else {
        setFlagNewAgentPage(true)
        setFlagAccordianExpanded(true);
        ClearAgentDataContext()
        // OLD CODE :
        // setCarrierLevelData([])

        // UPDATED CODE 
        setCarrierLifeLevelData([])
        setCarrierAnnuityLevelData([])
      }
    })()
  }, [])

  useEffect(() => {
    let tempStatusData = { ...flagTextFieldFocus }
    let detailKeys = ["username", "email_address", "address", "state", "country", "zip_code", "sso_email"]
    for (let i = 0; i < detailKeys?.length; i++) {
      if (agentUserData?.primary[detailKeys[i]]) {
        tempStatusData.primary[detailKeys[i]] = true
      } else {
        tempStatusData.primary[detailKeys[i]] = false
      }
      if (agentUserData?.secondary[detailKeys[i]]) {
        tempStatusData.secondary[detailKeys[i]] = true
      } else {
        tempStatusData.secondary[detailKeys[i]] = false
      }
    }
    setFlagTextFieldFocus(tempStatusData)
  }, [agentUserData])

  useEffect(() => {
    if ((selectedTab == "annuity" && selectedAnnuityTemplate != null) || (selectedTab == "life" && selectedLifeTemplate != null)) {
      setEditButtonVisible(true)
    } else {
      setEditButtonVisible(false)
    }
    if ((selectedTab == "annuity" && globalAnnuityCarrierData?.length && selectedAnnuityTemplate == null) ||
      (selectedTab == "life" && globalLifeCarrierData?.length && selectedLifeTemplate == null)) {
      setClearEditButtonVisible(true)
    } else {
      setClearEditButtonVisible(false)
    }
  }, [selectedTab, selectedLifeTemplate, selectedAnnuityTemplate, globalLifeCarrierData, globalAnnuityCarrierData])

  useEffect(() => {
    if (selectedTab == "life") {
      setFlagType(true)
    }
    if (selectedTab == "annuity") {
      setFlagType(false)
    }
  }, [selectedTab])

  useEffect(() => {
    if (selectedTab == "life") {
      if (globalLifeCarrierData?.length > 0) {
        setFlagShowBottomButtons(true)
      } else {
        setFlagShowBottomButtons(false)
      }
    } else if (selectedTab == "annuity") {
      if (globalAnnuityCarrierData?.length > 0) {
        setFlagShowBottomButtons(true)
      } else {
        setFlagShowBottomButtons(false)
      }
    }
  }, [globalLifeCarrierData, globalAnnuityCarrierData, selectedTab])

  useEffect(() => {
    if (selectedTab == "life") {
      if (selectedLifeTemplate == null) {
        setEditFlag(true)
      } else {
        setEditFlag(false)
      }
    } else if (selectedTab == "annuity") {
      if (selectedAnnuityTemplate == null) {
        setEditFlag(true)
      } else {
        setEditFlag(false)
      }
    }
  }, [selectedLifeTemplate, selectedAnnuityTemplate, selectedTab])

  // UseEffect for get life and Annuity Template details
  useEffect(() => {
    if (selectedTab == 'life') {
      setSelectedTab("life");
    }
    else if (selectedTab == 'annuity') {
      setSelectedTab("annuity");
    }
    else {
      if (agentUserData.carrier_type_life) {
        setSelectedTab("life");
      } else if (agentUserData.carrier_type_annuity) {
        setSelectedTab("annuity");
      } else {
        setSelectedTab("");
      }
    }
  }, [agentUserData])

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))`
  & .MuiTooltip-tooltip {
    background-color: #1565C0;
    color: #FFFF;
  }
`;

  const TextFieldFocusStatus = (type, field) => {
    let tempStatus = { ...flagTextFieldFocus }
    tempStatus[type][field] = true
    setFlagTextFieldFocus(tempStatus)
  }

  const TextFieldBlurStatus = (type, field) => {
    let dataLength = agentUserData[type][field]?.length
    let tempStatus = { ...flagTextFieldFocus }
    if (dataLength) {
      tempStatus[type][field] = true
    } else {
      tempStatus[type][field] = false
    }
    setFlagTextFieldFocus(tempStatus)
  }

  const RemindNdaPopup = () => {
    return (
      <>
        <Grid item xs={12} >
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => {
                setFlagResendNdaPopup({
                  flag: false,
                  type: "",
                  expiredFlag: false
                })
              }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              {flagResendNdaPopup.expiredFlag == true ? "Are you sure you want to resend a new Non-Disclosure Agreement?" : "Are you sure you want to remind NDA?"}
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        FlagApiLoader(true)
                        if (flagResendNdaPopup.expiredFlag == true) {
                          let data = {
                            agent_user_id: flagResendNdaPopup.type == "primary" ?
                              agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                          }
                          SendNewNdaAgentApi(data, (res) => {
                            if (res?.data?.status) {
                              console.log("response true of SendNewNdaAgentApi api", res);
                              let tempOldNda = { ...flagShowExpiredIcon }
                              if (flagResendNdaPopup.type == "primary") {
                                tempOldNda.primary = false
                              } else if (flagResendNdaPopup.type == "secondary") {
                                tempOldNda.secondary = false
                              }
                              // setFlagShowRemindIcon(tempOldNda);
                              setFlagShowExpiredIcon(tempOldNda)
                              setCustomNdaStatus({
                                type: flagResendNdaPopup.type,
                                message: `Sent at ${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : "0" + new Date().getMonth() + 1}-${new Date().getDate() > 9 ? new Date().getDate() : "0" + new Date().getDate()} ${new Date().getHours() > 9 ? new Date().getHours() : "0" + new Date().getHours()}:${new Date().getMinutes() > 9 ? new Date().getMinutes() : "0" + new Date().getMinutes()}:${new Date().getSeconds() > 9 ? new Date().getSeconds() : "0" + new Date().getSeconds()}`
                              })
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "success",
                                message: res?.data?.data?.message
                              });
                              FlagApiLoader(false)
                            }
                            else {
                              console.log("response false of SendNewNdaAgentApi api", res);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              if (res?.data?.error?.message) {
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res.data?.error?.message
                                });
                              }
                              else {
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: "Something Went Wrong!"
                                });
                              }
                              FlagApiLoader(false)
                            }
                          },
                            (err) => {
                              console.log("error response of SendNewNdaAgentApi", err);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                        } else if (flagResendNdaPopup.expiredFlag == false) {
                          let data = {
                            user_id: flagResendNdaPopup.type == "primary" ?
                              agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                          }
                          ResendNdaAgentApi(data,
                            (res) => {
                              if (res?.data?.status) {
                                console.log("response true of ResendNdaAgentAPI", res);
                                setCustomNdaStatus({
                                  type: "",
                                  message: ""
                                })
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "success",
                                  message: res?.data?.data?.message
                                });
                                FlagApiLoader(false)
                              }
                              else {
                                console.log("response false of ResendNdaAgentAPI", res);
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                if (res?.data?.error?.message) {
                                  UpdateSnackbarMessage({
                                    status: "error",
                                    message: res?.data?.error?.message
                                  });
                                }
                                else {
                                  UpdateSnackbarMessage({
                                    status: "error",
                                    message: "Something Went Wrong!"
                                  });
                                }
                                FlagApiLoader(false)
                              }
                            },
                            (err) => {
                              console.log("error of ResendNdaAgent API", err);
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                        }
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagResendNdaPopup({
                          flag: false,
                          type: "",
                          expiredFlag: false
                        })
                      }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTONS CONTAINER */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setFlagResendNdaPopup({
                            flag: false,
                            type: "",
                            expiredFlag: false
                          })
                        }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          FlagApiLoader(true)
                          if (flagResendNdaPopup.expiredFlag == true) {
                            let data = {
                              agent_user_id: flagResendNdaPopup.type == "primary" ?
                                agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                            }
                            SendNewNdaAgentApi(data, (res) => {
                              if (res?.data?.status) {
                                let tempOldNda = { ...flagShowExpiredIcon }
                                if (flagResendNdaPopup.type == "primary") {
                                  tempOldNda.primary = false
                                } else if (flagResendNdaPopup.type == "secondary") {
                                  tempOldNda.secondary = false
                                }
                                // setFlagShowRemindIcon(tempOldNda);
                                setFlagShowExpiredIcon(tempOldNda)
                                setCustomNdaStatus({
                                  type: flagResendNdaPopup.type,
                                  message: `Sent at ${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : "0" + new Date().getMonth() + 1}-${new Date().getDate() > 9 ? new Date().getDate() : "0" + new Date().getDate()} ${new Date().getHours() > 9 ? new Date().getHours() : "0" + new Date().getHours()}:${new Date().getMinutes() > 9 ? new Date().getMinutes() : "0" + new Date().getMinutes()}:${new Date().getSeconds() > 9 ? new Date().getSeconds() : "0" + new Date().getSeconds()}`
                                })
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "success",
                                  message: res?.data?.data?.message
                                });
                                FlagApiLoader(false)
                              } else {
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res.data?.error?.message
                                });
                                FlagApiLoader(false)
                              }
                            }, (err) => {
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                          } else if (flagResendNdaPopup.expiredFlag == false) {
                            let data = {
                              user_id: flagResendNdaPopup.type == "primary" ?
                                agentUserData?.primary?.user_id : flagResendNdaPopup.type == "secondary" ? agentUserData?.secondary?.user_id : ""
                            }
                            ResendNdaAgentApi(data, (res) => {
                              if (res?.data?.status) {
                                setCustomNdaStatus({
                                  type: "",
                                  message: ""
                                })
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "success",
                                  message: res?.data?.data?.message
                                });
                                FlagApiLoader(false)
                              } else {
                                setFlagResendNdaPopup({
                                  flag: false,
                                  type: "",
                                  expiredFlag: false
                                })
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: res.data?.error?.message
                                });
                                FlagApiLoader(false)
                              }
                            }, (err) => {
                              setFlagResendNdaPopup({
                                flag: false,
                                type: "",
                                expiredFlag: false
                              })
                              UpdateSnackbarMessage({
                                status: "error",
                                message: "Something went wrong!"
                              });
                              FlagApiLoader(false)
                            })
                          }
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </>
    )
  }

  const NdaInfoPopup = () => {
    return (
      <Grid container className="status-popup">
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11} textAlign="center">
              <Typography sx={{ fontWeight: "600", fontSize: "15px", color: "#1565C0", padding: "16px 10px" }}>NDA Status Log</Typography>
            </Grid>
            <Grid item xs={1} textAlign={"center"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagNdaInfoPopup(false) }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container flexDirection="column" alignItems="center" rowGap={4}>
            <Grid item>
              <Table
                className="templateCarrierTable"
                aria-label="a dense table"
                size="small"
                sx={{ borderRadius: "10px" }}
              >
                <TableHead>
                  <TableRow>

                    <TableCell
                      className="header-color"
                      sx={{ minWidth: "200px", borderRadius: "10px 0px 0px 0px" }}
                    >
                      Activity
                    </TableCell>


                    <TableCell
                      className="header-color"
                      align="left"
                      nowrap
                      sx={{ minWidth: "200px", borderRadius: "0px 10px 0px 0px" }}
                    >
                      Date & Time
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {(ndaStatus == "primary" ? agentUserData?.primary?.nda_status : ndaStatus == "secondary" ? agentUserData?.secondary?.nda_status : [])?.map((row, i) => {
                    return (
                      <>
                        <TableRow sx={{ backgroundColor: "#EFEFF0", }} >
                          <TableCell
                            stickyHeader
                          >{row?.nda_activity}</TableCell>
                          <TableCell align="left" stickyHeader>{ConvertDateTimeFunction(row?.nda_activity_timestamp)}</TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
            <Grid item padding="10px 10px 15px 10px" >
              <Button
                variant="contained"
                sx={{ marginBottom: "10px", paddingX: "40px", paddingY: "8px", backgroundColor: "#1565C0" }}
                onClick={() => setFlagNdaInfoPopup(false)}
              >Close
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    )
  }

  const SecondaryDetailAlert = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container justifyContent="center" className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagSecondaryDetailsAlert(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to add a secondary agent?
            </Grid>
            <Grid item xs={8} color="#00345E" textAlign="center" className="popup-note">
              Note: The primary agent's access will be suspended until the secondary agent signs the Non-Disclosure Agreement.
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        FlagApiLoader(true)
                        setFlagSecondaryDetailsAlert(false)
                        AddSecondaryData()
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagSecondaryDetailsAlert(false) }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
                {/* OLD BUTTON CODE */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => { setFlagSecondaryDetailsAlert(false) }}
                      >No
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          FlagApiLoader(true)
                          setFlagSecondaryDetailsAlert(false)
                          AddSecondaryData()
                        }}
                      >Yes</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const GetTemplateDataFunction = (templateId) => {
    FlagApiLoader(true);
    GetTemplateData(
      {
        template_id: templateId,
        template_version_id: null
      },
      (res) => {
        if (res.data.status === true) {
          console.log("response true of GetTemplateData api", res);
          setFlagType(res?.data?.data[0]?.carrier_type_flag)
          // setTemplateName(res?.data?.data[0]?.template_name)
          // setTemplateId(res?.data?.data[0]?.template_id)

          let tempInnerArray = []
          res?.data?.data[0]?.template_data?.map((row) => {
            // setAssignmentId([...assignmentId, row?.template_carrier_assignment_id])
            let obj = {}
            obj.carrier_name = row?.carrier_name
            obj.carrier_id = row?.carrier_id
            obj.template_carrier_assignment_id = row?.template_carrier_assignment_id
            obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: res?.data?.data[0]?.carrier_type_flag }]
            obj.baLevel = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == true)[0]
            obj.directCarrier = row?.carrier_level_data.filter(gg => gg?.carrier_direct_level_flag == true)[0]
            obj.carrier_level_data = row?.carrier_level_data.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
            tempInnerArray.push(obj)
          })
          if (res?.data?.data[0]?.carrier_type_flag) {
            setGlobalLifeCarrierData(tempInnerArray);

          } else {
            setGlobalAnnuityCarrierData(tempInnerArray);
          }

          let carrierIds = ''
          res?.data?.data[0]?.template_data?.map((ele) => {
            carrierIds += `,${ele?.carrier_id}`
          })
          carrierIds = carrierIds.slice(1)
          GetCarrierLevelList(carrierIds,
            (res) => {
              if (res.data.status) {
                setFlagCarrierList(true);
                setFlagAddPage(false)
                // OLD CODE ********
                // setCarrierLevelData(res.data.data)
                // OLD CODE ********

                // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                if (selectedTab == "life") {
                  setCarrierLifeLevelData(res.data.data)
                }
                else if (selectedTab == "annuity") {
                  setCarrierAnnuityLevelData(res.data.data)
                }
                // // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
              }
              FlagApiLoader(false);
            },
            (err) => {
              FlagApiLoader(false);
              console.log(err);
            })
          FlagApiLoader(false);

        }
        else {
          console.log("response false of GetTemplateData api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
          FlagApiLoader(false);
        }
      },
      (err) => {
        console.log("error of GetTemplateData api", err);
        FlagApiLoader(false);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!"
        });
      }
    );
  }

  // console.log("carrierAnnuityLevelData->>>>", carrierAnnuityLevelData);
  // console.log("carrierAnnuityLevelData1->>>>", carrierLevelData);

  const AssignTemplateFunction = (templateId) => {
    if (flagNewAgentPage == false) {
      let payload = {
        agent_id: agentId,
        template_id: templateId,
        carrier_type_flag: selectedTab == "life" ? true : selectedTab == "annuity" ? false : null
      }
      AssignTemplateToAgentApi(payload,
        (res) => {
          if (res?.data?.status) {
            console.log("response true of AssignTemplateToAgentApi api", res);
            UpdateSnackbarMessage({
              status: "success",
              message: res.data?.data?.message
            });
            GetTemplateDataFunction(templateId)
          }
          else {
            console.log("response false of AssignTemplateToAgentApi api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
          }
          setEditFlag(false)
        },
        (err) => {
          console.log("error of AssignTemplateToAgentApi api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!"
          });
        })
    }

  }

  const DetachTemplateFunction = (carrierTypeFlag) => {
    let payload = {
      agent_id: agentId,
      carrier_type_flag: carrierTypeFlag
    }
    let searchParamsAgentId = searchParams.get("id");
    DetachTemplateToAgentApi(payload,
      (res) => {
        if (res?.data?.status) {
          GetAgentData(searchParamsAgentId,
            (res) => {
              if (res?.data?.status) {
                console.log("response true of GetAgentData api", res);
                let tempAgentData = { ...agentUserData }
                let tempChangedDetails = { ...oldUserDetails }
                let tempFlagRemind = { ...flagShowRemindIcon }
                let tempFlagExpired = { ...flagShowExpiredIcon }
                let flagPrimaryRemind;
                let flagSecondaryRemind;
                let flagPrimaryExpired = false;
                let flagSecondaryExpired = false;
                res?.data?.data[0].agent_user_data?.map((row) => {
                  if (row.primary_data_flag) {
                    tempAgentData['primary']['primary_data_flag'] = true
                    tempAgentData['primary']['user_id'] = row?.user_id
                    tempAgentData['primary']['username'] = row?.username
                    tempAgentData['primary']['email_address'] = row?.email_address
                    tempAgentData['primary']['address'] = row?.address
                    tempAgentData['primary']['state'] = row?.state
                    tempAgentData['primary']['country'] = row?.country
                    tempAgentData['primary']['zip_code'] = row?.zip_code
                    tempAgentData['primary']['sso_email'] = row?.sso_email
                    tempAgentData['primary']['nda_status'] = row?.user_nda_log
                    tempAgentData['primary']['created_by'] = row?.created_by_user_email_address

                    tempChangedDetails['primary']['username'] = row?.username
                    tempChangedDetails['primary']['address'] = row?.address
                    tempChangedDetails['primary']['state'] = row?.state
                    tempChangedDetails['primary']['country'] = row?.country
                    tempChangedDetails['primary']['zip_code'] = row?.zip_code
                    tempChangedDetails['primary']['sso_email'] = row?.sso_email
                    setPrimaryUsername(row?.username)

                    flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                    flagPrimaryExpired = row?.nda_expire_flag == true ? true : false

                  } else {
                    if (row?.username) {
                      setFlagShowNdaSecondary(true)
                    } else {
                      setFlagShowNdaSecondary(false)
                    }
                    tempAgentData['secondary']['primary_data_flag'] = false
                    tempAgentData['secondary']['user_id'] = row?.user_id
                    tempAgentData['secondary']['username'] = row?.username
                    tempAgentData['secondary']['email_address'] = row?.email_address
                    tempAgentData['secondary']['address'] = row?.address
                    tempAgentData['secondary']['state'] = row?.state
                    tempAgentData['secondary']['country'] = row?.country
                    tempAgentData['secondary']['zip_code'] = row?.zip_code
                    tempAgentData['secondary']['sso_email'] = row?.sso_email
                    tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                    tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address

                    tempChangedDetails['secondary']['username'] = row?.username
                    tempChangedDetails['secondary']['address'] = row?.address
                    tempChangedDetails['secondary']['state'] = row?.state
                    tempChangedDetails['secondary']['country'] = row?.country
                    tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                    tempChangedDetails['secondary']['sso_email'] = row?.sso_email

                    flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                    flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
                  }
                })

                tempFlagRemind.primary = flagPrimaryRemind
                tempFlagRemind.secondary = flagSecondaryRemind
                tempFlagExpired.primary = flagPrimaryExpired
                tempFlagExpired.secondary = flagSecondaryExpired
                setFlagShowRemindIcon(tempFlagRemind)
                setFlagShowExpiredIcon(tempFlagExpired)

                let carrierIds = ''
                res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
                  if (commissionRow?.carrier_type_flag == true) {
                    tempAgentData.carrier_type_life = true
                    if (commissionRow?.template_assign_flag == true) {
                      setSelectedLifeTemplate({
                        carrier_type_flag: true,
                        template_id: commissionRow?.template_id,
                        template_name: commissionRow?.template_name
                      })
                    } else {
                      setSelectedLifeTemplate(null)
                    }
                  } else if (commissionRow?.carrier_type_flag == false) {
                    tempAgentData.carrier_type_annuity = true
                    if (commissionRow?.template_assign_flag == true) {
                      setSelectedAnnuityTemplate({
                        carrier_type_flag: false,
                        template_id: commissionRow?.template_id,
                        template_name: commissionRow?.template_name
                      })
                    } else {
                      setSelectedAnnuityTemplate(null)
                    }
                  }

                  let tempInnerArray = []
                  if (commissionRow?.template_assign_flag == true) {
                    commissionRow?.template_data?.map((row) => {
                      let obj = {}
                      obj.carrier_name = row?.carrier_name
                      obj.carrier_id = row?.carrier_id
                      obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                      obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]

                      row?.carrier_level_data?.map((element) => {
                        element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                        element.carrier_level_sequence = element?.template_level_sequence
                      })
                      obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                      obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                      obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                      tempInnerArray.push(obj)
                      carrierIds += `,${row?.carrier_id}`
                    })
                  } else {
                    commissionRow?.agent_carrier_assignment_data?.map((row) => {
                      let obj = {}
                      obj.carrier_name = row?.carrier_name
                      obj.carrier_id = row?.carrier_id
                      obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                      obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                      obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                      obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                      obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                      tempInnerArray.push(obj)
                      carrierIds += `,${row?.carrier_id}`
                    })
                  }
                  if (commissionRow?.carrier_type_flag == true) {
                    setGlobalLifeCarrierData(tempInnerArray)
                  } else {
                    setGlobalAnnuityCarrierData(tempInnerArray)
                  }

                })
                carrierIds = carrierIds.slice(1)
                GetCarrierLevelList(carrierIds,
                  (res) => {
                    if (res.data.status) {
                      // OLD CODE ********
                      // setCarrierLevelData(res.data.data)
                      // OLD CODE ********

                      // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                      if (selectedTab == "life") {
                        setCarrierLifeLevelData(res.data.data)
                      }
                      else if (selectedTab == "annuity") {
                        setCarrierAnnuityLevelData(res.data.data)
                      }
                      // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                    }
                    else {
                      console.log("response false of GetCarrierLevelList api", res);
                      if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: res?.data?.error?.message
                        });
                      }
                      else {
                        UpdateSnackbarMessage({
                          status: "error",
                          message: "Something Went Wrong!"
                        });
                      }
                    }
                  },
                  (err) => {
                    console.log("response error of GetTemplateData api", err);
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!"
                    });
                    FlagApiLoader(false)
                  })
                setAgentUserData(tempAgentData);
                setOldUserDetails(tempChangedDetails);
                FlagApiLoader(false)
              }
              else {
                FlagApiLoader(false)
                console.log("response false of GetAgentData api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
              }
            },
            (err) => {
              console.log("error of GetCarrierLevelList api", err);
              FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!",
              });
            })
          if (carrierTypeFlag) {
            setSelectedLifeTemplate(null)
          } else {
            setSelectedAnnuityTemplate(null)
          }
          FlagApiLoader(false)
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });
        }
        else {
          FlagApiLoader(false)
          console.log("response false of DetachTemplateToAgentApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of DetachTemplateToAgentApi api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
        FlagApiLoader(false)
      })
  }

  const ChangeLifeTemplate = (e) => {
    if (flagNewAgentPage) {
      if (selectedLifeTemplate) {
        setOpenChangeTemplatePopup({
          flag: true,
          type: "life",
          newValue: e.target.value
        })
      } else {
        setSelectedLifeTemplate(e.target.value)
        GetTemplateDataFunction(e.target.value.template_id)
      }
    } else {
      setOpenChangeTemplatePopup({
        flag: true,
        type: "life",
        newValue: e.target.value
      })
    }
  }


  const ChangeAnnuityTemplate = (e) => {
    if (flagNewAgentPage) {
      if (selectedAnnuityTemplate) {
        setOpenChangeTemplatePopup({
          flag: true,
          type: "annuity",
          newValue: e.target.value
        })
      } else {
        setSelectedAnnuityTemplate(e.target.value)
        GetTemplateDataFunction(e.target.value.template_id)
      }
    } else {
      setOpenChangeTemplatePopup({
        flag: true,
        type: "annuity",
        newValue: e.target.value
      })
    }

  }

  const ClearAgentDataContext = () => {
    setAgentUserData({
      primary: {
        "primary_data_flag": true,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      secondary: {
        "primary_data_flag": false,
        "user_id": "",
        "username": "",
        "email_address": "",
        "nda_status": []
      },
      carrier_type_life: false,
      carrier_type_annuity: false
    })
    FlagApiLoader(false)
  }

  const GetLifeAndAnnuityTemplate = async () => {

    await GetTemplateDataList({},
      (res) => {
        if (res.data.status) {
          console.log("response true of GetTemplateDataList api", res);
          var data = res.data.data;
          var lifeTemplateDataList = data.filter(rr => rr.carrier_type_flag == true)
          var annuityTemplateDataList = data.filter(rr => rr.carrier_type_flag == false)
          lifeTemplateDataList = lifeTemplateDataList.length ? lifeTemplateDataList : [{}]
          annuityTemplateDataList = annuityTemplateDataList.length ? annuityTemplateDataList : [{}]
          setLifeTemplateList(lifeTemplateDataList)
          setAnnuityTemplateList(annuityTemplateDataList)
        }
        else {
          console.log("response false of GetTemplateDataList api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateDataList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!",
        });
      })
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const OpenEditCarrierPopup = (btnFlag) => {
    if (btnFlag == "editCarrier") {
      setFlagEditCarrierPopup(true)
      setEditClearFlag("editCarrier")
    }
    if (btnFlag == "clearAll") {
      setFlagEditCarrierPopup(true)
      setEditClearFlag("clearAll")
    }
  }

  const EditCarrierPopup = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagEditCarrierPopup(false) }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to edit carriers?
            </Grid>
            <Grid item xs={12} color="#00345E" textAlign="center" className="popup-note">
              {editClearFlag == "clearAll" ?
                <>
                  Note: All carriers will be removed
                </>
                : editClearFlag == "editCarrier" ?
                  <>
                    Note: {selectedTab == "annuity" ? selectedAnnuityTemplate?.template_name : selectedTab == "life" ? selectedLifeTemplate?.template_name : ""} template assignment will be removed.
                  </>
                  : ""}
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setFlagEditCarrierPopup(false)
                        FlagApiLoader(true)
                        if (editClearFlag == "editCarrier") {
                          setEditFlag(true)
                          setEditButtonVisible(false)
                          if (selectedTab == "life") {
                            if (flagNewAgentPage == false) {
                              DetachTemplateFunction(true)
                            } else {
                              setSelectedLifeTemplate(null)
                              FlagApiLoader(false)
                            }
                          } else if (selectedTab == "annuity") {
                            if (flagNewAgentPage == false) {
                              DetachTemplateFunction(false)
                            } else {
                              setSelectedAnnuityTemplate(null)
                              FlagApiLoader(false)
                            }
                          }
                        } else if (editClearFlag == "clearAll") {
                          let tempArray = []
                          if (selectedTab == "life") {
                            let tempLife = [...globalLifeCarrierData]
                            tempLife?.map((obj) => {
                              if (obj?.agent_commission_carrier_assignment_id) {
                                tempArray.push(obj.agent_commission_carrier_assignment_id)
                              }
                            })
                            setDeletedCarrierId(tempArray)
                            setGlobalLifeCarrierData([])
                          } else if (selectedTab == "annuity") {
                            let tempLife = [...globalAnnuityCarrierData]
                            tempLife?.map((obj) => {
                              if (obj?.agent_commission_carrier_assignment_id) {
                                tempArray.push(obj.agent_commission_carrier_assignment_id)
                              }
                            })
                            setDeletedCarrierId(tempArray)
                            setGlobalAnnuityCarrierData([])
                          }
                          setClearEditButtonVisible(false)
                          FlagApiLoader(false)
                        }
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => { setFlagEditCarrierPopup(false) }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTONS CODE*/}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          setFlagEditCarrierPopup(false)
                          FlagApiLoader(true)
                          if (editClearFlag == "editCarrier") {
                            setEditFlag(true)
                            setEditButtonVisible(false)
                            if (selectedTab == "life") {
                              if (flagNewAgentPage == false) {
                                DetachTemplateFunction(true)
                              } else {
                                setSelectedLifeTemplate(null)
                                FlagApiLoader(false)
                              }
                            } else if (selectedTab == "annuity") {
                              if (flagNewAgentPage == false) {
                                DetachTemplateFunction(false)
                              } else {
                                setSelectedAnnuityTemplate(null)
                                FlagApiLoader(false)
                              }
                            }
                          } else if (editClearFlag == "clearAll") {
                            let tempArray = []
                            if (selectedTab == "life") {
                              let tempLife = [...globalLifeCarrierData]
                              tempLife?.map((obj) => {
                                if (obj?.agent_commission_carrier_assignment_id) {
                                  tempArray.push(obj.agent_commission_carrier_assignment_id)
                                }
                              })
                              setDeletedCarrierId(tempArray)
                              setGlobalLifeCarrierData([])
                            } else if (selectedTab == "annuity") {
                              let tempLife = [...globalAnnuityCarrierData]
                              tempLife?.map((obj) => {
                                if (obj?.agent_commission_carrier_assignment_id) {
                                  tempArray.push(obj.agent_commission_carrier_assignment_id)
                                }
                              })
                              setDeletedCarrierId(tempArray)
                              setGlobalAnnuityCarrierData([])
                            }
                            setClearEditButtonVisible(false)
                            FlagApiLoader(false)
                          }
                        }}
                      >Yes
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => { setFlagEditCarrierPopup(false) }}
                      >No</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </>
    )
  }

  const AssignTemplateAlert = () => {
    return (
      <>
        <Grid item xs={12}>
          <Grid container className="delete-main-container">
            <Grid item xs={11.5} textAlign={"end"}>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => {
                if (selectedLifeTemplate == null) {
                  if (openChangeTemplatePopup.type == "life") {
                    setSelectedLifeTemplate(null)
                  } else {
                    setSelectedAnnuityTemplate(null)
                  }
                }
                setOpenChangeTemplatePopup({
                  flag: false,
                  type: "",
                  newValue: {}
                })
              }} />
            </Grid>
            <Grid item xs={12} className="delete-alert-item">
              <img src={deleteAlert}></img>
            </Grid>
            <Grid item xs={12} className="delete-mesg-item">
              Are you sure you want to change to {openChangeTemplatePopup?.newValue?.template_name} template?
            </Grid>
            <Grid item xs={12} color="#00345E" textAlign="center" className="popup-note">
              <>
                Note: {(selectedTab == "annuity" && selectedAnnuityTemplate?.template_name) ?
                  selectedAnnuityTemplate?.template_name + " Template assignment will be removed." : (selectedTab == "life" && selectedLifeTemplate?.template_name) ?
                    selectedLifeTemplate?.template_name + " Template assignment will be removed." : " All carriers will be removed"}
              </>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="delete-btn-container">
                <Grid item xs={12}>
                  <Grid container className="popup-delete-button-container" >
                    <Button
                      variant="contained"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        let tempFlag = { ...openChangeTemplatePopup }
                        tempFlag.flag = false;
                        setOpenChangeTemplatePopup(tempFlag);
                        FlagApiLoader(true)
                        if (openChangeTemplatePopup.type == "life") {
                          setSelectedLifeTemplate(openChangeTemplatePopup?.newValue)
                        } else if (openChangeTemplatePopup.type == "annuity") {
                          setSelectedAnnuityTemplate(openChangeTemplatePopup?.newValue)
                        }

                        if (flagNewAgentPage) {
                          // setSelectedLifeTemplate(e.target.value)
                          GetTemplateDataFunction(openChangeTemplatePopup?.newValue.template_id)
                        } else {
                          AssignTemplateFunction(openChangeTemplatePopup?.newValue?.template_id)
                        }

                        // GetTemplateDataFunction(openChangeTemplatePopup?.newValue?.template_id)
                      }}
                    >
                      Yes
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: "100px" }}
                      onClick={() => {
                        setOpenChangeTemplatePopup({
                          flag: false,
                          type: "",
                          newValue: {}
                        })
                        if (selectedLifeTemplate == null) {
                          if (openChangeTemplatePopup.type == "life") {
                            setSelectedLifeTemplate(null)
                          } else {
                            setSelectedAnnuityTemplate(null)
                          }
                        }
                      }}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>

                {/* OLD BUTTONS */}
                {/* <Grid item xs={6} >
                  <Grid container className="delete-no-btn-container">
                    <Grid item xs={3} className="delete-no-btn-item">
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          let tempFlag = { ...openChangeTemplatePopup }
                          tempFlag.flag = false;
                          setOpenChangeTemplatePopup(tempFlag);
                          FlagApiLoader(true)
                          if (openChangeTemplatePopup.type == "life") {
                            setSelectedLifeTemplate(openChangeTemplatePopup?.newValue)
                          } else if (openChangeTemplatePopup.type == "annuity") {
                            setSelectedAnnuityTemplate(openChangeTemplatePopup?.newValue)
                          }

                          if (flagNewAgentPage) {
                            // setSelectedLifeTemplate(e.target.value)
                            GetTemplateDataFunction(openChangeTemplatePopup?.newValue.template_id)
                          } else {
                            AssignTemplateFunction(openChangeTemplatePopup?.newValue?.template_id)
                          }

                          // GetTemplateDataFunction(openChangeTemplatePopup?.newValue?.template_id)
                        }}
                      >Yes
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={3} className="delete-yes-btn-item">
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setOpenChangeTemplatePopup({
                            flag: false,
                            type: "",
                            newValue: {}
                          })
                          if (selectedLifeTemplate == null) {
                            if (openChangeTemplatePopup.type == "life") {
                              setSelectedLifeTemplate(null)
                            } else {
                              setSelectedAnnuityTemplate(null)
                            }
                          }
                        }}
                      >No</Button>
                    </Grid>
                  </Grid>
                </Grid> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </>
    )
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const BackIconComponent = () => (
    <img
      src={BackIcon}
      alt="Logo"
      width="28px"
      height="28px"
      text-align="center"
      justify-content="center"
    />
  );

  const UserDetailValidation = () => {
    if (agentUserData?.primary?.username?.length == 0 && agentUserData?.primary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name and email"
      });
      return false;
    }
    if (agentUserData?.primary?.username?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name"
      });
      return false;
    }
    if (agentUserData?.primary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent email"
      });
      return false;
    }
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.primary?.email_address))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid primary agent email"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length !== 0 && agentUserData?.secondary?.email_address?.length == 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter secondary agent email"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length == 0 && agentUserData?.secondary?.email_address?.length !== 0) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter primary agent name"
      });
      return false;
    }
    if (agentUserData?.secondary?.username?.length !== 0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.secondary?.email_address))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid secondary agent email"
      });
      return false;
    }
    if (agentUserData?.primary?.sso_email?.length && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.primary?.sso_email))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid primary sso email"
      });
      return false;
    }
    if (agentUserData?.secondary?.sso_email?.length && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(agentUserData?.secondary?.sso_email))) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please enter valid secondary sso email"
      });
      return false;
    }
    return true;
  }

  const CreateNewAgent = () => {

    let validate = UserDetailValidation()
    if (validate == false) {
      return;
    }
    FlagApiLoader(true)
    let finalPayload = {}
    let userArray = []
    userArray[0] = {
      "primary_data_flag": true,
      "username": agentUserData?.primary?.username,
      "email_address": agentUserData?.primary?.email_address,
      "address": agentUserData?.primary?.address,
      "state": agentUserData?.primary?.state,
      "country": agentUserData?.primary?.country,
      "zip_code": agentUserData?.primary?.zip_code,
      "sso_email": agentUserData?.primary?.sso_email,
    }
    if (agentUserData?.secondary?.username) {
      userArray[1] = {
        "primary_data_flag": false,
        "username": agentUserData?.secondary?.username,
        "email_address": agentUserData?.secondary?.email_address,
        "address": agentUserData?.secondary?.address,
        "state": agentUserData?.secondary?.state,
        "country": agentUserData?.secondary?.country,
        "zip_code": agentUserData?.secondary?.zip_code,
        "sso_email": agentUserData?.secondary?.sso_email,
      }
    }
    finalPayload["agent_user_data"] = userArray;

    var templateLifeDataArr = [...globalLifeCarrierData];
    var templateAnnuityDataArr = [...globalAnnuityCarrierData];
    var templateLifeDataObj = [];
    var templateAnnuityDataObj = [];
    var cnt = 1;
    if (agentUserData?.carrier_type_life) {
      for (var i in templateLifeDataArr) {
        templateLifeDataObj[i] = {};
        templateLifeDataObj[i]["carrier_id"] = templateLifeDataArr[i]["carrier_types"][0]["carrier_id"];
        templateLifeDataObj[i]["carrier_level_data"] = [];
        cnt = 1;
        if (templateLifeDataArr[i]["baLevel"]?.carrier_level_name) {
          templateLifeDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateLifeDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            carrier_level_sequence: cnt,
          });
          cnt++;
        }
        if (templateLifeDataArr[i]["directCarrier"]?.carrier_level_name) {
          templateLifeDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateLifeDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            carrier_level_sequence: cnt,
          });
          cnt++;
        }
        for (var j in templateLifeDataArr[i]["carrier_level_data"]) {
          if (templateLifeDataArr[i]["carrier_level_data"][j]) {
            templateLifeDataObj[i]["carrier_level_data"].push({
              carrier_level_name: templateLifeDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              carrier_level_sequence: cnt++,
            });
          }
        }
      }
    }
    if (agentUserData?.carrier_type_annuity) {
      for (var i in templateAnnuityDataArr) {
        templateAnnuityDataObj[i] = {};
        templateAnnuityDataObj[i]["carrier_id"] = templateAnnuityDataArr[i]["carrier_types"][0]["carrier_id"];
        templateAnnuityDataObj[i]["carrier_level_data"] = [];
        cnt = 1;
        if (templateAnnuityDataArr[i]["baLevel"]?.carrier_level_name) {
          templateAnnuityDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateAnnuityDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            carrier_level_sequence: cnt,
          });
          cnt++;
        }
        if (templateAnnuityDataArr[i]["directCarrier"]?.carrier_level_name) {
          templateAnnuityDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateAnnuityDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            carrier_level_sequence: cnt,
          });
          cnt++;
        }
        for (var j in templateAnnuityDataArr[i]["carrier_level_data"]) {
          if (templateAnnuityDataArr[i]["carrier_level_data"][j]) {
            templateAnnuityDataObj[i]["carrier_level_data"].push({
              carrier_level_name: templateAnnuityDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              carrier_level_sequence: cnt++,
            });
          }
        }
      }
    }
    let agentCommissionArray = []
    if (agentUserData?.carrier_type_life) {
      let agentCommissionRow = {}
      agentCommissionRow.carrier_type_flag = true
      agentCommissionRow.template_assign_flag = selectedLifeTemplate != null ? true : false
      agentCommissionRow.template_id = selectedLifeTemplate?.template_id ? selectedLifeTemplate?.template_id : null
      agentCommissionRow.agent_carrier_assignment_data = selectedLifeTemplate == null ? templateLifeDataObj : []
      agentCommissionArray.push(agentCommissionRow)
    }
    if (agentUserData?.carrier_type_annuity) {
      let agentCommissionRow = {}
      agentCommissionRow.carrier_type_flag = false
      agentCommissionRow.template_assign_flag = selectedAnnuityTemplate != null ? true : false
      agentCommissionRow.template_id = selectedAnnuityTemplate?.template_id ? selectedAnnuityTemplate?.template_id : null
      agentCommissionRow.agent_carrier_assignment_data = selectedAnnuityTemplate == null ? templateAnnuityDataObj : []
      agentCommissionArray.push(agentCommissionRow)
    }

    finalPayload["agent_commission_data"] = agentCommissionArray;

    CreateNewAgentApi(finalPayload, (res) => {
      if (res?.data?.status) {
        console.log("response true of CreateNewAgentApi api", res);
        UpdateSnackbarMessage({
          status: "success",
          message: "Agent Created Successfully!"
        });
        FlagApiLoader(false)
        navigate("/");
      }
      else {
        console.log("response false of CreateNewAgentApi api", res);
        FlagApiLoader(false)
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
    }, (err) => {
      console.log("error of CreateNewAgentApi api", err);
      FlagApiLoader(false)
      UpdateSnackbarMessage({
        status: "error",
        message: "Something went wrong!"
      });
    })
  }

  const DisableEditTable = () => {
    let tempCarrierData =
      selectedTab == "life" ? [...globalLifeCarrierData] : [...globalAnnuityCarrierData]
    tempCarrierData?.map((obj) => {
      obj.edit_flag = false
    })
    if (selectedTab == "life") {
      setGlobalLifeCarrierData(tempCarrierData)
    } else {
      setGlobalAnnuityCarrierData(tempCarrierData)
    }
  }

  const UpdateAgent = () => {
    FlagApiLoader(true)
    let finalPayload = {
      agent_id: agentId,
      carrier_type_flag: selectedTab == "life" ? true : selectedTab == "annuity" ? false : null,
      deleted_agent_commission_carrier_assignment_id: deletedCarrierId,
    }
    let templateDataArr;
    if (selectedTab == "life") {
      templateDataArr = [...globalLifeCarrierData]
      console.log("templateDataArr----", templateDataArr);
    } else if (selectedTab == "annuity") {
      templateDataArr = [...globalAnnuityCarrierData]
    }
    var templateDataObj = [];
    var cnt = 1;
    for (var i in templateDataArr) {
      templateDataObj[i] = {};
      if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
        templateDataObj[i]["agent_commission_carrier_assignment_id"] = templateDataArr[i]["agent_commission_carrier_assignment_id"];
        templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
      } else {
        templateDataObj[i]["carrier_id"] = templateDataArr[i]["carrier_types"][0]["carrier_id"];
      }
      templateDataObj[i]["carrier_level_data"] = [];
      cnt = 1;
      if (templateDataArr[i]["baLevel"]?.carrier_level_name) {
        if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
          templateDataObj[i]["carrier_level_data"].push({
            agent_commission_carrier_assignment_level_id: templateDataArr[i]["baLevel"]["agent_commission_carrier_assignment_level_id"],
            carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            carrier_level_sequence: cnt,
          });
        } else {
          templateDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateDataArr[i]["baLevel"]["carrier_level_name"],
            carrier_ba_level_flag: true,
            carrier_direct_level_flag: false,
            carrier_level_sequence: cnt,
          });
        }
        cnt++;
      }
      if (templateDataArr[i]["directCarrier"]?.carrier_level_name) {
        if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
          templateDataObj[i]["carrier_level_data"].push({
            agent_commission_carrier_assignment_level_id: templateDataArr[i]["directCarrier"]["agent_commission_carrier_assignment_level_id"],
            carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            carrier_level_sequence: cnt,
          });
        } else {
          templateDataObj[i]["carrier_level_data"].push({
            carrier_level_name: templateDataArr[i]["directCarrier"]["carrier_level_name"],
            carrier_ba_level_flag: false,
            carrier_direct_level_flag: true,
            carrier_level_sequence: cnt,
          });
        }
        cnt++;
      }
      for (var j in templateDataArr[i]["carrier_level_data"]) {
        if (templateDataArr[i]["carrier_level_data"][j]) {
          if (templateDataArr[i]?.agent_commission_carrier_assignment_id) {
            templateDataObj[i]["carrier_level_data"].push({
              agent_commission_carrier_assignment_level_id: templateDataArr[i]["carrier_level_data"][j]["agent_commission_carrier_assignment_level_id"],
              carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              carrier_level_sequence: cnt++,
            });
          } else {
            templateDataObj[i]["carrier_level_data"].push({
              carrier_level_name: templateDataArr[i]["carrier_level_data"][j]["carrier_level_name"],
              carrier_ba_level_flag: false,
              carrier_direct_level_flag: false,
              carrier_level_sequence: cnt++,
            });
          }
        }
      }
    }

    finalPayload["agent_carrier_assignment_data"] = templateDataObj

    // console.log("templateDataObj", JSON.parse(JSON.stringify(templateDataObj)));
    // console.log("finalPayload", JSON.parse(JSON.stringify(finalPayload["agent_carrier_assignment_data"])));
    let searchParamsAgentId = searchParams.get("id");

    UpdateAgentDataApi(finalPayload, (res) => {
      if (res?.data?.status) {
        console.log("response true of UpdateAgentDataApi api", res);
        UpdateSnackbarMessage({
          status: "success",
          message: res?.data?.data?.message
        });
        GetAgentData(searchParamsAgentId, (res) => {
          if (res?.data?.status) {
            console.log("response true of GetAgentData api", res);
            let tempAgentData = { ...agentUserData }
            let tempChangedDetails = { ...oldUserDetails }
            let tempFlagRemind = { ...flagShowRemindIcon }
            let tempFlagExpired = { ...flagShowExpiredIcon }
            let flagPrimaryRemind;
            let flagSecondaryRemind;
            let flagPrimaryExpired = false;
            let flagSecondaryExpired = false;
            res?.data?.data[0].agent_user_data?.map((row) => {
              if (row.primary_data_flag) {
                tempAgentData['primary']['primary_data_flag'] = true
                tempAgentData['primary']['user_id'] = row?.user_id
                tempAgentData['primary']['username'] = row?.username
                tempAgentData['primary']['email_address'] = row?.email_address
                tempAgentData['primary']['address'] = row?.address
                tempAgentData['primary']['state'] = row?.state
                tempAgentData['primary']['country'] = row?.country
                tempAgentData['primary']['zip_code'] = row?.zip_code
                tempAgentData['primary']['sso_email'] = row?.sso_email
                tempAgentData['primary']['nda_status'] = row?.user_nda_log
                tempAgentData['primary']['created_by'] = row?.created_by_user_email_address

                tempChangedDetails['primary']['username'] = row?.username
                tempChangedDetails['primary']['address'] = row?.address
                tempChangedDetails['primary']['state'] = row?.state
                tempChangedDetails['primary']['country'] = row?.country
                tempChangedDetails['primary']['zip_code'] = row?.zip_code
                tempChangedDetails['primary']['sso_email'] = row?.sso_email
                setPrimaryUsername(row?.username)

                flagPrimaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                flagPrimaryExpired = row?.nda_expire_flag == true ? true : false

              } else {
                if (row?.username) {
                  setFlagShowNdaSecondary(true)
                } else {
                  setFlagShowNdaSecondary(false)
                }
                tempAgentData['secondary']['primary_data_flag'] = false
                tempAgentData['secondary']['user_id'] = row?.user_id
                tempAgentData['secondary']['username'] = row?.username
                tempAgentData['secondary']['email_address'] = row?.email_address
                tempAgentData['secondary']['address'] = row?.address
                tempAgentData['secondary']['state'] = row?.state
                tempAgentData['secondary']['country'] = row?.country
                tempAgentData['secondary']['zip_code'] = row?.zip_code
                tempAgentData['secondary']['sso_email'] = row?.sso_email
                tempAgentData['secondary']['nda_status'] = row?.user_nda_log
                tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address

                tempChangedDetails['secondary']['username'] = row?.username
                tempChangedDetails['secondary']['address'] = row?.address
                tempChangedDetails['secondary']['state'] = row?.state
                tempChangedDetails['secondary']['country'] = row?.country
                tempChangedDetails['secondary']['zip_code'] = row?.zip_code
                tempChangedDetails['secondary']['sso_email'] = row?.sso_email

                flagSecondaryRemind = (row?.user_nda_signed_flag == false) ? false : true
                flagSecondaryExpired = row?.nda_expire_flag == true ? true : false
              }
            })

            tempFlagRemind.primary = flagPrimaryRemind
            tempFlagRemind.secondary = flagSecondaryRemind
            tempFlagExpired.primary = flagPrimaryExpired
            tempFlagExpired.secondary = flagSecondaryExpired
            setFlagShowRemindIcon(tempFlagRemind)
            setFlagShowExpiredIcon(tempFlagExpired)

            let carrierIds = ''
            res?.data?.data[0]?.agent_commission_data?.map((commissionRow) => {
              if (commissionRow?.carrier_type_flag == true) {
                tempAgentData.carrier_type_life = true
                if (commissionRow?.template_assign_flag == true) {
                  setSelectedLifeTemplate({
                    carrier_type_flag: true,
                    template_id: commissionRow?.template_id,
                    template_name: commissionRow?.template_name
                  })
                } else {
                  setSelectedLifeTemplate(null)
                }
              } else if (commissionRow?.carrier_type_flag == false) {
                tempAgentData.carrier_type_annuity = true
                if (commissionRow?.template_assign_flag == true) {
                  setSelectedAnnuityTemplate({
                    carrier_type_flag: false,
                    template_id: commissionRow?.template_id,
                    template_name: commissionRow?.template_name
                  })
                } else {
                  setSelectedAnnuityTemplate(null)
                }
              }

              let tempInnerArray = []
              if (commissionRow?.template_assign_flag == true) {
                commissionRow?.template_data?.map((row) => {
                  let obj = {}
                  obj.carrier_name = row?.carrier_name
                  obj.carrier_id = row?.carrier_id
                  obj.agent_commission_carrier_assignment_id = row?.template_carrier_assignment_id
                  obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]

                  row?.carrier_level_data?.map((element) => {
                    element.agent_commission_carrier_assignment_level_id = element?.template_carrier_assignment_level_id
                    element.carrier_level_sequence = element?.template_level_sequence
                  })
                  obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                  obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                  obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                  tempInnerArray.push(obj)
                  carrierIds += `,${row?.carrier_id}`
                })
              } else {
                commissionRow?.agent_carrier_assignment_data?.map((row) => {
                  let obj = {}
                  obj.carrier_name = row?.carrier_name
                  obj.carrier_id = row?.carrier_id
                  obj.agent_commission_carrier_assignment_id = row?.agent_commission_carrier_assignment_id
                  obj.carrier_types = [{ carrier_id: row?.carrier_id, carrier_type_flag: commissionRow?.carrier_type_flag }]
                  obj.baLevel = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == true)[0]
                  obj.directCarrier = row?.carrier_level_data?.filter(gg => gg?.carrier_direct_level_flag == true)[0]
                  obj.carrier_level_data = row?.carrier_level_data?.filter(gg => gg?.carrier_ba_level_flag == false && gg?.carrier_direct_level_flag == false)
                  tempInnerArray.push(obj)
                  carrierIds += `,${row?.carrier_id}`
                })
              }
              if (commissionRow?.carrier_type_flag == true) {
                setGlobalLifeCarrierData(tempInnerArray)
              } else {
                setGlobalAnnuityCarrierData(tempInnerArray)
              }

            })
            carrierIds = carrierIds.slice(1)
            GetCarrierLevelList(carrierIds,
              (res) => {
                if (res.data.status) {
                  // OLD CODE ********
                  // setCarrierLevelData(res.data.data)
                  // OLD CODE ********

                  // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                  if (selectedTab == "life") {
                    setCarrierLifeLevelData(res.data.data)
                  }
                  else if (selectedTab == "annuity") {
                    setCarrierAnnuityLevelData(res.data.data)
                  }
                  // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                }
                else {
                  console.log("response false of GetCarrierLevelList api", res);
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!"
                    });
                  }
                }
              },
              (err) => {
                console.log("response error of GetTemplateData api", err);
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
                FlagApiLoader(false)
              })
            setAgentUserData(tempAgentData);
            setOldUserDetails(tempChangedDetails);
            FlagApiLoader(false)
          }
          else {
            console.log("response false of GetAgentData api", res);
            FlagApiLoader(false)
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
          }
        },
          (err) => {
            console.log("error of GetAgentData api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
            FlagApiLoader(false)
          })
        DisableEditTable()
        FlagApiLoader(false)
      }
      else {
        console.log("response false of UpdateAgentDataApi api", res);
        DisableEditTable()
        FlagApiLoader(false)
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
    },
      (err) => {
        console.log("error of UpdateAgentDataApi api", err);
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!"
        });
      })
  }

  const AddSecondaryData = () => {
    let payload = {
      agent_id: agentId,
      username: agentUserData?.secondary?.username,
      email_address: agentUserData?.secondary?.email_address,
      address: agentUserData?.secondary?.address,
      state: agentUserData?.secondary?.state,
      country: agentUserData?.secondary?.country,
      zip_code: agentUserData?.secondary?.zip_code,
      sso_email: agentUserData?.secondary?.sso_email,
      primary_data_flag: false
    }
    if (agentUserData?.secondary?.username && agentUserData?.secondary?.email_address) {
      AddSecondaryAgentDataApi(payload,
        (res) => {
          if (res?.data?.status) {
            console.log("response true of AddSecondaryAgentDataApi api", res);
            let tempAgentData = { ...agentUserData }
            let row = res?.data?.data[0]
            tempAgentData['secondary']['primary_data_flag'] = false
            tempAgentData['secondary']['user_id'] = row?.user_id
            tempAgentData['secondary']['username'] = row?.username
            tempAgentData['secondary']['email_address'] = row?.email_address
            tempAgentData['secondary']['address'] = row?.address
            tempAgentData['secondary']['state'] = row?.state
            tempAgentData['secondary']['country'] = row?.country
            tempAgentData['secondary']['zip_code'] = row?.zip_code
            tempAgentData['secondary']['sso_email'] = row?.sso_email
            tempAgentData['secondary']['nda_status'] = row?.user_nda_log
            tempAgentData['secondary']['created_by'] = row?.created_by_user_email_address

            setAgentUserData(tempAgentData)
            let tempNdaFlag = { ...flagShowRemindIcon }
            tempNdaFlag.secondary = row.user_nda_signed_flag
            setFlagShowRemindIcon(tempNdaFlag)

            UpdateSnackbarMessage({
              status: "success",
              message: "Secondary agent created successfully."
            });

            setFlagShowNdaSecondary(true)
            FlagApiLoader(false)

          }
          else {
            console.log("response false of AddSecondaryAgentDataApi api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
        },
        (err) => {
          FlagApiLoader(false)
          console.log("error of AddSecondaryAgentDataApi api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something went wrong!"
          });
        })
    }
  }

  const ConvertDateTimeFunction = (givenInputDate) => {
    const inputDate = new Date(givenInputDate);
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
    const year = inputDate.getUTCFullYear();
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    const seconds = inputDate.getUTCSeconds();
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedDate;
  }

  const UpdateUserDetails = (type) => {

    FlagApiLoader(true)
    let payload = {
      agent_id: agentId,
      user_id: agentUserData[type]?.user_id,
      agent_user_data_flag: true
    }
    let fieldKeys = ["username", "address", "state", "country", "zip_code", "sso_email"]
    let oldDetail = { ...oldUserDetails }
    let newDetail = { ...agentUserData }
    let flagApiCall = false
    for (let i = 0; i < fieldKeys.length; i++) {
      if (oldDetail[type][fieldKeys[i]] != newDetail[type][fieldKeys[i]]) {
        flagApiCall = true
        payload[fieldKeys[i]] = newDetail[type][fieldKeys[i]]
      }
    }

    if (flagApiCall) {
      UpdateUserDataApi(payload, (res) => {
        if (res?.data?.status) {
          console.log("response true of UpdateUserDataApi api", res);
          setPrimaryUsername(agentUserData?.primary?.username)
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });

          for (let i = 0; i < fieldKeys.length; i++) {
            oldDetail[type][fieldKeys[i]] = newDetail[type][fieldKeys[i]]
          }
          setOldUserDetails(oldDetail)
          FlagApiLoader(false)
        }
        else {
          console.log("response false of UpdateUserDataApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
          FlagApiLoader(false)
        }
        setFlagDisableUserDetails((old) => {
          let temp = { ...old }
          temp[type] = true
          return temp
        })
      },
        (err) => {
          console.log("error of UpdateUserDataApi api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });

          setFlagDisableUserDetails((old) => {
            let temp = { ...old }
            temp[type] = true
            return temp
          })
          FlagApiLoader(false)
        })
    }
    else {
      setFlagDisableUserDetails((old) => {
        let temp = { ...old }
        temp[type] = true
        return temp
      })
      FlagApiLoader(false)
    }
  }

  // console.log("selectedTab---------", selectedTab);

  return (
    <Fragment>
      {flagResendNdaPopup.flag && (
        <GlobalDialog
          open={flagResendNdaPopup.flag}
          data={RemindNdaPopup()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {flagSecondaryDetailsAlert && (
        <GlobalDialog
          open={flagSecondaryDetailsAlert}
          data={SecondaryDetailAlert()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {flagNdaInfoPopup && (
        <GlobalDialog
          open={flagNdaInfoPopup}
          data={NdaInfoPopup()}
          maxWidth={"sm"}
          className="nda_info_popup"
          sxDialog={{ borderRadius: "25px" }}
        />
      )}
      {flagEditCarrierPopup && (
        <GlobalDialog
          open={flagEditCarrierPopup}
          data={EditCarrierPopup()}
          maxWidth={"sm"}
          sxDialog={{ borderRadius: "20px" }}
        />
      )}
      {openChangeTemplatePopup.flag && (
        <GlobalDialog
          open={openChangeTemplatePopup.flag}
          data={AssignTemplateAlert()}
          maxWidth={"sm"}

        />
      )}
      <Helmet title="myAdvisorGrids | Add New Agent" />

      {/* Navigation Section */}

      <Grid justifyContent="space-between" container alignItems="center" mb={2} className="agentmainPageStickyHeader">
        <Grid item xl={10} sx={{ textAlign: "center" }}>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xl={0.8}>
              <IconButton
                sx={{ padding: "0px 10px 0px 0px" }}
                onClick={() => {
                  navigate("/")
                }}
              >
                <BackIconComponent />
              </IconButton>
            </Grid>
            <Grid item xl={8} textAlign={"left"}>
              <Typography variant="h3" gutterBottom display="inline">
                {agentId == null ? "New Agent" : primaryUsername}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider mt={4} mb={2} />
        </Grid>
      </Grid>


      {/* Content Section */}
      <Grid container className="cg-global-inner-scrollable-wrapper-style">
        <Grid item className="main_box_shadow" xs={12} sx={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
          {/* Form to Fill */}

          {/* Accordian Start */}

          <Accordion
            expanded={flagAccordianExpanded}
            sx={{
              backgroundColor: "#EFEFF0",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="add_agent_accordian_summary"
              onClick={() => setFlagAccordianExpanded(!flagAccordianExpanded)}
            >
              {!flagAccordianExpanded && (

                <Grid
                  container
                  alignItems="center"
                  justifyContent={{ xs: "center", lg: "start" }}
                >
                  {(agentUserData?.primary?.username || agentUserData?.primary?.email_address) && (
                    <Grid item marginRight="20px">
                      <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
                        {(agentUserData?.primary?.username || agentUserData?.primary?.email_address) && (
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "15px", md: "15px", lg: "10px", xl: "15px" } }}>
                            <img src={PrimaryLogo} />
                          </Grid>
                        )}

                        {(agentUserData?.primary?.username) && (
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                            <img src={Agent} />
                          </Grid>
                        )}

                        <Grid item sx={{ marginRight: { xs: "10px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.primary?.username}
                          </Typography>
                        </Grid>

                        {agentUserData?.primary?.email_address && (
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>|</Grid>

                            <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                              <img src={Mail} />
                            </Grid>
                          </>
                        )}
                        <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.primary?.email_address}
                          </Typography>
                        </Grid>
                        {!flagNewAgentPage &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>
                            <Grid item sx={{ marginRight: { lg: "5px", xl: "10px" } }}>
                              {agentUserData?.primary?.nda_status[0]?.nda_activity == "NDA signed" ?
                                <span style={{ color: "#3FA976" }}>Signed</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Signed</span>
                              }
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Grid>
                  )}

                  {(agentUserData?.secondary?.username || agentUserData?.secondary?.email_address) && (
                    <Grid item marginRight="25px">
                      <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
                        {(agentUserData?.secondary?.username || agentUserData?.secondary?.email_address) &&
                          <>
                            <Grid item textAlign="center" sx={{ marginRight: { xs: "15px", md: "15px", lg: "10px", xl: "15px" } }}>
                              <img src={SecondaryLogo2} />
                            </Grid>
                          </>
                        }

                        {agentUserData?.secondary?.username &&
                          <Grid item textAlign="center" sx={{ marginRight: { xs: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                            <img src={Agent} />
                          </Grid>
                        }

                        <Grid item sx={{ marginRight: { xs: "10px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.secondary?.username}
                          </Typography>
                        </Grid>

                        {agentUserData?.secondary?.email_address &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>

                            <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "10px", md: "10px", lg: "5px", xl: "10px" } }}>
                              <img src={Mail} />
                            </Grid>
                          </>
                        }

                        <Grid item sx={{ display: { xs: "none", sm: "inline" }, marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                          <Typography className="overflow-dot-dot-dot">
                            {agentUserData?.secondary?.email_address}
                          </Typography>
                        </Grid>

                        {!flagNewAgentPage &&
                          <>
                            <Grid item sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "center", marginRight: { sm: "15px", md: "15px", lg: "5px", xl: "10px" } }}>
                              |
                            </Grid>
                            <Grid item>
                              {agentUserData?.secondary?.nda_status[0]?.nda_activity == "NDA signed" ?
                                <span style={{ color: "#3FA976" }}>Signed</span>
                                :
                                <span style={{ color: "#D65656" }}>Not Signed</span>
                              }
                            </Grid>
                          </>
                        }

                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container spacing={3}>
                      {agentUserData?.carrier_type_life && (
                        <Grid item>
                          <img src={LifeInsuranceChip} alt="life-insurance" />
                        </Grid>
                      )}
                      {agentUserData?.carrier_type_annuity && (
                        <Grid item>
                          <img src={AnnuityInsuranceChip} alt="annuity-insurance" />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </AccordionSummary>

            <AccordionDetails sx={{ marginTop: "-40px", marginBottom: "20px" }}>
              <Grid
                container
                // flexDirection="column"
                justifyContent="center"
                alignItems="center"
              // sx={{ marginTop: "-30px" }}
              >
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    // flexDirection={{ xs: "column" }}
                    justifyContent="center"
                    rowGap={6}
                  >
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                      <Grid container rowGap={{ xs: 5, sm: 0 }} justifyContent={{ xs: "center", md: "space-between" }}>
                        <Grid item xs={11} sm={5.6} md={5.7} lg={5.8} xl={5.8} >
                          <Grid container>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center" mb={4}>
                              <Typography fontWeight="600" sx={{ color: "#1565C0", fontSize: "15px" }} >Primary Agent</Typography>
                            </Grid>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} sx={{ backgroundColor: "#F9F9FC", borderRadius: "10px", border: flagDisableUserDetails.primary == false ? "1px solid #1565C0" : "" }}>
                              <Grid container rowGap={3} justifyContent={{ xs: "center" }} sx={{ padding: "20px" }}>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "req_fields user_field_color_disable" : "req_fields user_field_color_enable"}
                                        label="Name"
                                        value={agentUserData?.primary?.username}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['username'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        fullWidth
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.username
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "username") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "username") }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className="req_fields"
                                        label="Email"
                                        value={agentUserData?.primary?.email_address}
                                        disabled={flagNewAgentPage == false ? true : false}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['email_address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.email_address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "email_address") }}
                                        error={flagEmailHelperText?.primary}
                                        onBlur={(event) => {
                                          TextFieldBlurStatus("primary", "email_address")
                                          let oldFlag = { ...flagEmailHelperText }
                                          if ((/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/.test(event.target.value)) || !event.target.value) {
                                            oldFlag.primary = false
                                            setFlagEmailHelperText(oldFlag)
                                          } else {
                                            oldFlag.primary = true
                                            setFlagEmailHelperText(oldFlag)
                                          }

                                        }}
                                        // sx={{ width: { xs: "210px", sm: "120px", md: "120px", lg: "240px", xl: "280px" } }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Address"
                                        value={agentUserData?.primary?.address}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "address") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "address") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="State"
                                        value={agentUserData?.primary?.state}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['state'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.state
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "state") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "state") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Country"
                                        value={agentUserData?.primary?.country}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['country'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.country
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "country") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "country") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Zip code"
                                        type="number"
                                        value={agentUserData?.primary?.zip_code}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['primary']['zip_code'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.primary?.zip_code
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("primary", "zip_code") }}
                                        onBlur={() => { TextFieldBlurStatus("primary", "zip_code") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          className={(flagDisableUserDetails?.primary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                          label="SSO Email"
                                          value={agentUserData?.primary?.sso_email}
                                          onChange={(e) => {
                                            let tempUserData = { ...agentUserData };
                                            tempUserData['primary']['sso_email'] = e.target.value;
                                            setAgentUserData(tempUserData);
                                          }}
                                          disabled={(flagNewAgentPage == false && flagDisableUserDetails?.primary == true) ? true : false}
                                          InputLabelProps={{
                                            shrink: flagTextFieldFocus?.primary?.sso_email
                                          }}
                                          onFocus={() => { TextFieldFocusStatus("primary", "sso_email") }}
                                          onBlur={() => { TextFieldBlurStatus("primary", "sso_email") }}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                    {!flagNewAgentPage &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-multiline-flexible"
                                          label="NDA Status"
                                          value={(customNdaStatus?.type == "primary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.primary?.nda_status?.length ? agentUserData?.primary?.nda_status[0]?.nda_activity_message : ""}
                                          disabled={true}
                                          fullWidth
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position='end'>
                                                {flagShowExpiredIcon.primary
                                                  ?
                                                  <CustomTooltip title="Send New NDA" color="#1565C0">
                                                    <Typography
                                                      variant="span"
                                                      sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                      onClick={() => {
                                                        setFlagResendNdaPopup({
                                                          flag: true,
                                                          type: "primary",
                                                          expiredFlag: true
                                                        })
                                                      }}
                                                    >
                                                      Send New NDA
                                                    </Typography>
                                                  </CustomTooltip>
                                                  :
                                                  flagShowRemindIcon.primary == false &&
                                                  <CustomTooltip title="Reminder NDA" color="#1565C0">
                                                    <Typography
                                                      variant="span"
                                                      sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                      onClick={() => {
                                                        setFlagResendNdaPopup({
                                                          flag: true,
                                                          type: "primary",
                                                          expiredFlag: false
                                                        })
                                                      }}
                                                    >
                                                      Remind
                                                    </Typography>
                                                  </CustomTooltip>
                                                }
                                                <CustomTooltip title="NDA Status Log" color="#1565C0">
                                                  <Typography
                                                    variant="span"
                                                    sx={{ cursor: "pointer", color: "#1565C0", fontSize: "13px", textDecoration: "underline" }}
                                                    onClick={() => {
                                                      setNdaStatus("primary"); setFlagNdaInfoPopup(true)
                                                    }}
                                                  >
                                                    Logs
                                                  </Typography>
                                                </CustomTooltip>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {!flagNewAgentPage && userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          label="Created by"
                                          value={agentUserData?.primary?.created_by}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          disabled={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center">
                                  {(!flagNewAgentPage && flagDisableUserDetails.primary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        setFlagDisableUserDetails((old) => {
                                          let temp = { ...old }
                                          temp.primary = false
                                          return temp
                                        })
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && !flagDisableUserDetails.primary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        UpdateUserDetails("primary")
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={11} sm={5.6} md={5.7} lg={5.8} xl={5.8}>
                          <Grid container>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center" mb={4}>
                              <Typography fontWeight="600" sx={{ color: "#1565C0", fontSize: "15px" }} >Secondary Agent</Typography>
                            </Grid>
                            <Grid item xs={11} sm={10} md={12} lg={12} xl={12} sx={{ backgroundColor: "#F9F9FC", borderRadius: "10px", border: flagDisableUserDetails.secondary == false ? "1px solid #1565C0" : "" }}>
                              <Grid container rowGap={3} justifyContent={{ xs: "center" }} sx={{ padding: "20px" }}>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Name"
                                        value={agentUserData?.secondary?.username}
                                        defaultValue={agentUserData?.secondary?.username}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['username'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.username
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "username") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "username") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        // className="user_field_color_disable"
                                        label="Email"
                                        value={agentUserData?.secondary?.email_address}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary) ? true : false}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['email_address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.email_address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "email_address") }}
                                        error={flagEmailHelperText?.secondary}
                                        onBlur={(event) => {
                                          TextFieldBlurStatus("secondary", "email_address")
                                          let oldFlag = { ...flagEmailHelperText }
                                          if ((/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/.test(event.target.value)) || !event.target.value) {
                                            oldFlag.secondary = false
                                            setFlagEmailHelperText(oldFlag)
                                          } else {
                                            oldFlag.secondary = true
                                            setFlagEmailHelperText(oldFlag)
                                          }
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Address"
                                        value={agentUserData?.secondary?.address}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['address'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.address
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "address") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "address") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="State"
                                        value={agentUserData?.secondary?.state}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['state'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.state
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "state") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "state") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Country"
                                        value={agentUserData?.secondary?.country}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['country'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.country
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "country") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "country") }}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                      <TextField
                                        variant="standard"
                                        id="standard-basic"
                                        className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                        label="Zip code"
                                        type="number"
                                        value={agentUserData?.secondary?.zip_code}
                                        onChange={(e) => {
                                          let tempUserData = { ...agentUserData };
                                          tempUserData['secondary']['zip_code'] = e.target.value;
                                          setAgentUserData(tempUserData);
                                        }}
                                        disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                        InputLabelProps={{
                                          shrink: flagTextFieldFocus?.secondary?.zip_code
                                        }}
                                        onFocus={() => { TextFieldFocusStatus("secondary", "zip_code") }}
                                        onBlur={() => { TextFieldBlurStatus("secondary", "zip_code") }}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {userDetail[0]?.user_role_name == "Admin" &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          className={(flagDisableUserDetails?.secondary == true) ? "user_field_color_disable" : "user_field_color_enable"}
                                          label="SSO Email"
                                          value={agentUserData?.secondary?.sso_email}
                                          onChange={(e) => {
                                            let tempUserData = { ...agentUserData };
                                            tempUserData['secondary']['sso_email'] = e.target.value;
                                            setAgentUserData(tempUserData);

                                          }}
                                          disabled={(flagNewAgentPage == false && flagShowNdaSecondary && flagDisableUserDetails?.secondary == true) ? true : false}
                                          InputLabelProps={{
                                            shrink: flagTextFieldFocus?.secondary?.sso_email
                                          }}
                                          onFocus={() => { TextFieldFocusStatus("secondary", "sso_email") }}
                                          onBlur={() => { TextFieldBlurStatus("secondary", "sso_email") }}
                                          fullWidth
                                        />
                                      </Grid>
                                    }

                                    {!flagNewAgentPage &&
                                      (flagShowNdaSecondary ?
                                        <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                          <TextField
                                            variant="standard"
                                            id="standard-basic"
                                            label="NDA Status"
                                            value={(customNdaStatus?.type == "secondary" && customNdaStatus?.message?.length) ? customNdaStatus.message : agentUserData?.secondary?.nda_status?.length ? agentUserData?.secondary?.nda_status[0]?.nda_activity_message : ""}
                                            disabled={true}
                                            fullWidth
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position='end'>
                                                  {flagShowExpiredIcon.secondary ?
                                                    <CustomTooltip title="Send New NDA" color="#1565C0">
                                                      <Typography
                                                        variant="span"
                                                        sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                        onClick={() => {
                                                          setFlagResendNdaPopup({
                                                            flag: true,
                                                            type: "secondary",
                                                            expiredFlag: true
                                                          })
                                                        }}
                                                      >
                                                        Send New NDA
                                                      </Typography>
                                                    </CustomTooltip>
                                                    :
                                                    flagShowRemindIcon.secondary == false &&
                                                    <CustomTooltip title="Reminder NDA" color="#1565C0">
                                                      <Typography
                                                        variant="span"
                                                        sx={{ cursor: "pointer", color: "#1565C0", marginRight: "10px", fontSize: "13px", textDecoration: "underline" }}
                                                        onClick={() => {
                                                          setFlagResendNdaPopup({
                                                            flag: true,
                                                            type: "secondary",
                                                            expiredFlag: false
                                                          })
                                                        }}
                                                      >
                                                        Remind
                                                      </Typography>
                                                    </CustomTooltip>
                                                  }
                                                  <CustomTooltip title="NDA Status Log" sx={{ backgroundColor: "red" }}>
                                                    <Typography
                                                      variant="span"
                                                      sx={{ cursor: "pointer", color: "#1565C0", fontSize: "13px", textDecoration: "underline" }}
                                                      onClick={() => {
                                                        setNdaStatus("secondary"); setFlagNdaInfoPopup(true)
                                                      }}
                                                    >
                                                      Logs
                                                    </Typography>
                                                  </CustomTooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                        :
                                        ""
                                      )
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12}>
                                  <Grid container rowGap={{ xs: 3, md: 0 }} justifyContent="space-between">
                                    {!flagNewAgentPage && userDetail[0]?.user_role_name == "Admin" && flagShowNdaSecondary &&
                                      <Grid item xs={12} sm={12} md={5.8} lg={5.7} xl={5.6}>
                                        <TextField
                                          variant="standard"
                                          id="standard-basic"
                                          label="Created by"
                                          value={agentUserData?.secondary?.created_by}
                                          InputLabelProps={{
                                            shrink: agentUserData?.secondary?.created_by?.length ? true : false
                                          }}
                                          disabled={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>

                                <Grid item xs={11} sm={10} md={12} lg={12} xl={12} textAlign="center">
                                  {(!flagNewAgentPage && !flagShowNdaSecondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        if ((!agentUserData?.secondary?.username) && (!agentUserData?.secondary?.email_address)) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's name and email"
                                          });
                                        } else if (!agentUserData?.secondary?.username) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's name"
                                          });
                                        } else if (!agentUserData?.secondary?.email_address) {
                                          UpdateSnackbarMessage({
                                            status: "error",
                                            message: "Please enter secondary agent's email"
                                          });
                                        } else {
                                          setFlagSecondaryDetailsAlert(true)
                                        }
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && flagShowNdaSecondary && flagDisableUserDetails.secondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        setFlagDisableUserDetails((old) => {
                                          let temp = { ...old }
                                          temp.secondary = false
                                          return temp
                                        })
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  }
                                  {(!flagNewAgentPage && flagShowNdaSecondary && !flagDisableUserDetails.secondary) &&
                                    <Button
                                      variant="contained"
                                      sx={{
                                        paddingX: "30px",
                                        fontSize: { sm: "12px", md: "14px" },
                                        fontWeight: "500",
                                        backgroundColor: "#1565C0",
                                        marginTop: { xs: "12px", sm: "none" }
                                      }}
                                      onClick={() => {
                                        UpdateUserDetails("secondary")
                                      }}
                                    >
                                      Save Details
                                    </Button>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Type Selection Section */}
                    <Grid item xs={9} sm={6.8} md={6} lg={5} xl={5}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            variant="outlined"
                            sx={{ color: "#1F1F1F" }}
                            gutterBottom
                            display="inline"
                          >
                            Type <span style={{ color: "red" }}>*</span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ padding: "0px 5px 0px 20px" }}
                                  checked={agentUserData?.carrier_type_life}
                                  onChange={(e) => {
                                    const updatedDetails = { ...agentUserData, carrier_type_life: e.target.checked };
                                    setAgentUserData(updatedDetails);
                                  }}
                                  value={agentUserData?.carrier_type_life}
                                  name="life"
                                  icon={<img src={UnCheckedIcon} />}
                                  checkedIcon={<img src={CheckedIcon} />}
                                />
                              }
                              label={agentUserData?.carrier_type_life == true ? "Life" : "Add Life"}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ padding: "0px 5px 0px 20px" }}
                                  checked={agentUserData?.carrier_type_annuity}
                                  onChange={(e) => {
                                    const updatedDetails = { ...agentUserData, carrier_type_annuity: e.target.checked };
                                    setAgentUserData(updatedDetails);
                                  }}
                                  value={agentUserData?.carrier_type_annuity}
                                  name="annuity"
                                  icon={<img src={UnCheckedIcon} />}
                                  checkedIcon={<img src={CheckedIcon} />}
                                />
                              }
                              label={agentUserData?.carrier_type_annuity == true ? "Annuity" : "Add Annuity"}

                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Accordian End */}

          {/* Table Section */}
          {(agentUserData?.carrier_type_life || agentUserData?.carrier_type_annuity) &&
            <Grid
              container
              sx={{
                backgroundColor: "#EFEFF0",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  {/* Tabs Selection Section */}
                  <Grid item xs={12}>
                    <Grid container alignItems="space-between" justifyContent="space-between">
                      <Grid item>
                        <Grid container columnGap={{ xs: 2, sm: 4 }}>
                          {agentUserData?.carrier_type_life &&
                            <Grid item sx={{ cursor: "pointer" }} onClick={() => { setSelectedTab("life") }}>
                              <Grid container
                                alignItems="end"
                                backgroundColor={selectedTab === "life" ? "#FFFFFF" : "#D7D7D7"}
                                paddingX={{ xs: 2, sm: 3 }}
                                columnGap={{ xs: 1.5, sm: 3 }}
                                sx={{ borderTopLeftRadius: "5px ", borderTopRightRadius: "5px ", paddingBottom: "18px", height: "54px" }}>
                                <Grid item sx={{ marginBottom: "4px" }}>
                                  <Typography component="span" color={selectedTab === "life" ? "#1565C0" : "#7A7A7A"}>
                                    {"Life"}
                                  </Typography>
                                  <Typography component="span" color="#7A7A7A" display={{ xs: "none", md: "inline" }}>
                                    {" | Template"}
                                  </Typography>
                                </Grid>
                                <Grid item sx={{ display: selectedTab === "life" ? "inline" : { xs: "none", sm: "inline" } }}>
                                  <FormControl variant="standard" sx={{ minWidth: 130 }} disabled={selectedTab !== "life"}>
                                    <InputLabel id="demo-simple-select-standard-label" shrink={false}> {selectedLifeTemplate == null ? "Select Template" : ""}</InputLabel>
                                    <Select
                                      className="addNewAgent-select"
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={selectedLifeTemplate && lifeTemplateList?.filter(rr => rr?.template_id == selectedLifeTemplate?.template_id)[0]}
                                      // value={selectedLifeTemplate}
                                      onChange={(e) => {
                                        ChangeLifeTemplate(e)
                                      }}
                                      // className="select_template_dropdown"
                                      MenuProps={{
                                        sx: { zIndex: 2002 }
                                      }}
                                      sx={{ paddingLeft: "10px" }}
                                      label="life"
                                    >
                                      {lifeTemplateList.length >= 1 ? lifeTemplateList.map(rr => {
                                        return (<MenuItem value={rr}>{rr.template_name}</MenuItem>)
                                      }) : (<MenuItem disabled>No template found</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          {agentUserData?.carrier_type_annuity &&
                            <Grid item sx={{ cursor: "pointer" }} onClick={() => setSelectedTab("annuity")}>
                              <Grid container
                                alignItems="end"
                                backgroundColor={selectedTab === "annuity" ? "#FFFFFF" : "#D7D7D7"}
                                paddingX={{ xs: 2, sm: 3 }}
                                columnGap={{ xs: 1.5, sm: 3 }}
                                sx={{ borderTopLeftRadius: "5px ", borderTopRightRadius: "5px ", paddingBottom: "18px", height: "54px" }}>
                                <Grid item sx={{ marginBottom: "4px" }}>
                                  <Typography component="span" color={selectedTab === "annuity" ? "#1565C0" : "#7A7A7A"}>
                                    {"Annuity"}
                                  </Typography>
                                  <Typography component="span" color="#7A7A7A" display={{ xs: "none", md: "inline" }}>
                                    {" | Template"}
                                  </Typography>
                                </Grid>
                                <Grid item sx={{ display: selectedTab === "annuity" ? "inline" : { xs: "none", sm: "inline" } }}>
                                  <FormControl variant="standard" sx={{ minWidth: 150, }} disabled={selectedTab !== "annuity"}>
                                    <InputLabel id="demo-simple-select-standard-label" shrink={false}> {selectedAnnuityTemplate == null ? "Select Template" : ""}</InputLabel>
                                    <Select
                                      className="addNewAgent-select"
                                      labelId="demo-simple-select-standard-label"
                                      id="demo-simple-select-standard"
                                      value={selectedAnnuityTemplate && annuityTemplateList?.filter(rr => rr?.template_id == selectedAnnuityTemplate?.template_id)[0]}
                                      // value={selectedAnnuityTemplate}
                                      sx={{ paddingLeft: "10px" }}
                                      MenuProps={{
                                        sx: { zIndex: 2002 }
                                      }}
                                      InputProps={{
                                        sx: {
                                          '& .Mui-focused': { background: 'none' }
                                        }
                                      }}
                                      onChange={(e) => {
                                        ChangeAnnuityTemplate(e)
                                      }}
                                      label="Annuity"
                                    >
                                      {annuityTemplateList.length >= 1 ? annuityTemplateList.map(rr => {
                                        return (<MenuItem value={rr}>{rr.template_name}</MenuItem>)
                                      }) : (<MenuItem disabled>No template found</MenuItem>)}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      </Grid>

                      <Grid item>
                        {((editButtonVisible == true))
                          ?
                          <Button
                            variant="contained"
                            sx={{ marginBottom: "10px", paddingX: "30px", paddingY: "8px", marginRight: "5px" }}
                            className="clearEditBtn"
                            onClick={() => {
                              OpenEditCarrierPopup("editCarrier")
                            }}
                          >Edit Carrier</Button>
                          :
                          (clearButtonVisible == true)
                            ?
                            <Button
                              variant="contained"
                              sx={{ marginBottom: "10px", paddingX: "30px", paddingY: "8px", marginRight: "5px" }}
                              onClick={() => { OpenEditCarrierPopup("clearAll") }}
                              className="clearEditBtn"
                            >Clear All</Button>
                            : null
                        }
                        {(delegateAccessFlag == false && agentId && primaryUsername) &&
                          <Button
                            variant="contained"
                            sx={{ padding: "8px 22px", marginLeft: "15px", marginBottom: "10px" }}
                            onClick={() => {
                              let obj = {
                                delegate_agent_id: agentId,
                                delegate_primary_agent_flag: true
                              }

                              GrantDelegateAccessForAdmin(obj, (res) => {
                                if (res?.data?.status) {
                                  window.location.href = "/"
                                } else {
                                  console.log("response false of GrantDelegateAccessForAdmin api", res);
                                  if (res?.data?.error?.message) {
                                    UpdateSnackbarMessage({
                                      status: "error",
                                      message: res?.data?.error?.message
                                    });
                                  }
                                  else {
                                    UpdateSnackbarMessage({
                                      status: "error",
                                      message: "Something Went Wrong!"
                                    });
                                  }
                                }
                              }, (err) => {
                                console.log("error false of GrantDelegateAccessForAdmin api", err);
                                UpdateSnackbarMessage({
                                  status: "error",
                                  message: "Something Went Wrong!"
                                });
                              })
                            }}
                          >
                            View As Agent
                          </Button>
                        }
                      </Grid>
                    </Grid>

                  </Grid>

                  {/* Tabs Content Section */}
                  <Grid item xs={12}>
                    <Grid
                      sx={{
                        backgroundColor: "#FFFFFF",
                        // width: { xs: "71vw", sm: "86vw", md: "77.5vw" },
                        // height: "45vh",
                        padding: "none !important",
                      }}
                      container
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Grid item xs={12}>
                        <GlobalCarrierTable
                          flagAddPage={flagAddPage}
                          deletedCarrierId={deletedCarrierId}
                          setDeletedCarrierId={setDeletedCarrierId}
                          pageName="Agent"
                          editFlag={editFlag}
                          selectedTab={selectedTab}
                          flagNewAgentPage={flagNewAgentPage}
                        />
                      </Grid>


                      {(flagNewAgentPage == false &&
                        ((selectedTab == "life" && selectedLifeTemplate == null) || (selectedTab == "annuity" && selectedAnnuityTemplate == null))
                      ) &&
                        <Grid item xs={12}>
                          <Grid
                            container
                            sx={{ justifyContent: "center", margin: "20px 0px" }}
                          >
                            <Grid item xl={1.5} lg={2} md={3} sm={3.5} xs={8} sx={{ textAlign: "center", margin: "0px 10px" }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  paddingX: "45px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#1565C0",
                                  borderColor: "#1565C0",
                                }}
                                fullWidth
                                onClick={() => { navigate("/") }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item xl={1.5} lg={2} md={3} sm={3.5} xs={8} sx={{ textAlign: "center", margin: { xs: "10px 10px 0", sm: "0px 10px" } }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  paddingX: "30px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  backgroundColor: "#1565C0",
                                }}
                                fullWidth
                                onClick={() => { UpdateAgent() }}
                                // onClick={() => createNewTemplate()}
                                disabled={agentUserData?.primary?.username?.length > 0 && agentUserData?.primary?.email_address?.length > 0 ? false : true}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }

          {/* Button Section */}
          {(flagShowBottomButtons && flagNewAgentPage) && (
            <Grid item xs={12}>
              <Grid
                container
                sx={{ justifyContent: "center", margin: "20px 0px" }}
              >
                <Grid item xl={1.4} lg={2} md={2.5} sm={2.5} xs={8} sx={{ textAlign: "center", margin: "0px 10px" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      paddingX: "45px",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#1565C0",
                      borderColor: "#1565C0",
                    }}
                    fullWidth
                    onClick={() => { navigate("/") }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xl={1.4} lg={2} md={2.5} sm={2.5} xs={8} sx={{ textAlign: "center", margin: { xs: "10px 10px 0", sm: "0px 10px" } }}>
                  <Button
                    variant="contained"
                    sx={{
                      paddingX: "30px",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "#1565C0",
                    }}
                    fullWidth
                    onClick={() => { CreateNewAgent() }}
                  // onClick={() => createNewTemplate()}
                  // disabled={agentUserData?.primary?.username?.length > 0 && agentUserData?.primary?.email_address?.length > 0 ? false : true}
                  >
                    Create Agent
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddNewAgents;

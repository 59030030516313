import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Avatar,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { LogOutApi, SwitchUserRole } from "../../utils/api/userDetailApi";
import { TrendingUpTwoTone } from "@mui/icons-material";

const BigAvatar = styled(Avatar)`
background: #1B2430;
cursor:pointer; 
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const {
    state,
    UpdateUserDetail,
    UpdateSnackbarMessage,
    UpdateFlagAuthenticated,
    FlagApiLoader,
  } = useContext(AuthContext);
  const { userDetail, delegateUserData } = {
    ...state,
  };

  console.log("delegateUserData---", delegateUserData)
  const { signOut } = useAuth();

  const [userAvatar, setUserAvatar] = useState("CG");
  const [changeMenuItemName, setChangeMenuItemName] = useState("");

  useEffect(() => {
    let gg = userDetail;
    let UserData = userDetail?.data ? userDetail['data'] : userDetail
    if (delegateUserData?.user_name) {
      const namesArray = (!delegateUserData) ? '' : delegateUserData?.user_name.split(' ');
      if (namesArray.length > 0) {
        setUserAvatar((namesArray[0][0].toUpperCase()) + "" + (namesArray[1] ? namesArray[1][0].toUpperCase() : ""))
      }
    } else {
      const namesArray = (!UserData[0]?.user_name) ? '' : UserData[0]?.user_name.split(' ');
      if (namesArray.length > 0) {
        setUserAvatar((namesArray[0][0].toUpperCase()) + "" + (namesArray[1] ? namesArray[1][0].toUpperCase() : ""))
      }
    }

  }, [userDetail, delegateUserData]);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    FlagApiLoader(true);
    await LogOutApi(
      {},
      (res) => {
        console.log("response of logout", res);
        FlagApiLoader(false);
        if (res.data.status) {
          UpdateUserDetail(null);
          UpdateFlagAuthenticated(false);

          UpdateSnackbarMessage({
            status: "success",
            message: "Logged Out Successfully",
          });

          navigate("/auth/sign");
        } else {
          console.log("response false of LogOutApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("response error of LogOutApi api", err);
        FlagApiLoader(false);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!"
        });
      }
    );

    // signOut();
    // navigate("/auth/sign-in");
  };

  useEffect(() => {
    if (userDetail[0].user_role_assignment_data?.length > 1) {
      let secondRole = userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)
      if (secondRole?.length) {
        let userRoleName = secondRole[0]?.user_role_name == "Member" ? "ARM" : secondRole[0]?.user_role_name
        setChangeMenuItemName(userRoleName)
      }
    }
  }, [userDetail])

  const changeLoginRole = (assignmentId) => {
    let obj = {
      user_role_assignment_id: assignmentId
    }
    SwitchUserRole(obj, (res) => {
      if (res?.data?.status) {
        console.log("res", res)
        UpdateUserDetail(res.data.data)
        navigate("/")
        FlagApiLoader(false);
      } else {
        console.log("response false of SwitchUserRole api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }

      }
    }, (err) => {
      FlagApiLoader(false);
      console.log("error of SwitchUserRole api", err);
      UpdateSnackbarMessage({
        status: "error",
        message: "Something Went Wrong!"
      });
    })
  }

  return (
    <React.Fragment>
      {/* <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip> */}
      <BigAvatar
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        onClick={toggleMenu}

      >{userAvatar} </BigAvatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        className="shadow_menu"
        autoFocus={false}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        {userDetail[0].user_role_assignment_data?.length > 1 &&
          userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)?.length &&
          <MenuItem
            onClick={() => {
              FlagApiLoader(true);
              let selectedRole = userDetail[0].user_role_assignment_data?.filter((obj) => obj.user_role_active_flag == false)[0]
              changeLoginRole(selectedRole.user_role_assignment_id)
            }}
          >Switch to {changeMenuItemName}</MenuItem>
        }
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>

      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;

import CreateDataContext from "./createDataContext";

const CarrierReducer = (state, action) => {
    switch (action.type) {
        case "globalLifeCarrierData": {
            return {
                ...state,
                globalLifeCarrierData: action.payload,
            };
        }
        case "globalAnnuityCarrierData": {
            return {
                ...state,
                globalAnnuityCarrierData: action.payload,
            };
        }

        case "carrierLevelData": {
            return {
                ...state,
                carrierLevelData: action.payload,
            };
        }

        case "wholeCarrierData": {
            return {
                ...state,
                wholeCarrierData: action.payload,
            };
        }

        case "flagType": {
            return {
                ...state,
                flagType: action.payload,
            };
        }

        case "templateName": {
            return {
                ...state,
                templateName: action.payload,
            };
        }

        case "templateId": {
            return {
                ...state,
                templateId: action.payload,
            };
        }

        case "setCarrierName": {
            return {
                ...state,
                carrierName: action.payload
            }
        }

        case "setCarrierAnnuityLevelData": {
            return {
                ...state,
                carrierAnnuityLevelData: action.payload
            }
        }

        case "setCarrierLifeLevelData": {
            return {
                ...state,
                carrierLifeLevelData: action.payload
            }
        }

        // case "setCarrierDetailsDuplicate": {
        //     return {
        //         ...state,
        //         carrierDetailsDuplicate: action.payload
        //     }
        // }

        default: {
            return state;
        }
    }
};

const setCarrierLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "carrierLevelData",
        payload: data,
    });
};

const setFlagType = (dispatch) => async (data) => {
    dispatch({
        type: "flagType",
        payload: data,
    });
};

const setTemplateId = (dispatch) => async (data) => {
    dispatch({
        type: "templateId",
        payload: data,
    });
};

const setTemplateName = (dispatch) => async (data) => {
    dispatch({
        type: "templateName",
        payload: data,
    });
};

const setGlobalLifeCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "globalLifeCarrierData",
        payload: data,
    });
};
const setGlobalAnnuityCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "globalAnnuityCarrierData",
        payload: data,
    });
};

const setWholeCarrierData = (dispatch) => async (data) => {
    dispatch({
        type: "wholeCarrierData",
        payload: data,
    });
};

const setCarrierName = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierName",
        payload: data,
    });
};

const setCarrierAnnuityLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierAnnuityLevelData",
        payload: data,
    });
};

const setCarrierLifeLevelData = (dispatch) => async (data) => {
    dispatch({
        type: "setCarrierLifeLevelData",
        payload: data,
    });
};

// const setCarrierDetailsDuplicate = (dispatch) => async (data) => {
//     console.log("entered here")
//     dispatch({
//         type: "setCarrierDetailsDuplicate",
//         payload: data,
//     });
// };


export const { Provider, Context, state } = CreateDataContext(
    CarrierReducer,
    {
        setFlagType,
        setGlobalLifeCarrierData,
        setGlobalAnnuityCarrierData,
        setCarrierLevelData,
        setWholeCarrierData,
        setTemplateName,
        setTemplateId,
        setCarrierName,
        // setCarrierDetailsDuplicate
        setCarrierAnnuityLevelData,
        setCarrierLifeLevelData
    },
    {
        flagType: null,
        templateId: null,
        templateName: "",
        carrierLevelData: [],
        carrierLifeLevelData: [],
        carrierAnnuityLevelData: [],
        wholeCarrierData: [{}],
        globalLifeCarrierData: [],
        globalAnnuityCarrierData: [],
        carrierName: "",
        // carrierDetailsDuplicate: []
    }
);

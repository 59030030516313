import React, { useContext, useEffect } from 'react'
import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Box,
    MenuItem,
    FormControl,
    Select,
    Button,
    capitalize,
    InputLabel,
    NativeSelect,
    ListItemIcon,
    useMediaQuery,
} from "@mui/material";
import { Close } from '@mui/icons-material';
import deleteAlert from '../../../assets/Delete-alert.png'
import '../../css/ViewCarriarStyles/UnsavedChangesDialog.css'
import { Context as CarrierContext } from "../../../contexts/reducerContexts/globalCarrierContext"
import { useNavigate } from "react-router-dom";

const UnsavedChangesDialog = (props) => {

    // console.log("pero", props);
    const pathname = window.location.pathname
    const navigate = useNavigate()
    // const { carrierDetailsDuplicate } = useContext(CarrierContext).state
    // const { setCarrierDetailsDuplicate } = useContext(CarrierContext)


    // useEffect(() => {
    //     // console.log("testing", carrierDetailsDuplicate)
    // }, [carrierDetailsDuplicate])


    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ padding: "5px 0px" }}>
                    <Grid container>
                        <Grid item xs={12} className='vcd-close-icon-item'>
                            <Close
                                sx={{ cursor: "pointer", fontSize: "18px" }}
                                onClick={() => {
                                    props.setEditDetails(true)
                                    props.setHideTransperentCell(true)
                                    props.setFlagUnsavedChangesDialog(false)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <img src={deleteAlert}></img>
                        </Grid>
                        <Grid item xs={11} className='vcd-warning-text-item' >
                            <Typography variant='span' className='vcd-warning-text'>
                                You have unsaved changes. If you leave without saving, your changes will be lost.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='vcd-btn-text-item' >
                            <Grid container sx={{ justifyContent: "center", gap: "25px" }} >
                                <Grid item xs={2.5}>
                                    <Grid container sx={{ justifyContent: "end" }}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    props.setFlagUnsavedChangesDialog(false)
                                                    props.setEditDetails(false)
                                                    props.setHideTransperentCell(false)
                                                    // let gg = { ...props.carrierDetailsDuplicate };
                                                    props.setCarrierDetails(props.carrierDetailsDuplicate)
                                                    props.removeUnsavedChangesHandler()
                                                    if (pathname == '/carriers/add_carrier') {
                                                        navigate('/carriers')
                                                    }
                                                    // gg['carrier_level_data'][0]['carrier_group_data'] = gg['carrier_level_data'][0]['carrier_group_data'].filter(rr => !rr.carrier_group_data_id_custom)
                                                }}

                                            >Yes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color='error'
                                                onClick={() => {
                                                    // props.setEditDetails(true)
                                                    props.setHideTransperentCell(true)
                                                    props.setFlagUnsavedChangesDialog(false)
                                                }}
                                            >No</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default UnsavedChangesDialog
import { FormControl, IconButton, Menu, MenuItem, Select, TableCell, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
import { MoreVert } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";

export const GlobalCarrierTableRow = (props) => {

    const { row, ChangeDirectLevel, ChangeBaLevel, DLLength, i, DisableEdit, ChangeDlSection, anchorEl, handleClick, handleClose, setFlagDeletePopup, open, EnableEdit, selectedTab } = props
    const { carrierLevelData, carrierAnnuityLevelData, carrierLifeLevelData } = useContext(globalCarrierContext).state;
    const navigate = useNavigate();

    // console.log("carrierAnnuityLevelData popup", carrierAnnuityLevelData);
    // console.log("carrierLevelData popup", carrierLevelData);
    // console.log("selectedTab props", selectedTab);

    return (

        row.edit_flag == true ?
            <TableRow
                key={row.carrier_name}
                className="carrier_table_row"
            >
                {/* Serial Number */}
                <TableCell
                    className="sticky-table-body sticky-table-body-1"
                    stickyHeader
                >
                    {i + 1}
                </TableCell>

                {/* Carrier */}
                <TableCell
                    align="left"
                    className="sticky-table-body sticky-table-body-2 clickable_item"
                    stickyHeader
                // onClick={() => {
                //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
                // }}
                >
                    <Link
                        target="_blank"
                        className="clickable_item"
                        style={{ textDecoration: "none" }}
                        to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
                        {row.carrier_name}
                    </Link>
                </TableCell>

                {/* BA Level */}
                <TableCell
                    align="center"
                    className="sticky-table-body sticky-table-body-3"
                    stickyHeader
                >
                    <FormControl m={2}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="ba-level"
                            size="small"
                            value={row?.baLevel}
                            MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                            // defaultValue={row?.baLevel}
                            renderValue={(val) => { return row?.baLevel ? val.carrier_level_name : "" }}
                            onChange={(e) => {
                                console.log(e.target.value)
                                if (e.target.value) {
                                    ChangeBaLevel(e, row)
                                }
                            }}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            {/*************** OLD CODE : BEFORE UPDATED LIFE ANNUITY DROPDOWN ISSUE IN PRODUCTION

                               carrierLevelData?.map((menu) => {
                                    if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                        return menu?.carrier_ba_level_data.map(
                                            (name, index) => (
                                                <MenuItem key={index} value={name}>
                                                    {name.carrier_level_name}
                                                </MenuItem>
                                            )
                                        );
                                    }
                                })

                            ***************/}

                            {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}
                            {(selectedTab == 'life' ? carrierLifeLevelData : selectedTab == 'annuity' ? carrierAnnuityLevelData : [])?.map((menu) => {
                                if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                    return menu?.carrier_ba_level_data.map(
                                        (name, index) => (
                                            <MenuItem key={index} value={name}>
                                                {name.carrier_level_name}
                                            </MenuItem>
                                        )
                                    );
                                }
                            })
                            }
                            {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}


                        </Select>
                    </FormControl>
                </TableCell>

                {/* Direct Carrier */}
                <TableCell
                    align="center"
                    sx={{
                        borderRight: "2px solid white",
                    }}
                    className="sticky-table-body sticky-table-body-4"
                >
                    <FormControl m={2}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="ba-level"
                            size="small"
                            value={row?.directCarrier}
                            renderValue={(val) => row?.directCarrier ? val.carrier_level_name : ""}
                            MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                            onChange={(e) => {
                                if (e.target.value) {
                                    ChangeDirectLevel(e, row)
                                }
                            }}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            {/*************** OLD CODE : BEFORE UPDATED LIFE ANNUITY DROPDOWN ISSUE IN PRODUCTION 
                           
                                carrierLevelData?.map((menu) => {
                                        if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                                            return menu?.carrier_direct_level_data?.map(
                                                (name, index) => (
                                                    <MenuItem key={index} value={name}>
                                                        {name?.carrier_level_name}
                                                    </MenuItem>
                                            )
                                        );
                                    }
                                })      
                            
                            ***************/}

                            {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}

                            {
                                (selectedTab == 'life') ? (
                                    carrierLifeLevelData?.map((menu) => {
                                        if (menu?.carrier_id == row?.carrier_types[0]?.carrier_id) {
                                            return menu?.carrier_direct_level_data?.map(
                                                (name, index) => (
                                                    <MenuItem key={index} value={name}>
                                                        {name?.carrier_level_name}
                                                    </MenuItem>
                                                )
                                            );
                                        }
                                    })
                                ) : (
                                    (selectedTab == 'annuity') &&
                                    (
                                        carrierAnnuityLevelData?.map((menu) => {
                                            if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                                return menu?.carrier_direct_level_data.map(
                                                    (name, index) => (
                                                        <MenuItem key={index} value={name}>
                                                            {name.carrier_level_name}
                                                        </MenuItem>
                                                    )
                                                );
                                            }
                                        })
                                    )
                                )
                            }

                            {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}

                        </Select>
                    </FormControl>
                </TableCell>

                {/* DL Selection */}
                {Array.from({ length: DLLength }).map((mm, j) => {
                    return (
                        <>
                            <TableCell align="center">
                                <FormControl m={2}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="ba-level"
                                        size="small"
                                        value={row?.carrier_level_data[j] ? row?.carrier_level_data[j] : ""}
                                        renderValue={(val) => val.carrier_level_name}
                                        onChange={(e) => {
                                            ChangeDlSection(e, row, j)
                                        }}
                                        MenuProps={{ sx: { zIndex: 2002, maxHeight: "240px", } }}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        <MenuItem value={""}>
                                            {"None"}
                                        </MenuItem>

                                        {/*************** OLD CODE : BEFORE UPDATED LIFE ANNUITY DROPDOWN ISSUE IN PRODUCTION
                                         * {carrierLevelData?.map((menu) => {
                                            if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                                return menu?.carrier_direct_level_data.map(
                                                    (name, index) => {
                                                        if (row?.directCarrier?.carrier_level_sequence <= name.carrier_level_sequence) {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={name}
                                                                >
                                                                    {name.carrier_level_name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                        else {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={name}
                                                                >
                                                                    {name.carrier_level_name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    }
                                                );
                                            }
                                        })}
                                        ***************/}

                                        {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}
                                        {
                                            (selectedTab == 'life') ?
                                                (
                                                    carrierLifeLevelData?.map((menu) => {
                                                        if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                                            return menu?.carrier_direct_level_data.map(
                                                                (name, index) => {
                                                                    if (row?.directCarrier?.carrier_level_sequence <= name.carrier_level_sequence) {
                                                                        return (
                                                                            <MenuItem
                                                                                key={index}
                                                                                value={name}
                                                                            >
                                                                                {name.carrier_level_name}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <MenuItem
                                                                                key={index}
                                                                                value={name}
                                                                            >
                                                                                {name.carrier_level_name}
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    })
                                                ) :
                                                (
                                                    (selectedTab == 'annuity') &&
                                                    (
                                                        carrierAnnuityLevelData?.map((menu) => {
                                                            if (menu.carrier_id == row.carrier_types[0].carrier_id) {
                                                                return menu?.carrier_direct_level_data.map(
                                                                    (name, index) => {
                                                                        if (row?.directCarrier?.carrier_level_sequence <= name.carrier_level_sequence) {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={name}
                                                                                >
                                                                                    {name.carrier_level_name}
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={name}
                                                                                >
                                                                                    {name.carrier_level_name}
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        })
                                                    )
                                                )
                                        }
                                        {/*** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE ***/}


                                    </Select>
                                </FormControl>
                            </TableCell >
                        </>
                    );
                })}

                {/* Menu Item */}
                <TableCell
                    className="sticky-table-body sticky-table-body-5"
                >
                    <div>
                        <IconButton
                            onClick={(e) => {
                                DisableEdit(row)
                            }}
                        >
                            <DoneIcon />
                        </IconButton>
                        <IconButton
                            onClick={(e) => { handleClick(e, row) }}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '10ch',
                                },
                            }}
                        >

                            <MenuItem onClick={() => {
                                setFlagDeletePopup(true)
                            }}>
                                Delete
                            </MenuItem>

                        </Menu>
                        {/* <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
            setDeleteCarrierData(row);
            setFlagDeletePopup(true)
        }} /> */}
                    </div>
                </TableCell>
            </TableRow >
            :
            <TableRow
                key={row.carrier_name}
                className="carrier_table_row"
            >
                {/* Serial Number */}
                <TableCell
                    className="sticky-table-body sticky-table-body-1"
                    stickyHeader
                >
                    {i + 1}
                </TableCell>

                {/* Carrier */}
                <TableCell
                    align="left"
                    className="sticky-table-body sticky-table-body-2 clickable_item"
                    stickyHeader
                // onClick={() => {
                //     navigate(`/carriers/view_carrier?id=${row.carrier_id}&version_id=`)
                // }}
                >
                    <Link
                        target="_blank"
                        className="clickable_item"
                        style={{ textDecoration: "none" }}
                        to={`/carriers/view_carrier?id=${row.carrier_types[0]?.carrier_id}&version_id=`} >
                        {row.carrier_name}
                    </Link>

                </TableCell>

                {/* BA Level */}
                <TableCell
                    align="center"
                    className="sticky-table-body sticky-table-body-3"
                    stickyHeader
                >
                    {row?.baLevel?.carrier_level_name}
                </TableCell>

                {/* Direct Carrier */}
                <TableCell
                    align="center"
                    sx={{
                        borderRight: "2px solid white",
                    }}
                    className="sticky-table-body sticky-table-body-4"
                >
                    {row?.directCarrier?.carrier_level_name}
                </TableCell>

                {/* DL Selection */}
                {Array.from({ length: DLLength }).map((mm, j) => {
                    return (
                        <>
                            <TableCell
                                align="center"
                            >
                                {row?.carrier_level_data?.length ? row?.carrier_level_data[j]?.carrier_level_name : ""}
                            </TableCell>
                        </>
                    );
                })}

                {/* Menu Item */}
                <TableCell
                    className="sticky-table-body sticky-table-body-5"
                >
                    <div>
                        <IconButton
                            onClick={(e) => {
                                EnableEdit(row)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={(e) => { handleClick(e, row) }}
                        >
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '10ch',
                                },
                            }}
                        >

                            <MenuItem onClick={() => {
                                setFlagDeletePopup(true)
                            }}>
                                Delete
                            </MenuItem>

                        </Menu>
                        {/* <DeleteForeverIcon sx={{ cursor: "pointer", color: "red" }} onClick={() => {
            setDeleteCarrierData(row);
            setFlagDeletePopup(true)
        }} /> */}
                    </div>
                </TableCell>
            </TableRow>

    )
}

import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
} from "@mui/material";
import "../../pages/css/CarriersPopup.css";
import {
    GetCarrierDataList,
    GetCarrierLevelList,
} from "../../utils/api/TemplateApi";
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as globalCarrierContext } from "../../contexts/reducerContexts/globalCarrierContext";
// import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";

import { useNavigate } from "react-router-dom";

const CarriersPopup = (props) => {
    const { FlagApiLoader, setFlagCarrierList, UpdateSnackbarMessage } = useContext(authContext)
    const { apiLoader } = useContext(authContext).state
    // const { setCarrierLevelData, setSelectedCarrier, setCarrierData, setTemplateData } = useContext(templateContext)
    // const { flagType, carrierLevelData, selectedCarrier, carrierData, templateData } = useContext(templateContext).state
    const { setGlobalCarrierData, setCarrierLevelData, setWholeCarrierData, setTemplateName, setTemplateId, setGlobalLifeCarrierData, setGlobalAnnuityCarrierData, setCarrierAnnuityLevelData, setCarrierLifeLevelData } = useContext(globalCarrierContext);
    const { carrierLevelData, flagType, wholeCarrierData, templateName, templateId, globalCarrierData, globalLifeCarrierData, globalAnnuityCarrierData, carrierAnnuityLevelData, carrierLifeLevelData } = useContext(globalCarrierContext).state;


    const [selectedCarrier, setSelectedCarrier] = useState(true)
    const [checkValue, setCheckValue] = useState(false)
    const [hidePopupData, setHidePopupData] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        FlagApiLoader(true)
        let global_carrierData = [...wholeCarrierData]
        if (flagType) {
            var global_templateData = [...globalLifeCarrierData]
        } else {
            var global_templateData = [...globalAnnuityCarrierData]
        }
        GetCarrierDataList(flagType?.toString(),
            (res) => {
                if (res.data.status) {
                    let temp2;
                    if (!props.flagAddPage) {
                        temp2 = res.data.data.map((ele) => {
                            var checkCarrrierSelected = global_templateData?.filter(rr => rr.carrier_name == ele.carrier_name)[0]
                            if (checkCarrrierSelected) {
                                return {
                                    ...ele,
                                    selected: true,
                                    disabled: true

                                }
                            } else {
                                return {
                                    ...ele,
                                    selected: false,
                                    disabled: false
                                }
                            }
                        })
                    } else {
                        temp2 = res.data.data.map((ele) => {
                            var checkCarrrierSelected = global_carrierData.filter(rr => rr.carrier_name == ele.carrier_name)[0]
                            if (checkCarrrierSelected?.selected) {
                                return {
                                    ...ele,
                                    selected: true,
                                    disabled: true

                                }
                            } else {
                                return {
                                    ...ele,
                                    selected: false,
                                    disabled: false
                                }
                            }
                        })
                    }

                    setWholeCarrierData(temp2)
                    FlagApiLoader(false)
                    setHidePopupData(true)
                }
                else {
                    console.log("response false of GetCarrierDataList api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    }
                    FlagApiLoader(false)
                }
            },
            (err) => {
                console.log("response error of GetCarrierDataList api", err);
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
                FlagApiLoader(false)
                setHidePopupData(true)
            })
    }, [])

    // useEffect(() => {

    //     // let gg = carrierData;
    //     // debugger
    //     let filteredCarrier = carrierData.filter((ff) => {
    //         return ff.selected == true 
    //     }).map(rr => ( 

    //         { ...rr,  directCarrierSelectList: (rr?.directCarrierSelectList?.length ? rr.directCarrierSelectList : []) ,directCarrier: (rr?.directCarrier?.length ? rr.directCarrier : []) ,baLevel: (rr?.baLevel?.length ? rr.baLevel : []) }
    //     ))

    //     let filteredCarrier1 = carrierData.filter((ff) => {
    //         return ff.selected == true 
    //     }).map(rr => ( 
    //         { ...rr,  carrier_level_data: []}
    //     ))
    //     setSelectedCarrier(filteredCarrier)
    //     var templateDataDetails = [...templateData];
    //     templateDataDetails[0]['template_data'] = filteredCarrier1
    //     setTemplateData(templateDataDetails)
    // }, [carrierData])

    useEffect(() => {
        let tempSelected = wholeCarrierData?.map((row) => row?.selected == true)
        let tempSelectedFlag = (tempSelected.indexOf(true) > -1) ? false : true
        setSelectedCarrier(tempSelectedFlag);
    }, [wholeCarrierData])


    console.log("selectedCarrier::", selectedCarrier)

    const GetCarriersList = () => {

        // alert('hello')
        let tempSelectedCarriers = wholeCarrierData.filter((ff) => {
            return ff.selected == true
        })
        tempSelectedCarriers = tempSelectedCarriers.map(rr => {
            if (!rr.disabled) {
                return { ...rr, carrier_level_data: [] }
            } else {
                return (flagType == true ? globalLifeCarrierData : globalAnnuityCarrierData).filter(tt => (tt.carrier_types[0]['carrier_id'] == rr.carrier_types[0]['carrier_id']))[0]
            }
        })
        if (flagType) {
            setGlobalLifeCarrierData(tempSelectedCarriers)
        } else {
            setGlobalAnnuityCarrierData(tempSelectedCarriers)
        }

        let data = wholeCarrierData.filter((ele) => {
            return (ele.selected == true && ele.disabled == false)
        })
        let id = ''
        for (let i = 0; i < data.length; i++) {
            id += `,${data[i].carrier_types[0].carrier_id}`
        }
        let result = id.slice(1)
        FlagApiLoader(true)
        GetCarrierLevelList(result,
            (res) => {
                if (res.data.status) {

                    setFlagCarrierList(true);
                    // var findCarrierData = [...carrierLevelData]
                    // setCarrierLevelData(findCarrierData.concat(res.data.data))


                    // UPDATED CODE : AFTER CONFIRM WITH KAVAL FOR LIFE ANNUITY DATA 
                    // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **
                    var findCarrierData = props.selectedTab == 'life' ? [...carrierLifeLevelData] : props.selectedTab == 'annuity' && [...carrierAnnuityLevelData]

                    if (props.selectedTab == 'life') {
                        setCarrierLifeLevelData(findCarrierData.concat(res.data.data))
                    } else if (props.selectedTab == 'annuity') {
                        setCarrierAnnuityLevelData(findCarrierData.concat(res.data.data))
                    }

                    // ** UPDATED CODE: HERE UPDATED CODE FOR RESOLVE LIFE ANNUITY DROPDOWN DATA ISSUE **

                    FlagApiLoader(false)
                    props.handleClosePopup();
                }
                else {
                    console.log("response false of GetCarrierLevelList api", res);
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        });
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        });
                    }
                }
            },
            (err) => {
                console.log("response error of GetCarrierLevelList api", err);
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                });
            })
    }

    return (
        <>
            {hidePopupData &&
                <Grid item xs={12}>
                    <Grid container className='cp-head-container' height={{ xs: "90vh", sm: "56vh", md: "initial" }}>
                        <Grid item xs={12} className='cp-head-item' height={{ xs: "60px", sm: "50px", md: "initial" }}>
                            <span className='cp-label'>Select Carriers</span>
                        </Grid>

                        {wholeCarrierData?.length > 1 ?
                            <>
                                <Grid item xs={11} className='carriers-checkbox-item'>
                                    <Grid container >
                                        <Grid item xs={12} textAlign="center">
                                            {
                                                <FormControl component="fieldset" variant="standard">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="gilad"
                                                                    checked={
                                                                        checkValue
                                                                    }
                                                                    onClick={(e) => {
                                                                        setCheckValue(e.target.checked)
                                                                        let temp = [...wholeCarrierData];
                                                                        temp = temp.map(ele => {
                                                                            ele.selected = e.target.checked
                                                                            return ele;
                                                                        });
                                                                        setWholeCarrierData(temp)
                                                                    }}
                                                                />
                                                            }
                                                            label="Select All"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11} className='carriers-checkbox-item'>
                                    <Grid container className='carriers-checkbox-container' flexDirection="row" height={{ xs: "65vh", sm: "35vh", md: "40vh" }}>
                                        {wholeCarrierData?.map((row) => {
                                            return (
                                                <Grid item xs={12} sm={4} md={2.4} textAlign="left">
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name={row.carrier_name}
                                                                        checked={row?.selected ? true : false}
                                                                        disabled={row?.disabled ? true : false}
                                                                        onClick={() => {
                                                                            let temp = wholeCarrierData.map((ele) => {
                                                                                if (ele.carrier_types[0].carrier_id == row.carrier_types[0].carrier_id) {
                                                                                    ele.selected = !row?.selected
                                                                                } return ele
                                                                            })
                                                                            setWholeCarrierData(temp)

                                                                            let temp2 = wholeCarrierData.filter((e) => {
                                                                                return e.selected == false
                                                                            })
                                                                            if (temp2.length > 0) {
                                                                                setCheckValue(false)
                                                                            } else {
                                                                                setCheckValue(true)

                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label={row.carrier_name}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </>
                            :
                            !apiLoader ?
                                <Grid item textAlign="center" mt={2} xs={12}>
                                    No Carrier Available
                                </Grid>
                                : ""
                        }
                        <Grid item xs={11} sx={{ margin: "30px auto" }}>
                            <Grid container justifyContent={"space-evenly"}>
                                {/* <Grid item>
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        onClick={() => { props.handleClosePopup() }}
                                    >
                                        Close
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        disabled={selectedCarrier ? true : false}
                                        onClick={() => {
                                            GetCarriersList()
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Grid> */}
                                <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
                                    <Grid container className='cp-checkbox-cancel-container'>
                                        <Grid item xl={3} lg={4} md={5} sm={5} xs={9}>
                                            <Button
                                                variant='outlined'
                                                fullWidth
                                                onClick={() => { props.handleClosePopup() }}
                                            >
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={5.9}>
                                    <Grid container>
                                        <Grid item xl={3} lg={4} md={5} sm={5} xs={9}>
                                            <Button
                                                variant='contained'
                                                fullWidth
                                                disabled={selectedCarrier ? true : false}
                                                onClick={() => {
                                                    GetCarriersList()
                                                }}
                                            >
                                                Continue
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
};

export default CarriersPopup;

import React, { useContext } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import myAdvisorLogo from "../../assets/myAdvisorLogo.png";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";


const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const { UpdateSnackbarMessage } = useContext(AuthContext);
  const { delegateAccessFlag } = useContext(AuthContext).state;

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" width={"98%"}>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs display={{ xs: "inline", sm: "none" }}></Grid>
            <Grid
              item
              justifyContent="center"
              textAlign="center"
              sx={{ color: "#1565C0", marginLeft: { xs: "10px", md: "32px" } }}
            >
              {/* <Typography
                alignSelf="center"
                sx={{ fontSize: "22px", fontWeight: "600" }}
              > */}
              <img src={myAdvisorLogo} height={"50vh"} width={"100%"} />
              {/* </Typography> */}
            </Grid>
            {/* <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search>
            </Grid> */}
            <Grid item xs />
            <Grid item>
              {delegateAccessFlag == true &&
                <Button
                  variant="contained"
                  sx={{ padding: "7px 20px", marginRight: "20px" }}
                  onClick={() => {
                    RevokeDelegateAccessForAdmin((res) => {
                      if (res?.data?.status) {
                        window.location.href = "/"
                      } else {
                        console.log("response false of RevokeDelegateAccessForAdmin api", res);
                        if (res?.data?.error?.message) {
                          UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                          });
                        }
                        else {
                          UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                          });
                        }
                      }
                    }, (err) => {
                      console.log("error false of RevokeDelegateAccessForAdmin api", err);
                      UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!"
                      });
                    })
                  }}
                >
                  Back to Admin Panel
                </Button>}
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);

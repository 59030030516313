import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Search, Add } from "@mui/icons-material";
import {
  Divider as MuiDivider,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";

// Navigation Imports
import { useNavigate } from "react-router-dom";

// API Imports
import { GetCarrierDataList } from "../../utils/api/TemplateApi";

// Context Imports
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import "../css/globalDesign.css"
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";
import AddNewCarrierDialog from "./ViewCarrierDialogs/AddNewCarrierDialog";
import GlobalDialog from "../../components/global/GlobalDialog";

const Divider = styled(MuiDivider)(spacing);

const CustomDataGrid = styled(DataGrid)({
  border: "none",
  padding: "20px",

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#233044",
    color: "white"
  },

  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },

  "& .MuiDataGrid-iconSeparator"
    : {
    display: "none",
    visibility: "none",
  },

  "& .MuiDataGrid-root, & .MuiDataGrid-columnHeader,& .MuiDataGrid-root & .MuiDataGrid-cell": {
    outline: "none !important"
  },

  ".MuiDataGrid-row": {
    backgroundColor: "rgba(27, 36, 48, 0.07)",
    margin: "1px 0",
  },

  "& .carrierNameCell:hover": {
    cursor: "pointer",
  },

  "& .MuiDataGrid-row:nth-child(odd)": {
    backgroundColor: "#efefef",
    margin: "1px 0"
  },

  "& .MuiDataGrid-row:nth-child(even)": {
    backgroundColor: "rgba(230 230 230)",
    margin: "1px 0"
  }
});

const CustomTextField = styled(TextField)({
  backgroundColor: "#EFEFF0",
  borderRadius: "5px",
  width: "230px",
  "& input": {
    padding: "9px 16px",
  },
  "& fieldset": {
    border: "none",
  },
});

const CustomChip = styled(Chip)`
  width: 65px;
  height: 27px;
  border-radius: 10px;
  font-weight: 600;
  font-family: 'Nunito';
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'red',
    },
    colorSecondary: {
      backgroundColor: 'brown',
    },
  },
`;

const Carriers = () => {
  // const [type, setType] = useState({
  //   flagLife: true,
  //   flagAnnuity: false,
  // });

  const { state, FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
  const { apiLoader } = useContext(authContext).state
  const { setCarrierData } = useContext(templateContext)
  const { carrierData } = useContext(templateContext).state
  const { userDetail } = useContext(authContext).state
  const navigate = useNavigate()

  const NavigateToViewCarrier = (carrierRow, carrier_flag) => {
    let carrierId
    carrierRow?.carrier_types?.map((ele) => {
      if (ele.carrier_type_flag == carrier_flag) {
        carrierId = ele.carrier_id
      }
    })
    userDetail[0].user_role_name == "Agent" ? navigate(`/carriers/view_carrier?id=${carrierId}`) : navigate(`/carriers/view_carrier?id=${carrierId}&version_id=`)
  }

  const columns = [
    {
      field: "carrier_name",
      headerName: "Name",
      headerClassName: "name_header",
      flex: 2,
      cellClassName: "carrierNameCell",
      align: "left",
      width: "50px",
      renderCell: (params) => {
        return (
          <Typography
            className="carrier-name"
            color="#1B6AC5"
            fontFamily="Segoe UI"
            fontWeight="400"
            fontSize="15px"
            lineHeight="20px"
            marginLeft={{ xs: "0", sm: "20px" }}
          >{params.value}</Typography>
        )
      }
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "name_type",
      flex: 3,
      valueGetter: (params) => params?.row?.carrier_types?.map(v => v.carrier_type_flag).join(" "),
      sortable: true,
      renderCell: (params) => {

        // if (params?.row?.length) {
        let flagsOfType = []
        params?.row?.carrier_types?.map((innerRow) => {
          flagsOfType.push(innerRow.carrier_type_flag)
        })

        if (flagsOfType.indexOf(true) > -1 && flagsOfType.indexOf(false) > -1) {
          return (
            <Grid container style={{ paddingLeft: "16px" }} columnGap={2}>
              <Grid item>
                <CustomChip
                  label="Life"
                  size="small"
                  sx={{ color: "#1B2DCF", borderColor: "#1B2DCF" }}
                  variant="outlined"
                  onClick={() => {
                    NavigateToViewCarrier(params?.row, true)
                  }}
                />
              </Grid>
              <Grid item>
                <CustomChip
                  label="Annuity"
                  size="small"
                  variant="outlined"
                  sx={{ color: "#2F970B", borderColor: "#2F970B" }}
                  onClick={() => {
                    NavigateToViewCarrier(params?.row, false)
                  }}
                />
              </Grid>
            </Grid>
          )

        } else if (flagsOfType.indexOf(false) > -1) {
          return (
            <Grid container style={{ paddingLeft: "16px" }}>
              <Grid item>
                <CustomChip
                  label="Annuity"
                  size="small"
                  variant="outlined"
                  sx={{ color: "#2F970B", borderColor: "#2F970B" }}
                  onClick={() => {
                    NavigateToViewCarrier(params?.row, false)
                  }}
                />
              </Grid>
            </Grid>
          )
        } else if (flagsOfType.indexOf(true) > -1) {
          return (
            <Grid container style={{ paddingLeft: "16px" }}>
              <Grid item>
                <CustomChip
                  label="Life"
                  size="small"
                  sx={{ color: "#1B2DCF", borderColor: "#1B2DCF" }}
                  variant="outlined"
                  onClick={() => {
                    NavigateToViewCarrier(params?.row, true)
                  }}
                />
              </Grid>
            </Grid>
          )
        }
      },
    },
  ];

  function DataGridDemo(prop) {

    const { carrierData } = prop
    const navigate = useNavigate();
    const handleOnCellClick = (e) => {
      if (e.field == "carrier_name") {
        let carrierRow = e.row.carrier_types.filter((row) => {
          if (row.carrier_type_flag == true) {
            return true
          } else {
            return false
          }
        })
        let carrierId
        if (!carrierRow?.length) {
          carrierId = e.row.carrier_types[0].carrier_id
        } else {
          carrierId = carrierRow[0].carrier_id
        }
        userDetail[0].user_role_name == "Agent" ? navigate(`/carriers/view_carrier?id=${carrierId}`) : navigate(`/carriers/view_carrier?id=${carrierId}&version_id=`)
      }
    }

    return (
      <Box sx={{ height: { xs: "72vh", sm: "78vh", lg: "77vh", xl: "82vh" }, width: "100%" }} className="carrier-table">
        <CustomDataGrid
          rows={carrierData?.length && carrierData}
          columns={columns}
          getRowId={() => Math.floor((Math.random() * 100000000) + 1)}
          pageSize={100}
          rowsPerPageOptions={[12]}
          headerHeight={39}
          experimentalFeatures={{ newEditingApi: true }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableSorting
          onCellClick={handleOnCellClick}
          className="carrier_data_grid"
          // hideFooterPagination
          // hideFooter
          sx={{
            "& .name_header .css-1jbbcbn-MuiDataGrid-columnHeaderTitle, & .name_type .css-1jbbcbn-MuiDataGrid-columnHeaderTitle": {
              marginLeft: { xs: "0", sm: "18px" }
            },
            padding: { xs: "10px 10px 0 10px", sm: "20px 20px 0 20px" },
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Data Found!
              </Stack>
            ),
          }}
        />
      </Box>
    );
  }


  useEffect(() => {
    FlagApiLoader(true)
    GetCarrierDataList(undefined,
      (res) => {
        console.log("res::", res)
        if (res?.data?.status) {
          setCarrierData(res.data.data)
          setGlobalCarrierData(res.data.data)
        } else {
          if (res?.data?.error?.code === 9087) {
            // window.location.href = "/"
            RevokeDelegateAccessForAdmin((res) => {
              if (res?.data?.status) {
                window.location.href = "/"
              } else {
                console.log("response false of RevokeDelegateAccessForAdmin api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                  FlagApiLoader(false)
                }
                else {
                  UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                  FlagApiLoader(false)
                }
              }
            }, (err) => {
              console.log("response error of RevokeDelegateAccessForAdmin api", res);
              UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
              FlagApiLoader(false)
            })
          } else {
            console.log("response false of GetCarrierDataList api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
              FlagApiLoader(false)
            }
            else {
              UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
              FlagApiLoader(false)
            }
          }
        }
        FlagApiLoader(false)
      },
      (err) => {
        console.log("error of GetCarrierDataList api", err);
        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
        FlagApiLoader(false)
      }
    )
  }, [])

  const [globalCarrierData, setGlobalCarrierData] = useState()
  const [flagAddNewCarrierDialog, setFlagAddNewCarrierDialog] = useState(false)

  // function Checkboxes() {
  //   const handleChange = (event, boxType) => {
  //     if (boxType == "life") {
  //       if (event.target.checked == false) {
  //         if (type.flagAnnuity == true) {
  //           setType((a) => {
  //             let tempData = { ...a };
  //             tempData.flagLife = event.target.checked;
  //             return tempData;
  //           });
  //         }
  //       } else {
  //         setType((a) => {
  //           console.log(a);
  //           let tempData = { ...a };
  //           tempData.flagLife = event.target.checked;
  //           return tempData;
  //         });
  //       }
  //     } else if (boxType == "annuity") {
  //       if (event.target.checked == false) {
  //         if (type.flagLife == true) {
  //           setType((a) => {
  //             let tempData = { ...a };
  //             tempData.flagAnnuity = event.target.checked;
  //             return tempData;
  //           });
  //         }
  //       } else {
  //         setType((a) => {
  //           let tempData = { ...a };
  //           tempData.flagAnnuity = event.target.checked;
  //           return tempData;
  //         });
  //       }
  //     }
  //   };

  //   const { flagLife, flagAnnuity } = type;

  //   return (
  //     <Grid container direction="row">
  //       <Grid item>
  //         <Typography
  //           variant="outlined"
  //           sx={{ color: "#7A7A7A" }}
  //           gutterBottom
  //           display="inline"
  //         >
  //           Type
  //         </Typography>
  //       </Grid>
  //       <Grid item>
  //         <FormGroup>
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 sx={{ padding: "0px 0px 0px 20px" }}
  //                 checked={flagLife}
  //                 onChange={(event) => {
  //                   handleChange(event, "life");
  //                 }}
  //                 name="flagLife"
  //               />
  //             }
  //             label="Life"
  //           />
  //         </FormGroup>
  //       </Grid>

  //       <Grid item>
  //         <FormGroup>
  //           <FormControlLabel
  //             control={
  //               <Checkbox
  //                 sx={{ padding: "0px 0px 0px 20px" }}
  //                 checked={flagAnnuity}
  //                 onChange={(event) => {
  //                   handleChange(event, "annuity");
  //                 }}
  //                 name="flagAnnuity"
  //               />
  //             }
  //             label="Annuity"
  //           />
  //         </FormGroup>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  const handleSearch = (searchQuery) => {
    let tempFiltered = [];
    if (!searchQuery) {
      setCarrierData(globalCarrierData);
    } else {
      tempFiltered = [...globalCarrierData];
      tempFiltered = tempFiltered.filter(
        (nn) => nn.carrier_name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
      );
      setCarrierData(tempFiltered);
    }
  };

  return (
    <>
      <Helmet title="myAdvisorGrids | Carriers" />

      {flagAddNewCarrierDialog && (
        <GlobalDialog
          open={flagAddNewCarrierDialog}
          data={<AddNewCarrierDialog setFlagAddNewCarrierDialog={setFlagAddNewCarrierDialog} />}
          maxWidth={"sm"}
        />
      )}

      {/* Navigation Section */}
      <Grid container justifyContent="center" className="mainPageStickyHeader">
        <Grid item xs={11.5} sm={11.2} md={11.5} lg={11.6} xl={11.7} my={3}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={12} textAlign={{ xs: "center", sm: "left" }}>
              <Typography variant="h3" gutterBottom display="inline">
                Carriers
              </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={7} xs={12} textAlign="right">
              <Grid container spacing={2} flexDirection={{ sm: "row" }} gap={4} sx={{ marginTop: "0px !important" }} justifyContent={{ xs: "end", sm: "end", md: "end", lg: "end", xl: "end" }} alignItems="center">
                {state.userDetail[0]?.user_role_name == "Admin" && state.userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" &&
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={() => navigate("")}
                      onClick={() => {
                        setFlagAddNewCarrierDialog(true)
                      }}

                      sx={{ width: "100% !important", padding: { xs: "6px 16px", sm: "6px 16px", md: "6px 6px", lg: "6px 16px" } }}
                    >
                      <Add />
                      New Carrier
                    </Button>
                  </div>
                }
                <CustomTextField
                  sx={{ marginTop: { xs: "20px", sm: "0" }, width: { xs: "100%", sm: "200px" }, }}
                  placeholder="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>


            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider mb={4} />
        </Grid>
      </Grid>


      {/* Agent Data Table Section */}
      <Grid container mt={1} height={"82vh"}>
        <Grid item xs={12} className="main_box_shadow" sx={{ backgroundColor: "#FFFFFF" }} height={"82vh"}>
          <DataGridDemo carrierData={carrierData} height={"82vh"} />
        </Grid>
      </Grid>
    </>
  );
};

export default Carriers;
import { Grid, Button, Box } from "@mui/material"
import "../../css/ViewCarriers.css"
export default function LifeAnnuityButtons({ setType, setToggleButton, carrierDetails, toggleButton, editDetails }) {

    // console.log("carrierDetailsLifeAnnuityButtons", carrierDetails)
    return (
        <>
            {
                !editDetails &&
                <>
                    {carrierDetails?.carrier_types_list?.length > 0 ?
                        <Grid container className='pp-monthly-annually-container'>
                            <Box className="mask-box">
                                <Box
                                    className="mask"
                                    style={{
                                        transform: `translateX(${toggleButton === "life" ? 0 : "150px"})`
                                    }}
                                />
                                <Button
                                    disableRipple
                                    variant="text"
                                    value={carrierDetails?.carrier_type_flag}
                                    onClick={(e) => {
                                        if (toggleButton === "life") {
                                            return
                                        }
                                        console.log("updatedCarrierDetails life", e.target.value)
                                        setType(e.target.value)
                                        setToggleButton("life")
                                    }}
                                    className={`${toggleButton === "life" ? 'selected-life-button' : ''} sliderButton`}
                                >
                                    Life
                                </Button>
                                <Button
                                    disableRipple
                                    variant="text"
                                    value={carrierDetails?.carrier_type_flag}
                                    onClick={(e) => {
                                        if (toggleButton === "annuity") {
                                            return
                                        }
                                        console.log("updatedCarrierDetails anu", e.target.value)
                                        setType(e.target.value)
                                        setToggleButton("annuity")
                                    }}
                                    className={`${toggleButton === "annuity" ? 'selected-annuity-button' : ''} sliderButton`}
                                >
                                    Annuity
                                </Button>
                            </Box>
                        </Grid>
                        :
                        carrierDetails?.carrier_type_flag == true
                            ?
                            <Grid container className='pp-monthly-annually-container'>
                                <Box className="mask-box">
                                    <Box
                                        className="mask"
                                        style={{
                                            transform: `translateX(${toggleButton === "life" ? 0 : "150px"})`
                                        }}
                                    />
                                    <Button
                                        disableRipple
                                        variant="text"
                                        value={carrierDetails?.carrier_type_flag}
                                        // onClick={(e) => {
                                        //     setType(e.target.value)
                                        //     setToggleButton("life")
                                        // }}
                                        className={`${toggleButton === "life" ? 'selected-life-button' : ''} sliderButton`}
                                    >
                                        Life
                                    </Button>
                                    <Button
                                        disableRipple
                                        variant="text"
                                        disabled
                                    >
                                        Annuity
                                    </Button>
                                </Box>
                            </Grid>
                            :
                            carrierDetails?.carrier_type_flag == false
                                ?
                                <Grid container className='pp-monthly-annually-container'>
                                    <Box className="mask-box">
                                        <Box
                                            className="mask"
                                            style={{
                                                transform: `translateX(${toggleButton === "life" ? 0 : "150px"})`
                                            }}
                                        />
                                        <Button
                                            disableRipple
                                            variant="text"
                                            disabled
                                        >
                                            Life
                                        </Button>
                                        <Button
                                            disableRipple
                                            variant="text"
                                            value={carrierDetails?.carrier_type_flag}
                                            // onClick={(e) => {
                                            //     setType(e.target.value)
                                            //     setToggleButton("annuity")
                                            // }}
                                            className={`${toggleButton === "annuity" ? 'selected-annuity-button' : ''} sliderButton`}
                                        >
                                            Annuity
                                        </Button>
                                    </Box>
                                </Grid>
                                :
                                ""
                    }

                </>
            }
        </>
    )
}
import React from 'react'
import '../../css/ViewCarriers.css'
import { Grid, Button, } from "@mui/material";
import { Close, ExpandMore, Search } from "@mui/icons-material";
import deleteAlert from '../../../assets/Delete-alert.png'

const DeleteCarriarSectionConfirmationDialog = (props) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className="delete-main-container">
                        <Grid item xs={12} sx={{ paddingRight: "15px" }} textAlign={"end"}>
                            <Close sx={{ cursor: "pointer" }} onClick={() => { props.setFlagDeleteCarriarSectionDialog(false) }} />
                        </Grid>
                        <Grid item xs={12} className="delete-alert-item">
                            <img src={deleteAlert}></img>
                        </Grid>
                        <Grid item xs={12} className="delete-mesg-item">
                            Are you sure you want to delete this section?
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: "center", gap: "10px" }} >

                                <Grid item xs={2.5}>
                                    <Grid container sx={{ justifyContent: "end" }}>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                onClick={() => { props.setFlagDeleteCarriarSectionDialog(false) }}
                                            >No
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={2.5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {

                                                }}
                                            >Yes</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default DeleteCarriarSectionConfirmationDialog
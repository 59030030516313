import Axios from "../axios";

export const GetTemplateDataList = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.get("/template/get-template-data-list");
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const GetTemplateData = async (data, onSuccess, onError) => {
  try {
    let apiUrl = data.template_version_id != null ?
      `/template/get-template-data?template_id=${data.template_id}&template_version_id=${data.template_version_id}`
      :
      `/template/get-template-data?template_id=${data.template_id}`
    const response = await Axios.get(apiUrl)
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const CreateTemplate = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/template/create-new-template", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const UpdateTemplate = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.put("/template/update-template-data", data);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const GetCarrierDataList = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.get(`/carrier/get-carrier-data-list?carrier_type_flag=${data}`,
      {
        headers: {
          "Content-Type": "application/json"
          // "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4LCJpYXQiOjE2ODAwMDM2OTMsImV4cCI6MTY4MjU5NTY5M30.tfB0RGsrZHso4kdTPr4PXQB-1b89AXHY7K_mRiDyVzg' 
        }
      })
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const GetCarrierLevelList = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.get(`/carrier/get-carrier-level-list?carrier_id=${data}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const UploadResources = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/carrier/upload-resource", data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const DeleteResources = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.delete(`/carrier/delete-carrier-resource?carrier_id=${data.carrier_id}&carrier_resource_id=${data.carrier_resource_id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};
export const DeleteTemplate = async (template_id, onSuccess, onError) => {
  try {
    const response = await Axios.delete(`/template/delete-template?template_id=${template_id}`);
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};

export const GetTemplateVersionList = async (id, onSuccess, onError) => {
  try {
    const response = await Axios.get(`/template/get-template-version-list?template_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
    onSuccess && onSuccess(response);
  } catch (err) {
    onError && onError(err);
  }
};
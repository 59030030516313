import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    Grid, Typography, Divider, Button, IconButton
} from '@mui/material'

import "../../css/Agency/AgencyCarrierDetails.css"

// COMPONENTS
import CarrierDetials from './CarrierDetails'
import AgencyDetails from './AgencyDetails'
import GlobalDialog from '../../../components/global/GlobalDialog'
import AgencyCheckURLPopUp from './AgencyDialogs/AgencyCheckURLPopUp'

// CONTEXT
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as authContext } from '../../../contexts/reducerContexts/authContext';
import { Context as AuthContext } from "../../../contexts/reducerContexts/authContext";

// API
// import { getAgencyData } from '../../../utils/api/AgencyDetailsAPI'
import { GetCarrierDataList } from '../../../utils/api/TemplateApi';
import {
    createNewAgency, updateAgency,
    addUpdateAgencyCarrier, getAgencyData
} from '../../../utils/api/AgencyDetailsAPI'

import BackIcon from "../../../assets/back-icon.png";
import { useNavigate, useSearchParams } from 'react-router-dom';


const AgencyCarrierDetails = () => {

    const pathName = window.location.pathname
    const navigate = useNavigate()
    const emailRegex = /^([a-zA-Z0-9\._-]+)@([a-zA-Z)-9])+.([a-zA-Z]+)(.[a-zA-Z]+)?$/
    const customUrlRegex = /^[a-z0-9]+([-.a-z0-9]+)?\.[a-z]{2,}(\.[a-z]{2,})?$/

    const { agencyDetails } = useContext(agencyContext).state
    const { userDetail } = useContext(AuthContext).state;
    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")

    const superAdmin = (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")

    const [toggleDetails, setToggleDetails] = useState(true)
    const [checkLogoFavId, setCheckLogoFavId] = useState({
        logoId: null,
        favIconId: null
    })

    const [checkedItems, setCheckedItems] = useState({ life: {}, annuity: {} })
    const [alreadyCheckedKey, setAlreadyCheckedKeys] = useState({ life: [], annuity: [] })
    const [searchCheckBoxes, setSearchCheckBoxes] = useState({ life: [], annuity: [] })
    const [lifeAnnuityCarrierList, setLifeAnuityCarrierList] = useState([])

    const [isDisabled, setIsDisabled] = useState(true)
    const [showAgencyCheckUrlPopUp, setShowAgencyCheckUrlPopUp] = useState(false)
    const [agencyStatus, setAgencyStatus] = useState("")
    const [agencyUpdated, setAgencyUpdated] = useState(false)
    const [customUrlChecked, setCustomUrlChecked] = useState(false)

    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)

    const {
        setAgencyDetails,
        setAgencyCarrieData, //
        setFileSelected,
        setFileUploaded,
        setAgencyFavIcon,
        setFavIconFileUploaded,
        setCarrierListData
    } = useContext(agencyContext)

    const {
        selectedSize,
        agencyFavIcon,
        fileUploaded,
        favIconFileUploaded,
        agencyCarrierData,
    } = useContext(agencyContext).state

    useEffect(() => {
        // const agencyStaus = agencyDetails.agnecy_status_name
        console.log("agencyStaus", agencyStatus)
        if (agencyStatus == "Registered" && pathName == "/agency/view_agency") { setShowAgencyCheckUrlPopUp(true) }
    }, [agencyStatus])

    useEffect(() => {
        const agency_id = searchParams.get("agency_id")
        setCheckedItems({ life: {}, annuity: {} })
        setAlreadyCheckedKeys({ life: [], annuity: [] })
        setShowAgencyCheckUrlPopUp(false)
        setAgencyStatus("")
        setAgencyUpdated(false)

        if (agency_id) {
            FlagApiLoader(true)
            getAgencyData(agency_id,
                (res) => {
                    if (res?.data?.status) {
                        const checkedData = res?.data?.data[0].agency_carrier_data
                        const agencyDetailsData = res?.data?.data[0]
                        setAgencyCarrieData(checkedData)
                        if (res?.data?.data) {
                            setAgencyDetails({
                                ...agencyDetails,
                                agency_name: agencyDetailsData?.agency_name,
                                agency_email_address: agencyDetailsData?.agency_email_address,
                                agency_mag_url: agencyDetailsData?.agency_mag_url.split("-grids.brokersalliance.com")[0],
                                agency_custom_url: agencyDetailsData.agency_custom_url,
                                agency_user_data: agencyDetailsData?.agency_user_data?.map((ele, i) => {
                                    return {
                                        index: i,
                                        master_user_id: ele.master_user_id,
                                        user_id: ele.user_id,
                                        username: ele.username,
                                        email_address: ele.email_address,
                                        user_role_flag: ele.user_role_flag,
                                    }
                                }),
                                agency_logo_gcs_id: agencyDetailsData?.agency_logo_gcs_id,
                                agency_favicon_gcs_id: agencyDetailsData?.agency_favicon_gcs_id,
                                agency_favicon_url: agencyDetailsData?.agency_favicon_url,
                                agency_logo_url: agencyDetailsData?.agency_logo_url,
                                agnecy_status_name: agencyDetailsData?.agnecy_status_name
                            })
                            setAgencyStatus(agencyDetailsData?.agnecy_status_name)
                            setFileUploaded(agencyDetailsData?.agency_logo_name || agencyDetailsData?.agency_logo_size ? true : false)
                            setFileSelected({
                                ...selectedSize,
                                name: agencyDetailsData?.agency_logo_name,
                                size: agencyDetailsData?.agency_logo_size,
                            })
                            setFavIconFileUploaded(agencyDetailsData.agency_favicon_name || agencyDetailsData?.agency_favicon_size ? true : false)
                            setAgencyFavIcon({
                                ...agencyFavIcon,
                                name: agencyDetailsData.agency_favicon_name,
                                size: agencyDetailsData?.agency_favicon_size,
                            })

                            setCheckLogoFavId({
                                ...checkLogoFavId,
                                logoId: agencyDetailsData.agency_logo_gcs_id,
                                favIconId: agencyDetailsData.agency_favicon_gcs_id
                            })

                        }
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                },
                (err) => {
                    console.log("getAgencyData err", err)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.data?.error?.message
                    })
                }
            )
        } else {
            setAgencyCarrieData([])
        }

        //BELOW CALL FOR LIFE ANNUITY CARRIER DATA LIST FOR SUPER ADMIN
        if (superAdmin) {
            FlagApiLoader(true)
            GetCarrierDataList(
                {},
                (res) => {
                    if (res?.data?.status) {
                        const LifeAnnuityCombineData = res?.data?.data
                        setLifeAnuityCarrierList([...LifeAnnuityCombineData])
                        FlagApiLoader(false)
                    } else {
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                },
                (err) => {
                    console.log("GetCarrierDataList err>>>>", err)
                    FlagApiLoader(false)
                    UpdateSnackbarMessage({
                        status: "error",
                        message: err?.data?.error?.message
                    })
                }
            )
        }
    }, [])

    // USEEFFECT TO SORT LIFE AND ANNUITY DATA
    useEffect(() => {
        setCheckedItems({ life: {}, annuity: {} })
        setAlreadyCheckedKeys({ life: [], annuity: [] })
        let life = []
        let annuity = []
        lifeAnnuityCarrierList.map((carrier) => {
            carrier?.carrier_types?.forEach((item) => {
                const checkedItem = agencyCarrierData?.filter(checkedItem => (
                    (checkedItem.carrier_id == item?.carrier_id)
                    &&
                    (checkedItem.carrier_type_flag == item?.carrier_type_flag)
                ))

                if (item?.carrier_type_flag) {
                    life.push({
                        carrier_name: carrier?.carrier_name,
                        carrier_id: item?.carrier_id,
                        carrier_type_flag: item?.carrier_type_flag,
                        checked: checkedItem.length > 0,
                        agency_carrier_id: checkedItem[0]?.agency_carrier_id ? checkedItem[0].agency_carrier_id : null
                    })
                    if (checkedItem.length > 0) {
                        setCheckedItems((prevState) => ({
                            ...prevState,
                            life: {
                                ...prevState.life,
                                [item?.carrier_id]: {
                                    carrier_name: carrier?.carrier_name,
                                    checked: true,
                                    carrier_id: item.carrier_id,
                                    carrier_type_flag: item.carrier_type_flag,
                                    agency_carrier_id: checkedItem[0]?.agency_carrier_id
                                }
                            }
                        }))
                        setAlreadyCheckedKeys((prevState) => ({
                            ...prevState,
                            life: [
                                ...prevState.life,
                                item?.carrier_id
                            ]
                        }))
                    }
                }
                else {
                    annuity.push({
                        carrier_name: carrier?.carrier_name,
                        carrier_id: item?.carrier_id,
                        carrier_type_flag: item?.carrier_type_flag,
                        checked: checkedItem.length > 0,
                        agency_carrier_id: checkedItem[0]?.agency_carrier_id ? checkedItem[0].agency_carrier_id : null
                    })
                    if (checkedItem.length > 0) {
                        setCheckedItems((prevState) => ({
                            ...prevState,
                            annuity: {
                                ...prevState.annuity,
                                [item?.carrier_id]: {
                                    carrier_name: carrier?.carrier_name,
                                    checked: true,
                                    carrier_id: item.carrier_id,
                                    carrier_type_flag: item.carrier_type_flag,
                                    agency_carrier_id: checkedItem[0]?.agency_carrier_id
                                }
                            }
                        }))
                        setAlreadyCheckedKeys((prevState) => ({
                            ...prevState,
                            annuity: [
                                ...prevState.annuity,
                                item?.carrier_id
                            ]
                        }))
                    }
                }
            })
        })

        setCarrierListData({
            life: life,
            annuity: annuity
        })
        setSearchCheckBoxes({
            life: life,
            annuity: annuity
        })

    }, [agencyCarrierData, lifeAnnuityCarrierList])

    useEffect(() => {
        const checkValidation = () => {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(agencyDetails.agency_email_address);
            const isValidAdminEmails = agencyDetails?.agency_user_data?.every(admin => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(admin.email_address));
            const isValidCustomUrl = customUrlRegex.test(agencyDetails.agency_custom_url)
            const isEmptyCustomUrl = agencyDetails.agency_custom_url == "" && agencyDetails.agency_custom_url == null
            // console.log("(isValidCustomUrl) || (!customUrlChecked) || (customUrlChecked && isEmptyCustomUrl)", isValidCustomUrl, !customUrlChecked, (customUrlChecked && isEmptyCustomUrl))
            const isValidAgency = (
                agencyDetails.agency_name &&
                ((isValidCustomUrl) || (!customUrlChecked) || (customUrlChecked && isEmptyCustomUrl)) &&
                isValidEmail &&
                isValidAdminEmails &&
                agencyDetails.agency_user_data.every(admin => admin.username && admin.email_address)
            );
            setIsDisabled(!isValidAgency);
        };
        checkValidation();
    }, [agencyDetails, customUrlChecked]);

    const handleSubmitAgency = (e) => {
        let inValidEmailFound = false;

        if (!emailRegex.test(agencyDetails?.agency_email_address)) {
            UpdateSnackbarMessage({ status: "error", message: "Invalid email address" });
            return;
        }

        for (let user of agencyDetails?.agency_user_data) {
            // debugger;
            if (!emailRegex.test(user.email_address)) {
                UpdateSnackbarMessage({ status: "error", message: "Invalid email address in table" });
                inValidEmailFound = true
                break;
            }
        }

        if (inValidEmailFound) {
            return;
        }

        if (agencyId) {
            let agencyUpdatePayload = {
                agency_id: agencyId,
                agency_email_address: agencyDetails?.agency_email_address,
                agency_mag_url: agencyDetails?.agency_mag_url + "-grids.brokersalliance.com",
                agency_custom_url: agencyDetails.agency_custom_url,
                agency_logo_gcs_id: agencyDetails.agency_logo_gcs_id,
                agency_favicon_gcs_id: agencyDetails.agency_favicon_gcs_id,
                agency_user_data: agencyDetails?.agency_user_data?.map((admin) => ({
                    username: admin?.username,
                    email_address: admin?.email_address,
                    user_role_flag: admin?.user_role_flag,
                    master_user_id: admin?.master_user_id ? admin?.master_user_id : null,
                    user_id: admin?.user_id ? admin?.user_id : null,
                })),
            }

            FlagApiLoader(true);
            updateAgency(agencyUpdatePayload,
                (res) => {
                    if (res?.data?.status) {
                        if (res?.data?.data.code == 9056) {
                            console.log("Update Agency Response", res?.data?.data.message)
                            UpdateSnackbarMessage({
                                status: "error",
                                message: res?.data?.data.message,
                            });
                            FlagApiLoader(false)
                        }
                        else {
                            UpdateSnackbarMessage({ status: "success", message: "Agency updated successfully" });
                            const agnecy_status_name = res?.data?.data[0].agnecy_status_name
                            FlagApiLoader(false);
                            setToggleDetails(true)
                            if (agnecy_status_name == "Registered") { setShowAgencyCheckUrlPopUp(true) }
                            setAgencyUpdated(true)
                        }
                        // setAgencyDetails(res?.data?.data?.[0])
                        // navigate('/agency')
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                            FlagApiLoader(false)
                        }
                        else {
                            UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                            FlagApiLoader(false)
                        }
                    }
                },
                (err) => {
                    console.log("error", err);
                    FlagApiLoader(false);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!",
                    });
                    navigate('/agency')
                })
        }
        else {
            let agencyPayload = {
                agency_name: agencyDetails?.agency_name,
                agency_email_address: agencyDetails?.agency_email_address,
                agency_mag_url: agencyDetails?.agency_mag_url + "-grids.brokersalliance.com",
                agency_custom_url: agencyDetails.agency_custom_url,
                agency_logo_gcs_id: agencyDetails.agency_logo_gcs_id,
                agency_favicon_gcs_id: agencyDetails.agency_favicon_gcs_id,
                agency_user_data: agencyDetails?.agency_user_data?.map((admin) => ({
                    username: admin?.username,
                    email_address: admin?.email_address,
                    user_role_flag: admin?.user_role_flag
                })),
            }

            FlagApiLoader(true);
            createNewAgency(agencyPayload,
                (res) => {
                    console.log("create agency response", agencyPayload);
                    if (res?.data?.status) {
                        UpdateSnackbarMessage({
                            status: "success",
                            message: "Agency Created Succesfully",
                        });
                        setCheckLogoFavId({
                            ...checkLogoFavId,
                            logoId: res?.data?.data[0].agency_logo_gcs_id,
                            favIconId: res?.data?.data[0].agency_favicon_gcs_id,
                        })
                        setAgencyDetails(res?.data?.data?.[0])
                        setAgencyStatus(res?.data?.data?.[0].agnecy_status_name)
                        // navigate(`/agency/view_agency?agency_id=${res?.data?.data[0]?.agency_id}`)
                        setShowAgencyCheckUrlPopUp(true)
                        // setToggleDetails(false)
                        FlagApiLoader(false);
                    }
                    else {
                        if (res?.data?.error?.message) {
                            UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                            FlagApiLoader(false)
                        }
                        else {
                            UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                            FlagApiLoader(false)
                        }
                    }
                },
                (err) => {
                    console.log("create agency error", err)
                    FlagApiLoader(false);
                    UpdateSnackbarMessage({
                        status: "error",
                        message: "Something Went Wrong!",
                    });
                    navigate('/agency')
                })
        }
    }

    // Carrie Details Save Button Validation
    const disableSaveCarrierButton = () => {
        const checkedLifItemsKey = Object.keys(checkedItems.life).sort()
        const checkedAnnuityItemsKey = Object.keys(checkedItems.annuity).sort()
        const alreadyLifeCheckedItemsKeys = alreadyCheckedKey.life.sort()
        const alreadyAnnuityCheckedItemsKeys = alreadyCheckedKey.annuity.sort()

        if ((checkedLifItemsKey.length != alreadyLifeCheckedItemsKeys.length) && (checkedLifItemsKey.length != 0)
            || (checkedAnnuityItemsKey.length != alreadyAnnuityCheckedItemsKeys.length) && (checkedAnnuityItemsKey.length != 0)) {
            return false
        }
        else {
            if (checkedAnnuityItemsKey.length == 0 && checkedLifItemsKey.length == 0) {
                return true
            } else {
                for (let j = 0; j < alreadyAnnuityCheckedItemsKeys.length; j++) {
                    if (parseInt(checkedAnnuityItemsKey[j]) != alreadyAnnuityCheckedItemsKeys[j]) {
                        return false
                    }
                }
                for (let j = 0; j < alreadyLifeCheckedItemsKeys.length; j++) {
                    if (parseInt(checkedLifItemsKey[j]) != alreadyLifeCheckedItemsKeys[j]) {
                        return false
                    }
                }
            }
            return true
        }

    }

    const handleSaveCarrierDetails = () => {
        const checkedCarriers = Object.keys(checkedItems).reduce((arr, key) => {
            const checkedItemArray = Object.keys(checkedItems[key]).reduce((acc, item) => {
                if (checkedItems[key][item].checked == true) {
                    const payLoadItme = checkedItems[key][item]
                    acc.push({
                        carrier_id: payLoadItme.carrier_id,
                        carrier_type_flag: payLoadItme.carrier_type_flag,
                        // check if  agency_carrier_id==null, if its null the don't add agency_carrier_id 
                        ...(payLoadItme.agency_carrier_id && { agency_carrier_id: payLoadItme.agency_carrier_id })
                    })
                }
                return acc
            }, [])
            return [...arr, ...checkedItemArray]
        }, [])

        const agency_id = searchParams.get("agency_id")
        const payLoad = {
            agency_id: agency_id,
            agency_carrier_data: checkedCarriers
        }

        FlagApiLoader(true)
        addUpdateAgencyCarrier(
            payLoad,
            (res) => {
                if (res.data.status) {
                    getAgencyData(
                        agency_id, (res) => {
                            if (res?.data?.status) {
                                const checkedData = res?.data?.data[0].agency_carrier_data
                                setAgencyCarrieData(checkedData)
                                FlagApiLoader(false)
                            } else {
                                if (res?.data?.error?.message) {
                                    UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                                    FlagApiLoader(false)
                                }
                                else {
                                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                                    FlagApiLoader(false)
                                }
                            }
                        }, (err) => {
                            UpdateSnackbarMessage({
                                status: "error",
                                message: err?.data?.error?.message,
                            })
                        })
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message,
                    })
                } else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                }

            },
            (err) => {
                console.log("handleSaveCarrier err", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message,
                });
            }
        )
    }

    const handleCheckURLPopUp = () => {
        // debugger
        if (pathName == "/agency/add_agency") {
            navigate(`/agency/view_agency?agency_id=${agencyDetails?.agency_id}`)
            setShowAgencyCheckUrlPopUp(false)
            setToggleDetails(false)
        } else if (agencyUpdated) {
            // navigate('/agency')
            setShowAgencyCheckUrlPopUp(false)
            setAgencyUpdated(false)
        } else {
            setShowAgencyCheckUrlPopUp(false)
        }
    }
    return (
        <>
            <GlobalDialog
                className={"agc-checkurlpop"}
                open={showAgencyCheckUrlPopUp}
                data={<AgencyCheckURLPopUp
                    magurl={agencyDetails.agency_mag_url}
                    customurl={agencyDetails.agency_custom_url}
                    agencyId={agencyId}
                    handleCheckURLPopUp={handleCheckURLPopUp}
                />}
            // onClose={() => handleCheckURLPopUp()}
            />

            <Grid container sx={{ height: "100%" }}>
                <Grid item xs={12} sx={{ height: "calc(100vh - 79px)" }} className='main-item-1'>
                    <Grid container sx={{ height: "100%" }}>

                        <Grid item xs={12} sx={{ marginTop: "21px" }}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item xs={6}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        {
                                            superAdmin &&
                                            <IconButton
                                                sx={{ padding: "0px 10px 0px 0px", cursor: "pointer" }}
                                                onClick={() => navigate("/agency")}
                                            >
                                                <img src={BackIcon} alt="Logo" style={{ height: '24px', width: 'auto' }} />
                                            </IconButton>
                                        }
                                        <Typography
                                            variant='span'
                                            className='ag-header-main-typo'
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => navigate("/agency")}
                                        >
                                            {agencyId ? agencyDetails.agency_name : "Agency"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {/* HEADER RIGHT CONTENT */}
                                <Grid item xs={6}></Grid>
                            </Grid>
                        </Grid>

                        {/* DIVIDER */}
                        <Grid item xs={12} sx={{ marginTop: "15px" }} >
                            <Divider />
                        </Grid>

                        <Grid item xs={12} className='agc-details-center-griditem'>
                            <Grid container className={'agency-details-container'}>
                                <Grid item md={agencyId ? 3 : 0.5} lg={agencyId ? 3 : 2} xl={2}>
                                    {
                                        pathName == "/agency/view_agency" &&
                                        <Grid container className='agc-toggle-button-container'>
                                            <Grid item xs={12}>
                                                <Button
                                                    className={toggleDetails ? "agc-selcted-toggle-button" : "agc-unselcted-toggle-button"}
                                                    fullWidth
                                                    variant='text'
                                                    onClick={() => setToggleDetails(true)}
                                                >
                                                    Agency Details
                                                </Button>

                                            </Grid>

                                            {  // THIS CONDITION IS TO HIDE CARRIER DETAILS BUTTON IN SUB-AGENCY
                                                superAdmin &&
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        className={!toggleDetails ? "agc-selcted-toggle-button" : "agc-unselcted-toggle-button"}
                                                        variant='text'
                                                        onClick={() => setToggleDetails(false)}
                                                    >
                                                        Carrier Details
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item md={agencyId ? 9 : 11} lg={agencyId ? 9 : 8} xl={8} className='agc-details-comp-item'>
                                    <Grid container className='agc-details-center-comp-container'>

                                        <Grid item xs={12} sx={{ height: "100%" }}>
                                            {
                                                toggleDetails ?
                                                    <AgencyDetails
                                                        toggleDetails={toggleDetails}
                                                        checkLogoFavId={checkLogoFavId}
                                                        customUrlChecked={customUrlChecked}
                                                        setToggleDetails={setToggleDetails}
                                                        setCheckLogoFavId={setCheckLogoFavId}
                                                        setCustomUrlChecked={setCustomUrlChecked}
                                                    />
                                                    :
                                                    <CarrierDetials
                                                        checkedItems={checkedItems}
                                                        searchCheckBoxes={searchCheckBoxes}
                                                        alreadyCheckedKey={alreadyCheckedKey}
                                                        setCheckedItems={setCheckedItems}
                                                        setSearchCheckBoxes={setSearchCheckBoxes}
                                                    />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={agencyId ? 0 : 0.5} lg={agencyId ? 0 : 2} xl={2}></Grid>

                                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                                    <Grid container className='agc-button-container'>
                                        {
                                            superAdmin &&
                                            <Button
                                                size='large'
                                                variant="outlined"
                                                sx={{ width: "140px", fontWeight: "500 !important" }}
                                                onClick={() => navigate('/agency')}
                                            >
                                                Cancel
                                            </Button>
                                        }

                                        {
                                            toggleDetails ?
                                                <Button
                                                    size='large'
                                                    variant="contained"
                                                    sx={{ width: "140px", fontWeight: "400 !important" }}
                                                    disabled={isDisabled}
                                                    onClick={(e) => handleSubmitAgency(e)}
                                                >
                                                    {agencyId ? "Save" : "Create Agency"}
                                                </Button>
                                                :
                                                <Button
                                                    size='large'
                                                    variant="contained"
                                                    sx={{ width: "140px", fontWeight: "400 !important" }}
                                                    disabled={disableSaveCarrierButton()}
                                                    onClick={() => handleSaveCarrierDetails()}
                                                >
                                                    Save
                                                </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyCarrierDetails
import React, { useContext } from 'react'
import {
    Grid, Typography, Divider,
    FormControl, Button, Checkbox,
    InputBase, FormGroup, FormControlLabel
} from '@mui/material'
import styled from '@emotion/styled'

import "../../css/Agency/CarrierDetails.css"

import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";

// Context
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';

const Search = styled.div`
  border-radius: 5px;
  background-color: #efeff0;
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("xs")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: 8px;
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: 8px;
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

function CarrierDetials({ searchCheckBoxes, checkedItems, setCheckedItems, setSearchCheckBoxes }) {

    const { carrierListData } = useContext(agencyContext).state

    const handleSelectAll = (section) => {

        const allItemsCheck = {}
        searchCheckBoxes[section].forEach(item => {
            allItemsCheck[item.carrier_id] = {
                carrier_name: item.carrier_name,
                checked: true,
                carrier_id: item.carrier_id,
                carrier_type_flag: item.carrier_type_flag,
                agency_carrier_id: item.agency_carrier_id
            }
        })
        setCheckedItems(prevState => ({
            ...prevState,
            [section]: allItemsCheck
        }))

    }

    const handleSelectNone = (section) => {
        if (section === "life") {
            setCheckedItems({
                life: {},
                annuity: checkedItems.annuity
            })
        } else {
            setCheckedItems({
                life: checkedItems.life,
                annuity: {}
            })
        }
    }

    const handleSearchBar = (query) => {
        const life = carrierListData.life.filter(
            item => item.carrier_name.toLowerCase().includes(query.toLowerCase())
        )
        const annuity = carrierListData.annuity.filter(
            item =>
                item.carrier_name.toLowerCase().includes(query.toLowerCase())
        )
        setSearchCheckBoxes({
            life: life,
            annuity: annuity
        })
    }

    return (
        <Grid container className='cd-main-container'>
            <Grid item xs={12} className='cd-main-item-container'>
                <Grid container className='cd-comp-container'>
                    {/* Carrier List Header */}
                    <Grid item xs={12} >
                        <Grid container alignItems={"center"}>
                            <Grid item xs={6}>
                                <Typography className='ag-details-carrierlist-Header-Typo'>
                                    Carrier List
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justifyContent={"end"}>
                                    <Grid item xs={6}>
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <Input
                                                placeholder={"Search"}
                                                onChange={(e) => handleSearchBar(e.target.value)}
                                            />
                                        </Search>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Divider */}
                    <Grid item xs={12} sx={{ marginTop: "18px" }}>
                        <Divider sx={{ width: "100%" }} />
                    </Grid>

                    {/* Carrier List */}
                    <Grid item xs={12} className='cd-checkbox-container-item'>
                        <Grid container className='cd-carrier-list-container'>

                            {/*  Life Header & CheckBoxes Section */}
                            <Grid item xs={12} className='cd-life-checkbox-item'>
                                <Grid container sx={{ height: "100%" }}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={"center"}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontWeight: 600 }} >
                                                    Life
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid
                                                    container
                                                    sx={{ justifyContent: "end", gap: "10px" }}
                                                >
                                                    <Button
                                                        className='cd-select-all-none-button'
                                                        variant='outlined'
                                                        onClick={() => handleSelectNone("life")}
                                                    >
                                                        Select None
                                                    </Button>
                                                    <Button
                                                        className='cd-select-all-none-button'
                                                        variant='outlined'
                                                        onClick={() => handleSelectAll("life")}
                                                    >
                                                        Select All
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: "8px" }} className='cd-checkboxes-griditem'>

                                        <Grid container className='cd-checkboxes-container'>
                                            {searchCheckBoxes?.life?.length !== 0 ?
                                                searchCheckBoxes?.life?.map((row, index) => {
                                                    return (
                                                        <Grid item xs={3} md={4} lg={3} xl={3} textAlign="left">
                                                            <FormControl component="fieldset" variant="standard" sx={{ width: "100%" }}>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        className='cd-formcontrol-label'
                                                                        control={
                                                                            <Checkbox
                                                                                sx={{ padding: "4px", width: "31px" }}
                                                                                name={row.label}
                                                                                checked={checkedItems?.life[row.carrier_id]?.checked || false}
                                                                                onChange={() => {
                                                                                    const isChecked = checkedItems?.life[row.carrier_id]
                                                                                    if (isChecked) {
                                                                                        const deleteCheckedItem = { ...checkedItems }
                                                                                        delete deleteCheckedItem?.life[row.carrier_id]
                                                                                        setCheckedItems(deleteCheckedItem)
                                                                                    } else {
                                                                                        setCheckedItems(prevState => ({
                                                                                            ...prevState,
                                                                                            life: {
                                                                                                ...prevState.life,
                                                                                                [row.carrier_id]: {
                                                                                                    carrier_name: row.carrier_name,
                                                                                                    checked: true,
                                                                                                    carrier_id: row?.carrier_id,
                                                                                                    carrier_type_flag: row?.carrier_type_flag,
                                                                                                    agency_carrier_id: row?.agency_carrier_id
                                                                                                }
                                                                                            }
                                                                                        }))
                                                                                    }
                                                                                }
                                                                                }
                                                                            />}
                                                                        label={row.carrier_name}
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    );
                                                })
                                                :
                                                <Grid item>
                                                    <Typography>
                                                        No Data Found
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Divider */}
                            <Grid item xs={12} className='cd-checkbox-divider'>
                                <Divider />
                            </Grid>

                            {/*  Annuity Header & CheckBoxes Section */}
                            <Grid item xs={12} className='cd-annuity-checkbox-item'>
                                <Grid container sx={{ height: "100%" }}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems={"center"}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{ fontSize: "16px", fontWeight: 600 }} >
                                                    Annuity
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid
                                                    container
                                                    sx={{ justifyContent: "end", gap: "10px" }}
                                                >
                                                    <Button
                                                        className='cd-select-all-none-button'
                                                        variant='outlined'
                                                        onClick={() => handleSelectNone("annuity")}
                                                    >
                                                        Select None
                                                    </Button>
                                                    <Button
                                                        className='cd-select-all-none-button'
                                                        variant='outlined'
                                                        onClick={() => handleSelectAll("annuity")}
                                                    >
                                                        Select All
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/*  */}
                                    <Grid item xs={12} sx={{ marginTop: "8px" }} className='cd-checkboxes-griditem' >
                                        <Grid container className='cd-checkboxes-container'>
                                            {searchCheckBoxes?.annuity?.length !== 0 ?
                                                searchCheckBoxes?.annuity?.map((row) => {
                                                    return (
                                                        <Grid item xs={3} md={4} lg={3} xl={3} textAlign="left">
                                                            <FormControl component="fieldset" variant="standard" sx={{ width: "100%" }}>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        className='cd-formcontrol-label'
                                                                        control={<Checkbox
                                                                            sx={{ padding: "4px" }}
                                                                            name={row.label}
                                                                            checked={checkedItems?.annuity[row.carrier_id]?.checked || false}
                                                                            onChange={() => {
                                                                                const isChecked = checkedItems?.annuity[row.carrier_id]
                                                                                if (isChecked) {
                                                                                    const deleteCheckedItem = { ...checkedItems }
                                                                                    delete deleteCheckedItem?.annuity[row.carrier_id]
                                                                                    setCheckedItems(deleteCheckedItem)
                                                                                } else {
                                                                                    setCheckedItems(prevState => ({
                                                                                        ...prevState,
                                                                                        annuity: {
                                                                                            ...prevState.annuity,
                                                                                            [row.carrier_id]: {
                                                                                                carrier_name: row.carrier_name,
                                                                                                checked: true,
                                                                                                carrier_id: row?.carrier_id,
                                                                                                carrier_type_flag: row?.carrier_type_flag,
                                                                                                agency_carrier_id: row?.agency_carrier_id
                                                                                            }
                                                                                        }
                                                                                    }))
                                                                                }
                                                                            }
                                                                            }
                                                                        />}
                                                                        label={row.carrier_name}
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    );
                                                })
                                                :
                                                <Grid item>
                                                    <Typography>
                                                        No Data Found
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export default CarrierDetials;
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button as MuiButton,
  TextField as MuiTextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import OtpInput from "react18-input-otp";

import useAuth from "../../hooks/useAuth";
import { constructStyleTagsFromChunks } from "@emotion/server";
import { Context as AuthContext } from "../../contexts/reducerContexts/authContext";
import { LoginApi, SendOtpApi } from "../../utils/api/userDetailApi";
import EditIcon from "../../assets/editIcon.png";
import myAdvisorLogo from "../../assets/myAdvisorLogo.png";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);
const LogoComponent = () => (
  <img
    src={EditIcon}
    alt="Logo"
    width="14px"
    height="14px"
  // text-align="center"
  // justify-content="center"
  />
);

const recapchaStyle = {
  "@media screen and (max-width:450px)": {
    transform: "scale(0.85)",
    // transformOrigin:"0 0"
  }
}

function SignIn() {
  const {
    UpdateUserDetail,
    UpdateSnackbarMessage,
    LoginContextApi,
    FlagApiLoader,
    UpdateFlagAuthenticated,
  } = useContext(AuthContext);
  const { ssoErrorMessage } = useContext(AuthContext).state

  const navigate = useNavigate();

  const [flagOtp, setflagOtp] = useState(false);
  const [flagResend, setFlagResend] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [otp, setOtp] = useState("");

  const handleFlagOtp = () => {
    setflagOtp(false);
  };

  // const handleVerify = () => {
  //   // Verify the OTP here
  //   setIsVerified(true);
  // };

  const captchaRef = useRef(null);

  const resendOTP = async (values) => {
    setMinutes(1);
    setSeconds(59);
    setFlagResend(true);
    setOtp("");
    // const token = await handleRecaptcha();
    var data = {
      email_address: values.email,
      resent_flag: true,
      re_captcha: values.recaptcha,
    };
    FlagApiLoader(true);
    await SendOtpApi(
      data,
      (res) => {
        FlagApiLoader(false);
        console.log(res);
        if (res.data.status) {
          UpdateSnackbarMessage({
            status: "success",
            message: res.data.data.message,
          });
          setMinutes(1);
          setSeconds(59);
        } else {
          console.log("response false of SendOtpApi api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
        // if (res.status) {
        // }
      },
      (err) => {
        FlagApiLoader(false);
        console.log("error of SendOtpApi api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something Went Wrong!"
        });
      }
    );

    console.log("resendOTP", values);
  };
  // Implementation of V3 ReCaptcha
  // const handleRecaptcha = async () => {
  //   const token = await window.grecaptcha.execute(
  //     "6Ld8kQIlAAAAAGh0D5RRKeQD08Y1eY0yZiUteFwM",
  //     { action: "login" }
  //   );
  //   console.log("tokenn", token);
  //   // setToken(token);
  //   return token;
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      {!flagOtp ? (
        <>
          <Grid container sx={{ width: "100%" }} justifyContent="center">
            <Grid item textAlign={"center"}>
              {/* <Typography
                component="h1"
                variant="h4"
                align="center"
                gutterBottom
              > */}
              <img src={myAdvisorLogo} height={"55vh"} width={"90%"} style={{ marginBottom: "20px" }} />
              {/* </Typography> */}
              <Typography component="h2" variant="body1" align="center">
                Sign in to your account to continue
              </Typography>
              {ssoErrorMessage?.length ?
                <Alert sx={{ marginTop: "10px" }} severity="warning">{ssoErrorMessage}</Alert>
                : ""
              }

            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Authenticate your account
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Please enter the verification code
          </Typography>
        </>
      )}
      <Formik
        initialValues={{
          email: "",
          remember: false,
          submit: false,
          recaptcha: "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          otp: Yup.string().min(6),
          // password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (!flagOtp) {
            // const token = await handleRecaptcha();
            // const token = captchaRef.current.getValue();
            // console.log("tokennn11111", token);
            var data = {
              email_address: values.email,
              resent_flag: flagResend,
              re_captcha: values.recaptcha,
            };

            await SendOtpApi(
              data,
              (res) => {
                console.log(res);
                if (res.data.status) {
                  setflagOtp(true);
                  UpdateSnackbarMessage({
                    status: "success",
                    message: res.data.data.message,
                  });
                  setSubmitting(false);
                  setMinutes(1);
                  setSeconds(59);
                } else {
                  setErrors({ submit: res.data.error.message });
                  window.grecaptcha.reset()
                  setSubmitting(false);
                }
                // if (res.status) {
                // }
              },
              (err) => {
                console.log(err);
                window.grecaptcha.reset()
              }
            );
          } else {
            var data = {
              email: values.email,
              authentication_code: otp,
              remember_me_flag: values.remember,
            };

            // FlagApiLoader(true);
            await LoginApi(
              data,
              (res) => {
                UpdateFlagAuthenticated(res.data.status);
                if (res.data.status) {
                  UpdateUserDetail(res?.data?.data);
                  setSubmitting(false);
                } else {
                  setErrors({ submit: res.data.error.message });
                  setSubmitting(false);
                  console.log("response false of LoginApi api", res);
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!"
                    });
                  }
                }
                // FlagApiLoader(false);
              },
              (err) => {
                console.log(err);
                // FlagApiLoader(false);
                setErrors({ submit: "Something went worng!" });
                console.log("error of LoginApi api", err);
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
            );
            // LoginContextApi({
            //   email: values.email,
            //   authentication_code: otp,
            //   remember_me_flag: values.remember,
            // });
          }
          console.log("onSubmit : ", values);
          // captchaRef.current.reset();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          // console.log("onSubmit : ", values);
          return (
            <>
              {isSubmitting && (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              )}

              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>

                    {errors.submit && (
                      <Alert mt={2} mb={3} severity="warning">
                        {errors.submit}
                      </Alert>
                    )}

                    <TextField
                      align="center"
                      type="email"
                      name="email"
                      variant="standard"
                      label="Email Address"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={flagOtp}
                      my={2}
                      mt={8}
                      mb={5}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleFlagOtp}>
                              {flagOtp ? <LogoComponent /> : null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {!flagOtp && (
                      <Grid container justifyContent="center">
                        <Grid item sx={{
                          transform: { xs: "scale(0.8)", sm: "scale(1)", md: "scale(1)", lg: "scale(1)", xl: "scale(1)" },
                          "@media screen and (max-width:400px)": {
                            transform: "scale(0.7)",
                          }
                        }}>

                          <ReCAPTCHA
                            sitekey="6Lfz0iAlAAAAACBpmvXHhGQvM19xZ8CR8Q7goMuD"
                            // ref={captchaRef}
                            style={recapchaStyle}
                            onChange={(value) =>
                              setFieldValue("recaptcha", value)
                            }
                          />

                        </Grid>
                      </Grid>
                    )}

                    {flagOtp && (
                      <>
                        <Typography
                          component="h2"
                          sx={{ marginTop: "8px", color: "#7A7A7A" }}
                          variant="body1"
                        >
                          <p
                            style={{
                              padding: "0px",
                              margin: "18px 0px 0px 0px",
                            }}
                          >
                            Verification Code
                          </p>
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              fontSize: "11px",
                            }}
                          >
                            Sent to {values.email}
                          </p>
                        </Typography>
                        <Field name="otp">
                          {({ field }) => (
                            <OtpInput
                              {...field}
                              value={otp}
                              // id="otp"
                              onChange={(value) => {
                                console.log("onSubmit  vaaa: ", value);
                                setOtp(value);
                              }}
                              numInputs={6}
                              inputStyle={{
                                fontSize: "16px",
                                width: "100%",
                                "@media screen and (maxWidth: 450px)": {
                                  width: "25px !important",
                                },
                                height: "34px",
                                margin: "4px",
                                borderTop: "0px",
                                borderLeft: "0px",
                                borderRight: "0px",
                                outline: "none",
                                textAlign: "center",
                                justifyContent: "center",
                                // borderColor: showWarning ? "red" : "#000a46",
                              }}
                              containerStyle={{
                                // margin: "0px auto 0px",
                                // padding: "10px"
                                justifyContent: "left",
                                textAlign: "left",
                              }}
                              isInputNum
                            />
                          )}
                        </Field>

                        {/* <OtpInput
                          {...field}
                            value={otp}
                            // id="otp"
                            onChange={value => setOtp(value)}
                            numInputs={6}
                            inputStyle={{
                              fontSize: "16px",
                              width: "100%",
                              "@media screen and (maxWidth: 450px)": {
                                width: "25px !important",
                              },
                              height: "34px",
                              margin: "4px",
                              borderTop: "0px",
                              borderLeft: "0px",
                              borderRight: "0px",
                              outline: "none",
                              textAlign: "center",
                              justifyContent: "center",
                              // borderColor: showWarning ? "red" : "#000a46",
                            }}
                            containerStyle={{
                              // margin: "0px auto 0px",
                              // padding: "10px"
                              justifyContent: "left",
                              textAlign: "left",
                            }}
                            isInputNum
                          /> */}
                        {/* <Grid direction="columns">
                            <Grid item>
                              <Field type="checkbox" name="toggle" />
                            </Grid>
                            <Grid item>
                              <Typography>
                                Remember this device for 30 days
                              </Typography>
                            </Grid>
                          </Grid> */}
                        {/* <Field
                            type="checkbox"
                            name="myFormikName"
                            as={FormControlLabel}
                            control={<Checkbox />}
                            label="This will trigger my formik name field"
                          /> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="remember"
                              checked={values.remember}
                              color="primary"
                              onChange={handleChange}
                            />
                          }
                          label="Remember this device for 30 days"
                        />
                      </>
                    )}

                    {!flagOtp ? (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        mt={3}
                      >
                        Continue
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={otp.length < 6 ? true : false}
                        mt={5}
                        mb={1}
                      >
                        Login
                      </Button>
                    )}

                    {flagOtp && (
                      <>
                        <Grid
                          className="countdown-text"
                          sx={{ fontWeight: "600" }}
                          align="center"
                        >
                          {seconds > 0 || minutes > 0 ? (
                            <>
                              <a style={{ fontSize: "12px" }}>
                                {" "}
                                Haven’t received code? Resend Code in{" "}
                              </a>
                              <a
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(30, 136, 229, 1)",
                                }}
                              >
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </a>
                            </>
                          ) : (
                            <>
                              <a style={{ fontSize: "12px" }}>
                                Haven't recieved code?
                              </a>
                              <Button
                                disabled={seconds > 0 || minutes > 0}
                                color="primary"
                                sx={{ fontSize: "12px" }}
                                onClick={() => resendOTP(values)}
                              >
                                Resend OTP
                              </Button>
                            </>
                          )}
                        </Grid>
                      </>
                    )}

                  </Grid>
                </Grid>
              </form>

            </>
          );
        }}
      </Formik>
    </>
  );
}

export default SignIn;

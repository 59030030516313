import React from 'react'
import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Box,
    MenuItem,
    FormControl,
    Select,
    Button,
    capitalize,
    InputLabel,
    NativeSelect,
    ListItemIcon,
    useMediaQuery,
    Checkbox,
    Dialog
} from "@mui/material";
import "../../css/ViewCarriarStyles/DeletePopupRow.css"
import deleteAlert from "../../../assets/Delete-alert.png"
import { FocusTrap } from "@mui/base";


const DeleteCarrierSectionDialog = (props) => {
    return (
        <FocusTrap open={true} disableEnforceFocus={true}>
            <Dialog
                open={true}
                // sx={{
                //     "& .MuiDialog-container": {
                //         "& .MuiPaper-root": {
                //             width: "100%",
                //             maxWidth: "400px",
                //             borderRadius: "10px"
                //         },
                //     },
                // }}
                container={() => document.getElementById('custom-grid')}
                onClose={() => {
                    props?.setFlagDialogGloballyRender(false)
                    props?.setDeleteRowPopupFlag(false)
                    props?.extraFunction()
                    let hiddenHighLighter = document.getElementById('deleteHighlighter')
                    hiddenHighLighter.style.width = `${0}px`;
                    hiddenHighLighter.style.height = `${0}px`;
                    hiddenHighLighter.style.border = "1px solid transparent"
                    document.body.style.overflow = 'auto';
                    props.setFlagAfterLevelPopoverRender(false)
                    document.getElementById("carrier_view_container").style.overflow = "auto"

                }}
                className='vw-delete-section-dialog'
                disableEnforceFocus={true}
            >
                <Grid container>
                    <Grid item xs={12} sx={{ padding: "15px" }}>
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign: "center", margin: "15px 0px" }}>
                                <img src={deleteAlert} alt="del img" style={{ width: "60px", height: "auto" }} />
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "center", margin: "20px 0px" }}>
                                <Typography component={'span'} sx={{ fontSize: "16px", fontWeight: "600" }}>
                                    Are you sure you want to delete this {props.sectionOrRow} ?
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: "center", margin: "10px 0px 15px" }}>
                                <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                    <Grid item sx={{ textAlign: "end", }}>
                                        <Button
                                            sx={{ width: "100px", maxWidth: "100px" }}
                                            fullWidth
                                            variant="contained"
                                            className=""
                                            onClick={() => {
                                                props?.sectionOrRow == "row" ? props?.deleteRowForSection() : props?.deleteSection()
                                                props?.setFlagDialogGloballyRender(false)
                                                props?.setDeleteRowPopupFlag(false)
                                                let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                hiddenHighLighter.style.width = `${0}px`;
                                                hiddenHighLighter.style.height = `${0}px`;
                                                hiddenHighLighter.style.border = "1px solid transparent"
                                                document.body.style.overflow = 'auto';
                                                props.setFlagAfterLevelPopoverRender(false)
                                                document.getElementById("carrier_view_container").style.overflow = "auto"
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button
                                            sx={{ width: "100px", maxWidth: "100px" }}
                                            fullWidth
                                            variant="outlined"
                                            color='error'
                                            className=""
                                            onClick={() => {
                                                props?.setDeleteRowPopupFlag(false)
                                                props?.extraFunction()
                                                props?.setFlagDialogGloballyRender(false)
                                                props?.setDeleteRowPopupFlag(false)
                                                let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                hiddenHighLighter.style.width = `${0}px`;
                                                hiddenHighLighter.style.height = `${0}px`;
                                                hiddenHighLighter.style.border = "1px solid transparent"
                                                document.body.style.overflow = 'auto';
                                                props.setFlagAfterLevelPopoverRender(false)
                                                document.getElementById("carrier_view_container").style.overflow = "auto"
                                            }}
                                        >
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </FocusTrap>
    )
}

export default DeleteCarrierSectionDialog